import React, { useState } from 'react'
import { Button, Col, Divider, Form, Input, message, Radio, Row, Space, TimePicker } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ITestQuestion } from '../../../../../interfaces'
import moment from 'moment'
import { queryClient } from '../../../../../index'
import { QUESTION_BASE_QUESTIONS } from '../../../../../hooks/query/question-bases'
import { useMutation } from 'react-query'
import { createQuestionApi, updateQuestionApi } from '../../../../../api/questions'
import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons'
import styles from './test-question-form.module.scss'
import TestUploadImage from '../test-upload-image/test-upload-image'
import { letters } from '../../../../../lib/letterVariants'
import TextEditor from '../../../../core/text-editor/text-editor'

interface props {
  modalHandler: () => void
  question?: ITestQuestion
}

const TestQuestionForm: React.FC<props> = ({ modalHandler, question }) => {
  const [modalClose, setModalClose] = useState<boolean>(false)
  const close = () => setModalClose(true)
  const open = () => setModalClose(false)

  const { id: testId } = useParams<{ id: string }>()
  const { t } = useTranslation(['translation', 'testing'])
  const [form] = Form.useForm()

  const format = 'mm:ss'

  const onFinish = (fieldValues: any) => {
    const values = {
      ...fieldValues,
      allotted_time: moment(fieldValues['allotted_time']).set({ hours: 0 }).format('HH:mm:ss'),
    }

    const data = new FormData()

    data.append('question', values.question)
    data.append('hint', values.hint)
    data.append('allotted_time', values.allotted_time)
    data.append('question_base_id', values.question_base_id!.toString())
    data.append('correct_answer', `VARIANT_${values.correct_answer}`)

    values.file &&
      values.file.length &&
      data.append('images[question]', values.file[0].originFileObj)

    values.delete_question_image && data.append('delete_images[question]', '1')

    values.variants.forEach((variant: any, index: number) => {
      data.append(`variant_${letters[index].toLowerCase()}`, variant.text)

      variant.delete_variant_image &&
        data.append(`delete_images[variant_${letters[index].toLowerCase()}]`, '1')

      if (variant.file && variant.file.length && !variant.file[0].originFileObj.status) {
        data.append(
          `images[variant_${letters[index].toLowerCase()}]`,
          variant.file[0].originFileObj,
        )
      }
    })

    question && data.append('_method', 'PATCH')
    return question ? update({ data, id: question.id }) : create(data)
  }

  const onSuccess = async () => {
    await queryClient.invalidateQueries([QUESTION_BASE_QUESTIONS, testId])
    message.success(t('responseSuccess'))
    form.resetFields()
    modalClose && modalHandler()
  }
  const onError = () => {
    message.error(t('responseError'))
  }
  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(createQuestionApi, {
    onSuccess,
    onError,
  })
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(updateQuestionApi, {
    onSuccess,
    onError,
  })

  const disableButton = isCreateLoading || isUpdateLoading

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      initialValues={{
        question_base_id: testId,
        variants: Array.from(new Array(4)).map(() => ({ text: '', file: null })),
        ...question,
        correct_answer: question
          ? letters[question.variants.findIndex(variant => variant.id === question.variant_id)]
          : undefined,
        allotted_time: question
          ? moment(question.allotted_time, format)
          : moment().minute(1).seconds(0),
      }}
    >
      <Form.Item hidden name={'id'}>
        <Input />
      </Form.Item>
      <Form.Item hidden name={'question_base_id'}>
        <Input />
      </Form.Item>
      <Form.Item name={'question'} label={t('question')} rules={[{ required: true }]}>
        <TextEditor placeholder={t('questionPlaceholder')} />
      </Form.Item>
      <TestUploadImage
        deleteImageFieldName={'delete_question_image'}
        uploadedImagePath={question?.image}
      />

      <Form.List name={'variants'}>
        {fields => (
          <Row gutter={[32, 32]}>
            {fields.map((field, index) => (
              <Col xs={24} lg={12} key={index}>
                <Form.Item
                  label={t('variant', { variant: letters[index] })}
                  name={[field.name, 'text']}
                  rules={[{ required: true, message: t('required') }]}
                >
                  <TextEditor placeholder={t('variantPlaceholder', { variant: letters[index] })} />
                </Form.Item>

                <TestUploadImage
                  fieldFieldName={[field.name, 'file']}
                  deleteImageFieldName={[field.name, `delete_variant_image`]}
                  uploadedImagePath={question?.variants[index].image}
                />
              </Col>
            ))}
          </Row>
        )}
      </Form.List>
      <Form.Item name={'correct_answer'} label={t('correctAnswer')} rules={[{ required: true }]}>
        <Radio.Group
          options={letters.map(variant => ({
            label: variant,
            value: variant,
          }))}
        />
      </Form.Item>
      <Form.Item
        name={'hint'}
        label={t('testing:questionSolution') + ' (' + t('notRequired') + ')'}
      >
        <TextEditor placeholder={t('testing:questionSolution')} />
      </Form.Item>

      <Form.Item
        name={'allotted_time'}
        label={t('numberOfMinutesToAnswer')}
        rules={[{ required: true }]}
      >
        <TimePicker
          allowClear={false}
          style={{ width: '100%' }}
          format={format}
          showNow={false}
          showHour={false}
          showMinute={true}
          showSecond={true}
          secondStep={10}
        />
      </Form.Item>

      <div className={styles.wrapper}>
        <Space>
          <Button onClick={modalHandler} icon={<CloseCircleOutlined />}>
            {t('close')}
          </Button>
          <Button
            type="primary"
            onClick={open}
            htmlType={'submit'}
            icon={<SaveOutlined />}
            disabled={disableButton}
            loading={disableButton}
          >
            {t('save')}
          </Button>
          <Divider type={'vertical'} />
          <Button
            onClick={close}
            type="primary"
            htmlType={'submit'}
            disabled={disableButton}
            loading={disableButton}
          >
            {t('saveAndClose')}
          </Button>
        </Space>
      </div>
    </Form>
  )
}

export default TestQuestionForm
