import React from 'react'
import { Card, Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface props {}

const Archive: React.FC<props> = () => {
  const { t } = useTranslation()

  return (
    <Row gutter={[32, 32]}>
      <Col xs={24} lg={6}>
        <Link to={'/archive/stuff'}>
          <Card bordered={false}>{t('stuffArchive')}</Card>
        </Link>
      </Col>
    </Row>
  )
}

export default Archive
