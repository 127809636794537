import React from 'react'
import styles from './leads-bid-form.module.scss'
import { Button, Form, Input, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { CommentOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { IOrderBid, IOrderColumn } from '../../../../../../interfaces'
import { idType } from '../../../../../../interfaces/types/types'
import MaskedInput from 'antd-mask-input'
import { v4 as uuid } from 'uuid'
import { useMutation } from 'react-query'
import { addBidApi, editBidApi } from '../../../../../../api/leads'
import { queryClient } from '../../../../../../index'
import { LEADS_KEY } from '../../../../../../hooks/query/leads'
import { AxiosResponse } from 'axios'

interface props {
  bid?: IOrderBid
  card_id?: idType
  handler: Function
}

const LeadsBidForm: React.FC<props> = ({ bid, card_id, handler }) => {
  const { t } = useTranslation(['translation', 'leads'])

  const onSuccess = async (response: AxiosResponse<IOrderBid>) => {
    const columns = queryClient.getQueryData<IOrderColumn[]>(LEADS_KEY)
    queryClient.setQueryData(
      LEADS_KEY,
      columns?.map(column => {
        return {
          ...column,
          cards: column.cards.map(card => {
            if (bid && bid.card_id === card.id) {
              return {
                ...card,
                bids: card.bids.map(b => (b.id === response.data.id ? response.data : b)),
              }
            } else if (!bid && card_id === card.id) {
              return {
                ...card,
                bids: [...card.bids, response.data],
              }
            }
            return card
          }),
        }
      }),
    )
    handler((prevState: boolean) => !prevState)
  }
  const onError = () => {
    message.error(t('responseError'))
  }
  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(addBidApi, {
    onSuccess,
    onError,
  })
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(editBidApi, {
    onSuccess,
    onError,
  })

  const onFinish = (values: IOrderBid) => (bid ? update(values) : create(values))

  return (
    <Form className={styles.form} name={uuid()} initialValues={bid} onFinish={onFinish}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="card_id" initialValue={card_id} hidden>
        <Input />
      </Form.Item>

      <Form.Item name="name" rules={[{ required: true, message: t('required') }]}>
        <Input prefix={<UserOutlined />} autoFocus={true} placeholder={t('inputName')} />
      </Form.Item>

      <Form.Item name="phone" rules={[{ required: true, message: t('required') }]}>
        <MaskedInput
          prefix={<PhoneOutlined />}
          maxLength={9}
          placeholder={t('inputPhone')}
          mask="(11) 111-11-11"
        />
      </Form.Item>
      <Form.Item name="comment">
        <Input prefix={<CommentOutlined />} placeholder={t('inputComment')} />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          block
          htmlType="submit"
          disabled={isCreateLoading || isUpdateLoading}
        >
          {bid ? t('edit') : t('add')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LeadsBidForm
