import React, { useState } from 'react'
import { Button, Form, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../../interfaces/types/types'
import SumInput from '../../../../core/form-group/sum-input/sum-input'
import { useMutation } from 'react-query'
import {
  createCoursePriceHistory,
  updateCoursePriceHistory,
} from '../../../../../api/course-prices'
import { queryClient } from '../../../../../index'
import { COURSE_PRICE_HISTORY_KEY, COURSES_KEY } from '../../../../../hooks/query/courses'
import MyDatePicker from '../../../../core/form-group/my-date-picker/my-date-picker'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'

interface props {
  price?: number
  priceId?: idType
  courseId: idType
}

const CoursePriceHistoryModal: React.FC<props> = ({ price, priceId, courseId }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const modalHandler = () => setVisible(!visible)
  const { t } = useTranslation(['translation', 'course'])

  const onSuccess = async () => {
    await queryClient.invalidateQueries([COURSE_PRICE_HISTORY_KEY, courseId!.toString()])
    await queryClient.invalidateQueries([COURSES_KEY, courseId!.toString()])
    message.success(t('responseSuccess'))
    modalHandler()
  }
  const onError = () => {
    message.error(t('responseError'))
  }
  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(
    createCoursePriceHistory,
    { onSuccess, onError },
  )
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(
    updateCoursePriceHistory,
    { onSuccess, onError },
  )

  const onFinish = (values: any) => {
    priceId
      ? update({ priceId, price: values.price })
      : create({ price: values.price, date: values.date, course_id: courseId })
  }

  const title = priceId ? t('changeThePrice') : t('course:setNewCoursePrice')

  return (
    <>
      <Button
        type={priceId ? 'primary' : 'default'}
        icon={priceId ? <EditOutlined /> : <PlusCircleOutlined />}
        onClick={modalHandler}
      >
        {title}
      </Button>
      <Modal
        visible={visible}
        onCancel={modalHandler}
        title={title}
        okText={t('submit')}
        confirmLoading={isCreateLoading || isUpdateLoading}
        okButtonProps={{ htmlType: 'submit', form: 'course-price-history-form' }}
        destroyOnClose={true}
        centered
      >
        <Form onFinish={onFinish} layout={'vertical'} id={'course-price-history-form'}>
          <SumInput name={'price'} label={t('coursePrice')} rest={{ initialValue: price }} />
          {!priceId && (
            <MyDatePicker
              name={'date'}
              format={'MMMM YYYY'}
              label={'course:validPriceFrom'}
              rest={{
                picker: 'month',
              }}
            />
          )}
        </Form>
      </Modal>
    </>
  )
}

export default React.memo(CoursePriceHistoryModal)
