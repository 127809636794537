import { useTranslation } from 'react-i18next'

export function usePaymentTableFilters() {
  const { t } = useTranslation()

  return [
    {
      text: t('cash'),
      value: 'cash',
    },
    {
      text: t('card'),
      value: 'card',
    },
    {
      text: t('bank'),
      value: 'bank',
    },
  ]
}
