import React from 'react'
import styles from './images-block.module.scss'
import images from './images'

interface props {}

const ImagesBlock: React.FC<props> = () => {
  return (
    <div className={styles.bg}>
      <img
        className={[styles.image, styles.animate__animated, styles.animate__fadeIn].join(' ')}
        src={`${images[Math.trunc(Math.random() * images.length)] || images[0]}`}
        alt=""
      />
    </div>
  )
}

export default React.memo(ImagesBlock)
