import React from 'react'
import { Alert } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  msg: string | undefined
}

const ErrorAlert: React.FC<props> = ({ msg }) => {
  const { t } = useTranslation()

  return <Alert message={t('error')} description={msg} type="error" showIcon />
}

export default ErrorAlert
