import React, { useEffect } from 'react'
import Examination from '../../../app/modules/testing/examination/examination'
import { useHistory, useLocation } from 'react-router-dom'

interface props {}

interface Location {
  state?: {
    confirmed?: boolean
    testId: string
  }
}

const ExaminationPage: React.FC<props> = () => {
  const location: Location = useLocation()
  const { push } = useHistory()
  const { state } = location

  useEffect(() => {
    if (!state || !state.confirmed) {
      push({
        pathname: '/testing/events',
      })
    }
  }, [state, push])

  return (
    <React.Fragment>
      <Examination testId={state!.testId} />
    </React.Fragment>
  )
}

export default ExaminationPage
