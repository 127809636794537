import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { StudentStatusType } from '../../../../interfaces'

interface props {
  balance?: number
  groupStartDate?: string
  added_at?: string
  status: StudentStatusType
  excluded_at?: string | null
  transferred_at?: string | null
}

const StudentStatus: React.FC<props> = ({
  balance,
  groupStartDate,
  added_at,
  status,
  excluded_at,
  transferred_at,
}) => {
  const { t } = useTranslation(['translation', 'groups'])

  const colors = {
    excluded: '#8c8c8c',
    transferred: '#fa8c16',
    debtor: '#f5222d',
    join: '#ffc53d',
    new: '#1890ff',
    active: '#52c41a',
  }

  const checkStatus = () => {
    if (status === 'excluded')
      return (
        <Tag color={colors.excluded}>
          {t('groups:excluded', {
            date: excluded_at ? moment(excluded_at).format('D MMMM') : null,
          })}
        </Tag>
      )
    if (status === 'transferred')
      return (
        <Tag color={colors.transferred}>
          {t('groups:transferred', {
            date: transferred_at ? moment(transferred_at).format('D MMMM') : null,
          })}
        </Tag>
      )
    if (moment(added_at) > moment())
      return (
        <Tag color={colors.join}>
          {t('studentJoins', { date: moment(added_at).format('D MMMM') })}
        </Tag>
      )
    if (
      moment(added_at).month() !== moment(groupStartDate).month() &&
      moment(added_at).month() === moment().month()
    ) {
      return <Tag color={colors.new}>{t('newStudent')}</Tag>
    }
    if (balance && balance < 0) return <Tag color={colors.debtor}>{t('groups:debtorStudent')}</Tag>

    return <Tag color={colors.active}>{t('groups:activeStudent')}</Tag>
  }

  return <>{checkStatus()}</>
}

export default React.memo(StudentStatus)
