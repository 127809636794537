import React from 'react'
import { Button, Card, Form, Input, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { addNewStudentPaymentApi } from '../../../../../api/payments'
import SumInput from '../../../../core/form-group/sum-input/sum-input'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../../index'
import { GROUPS_FINANCES_KEY } from '../../../../../hooks/query/groups'
import FormPaymentMethods from '../../../../core/form-payment-methods/form-payment-methods'
import { useParams } from 'react-router-dom'
import FormGroupStudents from '../../../../core/form-group-students/form-group-students'
import FormStudyMonthPicker from '../../../../core/form-study-month-picker/form-study-month-picker'

interface props {
  startDate: string
  endDate: string
}

const FinancesGroupPayment: React.FC<props> = ({ startDate, endDate }) => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const { isLoading, mutateAsync } = useMutation(addNewStudentPaymentApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([GROUPS_FINANCES_KEY, id])
      message.success(t('responseSuccess'))
      form.resetFields()
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  const onFinish = (values: any) => mutateAsync(values)

  return (
    <Card>
      <Form form={form} layout={'vertical'} onFinish={onFinish}>
        <h2>{t('addPayment')}</h2>
        <Form.Item hidden initialValue={id} name={'group_id'}>
          <Input />
        </Form.Item>
        <FormGroupStudents />
        <SumInput name={'amount'} label={t('sum')} />
        <FormStudyMonthPicker groupStartDate={startDate} groupEndDate={endDate} />
        <FormPaymentMethods />
        <Form.Item name="comment" label={t('comment')}>
          <Input.TextArea placeholder={t('inputComment')} rows={3} />
        </Form.Item>
        <Form.Item>
          <Button block type={'primary'} htmlType={'submit'} disabled={isLoading}>
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default React.memo(FinancesGroupPayment)
