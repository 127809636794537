import React from 'react'
import { Card, Descriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { IRoom } from '../../../../interfaces'
import { descriptionsSettings } from '../../../../lib/settings'

interface props {
  room: IRoom
}

const RoomCard: React.FC<props> = ({ room }) => {
  const { t } = useTranslation()
  const { name } = room

  return (
    <Card bordered={false} title={t('generalInformation')}>
      <Descriptions {...descriptionsSettings} column={1}>
        <Descriptions.Item label={t('name')}>{name}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default RoomCard
