import React from 'react'
import Branches from '../../app/modules/branches/branches'

interface props {}

const BranchesPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Branches />
    </React.Fragment>
  )
}

export default BranchesPage
