import React from 'react'
import { Card, Table } from 'antd'
import { serialize } from '../../../lib/serializeQuery'
import { queryClient } from '../../../index'
import { useHistory } from 'react-router-dom'
import { useIsFetching } from 'react-query'

interface props {
  columns: any[]
  data: any[]
  fetchingQueryKey: string
}

const MyTable: React.FC<props> = ({ columns, data, fetchingQueryKey }) => {
  const { push } = useHistory()
  const isFetching = useIsFetching(fetchingQueryKey)

  const handleSort = (pagination: any, filters: any, sorter: any) => {
    let order = ''

    if (sorter.order === 'ascend') order = 'asc'
    else if (sorter.order === 'descend') order = 'desc'

    push({
      search: serialize('sort', [sorter.columnKey, order].join(',')),
    })

    return queryClient.invalidateQueries(fetchingQueryKey)
  }

  return (
    <Card bordered={false}>
      <Table
        className={'adaptive-table'}
        scroll={{ x: true }}
        rowKey="id"
        pagination={false}
        loading={!!isFetching}
        dataSource={data}
        columns={columns}
        onChange={handleSort}
      />
    </Card>
  )
}

export default React.memo(MyTable)
