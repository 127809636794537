import React from 'react'
import Groups from '../../app/modules/groups/groups'

interface props {}

const GroupsPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Groups />
    </React.Fragment>
  )
}

export default GroupsPage
