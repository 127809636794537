import React, { ChangeEvent, useState } from 'react'
import { Button, Col, FormInstance, Input, message, Row } from 'antd'
import { useMutation } from 'react-query'
import { createExpenseType } from '../../../../../api/expense-types'
import { useTranslation } from 'react-i18next'
import { queryClient } from '../../../../../index'
import { EXPENSE_TYPE_KEY } from '../../../../../hooks/query/expense-types'
import { AxiosResponse } from 'axios'
import { IExpenseType } from '../../../../../interfaces'

interface props {
  form: FormInstance
  fieldName: string
}

const ExpenseTypesAdd: React.FC<props> = ({ form, fieldName }) => {
  const { t } = useTranslation(['finances'])
  const [value, setValue] = useState<string>('')
  const handleValueChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setValue(value)

  const { isLoading, mutateAsync } = useMutation(createExpenseType, {
    onSuccess: (response: AxiosResponse<IExpenseType>) => {
      queryClient.invalidateQueries(EXPENSE_TYPE_KEY)
      setValue('')
      form.setFieldsValue({
        [fieldName]: response.data.id,
      })
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const onClick = () => {
    if (!value.length) return
    return mutateAsync({
      title: value,
    })
  }

  const style = { padding: '5px 12px' }
  return (
    <Row gutter={[0, 8]} style={style}>
      <Col xs={24}>
        <Input
          disabled={isLoading}
          value={value}
          onChange={handleValueChange}
          placeholder={t('inputExpenseType')}
        />
      </Col>
      <Col xs={24}>
        <Button type={'primary'} onClick={onClick} block loading={isLoading} disabled={isLoading}>
          {t('addExpenseType')}
        </Button>
      </Col>
    </Row>
  )
}

export default ExpenseTypesAdd
