import React, { useState } from 'react'
import { AxiosPromise } from 'axios'
import { idType } from '../../../interfaces/types/types'
import { useMutation } from 'react-query'
import { Button, message, Modal, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { queryClient } from '../../../index'
import { RollbackOutlined } from '@ant-design/icons'

interface props {
  id: idType
  restoreFunction: (id: idType) => AxiosPromise
  fetchingQueryKey: string
  modalText: string
}

const RestoreModal: React.FC<props> = ({ id, restoreFunction, fetchingQueryKey, modalText }) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const handleShowChange = () => setShow(!show)
  const { isLoading, mutateAsync } = useMutation(restoreFunction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(fetchingQueryKey)
      handleShowChange()
      message.success('responseSuccess')
    },
    onError: () => {
      message.success('responseError')
    },
  })

  const onClick = () => mutateAsync(id)

  return (
    <>
      <Button
        type={'primary'}
        loading={isLoading}
        disabled={isLoading}
        onClick={handleShowChange}
        icon={<RollbackOutlined />}
      >
        {t('restore')}
      </Button>
      <Modal
        visible={show}
        title={t('restore')}
        onCancel={handleShowChange}
        okButtonProps={{ onClick }}
        confirmLoading={isLoading}
        destroyOnClose={true}
      >
        <Typography.Text>{t(modalText)}</Typography.Text>
      </Modal>
    </>
  )
}

export default RestoreModal
