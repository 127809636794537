import React from 'react'
import { IRoom } from '../../../../interfaces'
import { Space } from 'antd'
import DeleteModal from '../../../core/delete-modal/delete-modal'
import { deleteRoomApi } from '../../../../api/rooms'
import RoomsDrawer from '../rooms-drawer/rooms-drawer'
import isEqual from 'react-fast-compare'
import { ROOMS_KEY } from '../../../../hooks/query/rooms'

interface props {
  room: IRoom
}

const RoomsAction: React.FC<props> = ({ room }) => {
  return (
    <Space>
      <RoomsDrawer title={'editTitle'} room={room} />
      <DeleteModal
        id={room.id}
        deleteFunction={deleteRoomApi}
        fetchingQueryKey={ROOMS_KEY}
        modalText={'rooms:deleteRoom'}
      />
    </Space>
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps, nextProps)
}

export default React.memo(RoomsAction, propsAreEqual)
