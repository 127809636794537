import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import styles from './layout.module.scss'
import LayoutMenu from './layout-menu/layout-menu'
import LayoutHeader from './layout-header/layout-header'
import LayoutFooter from './layout-footer/layout-footer'
import LayoutContent from './layout-content/layout-content'
import Breadcrumbs from '../../app/core/breadcrumbs/breadcrumbs'
import AppLoader from '../../app/core/app-loader/app-loader'
import { ACCESS_TOKEN } from '../../constants/auth'
import { useHistory } from 'react-router-dom'

interface props {}

const DashboardLayout: React.FC<props> = ({ children }) => {
  const [collapsed, setCollapsed] = useState<boolean>(window.screen.width < 1600)
  const { replace } = useHistory()

  useEffect(() => {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      replace('/auth')
    }
  }, [])

  return (
    <AppLoader>
      <Layout className={[styles.layout, collapsed ? styles.collapsed : ''].join(' ')}>
        <LayoutMenu collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout className={['site-layout', styles.siteLayout].join(' ')}>
          <LayoutHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          <LayoutContent>
            <Breadcrumbs />
            {children}
          </LayoutContent>
          <LayoutFooter />
        </Layout>
      </Layout>
    </AppLoader>
  )
}

export default React.memo(DashboardLayout)
