import React from 'react'
import { STUDENT_TEST_RESULT_KEY, useStudentTestResult } from '../../../../../hooks/query/students'
import { idType } from '../../../../../interfaces/types/types'
import { Link, useParams } from 'react-router-dom'
import { Skeleton } from 'antd'
import ErrorAlert from '../../../../core/error-alert/error-alert'
import EventResultTable from '../../../../core/event-result-table/event-result-table'
import { ITestResult } from '../../../../../interfaces'
import EventResultPercent from '../../../../core/event-result-percent/event-result-percent'
import { useTranslation } from 'react-i18next'
import TableMobileTitle from '../../../../core/table-mobile-title/table-mobile-title'

interface props {
  studentId: idType
  studentName: string
}

const EventStudentResult: React.FC<props> = ({ studentId, studentName }) => {
  const { id: testId } = useParams<{ id: string }>()
  const { isLoading, isFetching, isError, error, data } = useStudentTestResult({
    studentId,
    testId,
  })
  const { t } = useTranslation()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { data: results, ...pagination } = data!

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => (
        <React.Fragment>
          <TableMobileTitle title={'ID'} /># {id}
        </React.Fragment>
      ),
    },
    {
      title: t('firstName'),
      key: 'user',
      render: ({ id }: ITestResult) => (
        <React.Fragment>
          <TableMobileTitle title={t('firstName')} />
          <Link to={'/testing/results/details/' + id}>{studentName}</Link>
        </React.Fragment>
      ),
    },
    {
      title: t('testing:rightAnswers'),
      dataIndex: 'correct_answers',
      render: (c: number) => (
        <React.Fragment>
          <TableMobileTitle title={t('testing:rightAnswers')} />
          {c}
        </React.Fragment>
      ),
    },
    {
      title: t('testing:wrongAnswers'),
      dataIndex: 'incorrect_answers',
      render: (ic: number) => (
        <React.Fragment>
          <TableMobileTitle title={t('testing:wrongAnswers')} />
          {ic}
        </React.Fragment>
      ),
    },
    {
      title: t('result'),
      dataIndex: 'correct_percent',
      render: (percent: number) => (
        <React.Fragment>
          <TableMobileTitle title={t('result')} />
          <EventResultPercent percent={percent} />
        </React.Fragment>
      ),
    },
    {
      title: t('testing:timeTakenToSolveTheTest'),
      dataIndex: 'spent_time',
      render: (spent_time: string) => (
        <React.Fragment>
          <TableMobileTitle title={t('testing:timeTakenToSolveTheTest')} />
          {spent_time}
        </React.Fragment>
      ),
    },
  ]

  return (
    <EventResultTable
      columns={columns}
      dataSource={results}
      isLoading={isFetching}
      pagination={pagination}
      fetchingQueryKey={[STUDENT_TEST_RESULT_KEY, testId]}
    />
  )
}

export default EventStudentResult
