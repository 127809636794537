import React, { useEffect, useState } from 'react'
import { IGroup } from '../../../../interfaces'
import { useTranslation } from 'react-i18next'
import { Col, DatePicker, Form, Input, Row, TimePicker } from 'antd'
import moment from 'moment'
import NameInput from '../../../core/form-group/name-input/name-input'
import StatusSwitch from '../../../core/form-group/status-switch/status-switch'
import FormDays from '../../../core/form-days/form-days'
import FormTeachersList from '../../../core/form-teachers-list/form-teachers-list'
import FormRoomsList from '../../../core/form-rooms-list/form-rooms-list'
import FormCoursesList from '../../../core/form-courses-list/form-courses-list'
import StartTime from '../../../core/form-group/start-time/start-time'
import BranchesList from '../../../core/form-group/branches-list/branches-list'
import { useActiveBranch } from '../../../../hooks/useActiveBranch'
import { queryClient } from '../../../../index'
import { ROOMS_LIST_KEY } from '../../../../hooks/query/rooms'
import { useHistory } from 'react-router-dom'
import { serialize } from '../../../../lib/serializeQuery'
import queryString from 'query-string'

const { RangePicker } = DatePicker

interface props {
  formId: string
  submitForm: any
  item?: IGroup
  name?: string
}

const GroupsForm: React.FC<props> = ({ formId, submitForm, item, name }) => {
  const [courseTime, setCourseTime] = useState<string>('')
  const [form] = Form.useForm()
  const { push, location } = useHistory()
  const { t } = useTranslation(['translation', 'groups'])
  const activeBranch = useActiveBranch()

  const changeBranch = (value: string) => {
    form.resetFields(['room_id'])
    push({ search: serialize('branch_id', value) })
    queryClient.invalidateQueries(ROOMS_LIST_KEY)
  }

  const changeTime = (time: any) => {
    if (time && courseTime) {
      const minutes = moment.duration(courseTime).asMinutes() || 0
      const end_time = moment(time).add(minutes, 'minutes')
      form.setFieldsValue({ end_time })
    }
  }

  useEffect(() => {
    changeTime(form.getFieldValue('start_time'))
  }, [courseTime])

  let group: any = {}

  if (item)
    group = {
      ...item,
      start_time: moment(item?.start_time, 'HH:mm'),
      end_time: moment(item?.end_time, 'HH:mm'),
      start_date: [moment(item?.start_date), moment(item?.end_date)],
    }

  const qs = queryString.parse(location.search)

  return (
    <Form
      form={form}
      id={formId}
      name="group-form"
      initialValues={{
        branch_id: Number(qs.branch_id) || activeBranch?.id,
        name,
        ...group,
      }}
      onFinish={submitForm}
      layout={'vertical'}
    >
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <NameInput label={'name'} />
      <FormCoursesList setCourseTime={setCourseTime} />
      <FormTeachersList />
      <BranchesList onChange={changeBranch} mode={'single'} />
      <FormRoomsList />

      <FormDays />

      <Row gutter={[16, 0]}>
        <Col xs={12}>
          <StartTime changeTime={changeTime} />
        </Col>
        <Col xs={12}>
          <Form.Item name="end_time" label={t('groups:groupEndTime')} rules={[{ required: true }]}>
            <TimePicker
              style={{ width: '100%' }}
              hideDisabledOptions={true}
              showNow={false}
              minuteStep={10}
              format={'HH:mm'}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="start_date"
        rules={[{ required: true }]}
        label={`${t('groups:groupStartDate')} | ${t('groups:groupEndDate')}`}
      >
        <RangePicker allowClear={false} style={{ width: '100%' }} />
      </Form.Item>
      <StatusSwitch />
    </Form>
  )
}

export default GroupsForm
