import React from 'react'
import { ITeacher } from '../../../../interfaces'
import { Space } from 'antd'
import TeachersDrawer from '../teachers-drawer/teachers-drawer'
import isEqual from 'react-fast-compare'

interface props {
  teacher: ITeacher
}

const TeachersAction: React.FC<props> = ({ teacher }) => {
  return (
    <Space>
      <TeachersDrawer title={'editTitle'} teacher={teacher} />
    </Space>
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps, nextProps)
}

export default React.memo(TeachersAction, propsAreEqual)
