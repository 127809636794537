import React from 'react'
import PaymentsHistory from '../../../app/modules/payments-history/payments-history'

interface props {}

const PaymentsHistoryPage: React.FC<props> = () => {
  return <PaymentsHistory />
}

export default PaymentsHistoryPage
