import React from 'react'
import { ICourse, ITestEvent, IUser } from '../../../../../interfaces'
import { ColumnsType } from 'antd/es/table'
import { Card, Table } from 'antd'
import { idType } from '../../../../../interfaces/types/types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import EventActions from '../event-actions/event-actions'
import TeacherLink from '../../../../core/teacher-link/teacher-link'
import CourseLink from '../../../../core/course-link/course-link'
import TableMobileTitle from '../../../../core/table-mobile-title/table-mobile-title'
import { useRoleChecker } from '../../../../../hooks/useRoleChecker'

interface props {
  events: ITestEvent[]
}

const EventsTable: React.FC<props> = ({ events }) => {
  const { t } = useTranslation()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'teacher'])

  const columns: ColumnsType<ITestEvent> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: idType) => (
        <React.Fragment>
          <TableMobileTitle title={'ID'} /># {text}
        </React.Fragment>
      ),
    },
    {
      title: t('name'),
      key: 'title',
      render: ({ id, title }: ITestEvent) => (
        <React.Fragment>
          <TableMobileTitle title={t('name')} />
          <Link to={'/testing/events/details/' + id}>{title}</Link>
        </React.Fragment>
      ),
    },
    {
      title: t('teacher'),
      dataIndex: 'user',
      className: !access ? 'hide' : '',
      render: ({ id, name }: IUser) => (
        <React.Fragment>
          <TableMobileTitle title={t('teacher')} />
          <TeacherLink name={name} id={id} />
        </React.Fragment>
      ),
    },
    {
      title: t('course'),
      dataIndex: 'course',
      className: !access ? 'hide' : '',
      render: ({ id, name }: ICourse) => (
        <React.Fragment>
          <TableMobileTitle title={t('course')} />
          <CourseLink courseId={id} courseName={name} />
        </React.Fragment>
      ),
    },
    {
      title: t('questionsCount'),
      dataIndex: 'questions_count',
      render: (qc: number) => (
        <React.Fragment>
          <TableMobileTitle title={t('questionsCount')} />
          {qc}
        </React.Fragment>
      ),
    },
    {
      title: t('period'),
      key: 'period',
      render: ({ start_at, end_at }: ITestEvent) => (
        <React.Fragment>
          <TableMobileTitle title={t('period')} />
          {start_at} - {end_at}
        </React.Fragment>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      render: (event: ITestEvent) => <EventActions event={event} />,
    },
  ]

  return (
    <Card bordered={false}>
      <Table
        className={'adaptive-table'}
        dataSource={events}
        pagination={false}
        rowKey={'id'}
        columns={columns}
        scroll={{ x: true }}
      />
    </Card>
  )
}

export default EventsTable
