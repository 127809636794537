import React from 'react'
import { Col, Row } from 'antd'
import FinanceGroupFilterForm from './finance-group-filter-form/finance-group-filter-form'
import FinancesGroupFilterStatistic from './finances-group-filter-statistic/finances-group-filter-statistic'
import { IFinanceTotal } from '../../../../../interfaces'
import { idType } from '../../../../../interfaces/types/types'

interface props {
  totals: IFinanceTotal
  groupId: idType
  startDate: string
  endDate: string
}

const FinancesGroupFilter: React.FC<props> = ({ totals, groupId, endDate, startDate }) => {
  return (
    <Row gutter={[16, 16]} align={'middle'} justify={'space-between'}>
      <Col xs={24} lg={7}>
        <FinanceGroupFilterForm startDate={startDate} endDate={endDate} groupId={groupId} />
      </Col>
      <Col xs={24} lg={16}>
        <FinancesGroupFilterStatistic totals={totals} />
      </Col>
    </Row>
  )
}

export default React.memo(FinancesGroupFilter)
