import React from 'react'
import DeleteModal from '../../../../core/delete-modal/delete-modal'
import { TEST_EVENTS_KEY } from '../../../../../hooks/query/events'
import { deleteTestApi } from '../../../../../api/tests'
import { Space } from 'antd'
import { ITestEvent } from '../../../../../interfaces'
import EventModal from '../event-modal/event-modal'
import EventStartModal from '../event-start-modal/event-start-modal'
import { useAuthUserInformation } from '../../../../../hooks/query/auth'

interface props {
  event: ITestEvent
  updateOne?: boolean
  redirect?: boolean
  redirectTo?: string
}

const EventActions: React.FC<props> = ({ event, updateOne, redirect, redirectTo }) => {
  const authUserInformation = useAuthUserInformation()
  const access = event.user_id === authUserInformation.data?.id

  return (
    <Space>
      <EventStartModal id={event.id} />
      {access && (
        <React.Fragment>
          <EventModal updateOne={updateOne} event={event} />
          <DeleteModal
            id={event.id}
            fetchingQueryKey={TEST_EVENTS_KEY}
            modalText={'testing:deleteEvent'}
            deleteFunction={deleteTestApi}
            redirect={redirect}
            redirectTo={redirectTo}
          />
        </React.Fragment>
      )}
    </Space>
  )
}

export default React.memo(EventActions)
