import React, { useState } from 'react'
import { PlayCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Button, Form, message, Modal } from 'antd'
import { useMutation } from 'react-query'
import { startTestApi } from '../../../../../api/tests'
import PasswordInput from '../../../../core/form-group/password-input/password-input'
import { idType } from '../../../../../interfaces/types/types'
import { useHistory } from 'react-router-dom'

interface props {
  id: idType
}

const EventStartModal: React.FC<props> = ({ id }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const modalHandler = () => setVisible(!visible)
  const { push } = useHistory()

  const { t } = useTranslation()

  const { isLoading, mutateAsync } = useMutation(startTestApi, {
    onSuccess: () => {
      push({
        pathname: '/testing/examination',
        state: {
          confirmed: true,
          testId: id,
        },
      })
    },
    onError: (e: any) => {
      if (e.response.status === 422) {
        message.error(t('incorrectPassword'))
      } else {
        message.error(t(e.response.data.message))
      }
    },
  })

  const onFinish = ({ password }: any) => mutateAsync({ id, password })

  return (
    <React.Fragment>
      <Button type={'primary'} icon={<PlayCircleOutlined />} onClick={modalHandler}>
        <span>{t('begin')}</span>
      </Button>
      <Modal
        title={t('password')}
        visible={visible}
        closable={true}
        onCancel={modalHandler}
        okText={t('submit')}
        okButtonProps={{
          htmlType: 'submit',
          form: 'password-form',
          disabled: isLoading,
          loading: isLoading,
        }}
      >
        <Form id={'password-form'} onFinish={onFinish} layout={'vertical'}>
          <PasswordInput label={t('password')} min={4} />
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default EventStartModal
