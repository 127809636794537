import React from 'react'
import { Form, FormItemProps, Select, SelectProps } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  label: string
  placeholder?: string
  formItemProps?: FormItemProps
  selectProps?: SelectProps<any>
  name: string
}

const FormItemSelect: React.FC<props> = ({
  label,
  placeholder,
  name,
  formItemProps,
  selectProps,
  children,
}) => {
  const { t } = useTranslation()
  const style = { width: '100%' }
  return (
    <Form.Item name={name} label={t(label)} {...formItemProps}>
      <Select style={style} {...selectProps} placeholder={t(placeholder ? placeholder : label)}>
        {children}
      </Select>
    </Form.Item>
  )
}

export default FormItemSelect
