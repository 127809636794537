import React from 'react'
import { Pagination, Typography } from 'antd'
import styles from './my-pagination.module.scss'
import { IPagination } from '../../../interfaces'
import { useHistory, useLocation } from 'react-router-dom'
import { serialize } from '../../../lib/serializeQuery'
import { queryClient } from '../../../index'
import { useIsFetching } from 'react-query'
import { useTranslation } from 'react-i18next'

interface props {
  pagination: IPagination
  fetchingQueryKey: string | string[]
}

const MyPagination: React.FC<props> = ({ pagination, fetchingQueryKey }) => {
  const { total, current_page, per_page } = pagination
  const history = useHistory()
  const location = useLocation()
  const isFetching = !!useIsFetching(fetchingQueryKey)
  const { t } = useTranslation()

  const paginationHandler = async (page: number, query: string) => {
    history.push({
      pathname: location.pathname,
      search: serialize(query, page),
    })

    await queryClient.invalidateQueries(fetchingQueryKey)

    window.scroll(0, 0)
  }
  const changePage = (page: number) => paginationHandler(page, 'page')
  const changeShowSize = (current: number, size: number) => paginationHandler(size, 'per_page')

  const showTotal = (total: number, range: number[]) => {
    return (
      <Typography.Text className={styles.text}>
        {t('shown')}: {range[0]}-{range[1]} / {t('total')}: {total}
      </Typography.Text>
    )
  }

  return (
    <div className={styles.pagination}>
      <Pagination
        current={current_page}
        defaultCurrent={1}
        total={total}
        defaultPageSize={15}
        pageSize={per_page}
        onChange={changePage}
        onShowSizeChange={changeShowSize}
        responsive={true}
        showSizeChanger
        disabled={isFetching}
        showTotal={showTotal}
        showQuickJumper
      />
    </div>
  )
}

export default React.memo(MyPagination)
