import React, { useState } from 'react'
import { Drawer, message } from 'antd'
import { IUser } from '../../../../interfaces'
import { useTranslation } from 'react-i18next'
import UsersForm from '../users-form/users-form'
import { registerUserApi, updateUserApi } from '../../../../api/user'
import DrawerFooter from '../../../core/drawer/drawer-footer/drawer-footer'
import DrawerOpen from '../../../core/drawer/drawer-open/drawer-open'
import { drawerSettings } from '../../../../lib/settings'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../index'
import { USERS_KEY } from '../../../../hooks/query/users'

interface props {
  title: string
  user?: IUser
  updateOne?: boolean
}

const UsersDrawer: React.FC<props> = ({ user, title, updateOne }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const drawerToggle = () => setVisible(!visible)

  const { t } = useTranslation(['translation', 'staff'])

  const onSuccess = async () => {
    await queryClient.invalidateQueries(updateOne ? [USERS_KEY, user!.id!.toString()] : USERS_KEY)
    message.success(t('responseSuccess'))
    drawerToggle()
  }

  const onError = () => {
    message.error(t('responseError'))
  }

  const { isLoading: isRegisterLoading, mutateAsync: register } = useMutation(registerUserApi, {
    onSuccess,
    onError,
  })
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(updateUserApi, {
    onSuccess,
    onError,
  })

  const submitForm = async (fieldsValues: any) => {
    const values: IUser = {
      ...fieldsValues,
      birthday: fieldsValues['birthday'] && fieldsValues['birthday'].format('YYYY-MM-DD'),
      status: fieldsValues['status'] || false,
    }

    const handler = user ? update : register

    await handler(values)
  }

  return (
    <>
      <DrawerOpen drawerToggle={drawerToggle} edit={user} />
      <Drawer
        {...drawerSettings}
        onClose={drawerToggle}
        title={t('staff:' + title)}
        visible={visible}
        footer={
          <DrawerFooter
            disabled={isRegisterLoading || isUpdateLoading}
            drawerToggle={drawerToggle}
            formId={'register-form'}
          />
        }
      >
        <UsersForm submitForm={submitForm} item={user} formId={'register-form'} />
      </Drawer>
    </>
  )
}

export default React.memo(UsersDrawer)
