import { getAllTeachersPaymentsApi } from '../../api/salaries'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { ITeacherPaymentsWithPagination } from '../../interfaces'

export const TEACHERS_PAYMENTS_KEY = 'teachers_payments_key'

export function useTeachersPayments(
  options?: UseQueryOptions<ITeacherPaymentsWithPagination, Error>,
) {
  return useQuery<ITeacherPaymentsWithPagination, Error>(
    TEACHERS_PAYMENTS_KEY,
    () =>
      getAllTeachersPaymentsApi().then(
        (response: AxiosResponse<ITeacherPaymentsWithPagination>) => response.data,
      ),
    {
      ...options,
    },
  )
}
