import React from 'react'
import { IGroup, IStudentGroupPivot, IStudentNameId } from '../../../../interfaces'
import {
  STUDENT_GROUPS_KEY,
  STUDENTS_KEY,
  useStudentGroups,
} from '../../../../hooks/query/students'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { idType } from '../../../../interfaces/types/types'
import { Link } from 'react-router-dom'
import GroupDays from '../../groups/group-days/group-days'
import GroupListBalance from '../../../core/group-list/group-list-balance/group-list-balance'
import GroupStudentsDropdown from '../../../core/group-students-dropdown/group-students-dropdown'
import { useTranslation } from 'react-i18next'
import StudentStatus from '../student-status/student-status'
import StudentIndividualPriceStatus from '../../../core/student-individual-price-status/student-individual-price-status'
import DateWithMonth from '../../../core/date-with-month/date-with-month'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'

interface props {
  name: string
  id: idType
}

const StudentGroups: React.FC<props> = ({ name, id }) => {
  const { isLoading, data } = useStudentGroups(id!.toString())

  const { t } = useTranslation(['translation', 'groups'])

  const groupsInvalidateKey = [STUDENT_GROUPS_KEY, id!.toString()]

  const studentNameAndId: IStudentNameId = { name, id }

  let columns: ColumnsType<IGroup> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => (
        <>
          <TableMobileTitle title={'ID'} /># {id}
        </>
      ),
    },
    {
      title: t('name'),
      key: 'name',
      render: ({ name, id }: IGroup) => (
        <>
          <TableMobileTitle title={t('name')} />
          <Link to={`/groups/details/${id}`}>{name}</Link>
        </>
      ),
    },
    {
      title: t('status'),
      key: 'status',
      render: ({ group_balance, start_date, pivot }: IGroup) => (
        <StudentStatus
          balance={group_balance!}
          groupStartDate={start_date}
          added_at={pivot!.added_at}
          status={pivot!.status}
          excluded_at={pivot!.excluded_at}
          transferred_at={pivot!.transferred_at}
        />
      ),
    },
    {
      title: `${t('groups:groupStartTime')} - ${t('groups:groupEndTime')}`,
      key: 'lesson_duration',
      render: ({ start_time, end_time }: IGroup) => (
        <>
          <TableMobileTitle title={`${t('groups:groupStartTime')} - ${t('groups:groupEndTime')}`} />
          <span>
            {start_time} - {end_time}
          </span>
        </>
      ),
    },
    {
      title: t('days'),
      dataIndex: 'days',
      render: (days: string[]) => (
        <>
          <TableMobileTitle title={t('days')} />
          <GroupDays days={days} />
        </>
      ),
    },
    {
      title: t('groups:addedToGroup'),
      key: 'added_at',
      render: ({ pivot }: IGroup) => (
        <>
          <TableMobileTitle title={t('groups:addedToGroup')} />
          <DateWithMonth date={pivot!.added_at} />
        </>
      ),
    },
    {
      title: t('individualPrice'),
      dataIndex: 'pivot',
      render: ({ free, price }: IStudentGroupPivot) => (
        <>
          <TableMobileTitle title={t('individualPrice')} />
          <StudentIndividualPriceStatus price={price} free={free} />
        </>
      ),
    },
    {
      title: t('balance'),
      key: 'balance',
      render: ({ group_balance }: IGroup) => (
        <>
          <TableMobileTitle title={t('balance')} />
          <GroupListBalance balance={group_balance} />
        </>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      render: (group: IGroup) => (
        <GroupStudentsDropdown
          groupStartDate={group.start_date}
          groupEndDate={group.end_date}
          studentAddedAt={group.pivot!.added_at}
          student={studentNameAndId}
          studentStatus={group.pivot!.status}
          groupId={group.id!.toString()}
          individualPrice={group.pivot!.price}
          paymentQueries={[groupsInvalidateKey, [STUDENTS_KEY, id!.toString()]]}
          deleteStudentQueries={[groupsInvalidateKey, [STUDENTS_KEY, id!.toString()]]}
          excludeStudentQueries={[groupsInvalidateKey]}
          transferQueries={[groupsInvalidateKey]}
          individualPriceQueries={[groupsInvalidateKey, [STUDENTS_KEY, id!.toString()]]}
        />
      ),
    },
  ]

  return (
    <Table
      className={'adaptive-table'}
      loading={isLoading}
      columns={columns}
      dataSource={data?.sort(a => (a.pivot?.status === 'active' ? -1 : 1))}
      scroll={{ x: true }}
      rowKey={'id'}
      pagination={false}
    />
  )
}

export default StudentGroups
