import React from 'react'
import { IGroup } from '../../../../interfaces'
import { idType } from '../../../../interfaces/types/types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GroupActions from '../group-actions/group-actions'
import GroupDays from '../group-days/group-days'
import StatusTag from '../../../core/status-tag/status-tag'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import TeacherLink from '../../../core/teacher-link/teacher-link'
import { ColumnsType } from 'antd/es/table'
import { GROUPS_KEY } from '../../../../hooks/query/groups'
import MyTable from '../../../core/my-table/my-table'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'
import DateWithMonth from '../../../core/date-with-month/date-with-month'

interface props {
  groups: IGroup[]
}

const GroupsTable: React.FC<props> = ({ groups }) => {
  const { t } = useTranslation(['translation', 'groups'])
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception'])

  const columns: ColumnsType<IGroup> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (text: idType) => (
        <React.Fragment>
          <TableMobileTitle title={'ID'} /># {text}
        </React.Fragment>
      ),
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
      render: (text: string, record: IGroup) => (
        <>
          <TableMobileTitle title={t('name')} />
          <Link to={`/groups/details/${record.id}`}>{text}</Link>
        </>
      ),
    },
    {
      title: t('days'),
      dataIndex: 'days',
      key: 'days',
      render: (array: string[]) => (
        <>
          <TableMobileTitle title={t('days')} />
          <GroupDays days={array} />
        </>
      ),
    },
    {
      title: t('teacher'),
      dataIndex: 'teacher_id',
      key: 'teacher_id',
      render: (text: number, record: IGroup) => (
        <>
          <TableMobileTitle title={t('teacher')} />
          {record.teacher && <TeacherLink id={text} name={record.teacher.name} />}
        </>
      ),
    },
    {
      title: t('groups:groupStartTime'),
      key: 'start_time',
      sorter: true,
      dataIndex: 'start_time',
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('groups:groupsStartTime')} />
          {text}
        </>
      ),
    },
    {
      title: t('groups:studentsCount'),
      dataIndex: 'students_count',
      key: 'students_count',
      sorter: true,
      render: (text: number) => (
        <>
          <TableMobileTitle title={t('groups:studentsCount')} />
          {text}
        </>
      ),
    },
    {
      title: t('createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('createdAt')} />
          <DateWithMonth date={text} />
        </>
      ),
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (status: number) => (
        <>
          <TableMobileTitle title={t('status')} />
          <StatusTag status={status} />
        </>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      className: access ? 'show' : 'hide',
      render: (_: any, record: IGroup) => <GroupActions group={record} />,
    },
  ]

  return <MyTable columns={columns} data={groups} fetchingQueryKey={GROUPS_KEY} />
}

export default React.memo(GroupsTable)
