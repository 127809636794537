import React from 'react'
import { Col, PageHeader, Row } from 'antd'
import UserCard from '../../app/modules/users/user-card/user-card'
import { useTranslation } from 'react-i18next'
import { useAuthUserInformation } from '../../hooks/query/auth'
import RestorePasswordModal from '../../app/core/restore-password-modal/restore-password-modal'

interface props {}

const ProfilePage: React.FC<props> = () => {
  const { data: user } = useAuthUserInformation()
  const { t } = useTranslation(['translation', 'staff'])

  return (
    <React.Fragment>
      <PageHeader
        title={user?.name}
        subTitle={t('staff:userSubtitle')}
        onBack={() => window.history.back()}
        extra={[<RestorePasswordModal key={'1'} />]}
      />
      <Row gutter={[32, 32]}>
        <Col xs={24} lg={12}>
          <UserCard user={user!} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ProfilePage
