import React from 'react'
import { useTranslation } from 'react-i18next'
import DashboardGroupTable from '../dashboard-group-table/dashboard-group-table'
import { Card, Tabs } from 'antd'
import { useDashboardSchedule } from '../../../../hooks/query/dashboard'

const { TabPane } = Tabs

interface props {}

const DashboardSchedule: React.FC<props> = () => {
  const { isLoading, data } = useDashboardSchedule()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <h2>{t('schedule')}</h2>
      <Card bordered={false}>
        <Tabs defaultActiveKey="0">
          <TabPane tab={t('allRooms')}>
            <DashboardGroupTable loaded={isLoading} groups={data?.groups} />
          </TabPane>
          {data?.rooms.map(room => (
            <TabPane tab={room.name} key={room.id}>
              <DashboardGroupTable loaded={isLoading} groups={room.groups} />
            </TabPane>
          ))}
        </Tabs>
      </Card>
    </React.Fragment>
  )
}

export default DashboardSchedule
