import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { IExpenseWithPagination } from '../../interfaces'
import { getAllExpenses } from '../../api/expenses'

export const EXPENSES_KEY = 'expenses_key'

export function useExpenses(options?: UseQueryOptions<IExpenseWithPagination, Error>) {
  return useQuery<IExpenseWithPagination, Error>(
    EXPENSES_KEY,
    () => getAllExpenses().then((response: AxiosResponse<IExpenseWithPagination>) => response.data),
    {
      ...options,
    },
  )
}
