import React from 'react'
import GroupNotificationsList from './group-notifications-list/group-notifications-list'
import GroupNotificationsForm from './group-notifications-form/group-notifications-form'
import { Col, Divider, Row } from 'antd'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'

interface props {}

const GroupNotifications: React.FC<props> = () => {
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'teacher', 'reception'])

  return (
    <Row gutter={[32, 32]}>
      <Col xs={24} lg={access ? 15 : 24}>
        <GroupNotificationsList />
      </Col>

      {access && (
        <React.Fragment>
          <Col xs={0} lg={1}>
            <Divider type={'vertical'} style={{ height: '100%' }} />
          </Col>
          <Col xs={24} lg={8}>
            <GroupNotificationsForm />
          </Col>
        </React.Fragment>
      )}
    </Row>
  )
}

export default GroupNotifications
