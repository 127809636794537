import { getAllBranches, getBranchById } from '../../api/branches'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { idType } from '../../interfaces/types/types'
import { IBranch } from '../../interfaces'

export const BRANCHES_KEY = 'branches_key'

export function useBranches(options?: UseQueryOptions<IBranch[], Error>) {
  return useQuery<IBranch[], Error>(
    BRANCHES_KEY,
    () => getAllBranches().then((response: AxiosResponse<IBranch[]>) => response.data),
    {
      ...options,
    },
  )
}

export function useBranch(id: idType, options?: UseQueryOptions<IBranch, Error>) {
  return useQuery<IBranch, Error>(
    [BRANCHES_KEY, id],
    () => getBranchById(id).then((response: AxiosResponse<IBranch>) => response.data),
    {
      ...options,
    },
  )
}
