import React from 'react'
import { Divider, Space, Table } from 'antd'
import { IGroup, IStudentPayment } from '../../../../interfaces'
import { useTranslation } from 'react-i18next'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import { ColumnsType } from 'antd/es/table'
import { idType } from '../../../../interfaces/types/types'
import { Link, useParams } from 'react-router-dom'
import { priceFormatter } from '../../../../lib/priceFormatter'
import moment from 'moment'
import DeleteModal from '../../../core/delete-modal/delete-modal'
import { deleteStudentPaymentApi } from '../../../../api/payments'
import StudentPaymentMethod from './student-payment-method/student-payment-method'
import { STUDENT_PAYMENTS_KEY, useStudentPayments } from '../../../../hooks/query/students'
import ErrorAlert from '../../../core/error-alert/error-alert'
import { usePaymentTableFilters } from '../../../../hooks/usePaymentTableFilters'
import StudentPaymentsDrawer from './student-payments-drawer/student-payments-drawer'
import DateWithMonth from '../../../core/date-with-month/date-with-month'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'

interface props {
  name: string
}

const StudentPayments: React.FC<props> = ({ name }) => {
  const { id: studentId } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { roleChecker } = useRoleChecker()
  const paymentFilters = usePaymentTableFilters()
  const access = roleChecker(['admin', 'cashier'])

  const { isLoading, isError, error, data } = useStudentPayments(studentId)
  if (isError) return <ErrorAlert msg={error?.message} />

  const filters = data
    ?.map(({ group }: IStudentPayment) => group!)
    .map(({ name }: IGroup) => ({
      text: name,
      value: name,
    }))
    .filter((group, index, self) => index === self.findIndex(t => t.value === group.value))

  const columns: ColumnsType<IStudentPayment> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => (
        <>
          <TableMobileTitle title={t('ID')} /># {id}
        </>
      ),
    },
    {
      title: t('group'),
      dataIndex: 'group',
      filters,
      onFilter: (value, record) => record.group!.name.includes(value as string),
      render: ({ id, name }: IGroup) => (
        <>
          <TableMobileTitle title={t('group')} />
          <Link to={'/groups/details/' + id}>{name}</Link>
        </>
      ),
    },
    {
      title: t('sum'),
      dataIndex: 'amount',
      render: (amount: number) => (
        <>
          <TableMobileTitle title={t('sum')} />
          <strong>{priceFormatter(amount)}</strong>
        </>
      ),
    },
    {
      title: t('paidFor'),
      dataIndex: 'date',
      render: (date: string) => (
        <>
          <TableMobileTitle title={t('paidFor')} />
          {moment(date).format('MMMM').toUpperCase()}
        </>
      ),
    },
    {
      title: t('paymentDate'),
      dataIndex: 'created_at',
      render: (created_at: string) => (
        <>
          <TableMobileTitle title={t('paymentDate')} />
          <DateWithMonth date={created_at} />
        </>
      ),
    },
    {
      title: t('paymentType'),
      dataIndex: 'method',
      filters: paymentFilters,
      onFilter: (value, record) => record.method.indexOf(value as string) === 0,
      render: (method: string) => (
        <>
          <TableMobileTitle title={t('paymentType')} />
          <StudentPaymentMethod method={method} />
        </>
      ),
    },
    {
      title: t('comment'),
      dataIndex: 'comment',
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('comment')} />
          {text}
        </>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right' as 'right',
      className: !access ? 'hide' : '',
      render: (payment: IStudentPayment) => (
        <Space split={<Divider type={'horizontal'} />}>
          <StudentPaymentsDrawer
            btnStyle={true}
            paymentQueries={[[STUDENT_PAYMENTS_KEY, studentId]]}
            payment={payment}
            student={{ name, id: studentId }}
            groupId={payment.group_id}
          />
          <DeleteModal
            id={payment.id}
            modalText={'teachers:deleteTeacherPayment'}
            deleteFunction={deleteStudentPaymentApi}
            fetchingQueryKey={[STUDENT_PAYMENTS_KEY, studentId]}
          />
        </Space>
      ),
    },
  ]

  return (
    <Table
      className={'adaptive-table'}
      loading={isLoading}
      columns={columns}
      dataSource={data}
      rowKey={'id'}
      scroll={{ x: true }}
      footer={(currentPageData: any) => {
        const sum: (string | number)[] = currentPageData.map(
          (payment: IStudentPayment) => payment.amount,
        )
        const total = sum.length && sum.reduce((sum, acc) => Number(sum) + Number(acc))

        return (
          <h3>
            {t('total')}: {priceFormatter(total)}
          </h3>
        )
      }}
    />
  )
}

export default StudentPayments
