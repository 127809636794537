import React from 'react'
import { useTestResult } from '../../../../hooks/query/test-session'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'
import ErrorAlert from '../../../core/error-alert/error-alert'
import ResultBlock from './result-block/result-block'

interface props {}

const Result: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, error, data } = useTestResult(id)
  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  return <ResultBlock result={data!} />
}

export default Result
