import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRoles } from '../../../hooks/query/roles'
import { Form, Select } from 'antd'
import { IUserRole } from '../../../interfaces'

interface props {
  setTeacherSalary?: (isTrue: boolean) => void
  required?: boolean
}

const FormRolesList: React.FC<props> = ({ setTeacherSalary, required = true }) => {
  const { t } = useTranslation()
  const { isLoading, data } = useRoles()

  const selectHandler = (value: any, optionsArr: any) => {
    const keys = optionsArr.map((option: any) => option.key)

    if (setTeacherSalary) {
      setTeacherSalary(keys.includes('teacher'))
    }
  }

  return (
    <Form.Item
      name="role_id"
      rules={[{ required, message: t('required') }]}
      label={t('chooseRole')}
    >
      <Select
        disabled={isLoading}
        loading={isLoading}
        mode={'multiple'}
        style={{ width: '100%' }}
        onChange={setTeacherSalary && selectHandler}
        placeholder={t('chooseRole')}
        allowClear={!required}
      >
        {data?.map(({ id, name, slug }: IUserRole) => (
          <Select.Option key={slug} value={id!} label={name}>
            {t(slug)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FormRolesList
