import { DescriptionsProps, DrawerProps } from 'antd'

export const drawerSettings: DrawerProps = {
  destroyOnClose: true,
  closable: true,
  maskClosable: false,
  width: 450,
}

export const descriptionsSettings: DescriptionsProps = {
  layout: window.screen.width < 768 ? 'vertical' : 'horizontal',
  bordered: false,
  size: 'middle',
}
