import React from 'react'
import Teachers from '../../app/modules/teachers/teachers'

interface props {}

const TeachersPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Teachers />
    </React.Fragment>
  )
}

export default TeachersPage
