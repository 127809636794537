import { getCurrentQuestionApi, getLatestTestResultApi } from '../../api/testing'
import { idType } from '../../interfaces/types/types'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { ICurrentQuestion, ITestResult } from '../../interfaces'

export const GET_CURRENT_QUESTION_KEY = 'get_current_question_key'
export const GET_LATEST_TEST_RESULT_KEY = 'get_test_result_key'

export function useCurrentQuestion(id: idType, options?: UseQueryOptions<ICurrentQuestion, Error>) {
  return useQuery<ICurrentQuestion, Error>(
    [GET_CURRENT_QUESTION_KEY, id],
    () =>
      getCurrentQuestionApi(id).then((response: AxiosResponse<ICurrentQuestion>) => response.data),
    {
      ...options,
      retry: 2,
    },
  )
}

export function useLatestTestResult(id: idType, options?: UseQueryOptions<ITestResult, Error>) {
  return useQuery<ITestResult, Error>(
    [GET_LATEST_TEST_RESULT_KEY, id],
    () => getLatestTestResultApi(id).then((response: AxiosResponse<ITestResult>) => response.data),
    {
      ...options,
    },
  )
}
