import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { priceFormatter } from '../../../../lib/priceFormatter'

interface props {
  balance: number | undefined
}

const GroupListBalance: React.FC<props> = ({ balance }) => {
  const { t } = useTranslation()

  const renderBalance = () => {
    if (balance && balance > 0) {
      return (
        <Tag color={'blue'}>
          {t('prepaidExpense')} {priceFormatter(balance)}
        </Tag>
      )
    } else if (balance && balance < 0) {
      return (
        <Tag color={'red'}>
          {t('debt')} {priceFormatter(balance)}
        </Tag>
      )
    } else if (balance === 0) {
      return <Tag color={'green'}>{t('paidUp')}</Tag>
    }
  }

  return <React.Fragment>{renderBalance()}</React.Fragment>
}

export default React.memo(GroupListBalance)
