import React from 'react'
import { Button, DatePicker, Form, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { FilterOutlined } from '@ant-design/icons'
import moment from 'moment'
import { idType } from '../../../../../../interfaces/types/types'
import { useMutation } from 'react-query'
import { getFinancesTeacherByIdApi } from '../../../../../../api/finances'
import { IFinanceTeacherResponse } from '../../../../../../interfaces'
import { queryClient } from '../../../../../../index'
import { TEACHERS_FINANCES_KEY } from '../../../../../../hooks/query/teachers'
import { AxiosResponse } from 'axios'
import { useHistory } from 'react-router-dom'
import { serialize } from '../../../../../../lib/serializeQuery'
import { getQueryParam } from '../../../../../../lib/getQueryParam'

interface props {
  teacher_id: idType
}

const FinanceTeacherFilterForm: React.FC<props> = ({ teacher_id }) => {
  const { t } = useTranslation()
  const { push } = useHistory()

  const { isLoading, mutateAsync } = useMutation(getFinancesTeacherByIdApi, {
    onSuccess: async (response: AxiosResponse<IFinanceTeacherResponse>) => {
      await queryClient.setQueryData([TEACHERS_FINANCES_KEY, teacher_id!.toString()], response.data)
      message.success(t('responseSuccess'))
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const onFinish = (values: any) => {
    push({
      search: serialize('date', moment(values['date']).format('YYYY-MM-DD')),
    })

    return mutateAsync(teacher_id)
  }

  const initialDate = getQueryParam('date')

  return (
    <Form onFinish={onFinish}>
      <h2>{t('chooseMonth')}</h2>
      <Form.Item
        name={'date'}
        initialValue={initialDate ? moment(initialDate, 'YYYY-MM-DD') : moment().startOf('month')}
        rules={[{ required: true, message: t('required') }]}
      >
        <DatePicker
          picker={'month'}
          format={'MMMM, YYYY'}
          allowClear={false}
          style={{ width: `100%` }}
        />
      </Form.Item>
      <Form.Item>
        <Button type={'primary'} htmlType={'submit'} block disabled={isLoading} loading={isLoading}>
          <FilterOutlined /> {t('filter')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default React.memo(FinanceTeacherFilterForm)
