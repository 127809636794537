import React, { useState } from 'react'
import { idType } from '../../../interfaces/types/types'
import { CalendarOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Alert, DatePicker, Divider, Form, message, Modal } from 'antd'
import moment from 'moment'
import { useMutation } from 'react-query'
import { changeStudentAddedToGroupDateApi } from '../../../api/student-groups'
import { queryClient } from '../../../index'
import { GROUP_STUDENTS_KEY } from '../../../hooks/query/groups'

interface props {
  groupId: idType
  studentIds: idType[]
  btnStyle?: boolean
}

const ChangeAddToGroupDate: React.FC<props> = ({ groupId, studentIds, btnStyle }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const modalHandler = () => setVisible(!visible)
  const { t } = useTranslation()

  const { isLoading, mutateAsync } = useMutation(changeStudentAddedToGroupDateApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([GROUP_STUDENTS_KEY, groupId!.toString()])
      message.success(t('responseSuccess'))
      modalHandler()
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const onFinish = (values: any) =>
    mutateAsync({
      groupId,
      studentIds,
      addedAt: moment(values.addToGroupDate).format('YYYY-MM-DD'),
    })

  const title = t('changeAddToGroupDate')
  return (
    <React.Fragment>
      <button className={btnStyle ? 'ant-btn' : 'ant-btn-no-style'} onClick={modalHandler}>
        <CalendarOutlined /> <span>{title}</span>
      </button>

      <Modal
        title={title}
        visible={visible}
        onCancel={modalHandler}
        okText={t('submit')}
        okButtonProps={{
          htmlType: 'submit',
          form: 'change-add-to-group',
          disabled: isLoading,
          loading: isLoading,
        }}
        destroyOnClose={true}
        centered
      >
        <Alert
          type={'warning'}
          message={t('changeAddToGroupDateAttention')}
          showIcon={false}
          banner
        />
        <Divider />
        <Form
          onFinish={onFinish}
          id={'change-add-to-group'}
          layout={'vertical'}
          initialValues={{
            addToGroupDate: moment(),
          }}
        >
          <Form.Item name={'addToGroupDate'} rules={[{ required: true, message: t('required') }]}>
            <DatePicker format={'DD MMMM YYYY'} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default ChangeAddToGroupDate
