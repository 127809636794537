import React from 'react'
import { ITestQuestion } from '../../../../../interfaces'
import { Divider, List, Space, Typography } from 'antd'
import styles from './test-question.module.scss'
import { ClockCircleOutlined } from '@ant-design/icons'
import DeleteModal from '../../../../core/delete-modal/delete-modal'
import { QUESTION_BASE_QUESTIONS } from '../../../../../hooks/query/question-bases'
import { deleteQuestionApi } from '../../../../../api/questions'
import TestQuestionDrawer from '../test-question-drawer/test-question-drawer'
import { letters } from '../../../../../lib/letterVariants'
import { idType } from '../../../../../interfaces/types/types'
import { useAuthUserInformation } from '../../../../../hooks/query/auth'
import TestQuestionImage from '../test-question-image/test-question-image'

interface props {
  testId: string
  testQuestion: ITestQuestion
  teacherId: idType
}

const TestQuestion: React.FC<props> = ({ testQuestion, testId, teacherId }) => {
  const { question, allotted_time, image, id, n, variants, variant_id, hint } = testQuestion
  const authUserInformation = useAuthUserInformation()
  const access = teacherId === authUserInformation.data?.id

  return (
    <List.Item
      className={styles.question}
      actions={[
        <Space key={'1'}>
          <ClockCircleOutlined /> <span>{allotted_time}</span>
        </Space>,
      ]}
      extra={[
        access && (
          <Space key={'1'} split={<Divider type={'horizontal'} />}>
            <TestQuestionDrawer question={testQuestion} />
            <DeleteModal
              id={id}
              modalText={'testing:deleteQuestion'}
              deleteFunction={deleteQuestionApi}
              fetchingQueryKey={[QUESTION_BASE_QUESTIONS, testId]}
            />
          </Space>
        ),
      ]}
    >
      <List.Item.Meta
        title={
          <Space direction={'vertical'}>
            <div className={styles.title}>
              <span>{n}.</span>
              <div dangerouslySetInnerHTML={{ __html: question }} />
            </div>
            {image && (
              <TestQuestionImage
                rest={{
                  src: image,
                  height: 150,
                }}
              />
            )}
          </Space>
        }
        description={
          <Space direction={'vertical'}>
            {variants.map(({ text, image, question_id, id }, index) => (
              <React.Fragment key={id}>
                <div className={styles.answer}>
                  <Typography.Text mark={id === variant_id} strong={id === variant_id}>
                    <span>{letters[index]})</span>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                  </Typography.Text>
                </div>
                {image && (
                  <TestQuestionImage
                    rest={{
                      src: image,
                      height: 150,
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </Space>
        }
      />
      {hint && hint !== 'undefined' ? <div dangerouslySetInnerHTML={{ __html: hint }} /> : null}
    </List.Item>
  )
}

export default TestQuestion
