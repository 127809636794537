import React from 'react'
import { Button, Card, Form, Input, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../../interfaces/types/types'
import { addNewTeacherPaymentApi } from '../../../../../api/payments'
import MyDatePicker from '../../../../core/form-group/my-date-picker/my-date-picker'
import SumInput from '../../../../core/form-group/sum-input/sum-input'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../../index'
import { TEACHERS_FINANCES_KEY } from '../../../../../hooks/query/teachers'
import FormTeacherPaymentType from '../../../../core/form-teacher-payment-type/form-teacher-payment-type'

interface props {
  teacherId: idType
}

const FinancesTeacherPayment: React.FC<props> = ({ teacherId }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const { isLoading, mutateAsync } = useMutation(addNewTeacherPaymentApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([TEACHERS_FINANCES_KEY, teacherId!.toString()])
      message.success(t('responseSuccess'))
      form.resetFields()
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  const onFinish = (values: any) => mutateAsync(values)

  return (
    <Card>
      <Form form={form} onFinish={onFinish} layout={'vertical'}>
        <h2>{t('addPayment')}</h2>
        <Form.Item hidden initialValue={teacherId} name={'teacher_id'}>
          <Input />
        </Form.Item>
        <FormTeacherPaymentType />
        <SumInput name={'amount'} label={t('sum')} />
        <MyDatePicker name={'date'} label={'choosePaymentDate'} />
        <Form.Item label={t('comment')} name={'comment'}>
          <Input.TextArea placeholder={t('inputComment')} rows={3} />
        </Form.Item>
        <Form.Item>
          <Button type={'primary'} htmlType={'submit'} disabled={isLoading}>
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default React.memo(FinancesTeacherPayment)
