import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageHeader, Skeleton } from 'antd'
import FinancesGroupsTable from './finances-groups-table/finances-groups-table'
import MyPagination from '../../../core/my-pagination/my-pagination'
import Head from '../../../../hoc/head/head'
import { GROUPS_FINANCES_KEY, useFinancesGroups } from '../../../../hooks/query/groups'
import ErrorAlert from '../../../core/error-alert/error-alert'

interface props {}

const FinancesGroups: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'groups'])

  const { isError, isLoading, error, data } = useFinancesGroups()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { data: groups, ...pagination } = data!

  return (
    <Head title={t('groups')}>
      <PageHeader
        onBack={() => window.history.back()}
        title={t('groups')}
        subTitle={t('groups:groupSubtitle')}
      />
      <FinancesGroupsTable groups={groups} />
      <MyPagination pagination={pagination} fetchingQueryKey={GROUPS_FINANCES_KEY} />
    </Head>
  )
}

export default FinancesGroups
