import React from 'react'
import { Form, TimePicker } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  name?: string
  label?: string
  required?: boolean
}

const LessonDuration: React.FC<props> = ({
  name = 'lesson_duration',
  label = 'lessonDuration',
  required = true,
}) => {
  const { t } = useTranslation()
  return (
    <Form.Item
      name={name}
      label={t(label)}
      rules={[
        {
          required,
          message: t('required'),
        },
      ]}
    >
      <TimePicker
        style={{ width: '100%' }}
        hideDisabledOptions={true}
        allowClear={!required}
        showNow={false}
        minuteStep={10}
        format={'HH:mm'}
      />
    </Form.Item>
  )
}

export default LessonDuration
