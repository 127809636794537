import React from 'react'
import { idType } from '../../../interfaces/types/types'
import { Link } from 'react-router-dom'
import { useRoleChecker } from '../../../hooks/useRoleChecker'

interface props {
  id: idType
  name: string
}

const TeacherLink: React.FC<props> = ({ id, name }) => {
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'teacher', 'cashier', 'accountant', 'reception'])

  return access ? (
    <Link to={'/teachers/details/' + id}>{name}</Link>
  ) : (
    <React.Fragment>{name}</React.Fragment>
  )
}

export default React.memo(TeacherLink)
