import React from 'react'
import { Link } from 'react-router-dom'
import { IGroup } from '../../../../interfaces'
import styles from './dashboard-group.module.scss'
import { ClockCircleOutlined, LayoutOutlined, ReadOutlined, UserOutlined } from '@ant-design/icons'
import { colors } from '../../../../lib/groupDashboardColors'
import isEqual from 'react-fast-compare'

interface props {
  group: IGroup
}

const DashboardGroup: React.FC<props> = ({ group }) => {
  const { name, room, start_time, end_time, course, students_count, id } = group

  return (
    <Link to={'/groups/details/' + id}>
      <div className={styles.group} style={{ backgroundColor: colors[Number(id) % colors.length] }}>
        <div className={styles.wrapper}>
          <span>
            <ClockCircleOutlined /> {start_time} - {end_time}
          </span>
          <span>
            <UserOutlined /> {students_count}
          </span>
        </div>
        <div className={styles.divider} />
        <h4>{name}</h4>
        <h5>
          <ReadOutlined /> {course.name}
        </h5>
        {room && (
          <h5>
            <LayoutOutlined /> {room.name}
          </h5>
        )}
      </div>
    </Link>
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps.group, nextProps.group)
}

export default React.memo(DashboardGroup, propsAreEqual)
