import React from 'react'
import { Card, Col } from 'antd'
import { Link } from 'react-router-dom'
import styles from './finance-card.module.scss'
import { useTranslation } from 'react-i18next'

interface props {
  title: string
  url: string
  icon: React.FC<{ className: any }>
}

const FinanceCard: React.FC<props> = ({ title, url, icon: Icon }) => {
  const { t } = useTranslation()

  return (
    <Col xs={24} lg={12}>
      <Link to={'/' + url}>
        <Card bordered={false} className={styles.card}>
          <h3>
            <Icon className={styles.icon} /> {t(title)}
          </h3>
        </Card>
      </Link>
    </Col>
  )
}

export default FinanceCard
