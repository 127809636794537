import { request as axios } from './index'
import { idType } from '../interfaces/types/types'

const url = '/expense-types'

export function getAllExpenseTypes() {
  return axios.get(url)
}

export function createExpenseType(data: { title: string }) {
  return axios.post(url, data)
}

export function updateExpenseType(data: { title: string; id: idType }) {
  return axios.patch(url + '/' + data.id, { title: data.title })
}
