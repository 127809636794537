import React from 'react'
import moment from 'moment'

interface props {
  date: string
}

const DateWithMonth: React.FC<props> = ({ date }) => {
  return <React.Fragment>{moment(date).format('DD MMMM YYYY')}</React.Fragment>
}

export default DateWithMonth
