import React from 'react'
import styles from './group-attendance-comment.module.scss'
import { Tooltip } from 'antd'
import { MessageTwoTone } from '@ant-design/icons'

interface props {
  comment: string
}

const GroupAttendanceComment: React.FC<props> = ({ comment }) => {
  return (
    <Tooltip title={comment}>
      <MessageTwoTone className={styles.icon} />
    </Tooltip>
  )
}

export default GroupAttendanceComment
