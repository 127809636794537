import React, { useEffect, useState } from 'react'
import styles from './examination.module.scss'
import { Alert, Button, Divider, Form, message, Radio, Space, Spin, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import LogoSmall from '../../../core/logo-small/logo-small'
import { GET_CURRENT_QUESTION_KEY, useCurrentQuestion } from '../../../../hooks/query/testing'
import moment from 'moment'
import { useMutation } from 'react-query'
import { markQuestion, saveAnswerApi } from '../../../../api/testing'
import { queryClient } from '../../../../index'
import { useHistory } from 'react-router-dom'
import LinkButton from '../../../core/link-button/link-button'
import { letters } from '../../../../lib/letterVariants'
import TestVariantWrapper from '../../../core/test-variant-wrapper/test-variant-wrapper'
import TestQuestionImage from '../tests/test-question-image/test-question-image'
import { idType } from '../../../../interfaces/types/types'
import { StarOutlined, StarTwoTone } from '@ant-design/icons'

interface props {
  testId: idType
}

const Examination: React.FC<props> = ({ testId }) => {
  const [highlight, setHighlight] = useState<boolean>(false)
  const highlightQuestion = () => {
    markQuestion(data!.test_session_question_id)
    setHighlight(!highlight)
  }
  const { push } = useHistory()
  const [form] = Form.useForm()
  const [time, setTime] = useState<number | undefined>()
  const { t } = useTranslation(['translation', 'testing'])
  const { isFetching, isSuccess, isError, data } = useCurrentQuestion(testId, {
    retry: false,
  })

  const { isLoading, mutateAsync } = useMutation(saveAnswerApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([GET_CURRENT_QUESTION_KEY, testId])
      form.resetFields()
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  useEffect(() => {
    if (isSuccess && !data) {
      queryClient.removeQueries([GET_CURRENT_QUESTION_KEY, testId])
      return push('/testing/results/test/' + testId)
    }

    setTime(data?.time_left)
  }, [data, isSuccess, push, testId])

  useEffect(() => {
    let interval = setInterval(() => {
      if (time !== undefined && time > 0) {
        setTime(prevState => prevState! - 1)
        clearInterval(interval)
      } else if (time !== undefined && !isLoading) {
        message.warn('Закончилось время на решение вопроса!')
        return skipQuestion(form.getFieldValue('answer'))
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [time, isLoading, isError, form])

  async function skipQuestion(answer?: number) {
    await mutateAsync({ questionId: data?.test_session_question_id, variantId: answer || 0 })
    setHighlight(false)
  }

  async function onFinish(values: any) {
    await mutateAsync({ questionId: data?.test_session_question_id, variantId: values.answer })
    setHighlight(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.logoWrapper}>
        <LogoSmall />
      </div>
      <Spin spinning={isFetching}>
        {isError ? (
          <Alert
            className={styles.errorBanner}
            banner
            message={t('testing:sessionEnd')}
            description={
              <React.Fragment>
                <Typography.Paragraph>{t('testing:sessionEndDescription')}</Typography.Paragraph>
                <LinkButton pathname={'/testing/results/test/' + testId}>
                  {t('testing:showResults')}
                </LinkButton>
              </React.Fragment>
            }
          />
        ) : (
          <React.Fragment>
            <div className={styles.topBar}>
              <span className={styles.questionsCount}>
                {t('questionsCount')}: <br /> {data?.question_number}/{data?.questions_count}
              </span>
              <span className={styles.wastedTime}>
                {t('testing:spentTime')}: <br /> {data?.spent_time} мин
              </span>
            </div>
            <Form form={form} onFinish={onFinish}>
              <h3 className={styles.question}>
                <TestVariantWrapper>
                  <Space align={'start'}>
                    <span>{data?.question_number}.</span>
                    <div dangerouslySetInnerHTML={{ __html: data?.question || '' }} />
                    {highlight ? (
                      <StarTwoTone twoToneColor={'red'} onClick={highlightQuestion} />
                    ) : (
                      <StarOutlined onClick={highlightQuestion} />
                    )}
                  </Space>
                  {data?.question_image && (
                    <div>
                      <TestQuestionImage
                        rest={{
                          src: data.question_image,
                          height: 100,
                        }}
                      />
                    </div>
                  )}
                </TestVariantWrapper>
              </h3>
              <Form.Item
                className={styles.formItem}
                name={'answer'}
                rules={[{ required: true, message: t('testing:chooseAnswer') }]}
              >
                <Radio.Group buttonStyle={'solid'} style={{ width: '100%' }} disabled={isLoading}>
                  <div className={styles.answers}>
                    {data &&
                      data.variants.map(({ id, image, text }, index) => (
                        <div className={styles.answer} key={id}>
                          <Radio.Button value={id}>
                            <TestVariantWrapper>
                              <Space align={'start'}>
                                <strong>{letters[index]})</strong>
                                <div dangerouslySetInnerHTML={{ __html: text }} />
                              </Space>
                            </TestVariantWrapper>
                          </Radio.Button>
                          {image && (
                            <TestQuestionImage
                              rest={{
                                src: image,
                                height: 75,
                              }}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <div className={styles.buttons}>
                  <h1
                    className={[styles.time, time && time < 15 ? styles.heartBeat : ''].join(' ')}
                    style={{ color: time !== undefined && time < 15 ? '#f5222d' : 'black' }}
                  >
                    {time !== undefined && time > 0
                      ? moment.utc(time * 1000).format('mm:ss')
                      : '00:00'}
                  </h1>

                  <Space split={<Divider type={'vertical'} />}>
                    <Button type={'default'} disabled={isLoading} onClick={() => skipQuestion()}>
                      {t('testing:skipQuestion')}
                    </Button>
                    <Button
                      type={'primary'}
                      htmlType={'submit'}
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      {t('submit')}
                    </Button>
                  </Space>
                </div>
              </Form.Item>
            </Form>
          </React.Fragment>
        )}
      </Spin>
    </div>
  )
}

export default Examination
