import React from 'react'
import moment from 'moment'

interface props {
  date: string
}

const DateWithTime: React.FC<props> = ({ date }) => {
  return <span>{moment(date).format('YYYY-MM-DD HH:mm')}</span>
}

export default React.memo(DateWithTime)
