import React from 'react'
import { Alert } from 'antd'

interface props {}

const FinanceReportInfo: React.FC<props> = () => {
  return (
    <Alert message="Все данные высчитываются за один учебный месяц! " type="warning" showIcon />
  )
}

export default FinanceReportInfo
