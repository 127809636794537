import React from 'react'
import Courses from '../../app/modules/courses/courses'

interface props {}

const CoursesPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Courses />
    </React.Fragment>
  )
}

export default CoursesPage
