import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGroupStudentsActivity } from '../../../../hooks/query/groups'
import { Divider, Skeleton, Space, Table, Typography } from 'antd'
import ErrorAlert from '../../../core/error-alert/error-alert'
import moment from 'moment'
import {
  IGroupStudentAttend,
  IGroupStudentAttendsAndGrades,
  IGroupStudentGrade,
  IStudent,
  IStudentGroupPivot,
} from '../../../../interfaces'
import GroupAttendanceStatus from '../group-attendance/group-attendance-status/group-attendance-status'
import GroupGradesMark from '../group-grades/group-grades-mark/group-grades-mark'
import StudentLink from '../../../core/student-link/student-link'
import StudentStatus from '../../students/student-status/student-status'
import { idType } from '../../../../interfaces/types/types'

interface props {}

const GroupActivity: React.FC<props> = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const { data, isLoading, isError } = useGroupStudentsActivity(id)
  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={t('serverError')} />

  const { days, students } = data!

  if (!days.length) {
    return <Typography.Title level={3}>{t('noLessonsOnThisMonth')}!</Typography.Title>
  }

  const renderDays = (
    studentId: idType,
    pivot: IStudentGroupPivot,
    day: string,
    index: number,
    attends: IGroupStudentAttend[] | null[],
    grades: IGroupStudentGrade[] | null[],
  ) => {
    const { excluded_at, transferred_at, old_group_id, added_at } = pivot
    if (old_group_id && moment(added_at) > moment(day)) return null
    if (excluded_at && moment(excluded_at) < moment(day)) return null
    if (transferred_at && moment(transferred_at) < moment(day)) return null

    return (
      <Space split={<Divider type={'vertical'} />}>
        <GroupAttendanceStatus studentId={studentId} day={day} attendance={attends[index]!} />
        <GroupGradesMark studentId={studentId} day={day} grade={grades[index]!} />
      </Space>
    )
  }

  const columns = [
    {
      title: '#',
      key: 'number',
      render: (_: any, record: any, index: number) => <># {index + 1}</>,
    },
    {
      title: t('firstName'),
      key: 'name',
      render: ({ id, pivot, name }: IStudent) => (
        <Space split={<Divider type={'vertical'} />}>
          <StudentLink studentName={name} studentId={id} />
          {pivot.status !== 'active' && <StudentStatus status={pivot.status} />}
        </Space>
      ),
    },
    ...days.map((day, index) => ({
      title: `${moment(day).format('D MMMM')}`,
      align: 'center' as 'center',
      ellipsis: true,
      key: day,
      render: ({ id, attends, grades, pivot }: IGroupStudentAttendsAndGrades) => {
        return renderDays(id, pivot, day, index, attends, grades)
      },
    })),
  ]

  return (
    <Table
      dataSource={students}
      columns={columns}
      scroll={{ x: true }}
      pagination={false}
      rowKey="id"
    />
  )
}

export default React.memo(GroupActivity)
