import React from 'react'
import { Checkbox, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDays } from '../../../hooks/useDays'

interface props {
  name?: string
  required?: boolean
}

const FormDays: React.FC<props> = ({ name = 'days', required = true }) => {
  const { t } = useTranslation()
  const days = useDays()

  return (
    <Form.Item name={name} label={t('days')} rules={[{ required, message: t('required') }]}>
      <Checkbox.Group options={days} />
    </Form.Item>
  )
}

export default FormDays
