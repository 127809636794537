import React from 'react'
import { useGroupsList } from '../../../hooks/query/groups'
import { Form, Select } from 'antd'
import { IGroup } from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import { filterOption } from '../../../lib/filterOption'
import styles from './form-groups-list.module.scss'
import { idType } from '../../../interfaces/types/types'

interface props {
  required?: boolean
  name?: string
  mode?: 'multiple' | 'tags' | 'single'
  allowClear?: boolean
  label?: string
  disabledGroupId?: idType
}

const FormGroupsList: React.FC<props> = ({
  label = 'chooseGroups',
  allowClear = false,
  mode,
  required = true,
  name = 'selected',
  disabledGroupId,
}) => {
  const { t } = useTranslation()
  const { data, isLoading } = useGroupsList()

  return (
    <Form.Item name={name} label={t(label)} rules={[{ required }]}>
      <Select
        className={styles.select}
        allowClear={allowClear}
        disabled={isLoading}
        loading={isLoading}
        mode={mode === 'single' ? undefined : 'multiple'}
        showSearch
        placeholder={t('chooseGroups')}
        filterOption={filterOption}
      >
        {data?.map(({ id, name }: IGroup) => (
          <Select.Option
            key={id}
            label={name}
            value={id!}
            disabled={disabledGroupId === id?.toString()}
          >
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FormGroupsList
