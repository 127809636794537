import React from 'react'
import moment from 'moment'
import { DatePicker, DatePickerProps, Form } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  name: string
  label: string
  disableFutureDays?: boolean
  format?: string
  rest?: DatePickerProps
  required?: boolean
}

const MyDatePicker: React.FC<props> = ({
  name,
  label,
  format = 'DD MMMM YYYY',
  disableFutureDays,
  required = true,
  rest,
}) => {
  const { t } = useTranslation(['translation', 'testing'])

  const checkDates = (date: moment.Moment) => {
    if (disableFutureDays) {
      return date > moment()
    }

    return false
  }

  const style = { width: '100%' }
  return (
    <Form.Item name={name} label={t(label)} rules={[{ required, message: t('required') }]}>
      <DatePicker
        format={format}
        allowClear={!required}
        style={style}
        disabledDate={checkDates}
        {...rest}
      />
    </Form.Item>
  )
}

export default React.memo(MyDatePicker)
