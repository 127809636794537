import { ICourse, ICoursePriceHistory, ICourseResponse } from '../../interfaces'
import {
  getAllCoursesApi,
  getCourseByIdApi,
  getCoursePriceHistory,
  getCoursesListApi,
} from '../../api/course'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { idType } from '../../interfaces/types/types'

export const COURSES_KEY = 'courses_key'
export const COURSES_LIST_KEY = 'courses_list_key'
export const COURSE_PRICE_HISTORY_KEY = 'course_price_history_key'

export function useCourses(options?: UseQueryOptions<ICourseResponse, Error>) {
  return useQuery<ICourseResponse, Error>(
    COURSES_KEY,
    () => getAllCoursesApi().then((response: AxiosResponse<ICourseResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function useCoursesList(options?: UseQueryOptions<ICourse[], Error>) {
  return useQuery<ICourse[], Error>(
    COURSES_LIST_KEY,
    () => getCoursesListApi().then((response: AxiosResponse<ICourse[]>) => response.data),
    {
      ...options,
    },
  )
}

export function useCourse(id: idType, options?: UseQueryOptions<ICourse, Error>) {
  return useQuery<ICourse, Error>(
    [COURSES_KEY, id],
    () => getCourseByIdApi(id).then((response: AxiosResponse<ICourse>) => response.data),
    {
      ...options,
    },
  )
}

export function useCoursePriceHistory(
  id: idType,
  options?: UseQueryOptions<ICoursePriceHistory[], Error>,
) {
  return useQuery<ICoursePriceHistory[], Error>(
    [COURSE_PRICE_HISTORY_KEY, id],
    () =>
      getCoursePriceHistory(id).then(
        (response: AxiosResponse<ICoursePriceHistory[]>) => response.data,
      ),
    {
      ...options,
    },
  )
}
