import React from 'react'
import { useParams } from 'react-router-dom'
import {
  QUESTION_BASE_QUESTIONS,
  useQuestionsBaseQuestions,
} from '../../../../../hooks/query/question-bases'
import { Card, List } from 'antd'
import ErrorAlert from '../../../../core/error-alert/error-alert'
import { ITestQuestion } from '../../../../../interfaces'
import MyPagination from '../../../../core/my-pagination/my-pagination'
import TestQuestion from './test-question'
import TestQuestionsHeader from '../test-questions-header/test-questions-header'
import { idType } from '../../../../../interfaces/types/types'

interface props {
  teacherId: idType
}

const TestQuestions: React.FC<props> = ({ teacherId }) => {
  const { id } = useParams<{ id: string }>()

  const { isLoading, isError, error, data } = useQuestionsBaseQuestions(id)
  if (isError) return <ErrorAlert msg={error?.message} />

  const renderFooter = () => {
    if (isLoading) return <React.Fragment />

    const { data: questions, ...pagination } = data!

    return <MyPagination pagination={pagination} fetchingQueryKey={[QUESTION_BASE_QUESTIONS, id]} />
  }

  return (
    <Card
      bordered={false}
      title={
        <TestQuestionsHeader
          questionsCount={data?.total}
          questionsBaseId={id}
          teacherId={teacherId}
        />
      }
    >
      <List
        loading={isLoading}
        itemLayout={'vertical'}
        dataSource={data?.data}
        footer={renderFooter()}
        renderItem={(item: ITestQuestion) => (
          <TestQuestion testQuestion={item} testId={id} key={item.id} teacherId={teacherId} />
        )}
      />
    </Card>
  )
}

export default TestQuestions
