import React from 'react'
import { Divider, PageHeader, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import FinancesDebtorTable from './finances-debtor-table/finances-debtor-table'
import Head from '../../../../hoc/head/head'
import { DEBTORS_KEY, useDebtors } from '../../../../hooks/query/debtors'
import ErrorAlert from '../../../core/error-alert/error-alert'
import FinancesDebtorsFilter from './finances-debtors-filter/finances-debtors-filter'
import MyPagination from '../../../core/my-pagination/my-pagination'

interface props {}

const FinancesDebtors: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'debtors'])

  const { isLoading, isError, data: response, error } = useDebtors()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const {
    debtors: { data, ...pagination },
    debtors_sum,
    debtors_count,
  } = response!

  return (
    <Head title={t('debtors')}>
      <PageHeader
        title={t('debtors')}
        subTitle={t('debtors:debtorsSubtitle')}
        onBack={() => window.history.back()}
      />
      <FinancesDebtorsFilter />
      <Divider />
      <FinancesDebtorTable debtors={data} debtors_count={debtors_count} debtors_sum={debtors_sum} />
      <MyPagination fetchingQueryKey={DEBTORS_KEY} pagination={pagination} />
    </Head>
  )
}

export default FinancesDebtors
