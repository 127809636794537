import React from 'react'
import { DatePicker, Form } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface props {
  name?: string
  groupStartDate: string
  groupEndDate: string
}

const FormStudyMonthPicker: React.FC<props> = ({ name = 'date', groupEndDate, groupStartDate }) => {
  const { t } = useTranslation()

  const style = { width: '100%' }
  return (
    <Form.Item
      name={name}
      label={t('selectStudyMonth')}
      rules={[{ required: true, message: t('required') }]}
    >
      <DatePicker
        format={'MMMM'}
        picker={'month'}
        allowClear={false}
        style={style}
        disabledDate={date =>
          date < moment(groupStartDate).startOf('month') ||
          date > moment(groupEndDate).endOf('month')
        }
      />
    </Form.Item>
  )
}

export default FormStudyMonthPicker
