import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Select, Tag } from 'antd'
import { useDays } from '../../../../hooks/useDays'

interface props {
  name?: string
  label?: string
  required?: boolean
}

const EducationDaysSelect: React.FC<props> = ({ name = 'days', label = 'days', required }) => {
  const { t } = useTranslation()
  const days = useDays()

  const style = { width: '100%' }

  const customTags = (props: any) => {
    return (
      <Tag color={'blue'} closable={false}>
        {props.label}
      </Tag>
    )
  }
  return (
    <Form.Item name={name} label={t(label)} rules={[{ required }]}>
      <Select
        mode={'multiple'}
        style={style}
        placeholder={t('chooseEducationDays')}
        tagRender={customTags}
        allowClear={true}
      >
        {days.map(({ value, label }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default EducationDaysSelect
