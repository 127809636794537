import { request as axios } from './index'
import { idType } from '../interfaces/types/types'
import { IRoom } from '../interfaces'

export function getAllRoomsApi() {
  return axios.get('/rooms')
}

export function getRoomsListApi() {
  return axios.get('/rooms/list')
}

export function getRoomByIdApi(id: idType) {
  return axios.get(`/rooms/${id}`)
}

export function updateRoomApi(room: IRoom) {
  return axios.patch(`/rooms/${room.id}`, room)
}

export function deleteRoomApi(id: idType) {
  return axios.delete(`/rooms/${id}`)
}

export function createRoomApi(room: IRoom) {
  return axios.post('/rooms', room)
}
