import React from 'react'
import { PageHeader, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import FinancesTeachersTable from './finances-teachers-table/finances-teachers-table'
import MyPagination from '../../../core/my-pagination/my-pagination'
import Head from '../../../../hoc/head/head'
import { TEACHERS_FINANCES_KEY, useFinancesTeachers } from '../../../../hooks/query/teachers'
import ErrorAlert from '../../../core/error-alert/error-alert'

interface props {}

const FinancesTeachers: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'teachers'])

  const { isLoading, isError, error, data } = useFinancesTeachers()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { data: teachers, ...pagination } = data!

  return (
    <Head title={t('teachers')}>
      <PageHeader
        onBack={() => window.history.back()}
        title={t('teachers')}
        subTitle={t('teachers:teachersSubtitle')}
      />
      <FinancesTeachersTable teachers={teachers} />
      <MyPagination pagination={pagination} fetchingQueryKey={TEACHERS_FINANCES_KEY} />
    </Head>
  )
}

export default FinancesTeachers
