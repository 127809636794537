import React from 'react'
import styles from './logo-small.module.scss'
import logo from '../../../logo.png'

interface props {}

const LogoSmall: React.FC<props> = () => {
  return (
    <div className={styles.logoSmall}>
      <img src={logo} alt="SPM" />
    </div>
  )
}

export default LogoSmall
