import React from 'react'
import { Form, Input } from 'antd'
import { ICourse } from '../../../../interfaces'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import NameInput from '../../../core/form-group/name-input/name-input'
import StatusSwitch from '../../../core/form-group/status-switch/status-switch'
import SumInput from '../../../core/form-group/sum-input/sum-input'
import CourseParentSelect from '../../../core/course-parent-select/course-parent-select'
import LessonDuration from '../../../core/form-group/lesson-duration/lesson-duration'
import FormCourseType from '../../../core/form-group/form-course-type/form-course-type'

interface props {
  formId: string
  submitForm: any
  item?: ICourse
}

const CoursesForm: React.FC<props> = ({ item, formId, submitForm }) => {
  const { t } = useTranslation(['translation', 'course'])

  let course = {}
  if (item)
    course = {
      ...item,
      lesson_duration: moment(item.lesson_duration, 'HH:mm'),
    }

  return (
    <Form id={formId} onFinish={submitForm} initialValues={course} layout={'vertical'}>
      <Form.Item hidden={true} name={'id'}>
        <Input />
      </Form.Item>
      <NameInput label={'name'} />
      <SumInput name={'price'} label={t('price')} />
      <LessonDuration />
      <CourseParentSelect activeCourseId={item?.id} />
      <FormCourseType />
      <Form.Item name="description" label={t('description')}>
        <Input.TextArea rows={6} />
      </Form.Item>

      <StatusSwitch />
    </Form>
  )
}

export default CoursesForm
