import React from 'react'
import Tests from '../../../app/modules/testing/tests/tests'

interface props {}

const TestsPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Tests />
    </React.Fragment>
  )
}

export default TestsPage
