import React from 'react'
import Group from '../../../app/modules/groups/group'

interface props {}

const GroupPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Group />
    </React.Fragment>
  )
}

export default GroupPage
