import React, { useEffect, useState } from 'react'
import { Progress } from 'antd'

interface props {
  loaded: boolean
  isError: boolean
}

const AppLoaderProgressbar: React.FC<props> = ({ loaded, isError }) => {
  const [time, setTime] = useState(1000)
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    loaded && setProgress(100)
  }, [loaded])

  useEffect(() => {
    let interval = setInterval(() => {
      if (progress < 50) {
        setProgress(prevState => prevState + 25)
      } else if (progress >= 50 && progress < 60) {
        setProgress(prevState => prevState + 10)
        setTime(50)
      } else if (progress >= 60 && progress < 90) {
        setProgress(prevState => prevState + 1)
      }

      clearInterval(interval)
    }, time)

    return function cleanup() {
      clearInterval(interval)
    }
  }, [progress, time])

  const style = { marginBottom: 10 }

  return (
    <Progress
      percent={progress}
      strokeWidth={8}
      strokeColor={{ from: '#ff9c6e', to: '#ff4d4f' }}
      status={isError ? 'exception' : undefined}
      style={style}
    />
  )
}

export default AppLoaderProgressbar
