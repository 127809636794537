import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IStudent, ITeacher } from '../../../../interfaces'
import { Drawer, message } from 'antd'
import DrawerOpen from '../../../core/drawer/drawer-open/drawer-open'
import { drawerSettings } from '../../../../lib/settings'
import DrawerFooter from '../../../core/drawer/drawer-footer/drawer-footer'
import StudentsForm from '../students-form/students-form'
import isEqual from 'react-fast-compare'
import { registerStudentApi, updateStudentApi } from '../../../../api/students'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../index'
import { STUDENTS_KEY } from '../../../../hooks/query/students'

interface props {
  title: string
  student?: Omit<IStudent, 'groups' | 'payments'>
  updateOne?: boolean
}

const StudentsDrawer: React.FC<props> = ({ student, updateOne, title }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const drawerToggle = () => setVisible(!visible)

  const onSuccess = async () => {
    await queryClient.invalidateQueries(
      updateOne ? [STUDENTS_KEY, student!.id!.toString()] : STUDENTS_KEY,
    )
    message.success(t('responseSuccess'))
    drawerToggle()
  }

  const onError = (e: any) => {
    message.error(e.response.data.message)
  }

  const { isLoading: isRegisterLoading, mutateAsync: createStudent } = useMutation(
    registerStudentApi,
    {
      onSuccess,
      onError,
    },
  )
  const { isLoading: isUpdateLoading, mutateAsync: updateStudent } = useMutation(updateStudentApi, {
    onSuccess,
    onError,
  })

  const { t } = useTranslation(['translation', 'students'])

  const submitForm = async (fieldsValues: any) => {
    const values: ITeacher = {
      ...fieldsValues,
      birthday: fieldsValues['birthday'] && fieldsValues['birthday'].format('YYYY-MM-DD'),
      status: fieldsValues['status'] || false,
    }

    return student ? updateStudent(values) : createStudent(values)
  }

  return (
    <>
      <DrawerOpen drawerToggle={drawerToggle} edit={student} />
      <Drawer
        {...drawerSettings}
        onClose={drawerToggle}
        title={t('students:' + title)}
        visible={visible}
        footer={
          <DrawerFooter
            disabled={isRegisterLoading || isUpdateLoading}
            drawerToggle={drawerToggle}
            formId={'finances-students-form'}
          />
        }
      >
        <StudentsForm item={student} formId={'finances-students-form'} submitForm={submitForm} />
      </Drawer>
    </>
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps, nextProps)
}

export default React.memo(StudentsDrawer, propsAreEqual)
