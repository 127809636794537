import React from 'react'
import PaymentsToTeachers from '../../../app/modules/payments-to-teachers/payments-to-teachers'

interface props {}

const PaymentsToTeachersPage: React.FC<props> = () => {
  return (
    <>
      <PaymentsToTeachers />
    </>
  )
}

export default PaymentsToTeachersPage
