import React from 'react'
import MyTable from '../../../../core/my-table/my-table'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../../interfaces/types/types'
import { IGroup } from '../../../../../interfaces'
import { Link } from 'react-router-dom'
import FinancesGroupDifference from '../../finance-difference/finances-difference'
import { GROUPS_FINANCES_KEY } from '../../../../../hooks/query/groups'
import TableMobileTitle from '../../../../core/table-mobile-title/table-mobile-title'

interface props {
  groups: IGroup[]
}

const FinancesGroupsTable: React.FC<props> = ({ groups }) => {
  const { t } = useTranslation(['translation', 'groups'])

  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      render: (text: idType) => (
        <>
          <TableMobileTitle title={t('id')} /># {text}
        </>
      ),
    },
    {
      title: t('name'),
      render: ({ id, name }: IGroup) => (
        <>
          <TableMobileTitle title={t('name')} />
          <Link to={`/finances/groups/${id}`}>{name}</Link>
        </>
      ),
    },
    {
      title: t('groups:studentsCount'),
      dataIndex: 'students_count',
      render: (text: number) => (
        <>
          <TableMobileTitle title={'groups:studentsCount'} />
          {text}
        </>
      ),
    },
    {
      title: t('balance'),
      dataIndex: 'balance',
      render: (text: number) => (
        <>
          <TableMobileTitle title={t('balance')} />
          <FinancesGroupDifference difference={text} />
        </>
      ),
    },
  ]

  return <MyTable columns={columns} data={groups} fetchingQueryKey={GROUPS_FINANCES_KEY} />
}

export default React.memo(FinancesGroupsTable)
