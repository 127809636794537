import React from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { IFinanceTeacherGroup } from '../../../../../interfaces'
import { Link } from 'react-router-dom'
import FinancesGroupDifference from '../../finance-difference/finances-difference'
import { priceFormatter } from '../../../../../lib/priceFormatter'
import isEqual from 'react-fast-compare'
import FinanceDifference from '../../finance-difference/finances-difference'

interface props {
  groups: IFinanceTeacherGroup[]
}

const FinanceTeacherGroups: React.FC<props> = ({ groups }) => {
  const { t } = useTranslation(['translation', 'groups'])

  const columns = [
    {
      title: t('id'),
      dataIndex: 'group_id',
      render: (text: string) => <span># {text}</span>,
    },
    {
      title: t('name'),
      key: 'name',
      render: ({ group_id, group_name }: IFinanceTeacherGroup) => (
        <Link to={`/groups/details/${group_id}`}>{group_name}</Link>
      ),
    },
    {
      title: t('groups:studentsCount'),
      dataIndex: 'students_count',
    },
    {
      title: t('numberOfLessons'),
      dataIndex: 'lessons_count',
    },
    {
      title: t('missedForReason'),
      dataIndex: 'reasons_count',
    },
    {
      title: t('reasonSum'),
      dataIndex: 'reasons_total',
      render: (number: number) => <span>{priceFormatter(number)}</span>,
    },
    {
      title: t('groupCost'),
      dataIndex: 'total',
      render: (number: number) => <span>{priceFormatter(number)}</span>,
    },
    {
      title: t('receivedFromGroup'),
      dataIndex: 'paid',
      render: (number: number) => <FinanceDifference difference={number} />,
    },
    {
      title: t('difference'),
      dataIndex: 'difference',
      render: (number: number) => <FinancesGroupDifference difference={number} />,
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={groups}
      scroll={{ x: true }}
      pagination={false}
      rowKey={'group_id'}
    />
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps.groups, nextProps.groups)
}

export default React.memo(FinanceTeacherGroups, propsAreEqual)
