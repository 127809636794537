import React from 'react'
import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { CheckCircleOutlined, HighlightOutlined } from '@ant-design/icons'

interface props {
  submitGrades: any
  loading: boolean
  mark: number
}

const GroupGradesForm: React.FC<props> = ({ submitGrades, mark, loading }) => {
  const { t } = useTranslation()

  const onFinish = (values: any) => {
    submitGrades(values.mark)
  }

  return (
    <Form initialValues={{ mark }} onFinish={onFinish} layout={'inline'}>
      <Form.Item name="mark" rules={[{ required: true, message: t('required') }]}>
        <Input max={5} prefix={<HighlightOutlined />} placeholder={t('inputGrade')} />
      </Form.Item>
      <Form.Item>
        <Button type={'primary'} htmlType={'submit'} loading={loading} disabled={loading}>
          <CheckCircleOutlined />
        </Button>
      </Form.Item>
    </Form>
  )
}

export default GroupGradesForm
