import React from 'react'
import { Button, Form, Input, Space } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

interface props {
  name: string
  label?: string
}

const AdditionalInputs: React.FC<props> = ({ name, label }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <h3>{t('additionalInformation')}</h3>

      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                  {...restField}
                  name={[name, 'key']}
                  fieldKey={[fieldKey, 'key']}
                  rules={[{ required: true, message: t('required') }]}
                >
                  <Input placeholder="Название поля" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'value']}
                  fieldKey={[fieldKey, 'value']}
                  rules={[{ required: true, message: t('required') }]}
                >
                  <Input placeholder="Значение поля" />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                {t('addInfoField')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </React.Fragment>
  )
}

export default AdditionalInputs
