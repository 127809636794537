import React from 'react'
import ImagesBlock from './images-block/images-block'
import LayoutLanguage from '../layout/layout-language/layout-language'
import LayoutFooter from '../layout/layout-footer/layout-footer'
import styles from './public-layout.module.scss'
import Logo from '../../app/core/logo/logo'

interface props {}

const PublicLayout: React.FC<props> = ({ children }) => {
  return (
    <div className={styles.layout}>
      <ImagesBlock />
      <div className={styles.container}>
        <div className={styles.language}>
          <LayoutLanguage />
        </div>
        <div className={styles.content}>
          <Logo />
          {children}
        </div>
        <div className={styles.footer}>
          <LayoutFooter />
        </div>
      </div>
    </div>
  )
}

export default PublicLayout
