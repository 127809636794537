import React from 'react'
import { Button, Form, message, Modal, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { UsergroupAddOutlined } from '@ant-design/icons'
import { idType } from '../../../../interfaces/types/types'
import { IAddStudentToGroup } from '../../../../interfaces'
import { addStudentToGroupsApi } from '../../../../api/students'
import moment from 'moment'
import MyDatePicker from '../../../core/form-group/my-date-picker/my-date-picker'
import { STUDENT_GROUPS_KEY } from '../../../../hooks/query/students'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../index'
import FormGroupsList from '../../../core/form-groups-list/form-groups-list'

interface props {
  studentId: idType
  hideText?: boolean
}

const StudentsAddToGroup: React.FC<props> = ({ studentId, hideText }) => {
  const { t } = useTranslation(['translation', 'students'])
  const [form] = Form.useForm()

  const { isLoading, mutateAsync } = useMutation(addStudentToGroupsApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([STUDENT_GROUPS_KEY, studentId!.toString()])
      setVisible(false)
      form.resetFields()
      message.success(t('responseSuccess'))
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  const [visible, setVisible] = React.useState(false)

  const modalHandler = () => setVisible(true)
  const cancelHandler = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinish = async ({ selected, added_at }: any) => {
    const selectedGroups: IAddStudentToGroup[] = selected.map((id: idType) => ({
      student_id: studentId,
      group_id: id,
      added_at: moment(added_at).format('YYYY-MM-DD'),
    }))

    return mutateAsync({ id: studentId, data: selectedGroups })
  }

  return (
    <React.Fragment>
      <Tooltip title={t('students:addToGroups')}>
        <Button type={'ghost'} onClick={modalHandler}>
          <UsergroupAddOutlined />
          {!hideText && t('students:addToGroups')}
        </Button>
      </Tooltip>
      <Modal
        title={t('students:addToGroups')}
        visible={visible}
        confirmLoading={isLoading}
        onCancel={cancelHandler}
        cancelText={t('cancel')}
        okText={t('submit')}
        onOk={form.submit}
      >
        <Form form={form} onFinish={onFinish} layout={'vertical'}>
          <FormGroupsList />
          <MyDatePicker name={'added_at'} label={'chooseAddDate'} />
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default React.memo(StudentsAddToGroup)
