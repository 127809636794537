import React, { useState } from 'react'
import { Button, Form, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { PlusCircleOutlined } from '@ant-design/icons'
import { IAddStudentToGroup, IStudent } from '../../../../interfaces'
import { idType } from '../../../../interfaces/types/types'
import { studentSearchApi } from '../../../../api/students'
import { postStudentsToGroupApi } from '../../../../api/groups'
import moment from 'moment'
import MyDatePicker from '../../../core/form-group/my-date-picker/my-date-picker'
import DebounceSelect from '../../../core/debounce-select/debounce-select'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../index'
import { GROUP_STUDENTS_KEY } from '../../../../hooks/query/groups'
import { useParams } from 'react-router-dom'

interface props {}

interface ISelectedStudent {
  label: string
  key: number
  value: idType
}

const GroupAddStudents: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const [data, setData] = useState<ISelectedStudent[]>([])

  const setNewData = (newData: ISelectedStudent[]) => setData(newData)

  const [form] = Form.useForm()

  const { mutateAsync: searchStudent } = useMutation(studentSearchApi)
  const handleSearch = (value: string) =>
    searchStudent(value).then(response =>
      response.data.map(({ name, id }: IStudent) => ({
        label: name,
        value: id,
      })),
    )

  const [modal, setModal] = useState<boolean>(false)

  const { t } = useTranslation(['translation', 'groups'])

  const modalHandler = () => setModal(!modal)

  const { isLoading, mutateAsync: postStudentToGroup } = useMutation(postStudentsToGroupApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([GROUP_STUDENTS_KEY, id])
      message.success(t('responseSuccess'))
      form.resetFields()
      modalHandler()
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  const onFinish = async ({ selected, added_at }: any) => {
    const selectedStudents: IAddStudentToGroup[] = selected.map(({ value }: ISelectedStudent) => ({
      group_id: id,
      student_id: value,
      added_at: moment(added_at).format('YYYY-MM-DD'),
    }))

    return postStudentToGroup({ id, data: selectedStudents })
  }

  return (
    <>
      <Button onClick={modalHandler}>
        <PlusCircleOutlined />
        {t('groups:addStudents')}
      </Button>
      <Modal
        title={t('groups:addStudents')}
        visible={modal}
        okButtonProps={{ form: 'group-add-students', htmlType: 'submit' }}
        okText={t('submit')}
        cancelText={t('cancel')}
        confirmLoading={isLoading}
        onCancel={modalHandler}
      >
        <Form form={form} id="group-add-students" layout={'vertical'} onFinish={onFinish}>
          <Form.Item name={'selected'} label={t('groups:chooseStudents')}>
            <DebounceSelect
              mode="multiple"
              value={data}
              placeholder={t('groups:chooseStudents')}
              onChange={setNewData}
              fetchOptions={handleSearch}
            />
          </Form.Item>
          <MyDatePicker name={'added_at'} label={'chooseAddDate'} />
        </Form>
      </Modal>
    </>
  )
}

export default GroupAddStudents
