import React from 'react'
import { Button, Form, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { loginApi } from '../../../../api/auth'
import PhoneInput from '../../../core/form-group/phone-input/phone-input'
import PasswordInput from '../../../core/form-group/password-input/password-input'
import { useMutation } from 'react-query'
import { ACCESS_TOKEN } from '../../../../constants/auth'
import { useHistory, useLocation } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { IAuthResponse } from '../../../../interfaces'

interface props {}

const AuthForm: React.FC<props> = () => {
  const location = useLocation()
  const { replace } = useHistory()
  const { t } = useTranslation(['translation', 'auth'])

  const { mutateAsync, isLoading } = useMutation(loginApi, {
    onSuccess: (response: AxiosResponse<IAuthResponse>) => {
      let { from }: any = location.state || { from: { pathname: '/dashboard' } }
      localStorage.setItem(ACCESS_TOKEN, response.data.access_token)
      message.success(t('auth:success'))
      replace(from)
    },
    onError: () => {
      message.error(t('auth:error'))
    },
  })

  const onFinish = async ({ phone, password }: any) => mutateAsync({ phone, password })

  return (
    <Form layout={'vertical'} name="auth-form" className="auth-form" onFinish={onFinish}>
      <PhoneInput />
      <PasswordInput />

      <Form.Item>
        <Button
          type="primary"
          block={true}
          htmlType="submit"
          loading={isLoading}
          disabled={isLoading}
        >
          {t('signIn')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AuthForm
