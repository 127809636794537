import React, { useEffect, useRef } from 'react'
import styles from './leads-columns.module.scss'
import LeadsColumn from './leads-column/leads-column'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { IOrderColumn } from '../../../../interfaces'
import { useOnDragEnd } from '../../../../hooks/useOnDragEnd'

interface props {
  columns: IOrderColumn[]
}

const LeadsColumns: React.FC<props> = ({ columns }) => {
  const onDragEnd = useOnDragEnd()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const slider = ref.current
    let mouseDown = false
    let startX: number = 0,
      scrollLeft = 0

    if (slider) {
      const startDragging = (e: MouseEvent) => {
        if (e.target === slider) {
          mouseDown = true
          startX = e.pageX - slider.offsetLeft
          scrollLeft = slider.scrollLeft
        }
      }

      const stopDragging = () => {
        mouseDown = false
      }

      const mouseMove = (e: MouseEvent) => {
        e.preventDefault()
        if (!mouseDown) return

        const x = e.pageX - slider.offsetLeft
        const scroll = x - startX
        slider.scrollLeft = scrollLeft - scroll
      }

      slider.addEventListener('mousemove', mouseMove)

      // Add the event listeners
      slider.addEventListener('mousedown', startDragging, false)
      slider.addEventListener('mouseup', stopDragging, false)
      slider.addEventListener('mouseleave', stopDragging, false)
    }
  }, [])

  return (
    <div className={styles.wrapper} ref={ref}>
      <DragDropContext onDragEnd={(result: DropResult) => onDragEnd(result, columns)}>
        {columns.map(column => (
          <LeadsColumn key={column.id} column={column} />
        ))}
      </DragDropContext>
    </div>
  )
}

export default LeadsColumns
