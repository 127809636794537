import React from 'react'
import { IBranch, IUser, IUserRole } from '../../../../interfaces'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { idType } from '../../../../interfaces/types/types'
import UserRole from '../user-role/user-role'
import MyTable from '../../../core/my-table/my-table'
import UsersAction from '../users-action/users-action'
import StatusTag from '../../../core/status-tag/status-tag'
import PhoneNumber from '../../../core/phone-number/phone-number'
import { USERS_KEY } from '../../../../hooks/query/users'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'
import DateWithMonth from '../../../core/date-with-month/date-with-month'
import UserBranches from '../../../core/user-branches/user-branches'

interface props {
  users: IUser[]
}

const UsersTable: React.FC<props> = ({ users }) => {
  const { t } = useTranslation()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text: idType) => (
        <>
          <TableMobileTitle title={'ID'} /># {text}
        </>
      ),
    },
    {
      title: t('firstName'),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: IUser) => (
        <>
          <TableMobileTitle title={t('firstName')} />
          <Link to={`/profile/details/${record.id}`}>{text}</Link>
        </>
      ),
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      key: 'phone',
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('phone')} />
          <PhoneNumber phone={text} />
        </>
      ),
    },
    {
      title: t('role'),
      dataIndex: 'roles',
      key: 'roles',
      render: (roles: IUserRole[]) => (
        <>
          <TableMobileTitle title={t('role')} />
          {roles.map(role => (
            <UserRole key={role.id} role={role.slug} />
          ))}
        </>
      ),
    },
    {
      title: t('branches'),
      dataIndex: 'branches',
      render: (branches: IBranch[]) => <UserBranches branches={branches} />,
    },
    {
      title: t('createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text: string) => <DateWithMonth date={text} />,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: number) => (
        <>
          <TableMobileTitle title={t('status')} />
          <StatusTag status={text} />
        </>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      render: (_: any, record: IUser) => <UsersAction user={record} />,
    },
  ]

  return <MyTable fetchingQueryKey={USERS_KEY} columns={columns} data={users} />
}

export default React.memo(UsersTable)
