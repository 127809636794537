import React from 'react'
import Result from '../../../app/modules/testing/result/result'

interface props {}

const ResultPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Result />
    </React.Fragment>
  )
}

export default ResultPage
