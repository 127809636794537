import { IStudentPayment, ITeacherPayment } from '../interfaces'
import { request as axios } from './index'
import { idType } from '../interfaces/types/types'

export function getStudentsPaymentsApi() {
  return axios.get('/payments')
}

export function addNewStudentPaymentApi(values: IStudentPayment) {
  return axios.post('/payments', values)
}

export function changeStudentPaymentApi(values: IStudentPayment) {
  return axios.patch('/payments/' + values.id, values)
}

export function deleteStudentPaymentApi(id: idType) {
  return axios.delete('/payments/' + id)
}

export function addNewTeacherPaymentApi(values: ITeacherPayment) {
  return axios.post('/salaries', values)
}

export function deleteTeacherPaymentApi(id: idType) {
  return axios.delete('/salaries/' + id)
}
