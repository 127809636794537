import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { IExpenseType } from '../../interfaces'
import { getAllExpenseTypes } from '../../api/expense-types'

export const EXPENSE_TYPE_KEY = 'expense_type_key'

export function useExpenseTypes(options?: UseQueryOptions<IExpenseType[], Error>) {
  return useQuery<IExpenseType[], Error>(
    EXPENSE_TYPE_KEY,
    () => getAllExpenseTypes().then((response: AxiosResponse<IExpenseType[]>) => response.data),
    {
      ...options,
    },
  )
}
