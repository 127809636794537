import React from 'react'
import { Col, PageHeader, Row, Skeleton, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import GroupCard from '../../groups/group-card/group-card'
import FinancesGroupPayment from './finances-group-payment/finances-group-payment'
import Head from '../../../../hoc/head/head'
import GroupFinanceRecalculation from '../../groups/group-finance-recalculation/group-finance-recalculation'
import { useFinancesGroup } from '../../../../hooks/query/groups'
import ErrorAlert from '../../../core/error-alert/error-alert'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import FinancesGroupInfo from '../../../core/finances-group-info/finances-group-info'
import LinkButton from '../../../core/link-button/link-button'

interface props {}

const FinancesGroup: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'groups'])
  const { id } = useParams<{ id: string }>()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'cashier'])

  const { isError, isLoading, error, data } = useFinancesGroup(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { group, students, totals, payments, summaries } = data!

  return (
    <Head title={group.name}>
      <PageHeader
        title={group.name}
        subTitle={t('groups:groupSubtitle')}
        onBack={() => window.history.back()}
        extra={[
          <Space key={'1'}>
            <LinkButton pathname={'/groups/details/' + id}>{t('groups:goToGroup')}</LinkButton>
            <GroupFinanceRecalculation groupId={group.id} />
          </Space>,
        ]}
      />
      <Row gutter={[32, 32]}>
        <Col xs={24} xl={15}>
          <GroupCard group={group} />
        </Col>
        <Col xs={24} xl={9}>
          {access && <FinancesGroupPayment startDate={group.start_date} endDate={group.end_date} />}
        </Col>
        <Col xs={24}>
          <FinancesGroupInfo
            groupId={id}
            groupStartDate={group.start_date}
            groupEndDate={group.end_date}
            groupTotals={totals}
            payments={payments}
            students={students}
            summaries={summaries}
          />
        </Col>
      </Row>
    </Head>
  )
}

export default FinancesGroup
