import React from 'react'
import { Divider, Space, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { IFinanceGroupPayment, IGroup, IStudentPayment } from '../../../../../interfaces'
import StudentPaymentMethod from '../../../students/student-payments/student-payment-method/student-payment-method'
import { priceFormatter } from '../../../../../lib/priceFormatter'
import { ColumnsType } from 'antd/es/table'
import StudentLink from '../../../../core/student-link/student-link'
import moment from 'moment'
import { usePaymentTableFilters } from '../../../../../hooks/usePaymentTableFilters'
import StudentPaymentsDrawer from '../../../students/student-payments/student-payments-drawer/student-payments-drawer'
import DeleteModal from '../../../../core/delete-modal/delete-modal'
import { deleteStudentPaymentApi } from '../../../../../api/payments'
import { GROUPS_FINANCES_KEY } from '../../../../../hooks/query/groups'
import { idType } from '../../../../../interfaces/types/types'
import DateWithMonth from '../../../../core/date-with-month/date-with-month'

interface props {
  payments: IFinanceGroupPayment[]
  groupId: idType
  groupStartDate: string
  groupEndDate: string
}

const FinancesGroupReport: React.FC<props> = ({
  payments,
  groupId,
  groupStartDate,
  groupEndDate,
}) => {
  const { t } = useTranslation()
  const paymentFilters = usePaymentTableFilters()

  const filters = payments
    ?.map(({ name }: IFinanceGroupPayment) => ({
      text: name,
      value: name,
    }))
    .filter((filter, index, self) => index === self.findIndex(t => t.value === filter.value))

  const columns: ColumnsType<IFinanceGroupPayment> = [
    {
      title: t('id'),
      dataIndex: 'id',
      render: (text: string) => <span># {text}</span>,
    },
    {
      title: t('firstName'),
      key: 'student',
      filters,
      onFilter: (value, record) => record.name.includes(value as string),
      render: ({ student_id, name }: IFinanceGroupPayment) => (
        <StudentLink studentId={student_id} studentName={name} />
      ),
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      render: (text: string) => <span>{priceFormatter(text)}</span>,
    },
    {
      title: t('paidFor'),
      dataIndex: 'date',
      render: (date: string) => <span>{moment(date).format('MMMM').toUpperCase()}</span>,
    },
    {
      title: t('paymentDate'),
      dataIndex: 'created_at',
      render: (date: string) => <DateWithMonth date={date} />,
    },
    {
      title: t('paymentMethod'),
      dataIndex: 'method',
      filters: paymentFilters,
      onFilter: (value, record) => record.method.indexOf(value as string) === 0,
      render: (text: string) => <StudentPaymentMethod method={text} />,
    },
    {
      title: t('comment'),
      dataIndex: 'comment',
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right' as 'right',
      render: (payment: IStudentPayment) => (
        <Space split={<Divider type={'horizontal'} />}>
          <StudentPaymentsDrawer
            btnStyle={true}
            paymentQueries={[[GROUPS_FINANCES_KEY, groupId!.toString()]]}
            payment={{
              ...payment,
              group: {
                start_date: groupStartDate,
                end_date: groupEndDate,
              } as IGroup,
            }}
            student={{ id: payment.student_id, name: payment.name }}
            groupId={groupId}
          />
          <DeleteModal
            id={payment.id}
            modalText={'teachers:deleteTeacherPayment'}
            deleteFunction={deleteStudentPaymentApi}
            fetchingQueryKey={[GROUPS_FINANCES_KEY, groupId!.toString()]}
          />
        </Space>
      ),
    },
  ]

  return (
    <Table
      pagination={false}
      columns={columns}
      scroll={{ x: true }}
      rowKey={'id'}
      dataSource={payments}
    />
  )
}

export default React.memo(FinancesGroupReport)
