import React, { useState } from 'react'
import { Col, Form, Row, Select } from 'antd'
import FilterButtons from '../../../core/form-group/filter-buttons/filter-buttons'
import { useTranslation } from 'react-i18next'
import FormTeachersList from '../../../core/form-teachers-list/form-teachers-list'
import FormCoursesList from '../../../core/form-courses-list/form-courses-list'
import FormRoomsList from '../../../core/form-rooms-list/form-rooms-list'
import { useHistory } from 'react-router-dom'
import EducationDaysSelect from '../../../core/form-group/education-days-select/education-days-select'
import StartTime from '../../../core/form-group/start-time/start-time'
import { serialize } from '../../../../lib/serializeQuery'
import moment from 'moment'
import { queryClient } from '../../../../index'
import { GROUPS_KEY } from '../../../../hooks/query/groups'
import queryString from 'query-string'
import MyDatePicker from '../../../core/form-group/my-date-picker/my-date-picker'
import { grid } from '../../../../constants/filtersGrid'
import SearchInput from '../../../core/form-group/search-input/search-input'
import FormItemSelect from '../../../core/form-group/form-item-select/form-item-select'

interface props {}

const GroupsFilters: React.FC<props> = () => {
  const [form] = Form.useForm()
  const { push, location } = useHistory()
  const { t } = useTranslation(['translation', 'groups'])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onFinish = async (values: any) => {
    setIsLoading(true)
    const query = {
      ...values,
      start_time: values.start_time && moment(values.start_time).format('HH:mm'),
      created_at: values.created_at && moment(values.created_at).format('YYYY-MM-DD'),
    }

    await push({ search: serialize(query) })
    await queryClient.invalidateQueries(GROUPS_KEY)
    setIsLoading(false)
  }

  const onReset = async () => {
    setIsLoading(true)
    await push({ search: '' })
    form.resetFields()
    await queryClient.invalidateQueries(GROUPS_KEY)
    setIsLoading(false)
  }

  const initialValues = () => {
    const qs = queryString.parse(location.search)

    return {
      ...qs,
      teacher_id: qs.teacher_id ? Number(qs.teacher_id) : undefined,
      course_id: qs.course_id ? Number(qs.course_id) : undefined,
      room_id: qs.room_id ? Number(qs.room_id) : undefined,
      start_time: qs.start_time ? moment(qs.start_time, 'HH:mm') : undefined,
      created_at: qs.created_at ? moment(qs.created_at, 'YYYY-MM-DD') : undefined,
      days: qs.days?.toString().split(','),
    }
  }

  return (
    <Form onFinish={onFinish} form={form} layout={'vertical'} initialValues={initialValues()}>
      <Row gutter={[16, 0]}>
        <Col {...grid}>
          <FormItemSelect name={'status'} label={'status'}>
            <Select.Option value={'true'}>{t('groups:active')}</Select.Option>
            <Select.Option value={'false'}>{t('groups:inactive')}</Select.Option>
          </FormItemSelect>
        </Col>
        <Col {...grid}>
          <FormTeachersList required={false} />
        </Col>
        <Col {...grid}>
          <FormCoursesList required={false} />
        </Col>
        <Col {...grid}>
          <FormRoomsList required={false} />
        </Col>
        <Col {...grid}>
          <EducationDaysSelect />
        </Col>
        <Col {...grid}>
          <StartTime required={false} />
        </Col>
        <Col {...grid}>
          <FormItemSelect label={'groups:groupWorkload'} name={'is_empty'}>
            <Select.Option value={'true'}>{t('groups:emptyGroup')}</Select.Option>
            <Select.Option value={'false'}>{t('groups:groupWithStudents')}</Select.Option>
          </FormItemSelect>
        </Col>
        <Col {...grid}>
          <MyDatePicker
            required={false}
            name={'created_at'}
            label={'createdAt'}
            disableFutureDays={true}
          />
        </Col>
        <Col flex={1}>
          <SearchInput />
        </Col>
        <Col xs={24}>
          <FilterButtons isLoading={isLoading} onReset={onReset} />
        </Col>
      </Row>
    </Form>
  )
}

export default GroupsFilters
