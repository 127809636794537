import React from 'react'
import { BlockOutlined } from '@ant-design/icons'
import styles from './layout-fullscreen-button.module.scss'

interface props {}

const LayoutFullscreenButton: React.FC<props> = () => {
  const fullScreenToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }

  return <BlockOutlined className={styles.button} onClick={fullScreenToggle} />
}

export default LayoutFullscreenButton
