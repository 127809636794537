import React from 'react'
import { Form, Radio } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  name?: string
  label?: string
  required?: boolean
}

const FormTeacherPaymentType: React.FC<props> = ({
  name = 'type',
  label = 'type',
  required = true,
}) => {
  const { t } = useTranslation()
  return (
    <Form.Item name={name} label={t(label)} rules={[{ required, message: t('required') }]}>
      <Radio.Group>
        <Radio.Button value={'salary'}>{t('salary')}</Radio.Button>
        <Radio.Button value={'prepayment'}>{t('prepayment')}</Radio.Button>
        <Radio.Button value={'another'}>{t('another')}</Radio.Button>
      </Radio.Group>
    </Form.Item>
  )
}

export default FormTeacherPaymentType
