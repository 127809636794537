import { request as axios } from './index'

export const getUserInformationApi = () => {
  return axios.get(`/auth/me`)
}

export const loginApi = (data: { phone: string; password: string }) => {
  return axios.post(`/auth/login`, data)
}

export const logoutApi = () => {
  return axios.post(`/auth/logout`)
}
