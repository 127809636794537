import React, { useState } from 'react'
import DrawerOpen from '../../../../core/drawer/drawer-open/drawer-open'
import { Drawer, Form, Input, message } from 'antd'
import { drawerSettings } from '../../../../../lib/settings'
import DrawerFooter from '../../../../core/drawer/drawer-footer/drawer-footer'
import SumInput from '../../../../core/form-group/sum-input/sum-input'
import { useTranslation } from 'react-i18next'
import ExpenseTypes from '../../../../core/form-group/expense-types/expense-types'
import { useMutation } from 'react-query'
import { IExpense } from '../../../../../interfaces'
import { queryClient } from '../../../../../index'
import { EXPENSES_KEY } from '../../../../../hooks/query/expenses'
import MyDatePicker from '../../../../core/form-group/my-date-picker/my-date-picker'
import moment from 'moment'
import { createExpense, updateExpense } from '../../../../../api/expenses'
import BranchesList from '../../../../core/form-group/branches-list/branches-list'
import { useActiveBranch } from '../../../../../hooks/useActiveBranch'

interface props {
  expense?: IExpense
}

const AdditionalExpensesDrawer: React.FC<props> = ({ expense }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const handleVisibleChange = () => setVisible(!visible)
  const { t } = useTranslation(['translation', 'finances'])
  const [form] = Form.useForm()
  const activeBranch = useActiveBranch()

  const onSuccess = async () => {
    await queryClient.invalidateQueries(EXPENSES_KEY)
    message.success(t('responseSuccess'))
    form.resetFields()
    handleVisibleChange()
  }
  const onError = () => {
    message.error(t('responseError'))
  }

  const create = useMutation(createExpense, { onSuccess, onError })
  const update = useMutation(updateExpense, { onSuccess, onError })

  const onFinish = (fieldValues: IExpense) => {
    const values = {
      ...fieldValues,
      date: moment(fieldValues.date).format('YYYY-MM-DD'),
    }
    return expense ? update.mutateAsync(values) : create.mutateAsync(values)
  }

  const formId = 'expense-form'
  return (
    <>
      <DrawerOpen drawerToggle={handleVisibleChange} edit={expense} />
      <Drawer
        {...drawerSettings}
        title={t(expense ? 'finances:editExpense' : 'finances:addExpense')}
        visible={visible}
        onClose={handleVisibleChange}
        closable
        footer={
          <DrawerFooter
            drawerToggle={handleVisibleChange}
            formId={formId}
            disabled={create.isLoading || update.isLoading}
          />
        }
      >
        <Form
          form={form}
          id={formId}
          layout={'vertical'}
          onFinish={onFinish}
          initialValues={{
            ...expense,
            date: expense && moment(expense.date, 'YYYY-MM-DD'),
            branch_id: activeBranch?.id,
          }}
        >
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <ExpenseTypes form={form} />
          <SumInput name={'amount'} label={t('amount')} />
          <BranchesList mode={'single'} />
          <MyDatePicker disableFutureDays={true} name={'date'} label={'paymentDate'} />
          <Form.Item name={'comment'} label={t('comment')}>
            <Input.TextArea placeholder={t('inputComment')} rows={7} />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default AdditionalExpensesDrawer
