import React, { useState } from 'react'
import { Button, Divider, Drawer, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { FundOutlined } from '@ant-design/icons'
import GroupFinanceDrawerReport from './group-finance-drawer-report/group-finance-drawer-report'

interface props {
  groupName: string
}

const GroupFinanceDrawer: React.FC<props> = ({ groupName }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const drawerHandler = () => setVisible(!visible)

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Button type={'primary'} onClick={drawerHandler} icon={<FundOutlined />}>
        {t('financialReport')}
      </Button>
      <Drawer
        title={
          <Space split={<Divider type={'vertical'} />}>
            <span>{t('financialReport')}</span>
            <span>{groupName}</span>
          </Space>
        }
        width={'100%'}
        destroyOnClose={true}
        placement="right"
        onClose={drawerHandler}
        visible={visible}
      >
        <GroupFinanceDrawerReport />
      </Drawer>
    </React.Fragment>
  )
}

export default GroupFinanceDrawer
