import { history } from '../api'
import queryString from 'query-string'

export const serialize = (q: string | object, value?: any) => {
  const {
    location: { search },
  } = history
  let qs = queryString.parse(search)
  delete qs['page']

  typeof q === 'string' ? (qs[q] = value) : (qs = Object.assign({}, qs, q))

  const newSearch = Object.entries(qs)
    .filter(([_, value]) => value)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
    .join('&')

  return `?${new URLSearchParams(newSearch)}`
}
