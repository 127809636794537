import React from 'react'
import { IStudent, IUserRole } from '../../../../interfaces'
import { Card, Descriptions } from 'antd'
import UserRole from '../../users/user-role/user-role'
import UserStatus from '../../users/user-status/user-status'
import { useTranslation } from 'react-i18next'
import { priceFormatter } from '../../../../lib/priceFormatter'
import PhoneNumber from '../../../core/phone-number/phone-number'
import isEqual from 'react-fast-compare'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import { descriptionsSettings } from '../../../../lib/settings'
import DateWithMonth from '../../../core/date-with-month/date-with-month'

interface props {
  student: Omit<IStudent, 'groups' | 'payments'>
}

const StudentCard: React.FC<props> = ({ student }) => {
  const { name, phone, additional_phone, balance, created_at, birthday, status, roles } = student
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['student', 'admin', 'accountant'])

  const { t } = useTranslation()

  return (
    <Card bordered={false} title={t('generalInformation')}>
      <Descriptions {...descriptionsSettings} column={1}>
        <Descriptions.Item label={t('firstName')}>{name}</Descriptions.Item>
        {access && (
          <Descriptions.Item label={t('balance')}>{priceFormatter(balance)}</Descriptions.Item>
        )}
        <Descriptions.Item label={t('phone')}>
          <PhoneNumber phone={phone} />
        </Descriptions.Item>
        <Descriptions.Item label={t('additionalPhone')}>
          <PhoneNumber phone={additional_phone} />
        </Descriptions.Item>
        <Descriptions.Item label={t('birthday')}>
          <DateWithMonth date={birthday} />
        </Descriptions.Item>
        <Descriptions.Item label={t('role')}>
          {roles.map(({ id, slug }: IUserRole) => (
            <UserRole key={id} role={slug} />
          ))}
        </Descriptions.Item>
        <Descriptions.Item label={t('createdAt')}>
          <DateWithMonth date={created_at} />
        </Descriptions.Item>
        <Descriptions.Item label={t('status')}>
          <UserStatus status={status} />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps.student, nextProps.student)
}

export default React.memo(StudentCard, propsAreEqual)
