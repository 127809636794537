import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { message } from 'antd'
import { logoutApi } from '../api/auth'
import { ACCESS_TOKEN, SELECTED_BRANCH } from '../constants/auth'
import { queryClient } from '../index'
import { useTranslation } from 'react-i18next'

export function useLogout() {
  const { t } = useTranslation()
  const { replace } = useHistory()

  const { isLoading, mutateAsync } = useMutation(logoutApi, {
    onSuccess: () => {
      localStorage.removeItem(ACCESS_TOKEN)
      localStorage.removeItem(SELECTED_BRANCH)
      queryClient.removeQueries()
      replace({
        pathname: '/auth',
      })
    },
    onError: () => {
      message.error(t('error'))
    },
  })

  const logout = () => mutateAsync()

  return { isLoading, logout }
}
