import React, { useState } from 'react'
import styles from './search-input-handler.module.scss'
import { Input } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { serialize } from '../../../lib/serializeQuery'
import { queryClient } from '../../../index'
import { getQueryParam } from '../../../lib/getQueryParam'

interface props {
  fetchingQueryKey: string | string[]
  placeholder?: string
}

const SearchInputHandler: React.FC<props> = ({ fetchingQueryKey, placeholder }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const location = useLocation()

  const { t } = useTranslation()

  const onSearch = async (value: string) => {
    setLoading(true)
    history.push({
      pathname: location.pathname,
      search: value.length ? serialize('q', value) : '',
    })

    await queryClient.invalidateQueries(fetchingQueryKey)
    setLoading(false)
  }

  return (
    <Input.Search
      className={styles.search}
      placeholder={placeholder || t('search')}
      loading={loading}
      enterButton
      onSearch={onSearch}
      defaultValue={getQueryParam('q')}
      allowClear={true}
    />
  )
}

export default SearchInputHandler
