import React from 'react'
import { IBranch } from '../../../../interfaces'
import MyTable from '../../../core/my-table/my-table'
import { BRANCHES_KEY } from '../../../../hooks/query/branches'
import { idType } from '../../../../interfaces/types/types'
import { useTranslation } from 'react-i18next'
import BranchesDrawer from '../branches-drawer/branches-drawer'
import { Link } from 'react-router-dom'
import BranchStatus from '../branch-status/branch-status'
import BranchPhones from '../branch-phones/branch-phones'

interface props {
  branches: IBranch[]
}

const BranchesTable: React.FC<props> = ({ branches }) => {
  const { t } = useTranslation()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: idType) => <span># {text}</span>,
    },
    {
      title: t('name'),
      key: 'title',
      render: ({ id, title }: IBranch) => <Link to={'/branches/details/' + id}>{title}</Link>,
    },
    {
      title: t('address'),
      dataIndex: 'address',
    },
    {
      title: t('status'),
      dataIndex: 'active',
      render: (status: boolean) => <BranchStatus status={status} />,
    },
    {
      title: t('phoneNumbers'),
      dataIndex: 'phones',
      render: (phones: string[]) => <BranchPhones phones={phones} />,
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      render: (branch: IBranch) => <BranchesDrawer title={'edit'} branch={branch} />,
    },
  ]

  return <MyTable fetchingQueryKey={BRANCHES_KEY} data={branches} columns={columns} />
}

export default BranchesTable
