import React from 'react'
import DashboardStatistics from './dashboard-statistics/dashboard-statistics'
import DashboardSchedule from './dashboard-schedule/dashboard-schedule'
import { Col, Row } from 'antd'
import Head from '../../../hoc/head/head'

interface props {}

const Dashboard: React.FC<props> = () => {
  return (
    <Head title={'Dashboard'}>
      <Row gutter={[32, 32]}>
        <Col xs={24}>
          <DashboardStatistics />
        </Col>
        <Col xs={24}>
          <DashboardSchedule />
        </Col>
      </Row>
    </Head>
  )
}

export default React.memo(Dashboard)
