import React from 'react'
import { Select, Spin } from 'antd'
import { SelectProps } from 'antd/es/select'

import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType>
  debounceTimeout?: number
}

export default function DebounceSelect<
  ValueType extends {
    key?: string
    label: React.ReactNode
    value: string | number
  } = any,
>({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps) {
  const [fetching, setFetching] = React.useState(false)
  const [options, setOptions] = React.useState<ValueType[]>([])
  const fetchRef = React.useRef(0)

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then(newOptions => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  const { t } = useTranslation()

  const style = { width: '100%' }

  return (
    <Select<ValueType>
      labelInValue
      filterOption={() => true}
      onSearch={debounceFetcher}
      showSearch
      notFoundContent={fetching ? <Spin size="small" /> : t('notFound')}
      options={options}
      style={style}
      {...props}
    />
  )
}
