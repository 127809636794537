import React from 'react'
import styles from './leads-card-bids-length.module.scss'
import { useTranslation } from 'react-i18next'

interface props {
  length: number
  collapseHandler: any
}

const LeadsCardBidsLength: React.FC<props> = ({ length, collapseHandler }) => {
  const { t } = useTranslation(['translation', 'leads'])

  return (
    <div className={styles.length} onClick={collapseHandler}>
      {t('leads:bidsCount')}: <span>{length}</span>
    </div>
  )
}

export default React.memo(LeadsCardBidsLength)
