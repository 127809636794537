import image14 from './undraw_annotation_re_h774.svg'
import image15 from './undraw_attached_file_re_0n9b.svg'
import image18 from './undraw_book_lover_re_rwjy.svg'
import image19 from './undraw_businessman_re_mlee.svg'
import image20 from './undraw_coding_re_iv62.svg'
import image24 from './undraw_designer_girl_re_h54c.svg'
import image25 from './undraw_developer_activity_re_39tg.svg'
import image28 from './undraw_in_the_office_re_jtgc.svg'
import image29 from './undraw_my_documents_re_13dc.svg'
import image32 from './undraw_pair_programming_re_or4x.svg'
import image34 from './undraw_programming_re_kg9v.svg'
import image36 from './undraw_science_re_mnnr.svg'
import image37 from './undraw_team_spirit_re_yl1v.svg'
import image38 from './undraw_team_collaboration_re_ow29.svg'
import image39 from './undraw_podcast_re_wr88.svg'

export default [
  image14,
  image15,
  image18,
  image19,
  image20,
  image24,
  image25,
  image28,
  image29,
  image32,
  image34,
  image36,
  image37,
  image38,
  image39,
]
