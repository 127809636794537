export const colors = [
  '#FFEFCA',
  '#FFF7EB',
  '#FFE8E1',
  '#FEFEDF',
  '#FBEAFF',
  '#d9f7be',
  '#DFF1F8',
  '#bae7ff',
  '#ffccc7',
  '#D3F6DB',
  '#ffd6e7',
]
