import React from 'react'
import { useTranslation } from 'react-i18next'
import { IStudent } from '../../../../interfaces'
import MyTable from '../../../core/my-table/my-table'
import StatusTag from '../../../core/status-tag/status-tag'
import StudentActions from '../student-actions/student-actions'
import PhoneNumber from '../../../core/phone-number/phone-number'
import { STUDENTS_KEY } from '../../../../hooks/query/students'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import StudentLink from '../../../core/student-link/student-link'
import { ColumnsType } from 'antd/es/table'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'
import DateWithMonth from '../../../core/date-with-month/date-with-month'

interface props {
  students: IStudent[]
}

const StudentsTable: React.FC<props> = ({ students }) => {
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception'])
  const { t } = useTranslation()

  const columns: ColumnsType<IStudent> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (text: string) => (
        <span>
          <TableMobileTitle title={'ID'} /># {text}
        </span>
      ),
    },
    {
      title: t('firstName'),
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
      render: ({ id, name }: IStudent) => (
        <React.Fragment>
          <TableMobileTitle title={t('firstName')} />
          <StudentLink studentId={id} studentName={name} />
        </React.Fragment>
      ),
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      key: 'phone',
      render: (text: string) => (
        <React.Fragment>
          <TableMobileTitle title={t('phone')} />
          <PhoneNumber phone={text} />
        </React.Fragment>
      ),
    },
    {
      title: t('createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('createdAt')} />
          <DateWithMonth date={text} />
        </>
      ),
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (status: number) => (
        <React.Fragment>
          <TableMobileTitle title={t('status')} />
          <StatusTag status={status} />
        </React.Fragment>
      ),
    },
    {
      title: t('actions'),
      key: 'action',
      align: 'right',
      className: access ? 'show' : 'hide',
      render: (text: string, record: IStudent) => <StudentActions student={record} />,
    },
  ]

  return <MyTable fetchingQueryKey={STUDENTS_KEY} columns={columns} data={students} />
}

export default React.memo(StudentsTable)
