import React from 'react'
import { Button } from 'antd'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

interface props {
  drawerToggle: any
  edit?: any
}

const DrawerOpen: React.FC<props> = ({ drawerToggle, edit }) => {
  const { t } = useTranslation()

  return (
    <Button type="primary" onClick={drawerToggle}>
      {edit ? <EditOutlined /> : <PlusCircleOutlined />}
      {edit ? t('edit') : t('add')}
    </Button>
  )
}

export default DrawerOpen
