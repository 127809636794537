import React from 'react'
import { Image, ImageProps } from 'antd'
import styles from './test-question-image.module.scss'

interface props {
  rest: ImageProps
}

const TestQuestionImage: React.FC<props> = ({ rest }) => {
  const onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.log(e)
  }
  return (
    <Image
      className={styles.image}
      alt={'Ошибка загрузки изображенния. Пожалуйста, обновите страницу'}
      {...rest}
      onError={onError}
    />
  )
}

export default TestQuestionImage
