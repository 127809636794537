import { useAuthUserInformation } from './query/auth'

export function useRoleChecker() {
  const { data } = useAuthUserInformation()

  const roleChecker = (accessRoles: string[]) => {
    let access = false

    accessRoles.forEach(role => {
      if (data?.roles_slug.includes(role)) {
        access = true
      }
    })

    return access
  }

  return { roleChecker }
}
