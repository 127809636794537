import React, { useEffect, useState } from 'react'
import { Layout, Menu } from 'antd'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './layout-menu.module.scss'
import { useRoleChecker } from '../../../hooks/useRoleChecker'
import { usePrevPathname } from '../../../hooks/usePrevPathname'
import { useMenuLinks } from '../../../hooks/useMenuLinks'
import LogoSmall from '../../../app/core/logo-small/logo-small'
import 'react-custom-scroll/dist/customScroll.css'
import CustomScroll from 'react-custom-scroll'

interface props {
  collapsed: boolean
  setCollapsed: (v: boolean) => void
}

const LayoutMenu: React.FC<props> = ({ collapsed, setCollapsed }) => {
  const [backdrop, setBackdrop] = useState<boolean>(false)
  const [menu] = useMenuLinks()
  const { pathname } = useLocation()
  const { roleChecker } = useRoleChecker()
  const prevPathname = usePrevPathname(pathname)
  const changeCollapseHandler = () => setCollapsed(!collapsed)
  const { t } = useTranslation()

  useEffect(() => {
    const resizeFunction = () => {
      if (window.screen.width < 1200 && !collapsed) {
        if (prevPathname !== pathname) {
          setCollapsed(true)
        }

        setBackdrop(true)
        document.body.style.overflow = 'hidden'
      } else {
        setBackdrop(false)
        document.body.style.overflow = ''
      }
    }

    resizeFunction()

    document.addEventListener('resize', resizeFunction)
    return () => {
      document.removeEventListener('resize', resizeFunction)
    }
  }, [collapsed, pathname, prevPathname])

  return (
    <React.Fragment>
      {backdrop && !collapsed && (
        <div className={styles.backdrop} onClick={changeCollapseHandler} />
      )}
      <Layout.Sider
        trigger={null}
        breakpoint={'xxl'}
        theme={'light'}
        width={250}
        className={styles.sider}
        collapsed={collapsed}
        collapsible={true}
      >
        <LogoSmall />
        <CustomScroll>
          <Menu className={styles.menu} theme={'light'} mode={'inline'} selectedKeys={[pathname]}>
            {menu.map((group, index) => {
              if (group.access && !roleChecker(group.access)) return false

              return (
                <React.Fragment key={index}>
                  <Menu.ItemGroup title={t(group.title)} />
                  {group.children.map(({ access: linkAccess, name, route, children, icon }) => {
                    if (!roleChecker(linkAccess)) return false

                    return (
                      <React.Fragment key={name}>
                        {children ? (
                          <Menu.SubMenu key={name} icon={icon} title={t(name)}>
                            {children.map(({ name, icon, route, access }) => {
                              if (!roleChecker(access)) return false

                              return (
                                <Menu.Item key={route} icon={icon}>
                                  <NavLink to={route!}>{t(name)}</NavLink>
                                </Menu.Item>
                              )
                            })}
                          </Menu.SubMenu>
                        ) : (
                          <Menu.Item icon={icon} key={route}>
                            <NavLink to={route!}>{t(name)}</NavLink>
                          </Menu.Item>
                        )}
                      </React.Fragment>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </Menu>
        </CustomScroll>
      </Layout.Sider>
    </React.Fragment>
  )
}

export default LayoutMenu
