import React from 'react'
import { Form, Input, InputProps } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  name?: string | number
  label?: string
  required?: boolean
  rest?: InputProps
}

const PhoneInput: React.FC<props> = ({ name = 'phone', label, required = true, rest }) => {
  const { t } = useTranslation()

  return (
    <Form.Item
      name={name}
      label={label ? t(label) : null}
      rules={[{ required, message: t('required') }, { max: 9 }]}
    >
      <Input
        maxLength={9}
        addonBefore={'+998'}
        placeholder={t('inputPhone')}
        autoFocus={true}
        {...rest}
      />
    </Form.Item>
  )
}

export default PhoneInput
