import React from 'react'
import { Space } from 'antd'
import StudentsDrawer from '../students-drawer/students-drawer'
import { IStudent } from '../../../../interfaces'
import StudentsAddToGroup from '../students-add-to-group/students-add-to-group'
import DeleteModal from '../../../core/delete-modal/delete-modal'
import { deleteStudentApi } from '../../../../api/students'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import { STUDENTS_KEY } from '../../../../hooks/query/students'

interface props {
  student: IStudent
}

const StudentActions: React.FC<props> = ({ student }) => {
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception'])

  return (
    <Space>
      {access && <StudentsAddToGroup hideText={true} studentId={student.id} />}
      <StudentsDrawer title={'editTitle'} student={student} />
      {access && (
        <DeleteModal
          modalText={'students:deleteStudent'}
          confirmationName={student.name}
          id={student.id}
          deleteFunction={deleteStudentApi}
          fetchingQueryKey={STUDENTS_KEY}
        />
      )}
    </Space>
  )
}

export default React.memo(StudentActions)
