import React, { useState } from 'react'
import { IOrderBid } from '../../../../../interfaces'
import styles from './leads-bid.module.scss'
import LeadsBidForm from './leads-bid-form/leads-bid-form'
import LeadsBidDropdown from './leads-bid-dropdown/leads-bid-dropdown'
import { DraggableProvided } from 'react-beautiful-dnd'
import { Divider } from 'antd'
import { CommentOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'

interface props {
  item: IOrderBid
  provided: DraggableProvided
}

const LeadsBid = ({ item, provided }: props) => {
  const [edit, setEdit] = useState<boolean>(false)
  const { name, phone, comment } = item

  const editHandler = () => setEdit(!edit)

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={provided.draggableProps.style}
      {...provided.dragHandleProps}
    >
      {edit ? (
        <LeadsBidForm handler={setEdit} bid={item} />
      ) : (
        <div className={styles.bid}>
          <LeadsBidDropdown bid={item} edit={edit} setEdit={setEdit} />

          <div className={styles.wrapper}>
            <span className={styles.name} onDoubleClick={editHandler}>
              <UserOutlined /> {name}
            </span>
            <Divider className={styles.divider} />
            <a className={styles.phone} href={'tel:+998' + phone} rel={'noopener noreferrer'}>
              <PhoneOutlined /> +998 {phone}
            </a>

            {comment ? (
              <React.Fragment>
                <Divider className={styles.divider} />
                <span className={styles.comment}>
                  <CommentOutlined /> {comment}
                </span>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(LeadsBid)
