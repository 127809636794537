import React, { useState } from 'react'
import { Col, DatePicker, Form, Row } from 'antd'
import { grid } from '../../../../../constants/filtersGrid'
import FilterButtons from '../../../../core/form-group/filter-buttons/filter-buttons'
import FiltersCard from '../../../../core/filters-card/filters-card'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import SumInput from '../../../../core/form-group/sum-input/sum-input'
import ExpenseTypes from '../../../../core/form-group/expense-types/expense-types'
import { serialize } from '../../../../../lib/serializeQuery'
import moment from 'moment'
import { queryClient } from '../../../../../index'
import { EXPENSES_KEY } from '../../../../../hooks/query/expenses'
import * as queryString from 'query-string'

interface props {}

const AdditionalExpensesFilters: React.FC<props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const { push, location } = useHistory()
  const [form] = Form.useForm()

  const onFinish = async (values: any) => {
    setIsLoading(true)
    const query = {
      ...values,
      date:
        values.date.length &&
        `${moment(values['date'][0]).format('YYYY-MM-DD')};${moment(values['date'][1]).format(
          'YYYY-MM-DD',
        )}`,
    }

    await push({
      search: serialize(query),
    })

    await queryClient.invalidateQueries(EXPENSES_KEY)
    setIsLoading(false)
  }
  const onReset = async () => {
    setIsLoading(true)
    await push({ search: '' })
    await queryClient.invalidateQueries(EXPENSES_KEY)
    form.resetFields()
    setIsLoading(false)
  }

  const qs = queryString.parse(location.search)
  return (
    <FiltersCard>
      <Form
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        initialValues={{
          ...qs,
          expense_type_id: Number(qs.expense_type_id) || undefined,
          date: qs.date && moment(qs.date, 'YYYY-MM-DD'),
        }}
      >
        <Row gutter={[16, 0]}>
          <Col {...grid}>
            <Form.Item name={'date'} label={t('period')}>
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                disabledDate={date => date > moment()}
                format={'DD MMMM YYYY'}
              />
            </Form.Item>
          </Col>
          <Col {...grid}>
            <ExpenseTypes required={false} />
          </Col>
          <Col flex={1}>
            <SumInput name={'amount'} label={t('amount')} rest={{ rules: [{ required: false }] }} />
          </Col>
          <Col xs={24}>
            <FilterButtons isLoading={isLoading} onReset={onReset} />
          </Col>
        </Row>
      </Form>
    </FiltersCard>
  )
}

export default AdditionalExpensesFilters
