import React from 'react'
import ErrorAlert from '../../core/error-alert/error-alert'
import { PageHeader, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import StudentsTable from './students-table/students-table'
import MyPagination from '../../core/my-pagination/my-pagination'
import StudentsDrawer from './students-drawer/students-drawer'
import Head from '../../../hoc/head/head'
import { STUDENTS_KEY, useStudents } from '../../../hooks/query/students'
import { useRoleChecker } from '../../../hooks/useRoleChecker'
import FiltersCard from '../../core/filters-card/filters-card'
import StudentsFilters from './students-filters/students-filters'

interface props {}

const Students: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'students'])

  const { isLoading, isError, error, data: response } = useStudents()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception'])

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { data, ...pagination } = response!

  return (
    <Head title={t('students')}>
      <PageHeader
        title={t('students')}
        subTitle={t('students:studentsSubtitle')}
        onBack={() => window.history.back()}
        extra={[access && <StudentsDrawer key={1} title={'addTitle'} />]}
      />
      <FiltersCard>
        <StudentsFilters />
      </FiltersCard>
      <StudentsTable students={data} />
      <MyPagination pagination={pagination} fetchingQueryKey={STUDENTS_KEY} />
    </Head>
  )
}

export default Students
