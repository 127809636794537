import React from 'react'
import { Button, Dropdown, Menu, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import ChangeAddToGroupDate from '../../../core/change-add-to-group-date/change-add-to-group-date'
import { idType } from '../../../../interfaces/types/types'
import DeleteStudentFromGroup from '../../../core/delete-student-from-group/delete-student-from-group'
import { GROUP_STUDENTS_KEY } from '../../../../hooks/query/groups'

interface props {
  selectedStudents: idType[]
  groupId: idType
}

const GroupBulkActions: React.FC<props> = ({ selectedStudents, groupId }) => {
  const { t } = useTranslation(['translation', 'groups'])

  const menu = (
    <Menu>
      <Menu.Item key={'change-add-to-group'}>
        <ChangeAddToGroupDate groupId={groupId} studentIds={selectedStudents} />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={'delete-students-group-from-group'} danger>
        <DeleteStudentFromGroup
          groupId={groupId}
          studentId={selectedStudents}
          deleteStudentQueries={[[GROUP_STUDENTS_KEY, groupId!.toString()]]}
        />
      </Menu.Item>
    </Menu>
  )

  return (
    <Space align={'center'} size={22}>
      <Dropdown overlay={menu} disabled={!selectedStudents.length} trigger={['click']}>
        <Button type={'primary'}>
          {t('bulkActions')} <DownOutlined />
        </Button>
      </Dropdown>
      {selectedStudents.length ? t('selectedStudents', { count: selectedStudents.length }) : null}
    </Space>
  )
}

export default React.memo(GroupBulkActions)
