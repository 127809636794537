import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  status: number
}

const UserStatus: React.FC<props> = ({ status }) => {
  const { t } = useTranslation()

  return status ? (
    <Tag color={'green'}>{t('enabled')}</Tag>
  ) : (
    <Tag color={'default'}>{t('disabled')}</Tag>
  )
}

export default React.memo(UserStatus)
