import React from 'react'
import FinancesTeacher from '../../../../app/modules/finances/finances-teachers/finances-teacher'

interface props {}

const FinancesTeacherPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <FinancesTeacher />
    </React.Fragment>
  )
}

export default FinancesTeacherPage
