import React from 'react'
import ReactQuill from 'react-quill'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import 'react-quill/dist/quill.snow.css'
import styles from './text-editor.module.scss'

window.katex = katex

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    ['formula'],
    ['clean'],
  ],
}

interface OnChangeHandler {
  (e: any): void
}

type Props = {
  value?: string
  placeholder?: string
  onChange?: OnChangeHandler
}

const TextEditor: React.FC<Props> = ({ value = '', onChange, placeholder }) => {
  return (
    <ReactQuill
      className={styles.editor}
      modules={modules}
      theme={'snow'}
      value={value || ''}
      onChange={onChange}
      placeholder={placeholder}
    />
  )
}

export default TextEditor
