import React from 'react'
import { Col, PageHeader, Row, Skeleton, Space } from 'antd'
import TeacherCard from './teacher-card/teacher-card'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TeachersDrawer from './teachers-drawer/teachers-drawer'
import Head from '../../../hoc/head/head'
import AdditonalInfos from '../../core/additonal-infos/additonal-infos'
import { useRoleChecker } from '../../../hooks/useRoleChecker'
import { useTeacher } from '../../../hooks/query/teachers'
import ErrorAlert from '../../core/error-alert/error-alert'
import TeacherTabs from './teacher-tabs/teacher-tabs'
import TeacherFinanceDrawer from './teacher-finance-drawer/teacher-finance-drawer'

interface props {}

const Teacher: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin'])
  const accountantAccess = roleChecker(['admin', 'accountant', 'cashier'])
  const { t } = useTranslation(['translation', 'teachers'])

  const { isLoading, isError, error, data } = useTeacher(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { groups, ...rest } = data!

  return (
    <Head title={data!.name}>
      <PageHeader
        title={data!.name}
        subTitle={t('teachers:teacherSubtitle')}
        onBack={() => window.history.back()}
        extra={[
          <Space key={'1'}>
            {accountantAccess && <TeacherFinanceDrawer teacherName={rest.name} />}
            {access && <TeachersDrawer updateOne={true} title={'editTitle'} teacher={rest} />}
          </Space>,
        ]}
      />
      <Row gutter={[32, 32]}>
        <Col xs={24} lg={12}>
          <TeacherCard teacher={rest} />
        </Col>
        <Col xs={24} lg={12}>
          <AdditonalInfos additionalInfos={data!.additional_infos} />
        </Col>
        <Col xs={24}>
          <TeacherTabs groups={groups} />
        </Col>
      </Row>
    </Head>
  )
}

export default Teacher
