import React from 'react'
import { Button, Form, Input, message, Radio, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { CloseCircleTwoTone, InfoCircleTwoTone, WarningTwoTone } from '@ant-design/icons'
import { createNotificationApi } from '../../../../../api/groups'
import { useAuthUserInformation } from '../../../../../hooks/query/auth'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../../index'
import { GROUP_NOTIFICATIONS_KEY } from '../../../../../hooks/query/groups'

interface props {}

const GroupNotificationsForm: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { data: user } = useAuthUserInformation()

  const { isLoading, mutateAsync } = useMutation(createNotificationApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([GROUP_NOTIFICATIONS_KEY, id])
      message.success(t('responseSuccess'))
      form.resetFields()
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  const [form] = Form.useForm()

  const onFinish = async (notification: any) => mutateAsync(notification)

  return (
    <Form form={form} layout={'vertical'} onFinish={onFinish}>
      <h2>{t('addNotification')}</h2>
      <Form.Item hidden name={'group_id'} initialValue={id}>
        <Input />
      </Form.Item>
      <Form.Item hidden name={'user_id'} initialValue={user?.id}>
        <Input />
      </Form.Item>
      <Form.Item name={'title'} label={t('title')} rules={[{ required: true }]}>
        <Input placeholder={t('inputTitle')} />
      </Form.Item>
      <Form.Item
        name={'type'}
        label={t('status')}
        rules={[{ required: true }]}
        initialValue={'info'}
      >
        <Radio.Group>
          <Tooltip title={t('information')} color={'blue'}>
            <Radio.Button value="info">
              <InfoCircleTwoTone />
            </Radio.Button>
          </Tooltip>
          <Tooltip title={t('warning')} color={'orange'}>
            <Radio.Button value="warning">
              <WarningTwoTone twoToneColor={'#ffa940'} />
            </Radio.Button>
          </Tooltip>
          <Tooltip title={t('important')} color={'red'}>
            <Radio.Button value="important">
              <CloseCircleTwoTone twoToneColor={'#ff4d4f'} />
            </Radio.Button>
          </Tooltip>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={'message'} label={t('comment')}>
        <Input.TextArea placeholder={t('inputComment')} rows={7}></Input.TextArea>
      </Form.Item>
      <Form.Item>
        <Button
          type={'primary'}
          loading={isLoading}
          disabled={isLoading}
          block={true}
          htmlType={'submit'}
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default GroupNotificationsForm
