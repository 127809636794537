import React from 'react'
import { Space } from 'antd'
import { IExpense, IExpenseType, IUser } from '../../../../../interfaces'
import { ColumnsType } from 'antd/es/table'
import { idType } from '../../../../../interfaces/types/types'
import { useTranslation } from 'react-i18next'
import { priceFormatter } from '../../../../../lib/priceFormatter'
import DateWithMonth from '../../../../core/date-with-month/date-with-month'
import AdditionalExpensesDrawer from '../additional-expenses-drawer/additional-expenses-drawer'
import DeleteModal from '../../../../core/delete-modal/delete-modal'
import { EXPENSES_KEY } from '../../../../../hooks/query/expenses'
import { deleteExpense } from '../../../../../api/expenses'
import MyTable from '../../../../core/my-table/my-table'

interface props {
  data: IExpense[]
}

const AdditionalExpensesTable: React.FC<props> = ({ data }) => {
  const { t } = useTranslation(['translation', 'finances'])

  const columns: ColumnsType<IExpense> = [
    {
      title: '#',
      dataIndex: 'id',
      sorter: true,
      render: (id: idType) => <># {id}</>,
    },
    {
      title: t('paymentDate'),
      dataIndex: 'date',
      sorter: true,
      render: (date: string) => <DateWithMonth date={date} />,
    },
    {
      title: t('sum'),
      dataIndex: 'amount',
      render: (sum: number) => <strong>{priceFormatter(sum)}</strong>,
    },
    {
      title: t('finances:expenseType'),
      dataIndex: 'type',
      render: (type: IExpenseType) => <>{type?.title}</>,
    },
    {
      title: t('comment'),
      dataIndex: 'comment',
    },
    {
      title: t('responsible'),
      dataIndex: 'user',
      render: (user: IUser) => <React.Fragment>{user?.name}</React.Fragment>,
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      render: (expense: IExpense) => (
        <Space>
          <AdditionalExpensesDrawer expense={expense} />
          <DeleteModal
            fetchingQueryKey={EXPENSES_KEY}
            deleteFunction={deleteExpense}
            id={expense.id}
            redirect={false}
            modalText={'finances:deleteExpense'}
          />
        </Space>
      ),
    },
  ]

  return <MyTable columns={columns} data={data} fetchingQueryKey={EXPENSES_KEY} />
}

export default AdditionalExpensesTable
