import { IUser, IUsersWithPagination } from '../../interfaces'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { getAllUsersApi, getArchivedUsers, getUserByIdApi } from '../../api/user'
import { idType } from '../../interfaces/types/types'

export const USERS_KEY = 'users_key'
export const ARCHIVED_USERS_KEY = 'archived-users-key'

export function useUsers(options?: UseQueryOptions<IUsersWithPagination, Error>) {
  return useQuery<IUsersWithPagination, Error>(
    USERS_KEY,
    () => getAllUsersApi().then((response: AxiosResponse<IUsersWithPagination>) => response.data),
    {
      ...options,
    },
  )
}

export function useArchivedUsers(options?: UseQueryOptions<IUsersWithPagination, Error>) {
  return useQuery<IUsersWithPagination, Error>(
    [ARCHIVED_USERS_KEY],
    () => getArchivedUsers().then((response: AxiosResponse<IUsersWithPagination>) => response.data),
    {
      ...options,
    },
  )
}

export function useUser(id: idType, options?: UseQueryOptions<IUser, Error>) {
  return useQuery<IUser, Error>(
    [USERS_KEY, id],
    () => getUserByIdApi(id).then((response: AxiosResponse<IUser>) => response.data),
    {
      ...options,
    },
  )
}
