import React, { useState } from 'react'
import { Dropdown, Menu, message } from 'antd'
import {
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import styles from './leads-card-dropdown.module.scss'
import { useTranslation } from 'react-i18next'
import LeadsCardNewGroup from '../leads-card-new-group/leads-card-new-group'
import { IOrderCard, IOrderColumn } from '../../../../../../interfaces'
import { useMutation } from 'react-query'
import { deleteCardApi } from '../../../../../../api/leads'
import { queryClient } from '../../../../../../index'
import { LEADS_KEY } from '../../../../../../hooks/query/leads'
import LeadsCardTransferModal from '../leads-card-transfer-modal/leads-card-transfer-modal'
import { deleteQueryFields } from '../../../../../../lib/deleteQueryFields'

interface props {
  edit: boolean
  setEdit: Function
  card: IOrderCard
}

const LeadsCardDropdown: React.FC<props> = ({ edit, setEdit, card }) => {
  const [group, setGroup] = useState<boolean>(false)
  const drawerToggle = () => setGroup(!group)

  const [transferModal, setTransferModal] = useState<boolean>(false)
  const handleTransferModal = () => {
    deleteQueryFields('branch_id')
    setTransferModal(!transferModal)
  }

  const { column_id, id, bids, name } = card
  const { t } = useTranslation('translation')

  const onError = () => {
    message.error(t('responseError'))
  }

  const { isLoading, mutateAsync } = useMutation(deleteCardApi, {
    onSuccess: () => {
      const columns = queryClient.getQueryData<IOrderColumn[]>(LEADS_KEY)
      queryClient.setQueryData(
        LEADS_KEY,
        columns?.map(column => {
          if (column.id === column_id) {
            return { ...column, cards: column.cards.filter(c => c.id !== id) }
          }
          return column
        }),
      )
      return queryClient.invalidateQueries(LEADS_KEY)
    },
    onError,
  })

  const editHandler = () => setEdit(!edit)
  const deleteCard = () => mutateAsync(id)

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={editHandler} icon={<EditOutlined />}>
        {t('edit')}
      </Menu.Item>
      <Menu.Item key="2" icon={<UsergroupAddOutlined />} onClick={drawerToggle}>
        {t('createGroup')}
      </Menu.Item>
      <Menu.Item key="3" icon={<ArrowRightOutlined />} onClick={handleTransferModal}>
        {t('transferToAnotherBranch')}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="4"
        onClick={deleteCard}
        icon={<DeleteOutlined />}
        danger={true}
        disabled={isLoading}
      >
        {t('delete')}
      </Menu.Item>
    </Menu>
  )

  return (
    <React.Fragment>
      <Dropdown overlay={menu} placement={'bottomCenter'} trigger={['click']}>
        <EllipsisOutlined className={styles.dots} />
      </Dropdown>
      <LeadsCardNewGroup
        name={name}
        id={id}
        visible={group}
        drawerToggle={drawerToggle}
        bids={bids}
      />
      <LeadsCardTransferModal
        card={card}
        visible={transferModal}
        setVisible={handleTransferModal}
      />
    </React.Fragment>
  )
}

export default LeadsCardDropdown
