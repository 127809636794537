import { Form, FormItemProps, Select } from 'antd'
import React from 'react'
import { useCardsList } from '../../../../hooks/query/leads'
import { useTranslation } from 'react-i18next'
import { filterOption } from '../../../../lib/filterOption'

interface props {
  name?: string
  label?: string
  required?: boolean
  formItemProps?: FormItemProps
}

const ColumnsList: React.FC<props> = ({
  name = 'card_id',
  label = 'card',
  required = true,
  formItemProps,
}) => {
  const { t } = useTranslation()
  const { isRefetching, data } = useCardsList()

  return (
    <Form.Item name={name} label={t(label)} rules={[{ required }]} {...formItemProps}>
      <Select
        showSearch
        filterOption={filterOption}
        disabled={isRefetching}
        loading={isRefetching}
        placeholder={t('chooseCard')}
      >
        {data?.map(({ id, name }) => (
          <Select.Option value={id!} key={id}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default ColumnsList
