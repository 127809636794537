import React from 'react'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import SumInput from '../sum-input/sum-input'
import PercentageInput from '../percentage-input/percentage-input'

interface props {}

const TeacherSalary: React.FC<props> = () => {
  const { t } = useTranslation()

  const style = { margin: 0 }

  return (
    <Form.Item label={t('teacherRate')} style={style}>
      <SumInput name={'salary'} label={t('salary') + ' (' + t('zeroIfAbsent') + ')'} />
      <PercentageInput label={t('percentage') + ' (' + t('zeroIfAbsent') + ')'} />
    </Form.Item>
  )
}

export default TeacherSalary
