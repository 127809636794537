import { request as axios } from './index'
import { idType } from '../interfaces/types/types'
import { IBranch } from '../interfaces'

const url = '/branches'

export function getAllBranches() {
  return axios.get(url)
}

export function getBranchById(id: idType) {
  return axios.get(url + '/' + id)
}

export function createBranch(data: IBranch) {
  return axios.post(url, data)
}

export function updateBranch(data: IBranch) {
  return axios.patch(url + '/' + data.id, data)
}

export function deleteBranch(id: idType) {
  return axios.delete(url + '/' + id)
}
