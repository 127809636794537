import React, { useState } from 'react'
import styles from './leads-bid-dropdown.module.scss'
import { useTranslation } from 'react-i18next'
import { Dropdown, Menu, message } from 'antd'
import {
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import { createStudentFromBidApi, deleteBidApi } from '../../../../../../api/leads'
import { validPhone } from '../../../../../../lib/validPhone'
import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryClient } from '../../../../../../index'
import { LEADS_KEY } from '../../../../../../hooks/query/leads'
import { IOrderBid, IOrderColumn } from '../../../../../../interfaces'
import LeadsBidTransferModal from '../leads-bid-transfer-modal/leads-bid-transfer-modal'
import { deleteQueryFields } from '../../../../../../lib/deleteQueryFields'

interface props {
  bid: IOrderBid
  edit: boolean
  setEdit: Function
}

const LeadsBidDropdown: React.FC<props> = ({ edit, bid, setEdit }) => {
  const [transferModal, setTransferModal] = useState<boolean>(false)
  const handleTransferModalChange = () => {
    deleteQueryFields('branch_id')
    setTransferModal(!transferModal)
  }

  const { t } = useTranslation(['translation', 'leads'])

  const { isLoading: isCreateStudentLoading, mutateAsync: createStudentApi } = useMutation(
    createStudentFromBidApi,
    {
      onSuccess: (response: AxiosResponse<any>) => {
        message.success(t('leads:' + response.data.message))
      },
      onError: (e: any) => {
        message.error(t('leads:' + e.response.data.message))
      },
    },
  )
  const createStudent = async () =>
    createStudentApi({ phone: validPhone(bid.phone), name: bid.name })

  const { isLoading: isDeleteBidLoading, mutateAsync: deleteBid } = useMutation(deleteBidApi, {
    onSuccess: () => {
      const columns = queryClient.getQueryData<IOrderColumn[]>(LEADS_KEY)
      queryClient.setQueryData(
        LEADS_KEY,
        columns?.map(column => {
          return {
            ...column,
            cards: column.cards.map(card => {
              if (card.id === bid.card_id) {
                return {
                  ...card,
                  bids: card.bids.filter(({ id }) => id !== bid.id),
                }
              }
              return card
            }),
          }
        }),
      )
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })
  const deleteBidHandler = () => deleteBid(bid.id)

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setEdit(!edit)} icon={<EditOutlined />}>
        {t('edit')}
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={createStudent}
        icon={<UserAddOutlined />}
        disabled={isCreateStudentLoading}
      >
        Создать студента
      </Menu.Item>
      <Menu.Item key="3" icon={<ArrowRightOutlined />} onClick={handleTransferModalChange}>
        {t('transferToAnotherBranch')}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="4"
        onClick={deleteBidHandler}
        icon={<DeleteOutlined />}
        disabled={isDeleteBidLoading}
        danger={true}
      >
        {t('delete')}
      </Menu.Item>
    </Menu>
  )

  return (
    <React.Fragment>
      <Dropdown overlay={menu} placement={'bottomCenter'} trigger={['click']}>
        <EllipsisOutlined className={styles.dots} />
      </Dropdown>
      <LeadsBidTransferModal
        bid={bid}
        visible={transferModal}
        setVisible={handleTransferModalChange}
      />
    </React.Fragment>
  )
}

export default LeadsBidDropdown
