import React from 'react'
import styles from './event-result-percent.module.scss'
import { resultColors } from '../../../lib/resultColors'

interface props {
  percent: number
}

const EventResultPercent: React.FC<props> = ({ percent }) => {
  const renderColor = () => {
    if (percent <= 50) return resultColors.red
    if (percent > 50 && percent <= 75) return resultColors.orange
    if (percent > 75 && percent <= 89) return resultColors.yellow
    return resultColors.green
  }

  return (
    <strong className={styles.percent} style={{ color: renderColor() }}>
      {percent} %
    </strong>
  )
}

export default EventResultPercent
