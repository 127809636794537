import React from 'react'
import { Button, Form, Input, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../../../interfaces/types/types'
import { v4 as uuid } from 'uuid'
import { IOrderColumn } from '../../../../../../interfaces'
import { useMutation } from 'react-query'
import { addColumnApi, editColumnApi } from '../../../../../../api/leads'
import { AxiosResponse } from 'axios'
import { queryClient } from '../../../../../../index'
import { LEADS_KEY } from '../../../../../../hooks/query/leads'

interface props {
  name?: string
  id?: idType
  setVisible: Function
}

const LeadsColumnForm: React.FC<props> = ({ name, id, setVisible }) => {
  const { t } = useTranslation()

  const onSuccess = async (response: AxiosResponse<IOrderColumn>) => {
    const oldQueryData = queryClient.getQueryData<IOrderColumn[]>(LEADS_KEY)!
    queryClient.setQueryData(LEADS_KEY, () => {
      if (id) {
        return oldQueryData.map(column => (column.id === id ? response.data : column))
      }
      return [...oldQueryData, response.data]
    })
    setVisible((state: boolean) => !state)
  }
  const onError = () => {
    message.error(t('responseError'))
  }
  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(addColumnApi, {
    onSuccess,
    onError,
  })
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(editColumnApi, {
    onSuccess,
    onError,
  })

  const onFinish = (column: IOrderColumn) => (name ? update(column) : create(column))

  return (
    <Form onFinish={onFinish} name={uuid()}>
      <Form.Item name="id" initialValue={id} hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item name="sort" initialValue={0} hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item
        name="name"
        initialValue={name}
        label={t('name')}
        rules={[{ required: true, message: t('required') }]}
      >
        <Input autoFocus style={{ width: '100%' }} placeholder={t('inputName')} />
      </Form.Item>
      <Button
        type={'primary'}
        htmlType={'submit'}
        block
        disabled={isCreateLoading || isUpdateLoading}
      >
        {t('submit')}
      </Button>
    </Form>
  )
}

export default LeadsColumnForm
