import { useEffect, useRef } from 'react'

export function usePrevPathname(value: string) {
  const ref = useRef()
  useEffect(() => {
    //@ts-ignore
    ref.current = value
  })
  return ref.current
}
