import React from 'react'
import { Card, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {}

const FiltersCard: React.FC<props> = ({ children }) => {
  const { t } = useTranslation()
  const style = { marginBottom: 20 }
  return (
    <Card
      bordered={false}
      style={style}
      title={<Typography.Title level={5}>{t('filters')}</Typography.Title>}
    >
      {children}
    </Card>
  )
}

export default FiltersCard
