import { request as axios } from './index'
import { idType } from '../interfaces/types/types'

// GROUPS
export function getAllFinancesGroupsApi() {
  return axios.get('/finances/groups')
}

export function getFinancesGroupByIdApi(id: idType) {
  return axios.get(`/finances/groups/${id}`)
}

// DEBTORS
export function getAllFinancesDebtorsApi() {
  return axios.get('/finances/debtors')
}

// TEACHERS
export function getAllFinancesTeachersApi() {
  return axios.get('/finances/teachers')
}

export function getFinancesTeacherByIdApi(id: idType) {
  return axios.get(`/finances/teachers/${id}`)
}

export function getPaymentsInfoApi(params?: any) {
  return axios.get('/finances/balance', {
    params,
  })
}
