import React from 'react'
import UsersTable from './users-table/users-table'
import ErrorAlert from '../../core/error-alert/error-alert'
import MyPagination from '../../core/my-pagination/my-pagination'
import { PageHeader, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import UsersDrawer from './users-drawer/users-drawer'
import Head from '../../../hoc/head/head'
import { USERS_KEY, useUsers } from '../../../hooks/query/users'
import FiltersCard from '../../core/filters-card/filters-card'
import UsersFilters from './users-filters/users-filters'

interface props {}

const Users: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'staff'])

  const { error, isLoading, isError, data: response } = useUsers()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { data, ...pagination } = response!

  return (
    <Head title={t('staff')}>
      <PageHeader
        title={t('staff')}
        subTitle={t('staff:usersSubtitle')}
        onBack={() => window.history.back()}
        extra={[<UsersDrawer key={1} title={'addTitle'} />]}
      />
      <FiltersCard>
        <UsersFilters />
      </FiltersCard>
      <UsersTable users={data} />
      <MyPagination fetchingQueryKey={USERS_KEY} pagination={pagination} />
    </Head>
  )
}

export default Users
