import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import useReactRouterBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'
import { Breadcrumb } from 'antd'
import styles from './breadcrumbs.module.scss'
import { privateRoutes } from '../../../routes'
import { useTranslation } from 'react-i18next'
import { queryCache } from '../../../index'

interface props {}

function useDynamicBreadcrumb(props: any) {
  const [title, setTitle] = useState<any>()
  const { id } = useParams<{ id: string }>()
  const { breadcrumbName, fetchingQueryKey } = privateRoutes.find(
    route => route.path === props.match.path,
  )!.breadcrumb

  useEffect(() => {
    const unsubscribe = queryCache.subscribe(listener => {
      if (!listener) {
        return
      }

      if (
        typeof listener.query.queryKey === 'object' &&
        listener.query.queryKey.join(',') === [fetchingQueryKey, id].join(',')
      ) {
        if (listener.query.state.data) {
          const state = listener.query.state.data
          const breadcrumbTitle =
            typeof state[breadcrumbName] === 'object'
              ? state[breadcrumbName].name
              : state[breadcrumbName]
          setTitle(breadcrumbTitle)
        }
      }
    })

    return function cleanup() {
      unsubscribe()
    }
  }, [breadcrumbName, fetchingQueryKey, id])

  return <>{title}</>
}

const Breadcrumbs: React.FC<props> = () => {
  const { t } = useTranslation()

  const breadcrumbs: BreadcrumbsRoute[] = privateRoutes.map(({ path, breadcrumb }) => ({
    path,
    breadcrumb: breadcrumb.fetchingQueryKey ? useDynamicBreadcrumb : t(breadcrumb.breadcrumbName),
  })) as BreadcrumbsRoute[]

  const bc = useReactRouterBreadcrumbs(breadcrumbs, {
    disableDefaults: true,
  })

  return (
    <Breadcrumb className={styles.breadcrumbs} separator={'»'}>
      <Breadcrumb.Item>
        <NavLink to={'/dashboard'}>EDUCRM</NavLink>
      </Breadcrumb.Item>
      {bc.map(({ breadcrumb, match }, index, arr) => (
        <Breadcrumb.Item key={match.url}>
          {index + 1 === arr.length ? breadcrumb : <NavLink to={match.url}>{breadcrumb}</NavLink>}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

export default Breadcrumbs
