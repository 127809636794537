import React from 'react'
import styles from './logo.module.scss'
import logo from '../../../logo.png'

interface props {}

const Logo: React.FC<props> = () => {
  return (
    <div className={styles.logo}>
      <img src={logo} alt="SPM" />
    </div>
  )
}

export default Logo
