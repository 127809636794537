import React, { useState } from 'react'
import { Button, Col, Divider, List, Modal, Row, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { FolderViewOutlined } from '@ant-design/icons'
import { idType } from '../../../../../interfaces/types/types'
import { useStudentTestResult } from '../../../../../hooks/query/students'
import { Link } from 'react-router-dom'
import EventResultPercent from '../../../../core/event-result-percent/event-result-percent'

interface props {
  studentId: idType
  studentName?: string
  testId: idType
}

const EventAllResultsModal: React.FC<props> = ({ studentId, studentName = '', testId }) => {
  const [open, setOpen] = useState<boolean>(false)
  const handleChangeOpen = () => setOpen(!open)
  const { isLoading, data } = useStudentTestResult({ studentId, testId })
  const { t } = useTranslation(['translation', 'testing'])

  const title = t('testing:viewAllResults')

  return (
    <React.Fragment>
      <Button icon={<FolderViewOutlined />} onClick={handleChangeOpen} type={'primary'}>
        {title}
      </Button>
      <Modal
        visible={open}
        onCancel={handleChangeOpen}
        footer={false}
        title={`${title} ${studentName}`}
        width={650}
      >
        <List
          dataSource={data?.data}
          loading={isLoading}
          renderItem={(result, index) => {
            return (
              <List.Item
                actions={[
                  <Link key={'1'} to={'/testing/results/details/' + result.id}>
                    {t('moreDetails')}
                  </Link>,
                ]}
              >
                <List.Item.Meta
                  avatar={<EventResultPercent percent={result.correct_percent} />}
                  title={
                    <Row gutter={[16, 16]}>
                      <Col xs={24}>
                        {t('attempt', { attempt: data && data.data.length - index })}
                      </Col>
                      <Col xs={24}>
                        <Space split={<Divider type={'vertical'} />}>
                          <Typography.Text>
                            {t('testing:rightAnswers')}: {result.correct_answers}
                          </Typography.Text>
                          <Typography.Text>
                            {t('testing:wrongAnswers')}: {result.incorrect_answers}
                          </Typography.Text>
                        </Space>
                      </Col>
                    </Row>
                  }
                  description={t('testing:timeTakenToSolveTheTest') + ': ' + result.spent_time}
                />
              </List.Item>
            )
          }}
        />
      </Modal>
    </React.Fragment>
  )
}

export default React.memo(EventAllResultsModal)
