import { Form, FormItemProps, Select } from 'antd'
import React from 'react'
import { useBranches } from '../../../../hooks/query/branches'
import { filterOption } from '../../../../lib/filterOption'
import { useTranslation } from 'react-i18next'

interface props {
  onChange?: (value: string) => void
  mode: 'single' | 'multiple' | 'tags'
  name?: string
  label?: string
  required?: boolean
  rest?: FormItemProps
}

const BranchesList: React.FC<props> = ({
  onChange,
  mode,
  name = 'branch_id',
  label = 'branch',
  required = true,
}) => {
  const { t } = useTranslation()
  const { isLoading, data } = useBranches()

  const style = { width: '100%' }

  return (
    <Form.Item name={name} label={t(label)} rules={[{ required, message: t('required') }]}>
      <Select
        mode={mode === 'single' ? undefined : mode}
        allowClear={!required}
        filterOption={filterOption}
        showSearch
        disabled={isLoading}
        loading={isLoading}
        style={style}
        placeholder={t('chooseBranch')}
        onChange={onChange && onChange}
      >
        {data?.map(({ id, title }) => (
          <Select.Option key={id} value={id!}>
            {title}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default BranchesList
