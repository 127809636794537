import React from 'react'
import { idType } from '../../../../interfaces/types/types'
import { useAllStudentTestsResults } from '../../../../hooks/query/students'
import { useTranslation } from 'react-i18next'
import { ITestEvent, ITestResult } from '../../../../interfaces'
import EventLink from '../../../core/event-link/event-link'
import EventResultPercent from '../../../core/event-result-percent/event-result-percent'
import EventResultTable from '../../../core/event-result-table/event-result-table'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'
import EventAllResultsModal from '../../testing/events/event-all-results-modal/event-all-results-modal'

interface props {
  studentId: idType
}

const StudentEventsResults: React.FC<props> = ({ studentId }) => {
  const { t } = useTranslation(['translation', 'testing'])
  const { isLoading, data } = useAllStudentTestsResults(studentId)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => (
        <>
          <TableMobileTitle title={'ID'} /># {id}
        </>
      ),
    },
    {
      title: t('event'),
      dataIndex: 'test',
      render: ({ id, title }: ITestEvent) => (
        <>
          <TableMobileTitle title={t('event')} />
          <EventLink title={title} id={id} />
        </>
      ),
    },
    {
      title: t('questionsCount'),
      dataIndex: 'test',
      render: ({ questions_count }: ITestEvent) => (
        <>
          <TableMobileTitle title={t('questionsCount')} />
          {questions_count}
        </>
      ),
    },
    {
      title: t('testing:rightAnswers'),
      dataIndex: 'correct_answers',
      render: (text: number) => (
        <>
          <TableMobileTitle title={'tesing:rightAnswers'} />
          {text}
        </>
      ),
    },
    {
      title: t('testing:wrongAnswers'),
      dataIndex: 'incorrect_answers',
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('testing:wrongAnswers')} />
          {text}
        </>
      ),
    },
    {
      title: t('result'),
      dataIndex: 'correct_percent',
      render: (percent: number) => (
        <>
          <TableMobileTitle title={t('result')} />
          <EventResultPercent percent={percent} />
        </>
      ),
    },
    {
      title: t('testing:timeTakenToSolveTheTest'),
      dataIndex: 'spent_time',
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('testing:timeTakenToSolveTheTest')} />
          {text}
        </>
      ),
    },
    {
      title: t('actions'),
      align: 'right',
      key: 'actions',
      render: (test: ITestResult) => (
        <EventAllResultsModal studentId={test.user_id} testId={test.id} />
      ),
    },
  ]

  return <EventResultTable columns={columns} dataSource={data} isLoading={isLoading} />
}

export default StudentEventsResults
