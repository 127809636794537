import React from 'react'
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons'
import styles from './result-icon.module.scss'
import { resultColors } from '../../../../../lib/resultColors'
import EventResultPercent from '../../../../core/event-result-percent/event-result-percent'

interface props {
  percent: number
}

const ResultIcon: React.FC<props> = ({ percent }) => {
  const renderIcon = () => {
    if (percent <= 50) return <FrownOutlined style={{ color: resultColors.red }} />
    if (percent > 50 && percent <= 75) return <MehOutlined style={{ color: resultColors.orange }} />
    if (percent > 75 && percent <= 89)
      return <SmileOutlined style={{ color: resultColors.yellow }} />
    return <SmileOutlined style={{ color: resultColors.green }} />
  }

  return (
    <div className={['ant-result-icon', styles.wrapper].join(' ')}>
      {renderIcon()}
      <EventResultPercent percent={percent} />
    </div>
  )
}

export default ResultIcon
