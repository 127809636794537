import React from 'react'
import TeachersTable from './teachers-table/teachers-table'
import { PageHeader, Skeleton } from 'antd'
import ErrorAlert from '../../core/error-alert/error-alert'
import MyPagination from '../../core/my-pagination/my-pagination'
import { useTranslation } from 'react-i18next'
import TeachersDrawer from './teachers-drawer/teachers-drawer'
import Head from '../../../hoc/head/head'
import { TEACHERS_KEY, useTeachers } from '../../../hooks/query/teachers'
import { useRoleChecker } from '../../../hooks/useRoleChecker'
import FiltersCard from '../../core/filters-card/filters-card'
import TeachersFilters from './teacher-filters/teachers-filters'

interface props {}

const Teachers: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'teachers'])
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin'])

  const { isError, isLoading, data: response, error } = useTeachers()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { data, ...pagination } = response!

  return (
    <Head title={t('teachers')}>
      <PageHeader
        title={t('teachers')}
        subTitle={t('teachers:teachersSubtitle')}
        onBack={() => window.history.back()}
        extra={[access && <TeachersDrawer key={1} title={'addTitle'} />]}
      />
      <FiltersCard>
        <TeachersFilters />
      </FiltersCard>

      {/*<GetEntitiesByStatus*/}
      {/*	activeTxt={'teachers:activeTeachers'}*/}
      {/*	archiveTxt={'teachers:inactiveTeachers'}*/}
      {/*	fetchingQueryKey={TEACHERS_KEY}*/}
      {/*/>*/}
      <TeachersTable teachers={data} />
      <MyPagination pagination={pagination} fetchingQueryKey={TEACHERS_KEY} />
    </Head>
  )
}

export default Teachers
