import React from 'react'
import { useParams } from 'react-router-dom'
import ErrorAlert from '../../core/error-alert/error-alert'
import { Col, PageHeader, Row, Skeleton, Space } from 'antd'
import GroupCard from './group-card/group-card'
import { useTranslation } from 'react-i18next'
import GroupsDrawer from './groups-drawer/groups-drawer'
import Head from '../../../hoc/head/head'
import GroupFinanceRecalculation from './group-finance-recalculation/group-finance-recalculation'
import { useGroup } from '../../../hooks/query/groups'
import { useRoleChecker } from '../../../hooks/useRoleChecker'
import GroupTabs from './group-tabs/group-tabs'
import GroupFinanceDrawer from './group-finance-drawer/group-finance-drawer'

interface props {}

const Group: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { roleChecker } = useRoleChecker()
  const adminAccess = roleChecker(['admin', 'accountant', 'cashier'])
  const editAccess = roleChecker(['admin', 'reception'])
  const financialReportAccess = roleChecker(['admin', 'cashier', 'accountant', 'teacher'])

  const { t } = useTranslation(['translation', 'groups'])

  const { isLoading, isError, error, data } = useGroup(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const group = data!

  return (
    <Head title={group.name}>
      <PageHeader
        title={group.name}
        subTitle={t('groups:groupSubtitle')}
        onBack={() => window.history.back()}
        extra={[
          <Space key={'1'}>
            {financialReportAccess && <GroupFinanceDrawer groupName={group.name} />}
            {adminAccess && <GroupFinanceRecalculation groupId={id} />}
            {editAccess && (
              <GroupsDrawer key={'1'} updateOne={true} title={'editTitle'} group={group} />
            )}
          </Space>,
        ]}
      />
      <Row gutter={[32, 32]}>
        <Col xs={24}>
          <GroupCard group={group} />
        </Col>
        <Col xs={24}>
          <GroupTabs start_date={group.start_date} end_date={group.end_date} />
        </Col>
      </Row>
    </Head>
  )
}

export default React.memo(Group)
