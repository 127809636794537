import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import RouteGuard from './hoc/route-guard'
import { privateRoutes, publicRoutes } from './routes'
import DashboardLayout from './hoc/layout/layout'
import ExaminationPage from './pages/testing/examination/examination-page'
import PublicLayout from './hoc/public-layout/public-layout'

function App() {
  return (
    <Switch>
      <Route path={privateRoutes.map(({ path }) => path)} exact>
        <DashboardLayout>
          <Switch>
            {privateRoutes.map(({ path, component, roles }) => (
              <RouteGuard key={path} path={path} component={component} roles={roles} exact />
            ))}
          </Switch>
        </DashboardLayout>
      </Route>

      <Route path="/testing/examination" exact component={ExaminationPage} />

      <Route path={publicRoutes.map(({ path }) => path)}>
        <PublicLayout>
          <Switch>
            {publicRoutes.map(({ path, component }) => (
              <Route key={path} path={path} component={component} exact />
            ))}
          </Switch>
        </PublicLayout>
      </Route>

      <Redirect to="/auth" />
    </Switch>
  )
}

export default App
