import React from 'react'
import { Button, message, Modal } from 'antd'
import styles from './delete-modal.module.scss'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../interfaces/types/types'
import { AxiosPromise } from 'axios'
import { DeleteOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { queryClient } from '../../../index'

interface props {
  deleteFunction: (id: idType) => AxiosPromise<any>
  fetchingQueryKey: string | string[]
  modalText: string
  confirmationName?: string
  redirect?: boolean
  redirectTo?: string
  id: idType
}

const DeleteModal: React.FC<props> = ({
  deleteFunction,
  fetchingQueryKey,
  id,
  modalText,
  redirect,
  redirectTo,
  confirmationName,
}) => {
  const [visible, setVisible] = React.useState(false)
  const { t } = useTranslation([
    'translation',
    'groups',
    'finances',
    'rooms',
    'students',
    'teachers',
    'testing',
  ])
  const history = useHistory()

  const modalHandler = () => {
    setVisible(!visible)
  }

  const { isLoading, mutateAsync } = useMutation(deleteFunction, {
    onSuccess: async () => {
      message.success(t('responseSuccess'))
      setVisible(false)

      if (redirect && redirectTo) {
        return history.push(redirectTo)
      }

      return queryClient.invalidateQueries(fetchingQueryKey)
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  const handleOk = async () => mutateAsync(id)

  return (
    <React.Fragment>
      <Button type={'default'} className={styles.button} onClick={modalHandler}>
        <DeleteOutlined /> {t('delete')}
      </Button>
      <Modal
        destroyOnClose={true}
        title={t('delete')}
        visible={visible}
        cancelText={t('cancel')}
        okText={t('yes')}
        onOk={handleOk}
        confirmLoading={isLoading}
        onCancel={modalHandler}
      >
        <p>{t(modalText, { name: confirmationName })}</p>
      </Modal>
    </React.Fragment>
  )
}

export default React.memo(DeleteModal)
