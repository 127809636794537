import React, { useEffect } from 'react'
import { Typography } from 'antd'
import styles from './app-loader.module.scss'
import { useTranslation } from 'react-i18next'
import PublicLayout from '../../../hoc/public-layout/public-layout'
import AppLoaderProgressbar from './app-loader-progressbar/app-loader-progressbar'
import { useHistory } from 'react-router-dom'
import { useAuthUserInformation } from '../../../hooks/query/auth'
import ErrorAlert from '../error-alert/error-alert'

interface props {}

const AppLoader: React.FC<props> = ({ children }) => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const { isSuccess, isError, error, isFetched, data } = useAuthUserInformation()

  useEffect(() => {
    if (data?.must_change_password) {
      return push({
        pathname: '/profile',
        state: {
          isRedirect: true,
        },
      })
    }
  }, [data, push])

  return (
    <React.Fragment>
      <div className={[styles.loaderContainer, isSuccess ? styles.hide : ''].join(' ')}>
        <PublicLayout>
          <AppLoaderProgressbar loaded={isFetched} isError={isError} />
          {isError ? (
            <ErrorAlert msg={error?.message} />
          ) : (
            <Typography.Title className={styles.title} level={4}>
              {t('loading')}...
            </Typography.Title>
          )}
        </PublicLayout>
      </div>
      {isSuccess && children}
    </React.Fragment>
  )
}

export default AppLoader
