import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { IStudentPaymentsWithPagination } from '../../interfaces'
import { getStudentsPaymentsApi } from '../../api/payments'

export const STUDENTS_PAYMENTS_KEY = 'students_payments_key'

export function useStudentsPayments(
  options?: UseQueryOptions<IStudentPaymentsWithPagination, Error>,
) {
  return useQuery<IStudentPaymentsWithPagination, Error>(
    STUDENTS_PAYMENTS_KEY,
    () =>
      getStudentsPaymentsApi().then(
        (response: AxiosResponse<IStudentPaymentsWithPagination>) => response.data,
      ),
    {
      ...options,
    },
  )
}
