import React from 'react'
import { Button, Col, Row } from 'antd'
import { useAuthUserInformation } from '../../../hooks/query/auth'
import { SELECTED_BRANCH } from '../../../constants/auth'
import { idType } from '../../../interfaces/types/types'

interface props {}

const LayoutBranches: React.FC<props> = () => {
  const { data } = useAuthUserInformation()

  function handleChange(id: idType) {
    localStorage.setItem(SELECTED_BRANCH, id!.toString())
    window.location.reload()
  }

  const isSelectedBranchExist = localStorage.getItem(SELECTED_BRANCH)

  return (
    <Row gutter={[4, 4]}>
      {data?.branches.map(({ title, id }, index) => (
        <Col flex={1} key={id}>
          <Button
            onClick={() => handleChange(id)}
            type={'default'}
            block
            disabled={isSelectedBranchExist ? Number(isSelectedBranchExist) === id! : index === 0}
          >
            {title}
          </Button>
        </Col>
      ))}
    </Row>
  )
}

export default LayoutBranches
