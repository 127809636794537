import React from 'react'
import { Descriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { priceFormatter } from '../../../../../../lib/priceFormatter'
import FinancesGroupDifference from '../../../finance-difference/finances-difference'
import FinanceDifference from '../../../finance-difference/finances-difference'
import { IFinanceTeacherTotal } from '../../../../../../interfaces'
import isEqual from 'react-fast-compare'
import { descriptionsSettings } from '../../../../../../lib/settings'

interface props {
  totals: IFinanceTeacherTotal
}

const FinanceTeacherFilterStatistic: React.FC<props> = ({ totals }) => {
  const { t } = useTranslation(['translation', 'groups'])

  const {
    first_day,
    difference,
    reasons_total,
    paid,
    groups_summary,
    lessons_count,
    groups_count,
    students_count,
    received_from_groups,
    total,
    total_in_fact,
  } = totals

  return (
    <Descriptions
      {...descriptionsSettings}
      bordered={true}
      title={`${t('informationFor')} ${moment(first_day).format('MMMM, YYYY')}`}
      column={{ xs: 1, lg: 2 }}
    >
      <Descriptions.Item label={t('groups:studentsCount')}>
        <b>{students_count}</b>
      </Descriptions.Item>
      <Descriptions.Item label={'Количество групп'}>
        <b>{groups_count}</b>
      </Descriptions.Item>
      <Descriptions.Item label={t('numberOfLessons')}>
        <b>{lessons_count}</b>
      </Descriptions.Item>
      <Descriptions.Item label={t('reasonSum')}>
        <b>{priceFormatter(reasons_total)}</b>
      </Descriptions.Item>
      <Descriptions.Item label={t('costOfTeacherGroups')}>
        <b>{priceFormatter(groups_summary)}</b>
      </Descriptions.Item>
      <Descriptions.Item label={t('teacherPotentialSalary')}>
        <b>{priceFormatter(total)}</b>
      </Descriptions.Item>
      <Descriptions.Item label={t('amountOfPaidStudents')}>
        <FinanceDifference difference={received_from_groups} />
      </Descriptions.Item>
      <Descriptions.Item label={t('teacherSalary')}>
        <FinanceDifference difference={total_in_fact} />
      </Descriptions.Item>
      <Descriptions.Item label={t('paidToTheTeacher')}>
        <b>{priceFormatter(paid)}</b>
      </Descriptions.Item>
      <Descriptions.Item label={t('difference')}>
        <FinancesGroupDifference difference={difference} />
      </Descriptions.Item>
    </Descriptions>
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps.totals, nextProps.totals)
}

export default React.memo(FinanceTeacherFilterStatistic, propsAreEqual)
