import React from 'react'
import { Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  drawerToggle: any
  formId: string
  disabled: boolean
}

const DrawerFooter: React.FC<props> = ({ drawerToggle, formId, disabled }) => {
  const { t } = useTranslation()

  return (
    <Space>
      <Button onClick={drawerToggle}>{t('cancel')}</Button>
      <Button
        form={formId}
        key="submit"
        htmlType="submit"
        type="primary"
        disabled={disabled}
        loading={disabled}
      >
        {t('submit')}
      </Button>
    </Space>
  )
}

export default DrawerFooter
