import React from 'react'
import Head from '../../../../hoc/head/head'
import { useTranslation } from 'react-i18next'
import { PageHeader, Skeleton } from 'antd'
import AdditionalExpensesFilters from './additional-expenses-filters/additional-expenses-filters'
import AdditionalExpensesDrawer from './additional-expenses-drawer/additional-expenses-drawer'
import AdditionalExpensesTable from './additional-expenses-table/additional-expenses-table'
import { EXPENSES_KEY, useExpenses } from '../../../../hooks/query/expenses'
import ErrorAlert from '../../../core/error-alert/error-alert'
import MyPagination from '../../../core/my-pagination/my-pagination'

interface props {}

const AdditionalExpenses: React.FC<props> = () => {
  const { t } = useTranslation()
  const { isLoading, isError, error, data } = useExpenses()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { data: expenses, ...pagination } = data!
  const title = t('additionalExpenses')
  return (
    <Head title={title}>
      <PageHeader
        title={title}
        onBack={() => window.history.back()}
        extra={[<AdditionalExpensesDrawer key={'1'} />]}
      />
      <AdditionalExpensesFilters />
      <AdditionalExpensesTable data={expenses} />
      <MyPagination pagination={pagination} fetchingQueryKey={EXPENSES_KEY} />
    </Head>
  )
}

export default AdditionalExpenses
