import React from 'react'
import { IOrderCard } from '../../../../interfaces'
import LeadsCard from './leads-card/leads-card'
import { Draggable } from 'react-beautiful-dnd'

interface props {
  cards: IOrderCard[]
}

const LeadsCards: React.FC<props> = ({ cards }) => {
  return (
    <React.Fragment>
      {cards.map((card, index) => (
        <Draggable key={card.id} draggableId={card.id!.toString()} index={index}>
          {provided => <LeadsCard card={card} provided={provided} />}
        </Draggable>
      ))}
    </React.Fragment>
  )
}

export default React.memo(LeadsCards)
