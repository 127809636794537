import { useAuthUserInformation } from './query/auth'
import { SELECTED_BRANCH } from '../constants/auth'

export function useActiveBranch() {
  const { data } = useAuthUserInformation()
  const isSelectedBranchExist = localStorage.getItem(SELECTED_BRANCH)
  if (!data) return

  const activeBranch = isSelectedBranchExist
    ? data.branches.find(branch => branch.id! === Number(isSelectedBranchExist))
    : data.branches[0]

  return activeBranch
}
