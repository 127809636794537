import React from 'react'
import Events from '../../../app/modules/testing/events/events'

interface props {}

const EventsPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Events />
    </React.Fragment>
  )
}

export default EventsPage
