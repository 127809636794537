import React, { useState } from 'react'
import { Button, Form, message, Modal } from 'antd'
import BranchesList from '../../../../../core/form-group/branches-list/branches-list'
import { IOrderColumn } from '../../../../../../interfaces'
import { editColumnApi } from '../../../../../../api/leads'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { queryClient } from '../../../../../../index'
import { LEADS_KEY } from '../../../../../../hooks/query/leads'
import { ArrowRightOutlined } from '@ant-design/icons'

interface props {
  column: IOrderColumn
}

const LeadsColumnTransfer: React.FC<props> = ({ column }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const handleModalVisibleChange = () => setVisible(!visible)
  const { t } = useTranslation()

  const { isLoading, mutateAsync } = useMutation(editColumnApi, {
    onSuccess: () => {
      const oldQueryData = queryClient.getQueryData<IOrderColumn[]>(LEADS_KEY)!
      queryClient.setQueryData(LEADS_KEY, () => {
        return oldQueryData.filter(c => c.id !== column.id)
      })
      message.success(t('responseSuccess'))
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const onFinish = ({ branch_id }: any) => mutateAsync({ ...column, branch_id })

  const title = t('transferToAnotherBranch')
  const formId = 'transfer-column'
  return (
    <>
      <Button
        onClick={handleModalVisibleChange}
        icon={<ArrowRightOutlined />}
        className={'ant-btn-no-style'}
        size={'small'}
      >
        {title}
      </Button>
      <Modal
        title={title}
        visible={visible}
        onCancel={handleModalVisibleChange}
        okButtonProps={{
          disabled: isLoading,
          loading: isLoading,
          htmlType: 'submit',
          form: formId,
        }}
        okText={t('submit')}
      >
        <Form id={formId} onFinish={onFinish} initialValues={column}>
          <BranchesList mode={'single'} />
        </Form>
      </Modal>
    </>
  )
}

export default LeadsColumnTransfer
