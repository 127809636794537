import React from 'react'
import { Spin } from 'antd'
import ErrorAlert from '../../../../core/error-alert/error-alert'
import FinanceTeacherInfo from '../../../../core/finance-teacher-info/finance-teacher-info'
import { useParams } from 'react-router-dom'
import { useFinancesTeacher } from '../../../../../hooks/query/teachers'

interface props {}

const TeacherFinanceDrawerReport: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { isLoading, isError, error, isSuccess, data } = useFinancesTeacher(id)

  return (
    <Spin spinning={isLoading}>
      {isError && <ErrorAlert msg={error?.message} />}
      {isSuccess && (
        <FinanceTeacherInfo
          teacherId={id}
          totals={data!.totals}
          students={data!.students}
          groupsSummaries={data!.groups_summaries}
          salaries={data!.salaries}
          teacherSummaries={data!.teacher_summaries}
        />
      )}
    </Spin>
  )
}

export default TeacherFinanceDrawerReport
