import { request as axios } from './index'
import { idType } from '../interfaces/types/types'
import { IStudentDiscount } from '../interfaces'

const url = '/discounts'

export function setGroupDiscounts(discount: IStudentDiscount) {
  return axios.post(url, discount)
}

export function deleteStudentDiscount(id: idType) {
  return axios.delete(url + '/' + id)
}
