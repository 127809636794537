import React from 'react'
import { Form, message, Modal } from 'antd'
import BranchesList from '../../../../../core/form-group/branches-list/branches-list'
import ColumnsList from '../../../../../core/form-group/columns-list/columns-list'
import { serialize } from '../../../../../../lib/serializeQuery'
import { queryClient } from '../../../../../../index'
import { useMutation } from 'react-query'
import { editCardApi } from '../../../../../../api/leads'
import { LEADS_COLUMNS_LIST_KEY, LEADS_KEY } from '../../../../../../hooks/query/leads'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IOrderCard } from '../../../../../../interfaces'
import { deleteQueryFields } from '../../../../../../lib/deleteQueryFields'

interface props {
  card: IOrderCard
  visible: boolean
  setVisible: () => void
}

const LeadsCardTransferModal: React.FC<props> = ({ card, visible, setVisible }) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { push } = useHistory()

  const transferFormId = 'transfer-form-id'

  const { isLoading: isTransferLoading, mutateAsync: transfer } = useMutation(editCardApi, {
    onSuccess: async () => {
      deleteQueryFields('branch_id')
      await queryClient.invalidateQueries(LEADS_KEY)
      message.success(t('responseSuccess'))
      setVisible()
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const changeBranch = (value: string) => {
    form.resetFields(['column_id'])
    push({ search: serialize('branch_id', value) })
    queryClient.invalidateQueries(LEADS_COLUMNS_LIST_KEY)
  }

  const onTransferFinish = ({ branch_id, column_id }: any) =>
    transfer({ ...card, branch_id, column_id })

  return (
    <Modal
      visible={visible}
      title={t('transferToAnotherBranch')}
      onCancel={setVisible}
      okText={t('submit')}
      confirmLoading={isTransferLoading}
      okButtonProps={{ htmlType: 'submit', form: transferFormId }}
    >
      <Form form={form} id={transferFormId} onFinish={onTransferFinish} layout={'vertical'}>
        <BranchesList onChange={changeBranch} mode={'single'} />
        <ColumnsList />
      </Form>
    </Modal>
  )
}

export default LeadsCardTransferModal
