import React from 'react'
import { IGroupNotification } from '../../../../../interfaces'
import { Alert } from 'antd'
import { ClockCircleOutlined, UserOutlined } from '@ant-design/icons'
import styles from './group-notification-item.module.scss'
import DateWithTime from '../../../../core/form-group/date-with-time/date-with-time'
import isEqual from 'react-fast-compare'

interface props {
  notification: IGroupNotification
}

const GroupNotificationsItem: React.FC<props> = ({ notification }) => {
  const { title, type, message, user, created_at } = notification

  return (
    <Alert
      className={[styles.item, type === 'info' ? styles.info : ''].join(' ')}
      type={type === 'important' ? 'error' : (type as 'info' | 'warning')}
      showIcon
      message={title}
      description={message}
      action={
        <div className={styles.wrapper}>
          <span className={styles.name}>
            <UserOutlined /> {user.name}
          </span>
          <span className={styles.time}>
            <ClockCircleOutlined /> <DateWithTime date={created_at} />
          </span>
        </div>
      }
    />
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps.notification, nextProps.notification)
}

export default React.memo(GroupNotificationsItem, propsAreEqual)
