import React from 'react'
import { useTranslation } from 'react-i18next'
import { priceFormatter } from '../../../../../../lib/priceFormatter'
import { Descriptions } from 'antd'
import FinancesGroupDifference from '../../../finance-difference/finances-difference'
import moment from 'moment'
import { IFinanceTotal } from '../../../../../../interfaces'
import { descriptionsSettings } from '../../../../../../lib/settings'

interface props {
  totals: IFinanceTotal
}

const FinancesGroupFilterStatistic: React.FC<props> = ({ totals }) => {
  const { t } = useTranslation(['translation', 'finances', 'groups'])

  const {
    course_price,
    lessons_count_in_period,
    difference,
    lesson_price,
    paid,
    reason_total,
    students_count,
    total,
    first_day,
    last_day,
  } = totals

  return (
    <Descriptions
      {...descriptionsSettings}
      bordered
      title={`${t('informationFor')} ${moment(first_day).format('D MMMM YYYY')} - ${moment(
        last_day,
      ).format('D MMMM YYYY')}`}
      column={{ xs: 1, lg: 2 }}
    >
      <Descriptions.Item label={t('groups:studentsCount')}>
        <b>{students_count}</b>
      </Descriptions.Item>
      <Descriptions.Item label={t('coursePrice')}>
        <b>{priceFormatter(course_price)}</b>
      </Descriptions.Item>
      <Descriptions.Item label={t('numberOfLessons')}>
        <b>{lessons_count_in_period}</b>
      </Descriptions.Item>
      <Descriptions.Item label={t('lessonPrice')}>
        <b>{priceFormatter(lesson_price)}</b>
      </Descriptions.Item>
      <Descriptions.Item label={t('totalPayment')}>
        <b>{priceFormatter(total)}</b>
      </Descriptions.Item>
      <Descriptions.Item label={t('paidUp')}>
        <b>{priceFormatter(paid)}</b>
      </Descriptions.Item>
      <Descriptions.Item label={t('reasonSum')}>
        <b>{priceFormatter(reason_total)}</b>
      </Descriptions.Item>
      <Descriptions.Item label={t('difference')}>
        <FinancesGroupDifference difference={difference} />
      </Descriptions.Item>
    </Descriptions>
  )
}

export default React.memo(FinancesGroupFilterStatistic)
