import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRoom } from '../../../../interfaces'
import { Drawer, message } from 'antd'
import DrawerOpen from '../../../core/drawer/drawer-open/drawer-open'
import { drawerSettings } from '../../../../lib/settings'
import DrawerFooter from '../../../core/drawer/drawer-footer/drawer-footer'
import RoomsForm from '../rooms-form/rooms-form'
import { createRoomApi, updateRoomApi } from '../../../../api/rooms'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../index'
import { ROOMS_KEY } from '../../../../hooks/query/rooms'

interface props {
  room?: IRoom
  title: string
  updateOne?: boolean
}

const RoomsDrawer: React.FC<props> = ({ room, title, updateOne }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const drawerToggle = () => setVisible(!visible)

  const { t } = useTranslation(['translation', 'rooms'])

  const onSuccess = async () => {
    await queryClient.invalidateQueries(updateOne ? [ROOMS_KEY, room!.id!.toString()] : ROOMS_KEY)
    message.success(t('responseSuccess'))
    drawerToggle()
  }
  const onError = (e: any) => {
    message.error(e.response.data.message)
  }

  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(createRoomApi, {
    onSuccess,
    onError,
  })
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(updateRoomApi, {
    onSuccess,
    onError,
  })

  const submitForm = async (fieldsValues: any) =>
    room ? update(fieldsValues) : create(fieldsValues)

  return (
    <>
      <DrawerOpen drawerToggle={drawerToggle} edit={room} />
      <Drawer
        {...drawerSettings}
        onClose={drawerToggle}
        title={t('rooms:' + title)}
        visible={visible}
        footer={
          <DrawerFooter
            disabled={isCreateLoading || isUpdateLoading}
            drawerToggle={drawerToggle}
            formId={'room-form'}
          />
        }
      >
        <RoomsForm item={room} formId={'room-form'} submitForm={submitForm} />
      </Drawer>
    </>
  )
}

export default React.memo(RoomsDrawer)
