import React from 'react'
import { IPagination, ITestResult } from '../../../interfaces'
import { Table } from 'antd'
import styles from './event-result-table.module.scss'
import MyPagination from '../my-pagination/my-pagination'

interface props {
  columns: any
  dataSource: ITestResult[] | undefined
  isLoading: boolean
  title?: () => React.ReactNode
  pagination?: IPagination
  fetchingQueryKey?: string | string[]
}

const EventResultTable: React.FC<props> = ({
  title,
  columns,
  dataSource,
  isLoading,
  pagination,
  fetchingQueryKey,
}) => {
  return (
    <React.Fragment>
      <Table
        className={[styles.table, 'adaptive-table'].join(' ')}
        loading={isLoading}
        dataSource={dataSource}
        title={title}
        pagination={false}
        scroll={{ x: true }}
        rowClassName={({ correct_percent }: ITestResult) => {
          if (correct_percent === null) return ''

          if (correct_percent <= 50) return 'red'
          if (correct_percent > 50 && correct_percent <= 75) return 'orange'
          if (correct_percent > 75 && correct_percent <= 89) return 'yellow'

          return 'green'
        }}
        rowKey={'id'}
        columns={columns}
      />
      {pagination && fetchingQueryKey && (
        <MyPagination pagination={pagination} fetchingQueryKey={fetchingQueryKey} />
      )}
    </React.Fragment>
  )
}

export default EventResultTable
