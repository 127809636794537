import React from 'react'
import styles from './test-variant-wrapper.module.scss'

interface props {
  className?: string
}

const TestVariantWrapper: React.FC<props> = ({ children, className }) => {
  return <div className={[styles.wrapper, className].join(' ')}>{children}</div>
}

export default TestVariantWrapper
