import React from 'react'
import styles from './leads-column.module.scss'
import LeadsCards from '../../leads-cards/leads-cards'
import { IOrderColumn } from '../../../../../interfaces'
import LeadsColumnDropdown from './leads-column-dropdown/leads-column-dropdown'
import LeadsCardForm from '../../leads-cards/leads-card/leads-card-form/leads-card-form'
import { Droppable } from 'react-beautiful-dnd'

interface props {
  column: IOrderColumn
}

const LeadsColumn: React.FC<props> = ({ column }) => {
  const { name, cards, id } = column

  return (
    <div className={styles.column}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{name}</h2>
        <LeadsColumnDropdown column={column} />
        <div className={styles.form}>
          <LeadsCardForm column_id={id} />
        </div>
      </div>

      <Droppable droppableId={id!.toString()} type={'droppableCard'}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className={styles.droppable}>
            <LeadsCards cards={cards} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

export default React.memo(LeadsColumn)
