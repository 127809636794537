import React from 'react'
import { List } from 'antd'
import { IGroupNotification } from '../../../../../interfaces'
import GroupNotificationsItem from '../group-notifications-item/group-notifications-item'
import { useGroupNotifications } from '../../../../../hooks/query/groups'
import { useParams } from 'react-router-dom'
import ErrorAlert from '../../../../core/error-alert/error-alert'
import { useTranslation } from 'react-i18next'

interface props {}

const GroupNotificationsList: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { isLoading, isError, data } = useGroupNotifications(id)

  if (isError) return <ErrorAlert msg={t('serverError')} />

  return (
    <List
      loading={isLoading}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(notification: IGroupNotification) => (
        <GroupNotificationsItem notification={notification} />
      )}
    />
  )
}

export default GroupNotificationsList
