import { useQuery, UseQueryOptions } from 'react-query'
import { IOrderCard, IOrderColumn } from '../../interfaces'
import { getAllLeadsApi, getCardsListApi, getColumnsListApi } from '../../api/leads'
import { AxiosResponse } from 'axios'

export const LEADS_KEY = 'leads_key'
export const LEADS_COLUMNS_LIST_KEY = 'leads_columns_list_api'
export const LEADS_CARDS_LIST_KEY = 'leads_cards_list_api'

export function useLeads(options?: UseQueryOptions<IOrderColumn[], Error>) {
  return useQuery<IOrderColumn[], Error>(
    LEADS_KEY,
    () => getAllLeadsApi().then((response: AxiosResponse<IOrderColumn[]>) => response.data),
    {
      ...options,
    },
  )
}

export function useColumnsList(options?: UseQueryOptions<IOrderColumn[], Error>) {
  return useQuery<IOrderColumn[], Error>(
    LEADS_COLUMNS_LIST_KEY,
    () => getColumnsListApi().then((response: AxiosResponse<IOrderColumn[]>) => response.data),
    {
      ...options,
    },
  )
}

export function useCardsList(options?: UseQueryOptions<IOrderCard[], Error>) {
  return useQuery<IOrderCard[], Error>(
    LEADS_CARDS_LIST_KEY,
    () => getCardsListApi().then((response: AxiosResponse<IOrderCard[]>) => response.data),
    {
      ...options,
    },
  )
}
