import React from 'react'
import { Link } from 'react-router-dom'
import { idType } from '../../../interfaces/types/types'
import { useRoleChecker } from '../../../hooks/useRoleChecker'

interface props {
  courseId: idType
  courseName: string
}

const CourseLink: React.FC<props> = ({ courseId, courseName }) => {
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception', 'accountant'])

  return access ? <Link to={'/courses/details/' + courseId}>{courseName}</Link> : <>{courseName}</>
}

export default React.memo(CourseLink)
