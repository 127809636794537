import React from 'react'
import Dashboard from '../../app/modules/dashboard/dashboard'

interface props {}

const DashboardPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Dashboard />
    </React.Fragment>
  )
}

export default DashboardPage
