import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row, Select } from 'antd'
import FormItemSelect from '../../../core/form-group/form-item-select/form-item-select'
import { grid } from '../../../../constants/filtersGrid'
import SearchInput from '../../../core/form-group/search-input/search-input'
import FilterButtons from '../../../core/form-group/filter-buttons/filter-buttons'
import LessonDuration from '../../../core/form-group/lesson-duration/lesson-duration'
import SumInput from '../../../core/form-group/sum-input/sum-input'
import MyDatePicker from '../../../core/form-group/my-date-picker/my-date-picker'
import { serialize } from '../../../../lib/serializeQuery'
import queryString from 'query-string'
import { queryClient } from '../../../../index'
import { COURSES_KEY } from '../../../../hooks/query/courses'
import moment from 'moment'

interface props {}

const CoursesFilters: React.FC<props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { push, location } = useHistory()
  const { t } = useTranslation(['translation', 'course'])
  const [form] = Form.useForm()

  const onFinish = async (values: any) => {
    setIsLoading(true)
    const query = {
      ...values,
      lesson_duration: values.lesson_duration && moment(values.lesson_duration).format('HH:mm'),
      created_at: values.created_at && moment(values.created_at).format('YYYY-MM-DD'),
    }
    await push({ search: serialize(query) })
    await queryClient.invalidateQueries(COURSES_KEY)
    setIsLoading(false)
  }
  const onReset = async () => {
    setIsLoading(true)
    await push({ search: '' })
    form.resetFields()
    await queryClient.invalidateQueries(COURSES_KEY)
    setIsLoading(false)
  }

  const initialValues = () => {
    const qs = queryString.parse(location.search)

    return {
      ...qs,
      lesson_duration: qs.lesson_duration ? moment(qs.lesson_duration, 'HH:mm') : undefined,
      created_at: qs.created_at ? moment(qs.created_at, 'YYYY-MM-DD') : undefined,
    }
  }

  return (
    <Form form={form} onFinish={onFinish} layout={'vertical'} initialValues={initialValues()}>
      <Row gutter={[16, 0]}>
        <Col {...grid}>
          <FormItemSelect label={'status'} name={'status'}>
            <Select.Option value={'true'}>{t('course:active')}</Select.Option>
            <Select.Option value={'false'}>{t('course:inactive')}</Select.Option>
          </FormItemSelect>
        </Col>
        <Col {...grid}>
          <LessonDuration required={false} />
        </Col>
        <Col {...grid}>
          <SumInput name={'price'} label={t('price')} rest={{ rules: [{ required: false }] }} />
        </Col>
        <Col {...grid}>
          <MyDatePicker
            required={false}
            name={'created_at'}
            label={'createdAt'}
            disableFutureDays={true}
          />
        </Col>
        <Col {...grid}>
          <FormItemSelect label={'type'} name={'course_type_id'}>
            <Select.Option value={'offline'}>{t('offline')}</Select.Option>
            <Select.Option value={'online'}>{t('online')}</Select.Option>
            <Select.Option value={'videocourse'}>{t('videocourse')}</Select.Option>
          </FormItemSelect>
        </Col>
        <Col flex={1}>
          <SearchInput />
        </Col>
        <Col xs={24}>
          <FilterButtons isLoading={isLoading} onReset={onReset} />
        </Col>
      </Row>
    </Form>
  )
}

export default CoursesFilters
