import { request as axios } from './index'
import { ITeacher } from '../interfaces'
import { idType } from '../interfaces/types/types'

export function getAllTeachersApi() {
  return axios.get('/teachers')
}

export function getTeachersListApi() {
  return axios.get('/teachers/list')
}

export function getTeacherByIdApi(id: idType) {
  return axios.get(`/teachers/${id}`)
}

export function createTeacherApi(data: ITeacher) {
  return axios.post('/teachers', data)
}

export function updateTeacherApi(data: ITeacher) {
  return axios.patch(`/teachers/${data.id}`, data)
}

export function getTeacherSalaryHistory(id: idType) {
  return axios.get(`/teachers/${id}/rates`)
}
