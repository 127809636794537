import React, { useState } from 'react'
import { IOrderCard, IOrderColumn } from '../../../../../interfaces'
import styles from './leads-card.module.scss'
import LeadsBids from '../../leads-bids/leads-bids'
import LeadsCardDropdown from './leads-card-dropdown/leads-card-dropdown'
import LeadsCardForm from './leads-card-form/leads-card-form'
import { Button } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import LeadsBidForm from '../../leads-bids/leads-bid/leads-bid-form/leads-bid-form'
import { useTranslation } from 'react-i18next'
import { DraggableProvided, Droppable } from 'react-beautiful-dnd'
import LeadsCardCollapse from './leads-card-collapse/leads-card-collapse'
import LeadsCardBidsLength from './leads-card-bids-length/leads-card-bids-length'
import { useMutation } from 'react-query'
import { editCardApi } from '../../../../../api/leads'
import { queryClient } from '../../../../../index'
import { LEADS_KEY } from '../../../../../hooks/query/leads'
import { AxiosResponse } from 'axios'

interface props {
  card: IOrderCard
  provided: DraggableProvided
}

const LeadsCard: React.FC<props> = ({ card, provided }) => {
  const { id, name, bids, collapse, column_id } = card

  const [edit, setEdit] = useState<boolean>(false)
  const [add, setAdd] = useState<boolean>(false)

  const { t } = useTranslation('leads')
  const { mutateAsync } = useMutation(editCardApi, {
    onSuccess: (response: AxiosResponse<IOrderCard>) => {
      const columns = queryClient.getQueryData<IOrderColumn[]>(LEADS_KEY)
      queryClient.setQueryData(
        LEADS_KEY,
        columns?.map(column => {
          if (column.id === column_id) {
            return {
              ...column,
              cards: column.cards.map(c => (c.id === id ? response.data : c)),
            }
          }
          return column
        }),
      )
    },
  })

  const changeCollapseHandler = () => mutateAsync({ ...card, collapse: !collapse })

  return (
    <div
      className={styles.card}
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{ ...provided.draggableProps.style }}
    >
      <div className={styles.draggable} {...provided.dragHandleProps}>
        {edit ? (
          <LeadsCardForm handler={setEdit} card={card} />
        ) : (
          <h3 className={styles.title} onDoubleClick={() => setEdit(true)}>
            {name}
          </h3>
        )}
      </div>

      {bids.length ? (
        <LeadsCardCollapse collapseHandler={changeCollapseHandler} collapse={collapse} />
      ) : null}

      <LeadsCardDropdown card={card} edit={edit} setEdit={setEdit} />
      <Droppable droppableId={id!.toString()} type={'droppableBids'}>
        {provided1 => (
          <div ref={provided1.innerRef} className={styles.bids}>
            {collapse ? (
              <LeadsCardBidsLength collapseHandler={changeCollapseHandler} length={bids.length} />
            ) : (
              <LeadsBids bids={bids} />
            )}
            {provided1.placeholder}
          </div>
        )}
      </Droppable>

      {add && <LeadsBidForm handler={setAdd} card_id={id} />}
      <Button onClick={() => setAdd(!add)} className={styles.button} type={'text'} block>
        {add ? (
          <MinusCircleOutlined />
        ) : (
          <>
            {' '}
            <PlusCircleOutlined /> {t('addBid')}
          </>
        )}
      </Button>
    </div>
  )
}

export default React.memo(LeadsCard)
