import React from 'react'
import { Col, Row } from 'antd'
import {
  DollarCircleOutlined,
  MinusCircleOutlined,
  MoneyCollectOutlined,
  QuestionCircleOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import FinanceCard from './finance-card/finance-card'
import Head from '../../../hoc/head/head'
import { useTranslation } from 'react-i18next'
import FinancePaymentsInfo from './finance-payments-info/finance-payments-info'

interface props {}

const Finances: React.FC<props> = () => {
  const { t } = useTranslation()

  return (
    <Head title={t('finances')}>
      <Row gutter={[16, 32]}>
        <Col xs={24} lg={13}>
          <Row gutter={[16, 16]}>
            <FinanceCard icon={UserOutlined} title={'students'} url={'students'} />
            <FinanceCard icon={TeamOutlined} title={'groups'} url={'finances/groups'} />
            <FinanceCard icon={SolutionOutlined} title={'teachers'} url={'finances/teachers'} />
            <FinanceCard icon={QuestionCircleOutlined} title={'debtors'} url={'finances/debtors'} />
            <FinanceCard
              title={'paymentsHistory'}
              url={'finances/payments-history'}
              icon={DollarCircleOutlined}
            />
            <FinanceCard
              title={'paymentsToTeachers'}
              url={'finances/payments-to-teachers'}
              icon={MoneyCollectOutlined}
            />
            <FinanceCard
              title={'additionalExpenses'}
              url={'finances/additional-expenses'}
              icon={MinusCircleOutlined}
            />
          </Row>
        </Col>
        <Col xs={24} lg={11}>
          <FinancePaymentsInfo />
        </Col>
      </Row>
    </Head>
  )
}

export default Finances
