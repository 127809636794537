import React, { useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import { IUser } from '../../../../interfaces'
import moment from 'moment'
import TeacherSalary from '../../../core/form-group/teacher-salary/teacher-salary'
import PhoneInput from '../../../core/form-group/phone-input/phone-input'
import PasswordInput from '../../../core/form-group/password-input/password-input'
import NameInput from '../../../core/form-group/name-input/name-input'
import BirthdaySelect from '../../../core/form-group/birthday-select/birthday-select'
import StatusSwitch from '../../../core/form-group/status-switch/status-switch'
import FormRolesList from '../../../core/form-roles-list/form-roles-list'
import BranchesList from '../../../core/form-group/branches-list/branches-list'

interface props {
  item?: IUser
  formId: string
  submitForm: any
}

const UsersForm: React.FC<props> = ({ item, formId, submitForm }) => {
  const [teacherSalary, setTeacherSalary] = useState<boolean>(false)

  useEffect(() => {
    if (item?.roles_slug.includes('teacher')) {
      setTeacherSalary(true)
    }
  }, [item])

  let user: any = {}

  if (item)
    user = {
      ...item,
      branch_id: item.branches.map(({ id }) => id),
      birthday: item.birthday ? moment(item.birthday) : undefined,
    }

  return (
    <Form
      id={formId}
      onFinish={submitForm}
      initialValues={{ ...user.rate, ...user }}
      layout={'vertical'}
    >
      <Form.Item name={'id'} hidden>
        <Input />
      </Form.Item>
      <NameInput />

      <PhoneInput label={'phone'} />
      <PasswordInput id={item?.id} label={'password'} />

      <BirthdaySelect />

      <FormRolesList setTeacherSalary={setTeacherSalary} />
      {teacherSalary && <TeacherSalary />}

      <BranchesList mode={'multiple'} />

      <StatusSwitch />
    </Form>
  )
}

export default UsersForm
