import { request as axios } from './index'
import { idType } from '../interfaces/types/types'

const url = '/question-bases'

export function getAllQuestionBasesApi() {
  return axios.get(url)
}

export function getQuestionBasesListApi() {
  return axios.get(url + '/list')
}

export function getQuestionBaseById(id: idType) {
  return axios.get(url + '/' + id)
}

export function getQuestionBaseQuestionsApi(id: idType) {
  return axios.get(url + '/' + id + '/questions')
}

export function createQuestionBaseApi(title: string) {
  return axios.post(url, { title })
}

export function updateQuestionBaseApi({ id, title }: { id: idType; title: string }) {
  return axios.patch(url + '/' + id, { title })
}

export function deleteQuestionBaseApi(id: idType) {
  return axios.delete(url + '/' + id)
}

export function importQuestionBaseApi({ id, data }: { id: idType; data: string }) {
  return axios.post(url + '/' + id + '/import', {
    text: data,
  })
}
