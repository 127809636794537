import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row, Select } from 'antd'
import FormItemSelect from '../../../core/form-group/form-item-select/form-item-select'
import { grid } from '../../../../constants/filtersGrid'
import SearchInput from '../../../core/form-group/search-input/search-input'
import FilterButtons from '../../../core/form-group/filter-buttons/filter-buttons'
import MyDatePicker from '../../../core/form-group/my-date-picker/my-date-picker'
import FormRolesList from '../../../core/form-roles-list/form-roles-list'
import { serialize } from '../../../../lib/serializeQuery'
import { queryClient } from '../../../../index'
import { USERS_KEY } from '../../../../hooks/query/users'
import queryString from 'query-string'
import moment from 'moment'
import BranchesList from '../../../core/form-group/branches-list/branches-list'

interface props {}

const UsersFilters: React.FC<props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { push, location } = useHistory()
  const { t } = useTranslation(['translation', 'staff'])
  const [form] = Form.useForm()

  const onFinish = async (values: any) => {
    setIsLoading(true)
    const query = {
      ...values,
      created_at: values.created_at ? moment(values.created_at).format('YYYY-MM-DD') : undefined,
    }
    await push({ search: serialize(query) })
    await queryClient.invalidateQueries(USERS_KEY)
    setIsLoading(false)
  }
  const onReset = async () => {
    setIsLoading(true)
    await push({ search: '' })
    form.resetFields()
    await queryClient.invalidateQueries(USERS_KEY)
    setIsLoading(false)
  }

  const initialValues = () => {
    const qs = queryString.parse(location.search)

    return {
      ...qs,
      branch_id: Number(qs.branch_id) || undefined,
      created_at: qs.created_at ? moment(qs.created_at, 'YYYY-MM-DD') : undefined,
      role_id: qs.role_id
        ? qs.role_id
            .toString()
            .split(',')
            .map(id => Number(id))
        : undefined,
    }
  }

  return (
    <Form form={form} onFinish={onFinish} layout={'vertical'} initialValues={initialValues()}>
      <Row gutter={[16, 0]}>
        <Col {...grid}>
          <BranchesList mode={'single'} required={false} />
        </Col>
        <Col {...grid}>
          <FormItemSelect label={'status'} name={'status'}>
            <Select.Option value={'true'}>{t('staff:active')}</Select.Option>
            <Select.Option value={'false'}>{t('staff:inactive')}</Select.Option>
          </FormItemSelect>
        </Col>
        <Col {...grid}>
          <FormRolesList required={false} />
        </Col>
        <Col {...grid}>
          <MyDatePicker
            required={false}
            disableFutureDays={true}
            name={'created_at'}
            label={'createdAt'}
          />
        </Col>
        <Col flex={1}>
          <SearchInput label={'firstnamePhoneSearch'} />
        </Col>
        <Col xs={24}>
          <FilterButtons isLoading={isLoading} onReset={onReset} />
        </Col>
      </Row>
    </Form>
  )
}

export default UsersFilters
