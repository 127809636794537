import React from 'react'
import { DatePicker, Form, message } from 'antd'
import moment from 'moment'
import { getGroupStudentsActivityApi } from '../../../../api/groups'
import { useTranslation } from 'react-i18next'
import styles from './group-month-picker.module.scss'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../index'
import { GROUP_STUDENTS_ACTIVITY_KEY } from '../../../../hooks/query/groups'
import { AxiosResponse } from 'axios'
import { IGroup } from '../../../../interfaces'
import { useHistory, useParams } from 'react-router-dom'
import { serialize } from '../../../../lib/serializeQuery'
import { getQueryParam } from '../../../../lib/getQueryParam'

interface props {
  start_date: string
  end_date: string
}

const GroupMonthPicker: React.FC<props> = ({ start_date, end_date }) => {
  const { push } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const { isLoading, mutateAsync } = useMutation(getGroupStudentsActivityApi, {
    onSuccess: async (response: AxiosResponse<IGroup>) => {
      await queryClient.setQueryData([GROUP_STUDENTS_ACTIVITY_KEY, id], response.data)
      message.success(t('responseSuccess'))
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  const changeMonth = async (value: any) => {
    push({
      search: serialize('date', moment(value).format('YYYY-MM-DD')),
    })

    return mutateAsync(id)
  }

  const renderDefaultValue = () => {
    const queryDate = getQueryParam('date')
    if (queryDate) return moment(queryDate)

    return moment() > moment(end_date) ? moment(end_date) : moment()
  }

  return (
    <Form.Item label={t('chooseMonth')} className={styles.item}>
      <DatePicker
        className={styles.datePicker}
        picker="month"
        defaultValue={renderDefaultValue()}
        format={'MMMM YYYY'}
        bordered={true}
        disabledDate={date =>
          date < moment(start_date) || date > moment(end_date) || date > moment().endOf('month')
        }
        allowClear={false}
        onChange={changeMonth}
        disabled={isLoading}
      />
    </Form.Item>
  )
}

export default React.memo(GroupMonthPicker)
