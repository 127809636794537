import { getPaymentsInfoApi } from '../../api/finances'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { IFinancePaymentsInfo } from '../../interfaces'

export const FINANCE_PAYMENTS_INFO = 'finance_payments_info'

export function useFinancePaymentsInfo(
  options?: UseQueryOptions<IFinancePaymentsInfo, Error>,
  params?: any,
) {
  return useQuery<IFinancePaymentsInfo, Error>(
    FINANCE_PAYMENTS_INFO,
    () =>
      getPaymentsInfoApi(params).then(
        (response: AxiosResponse<IFinancePaymentsInfo>) => response.data,
      ),
    {
      ...options,
    },
  )
}
