import React from 'react'
import { useParams } from 'react-router-dom'
import { Col, PageHeader, Row, Skeleton } from 'antd'
import UserCard from './user-card/user-card'
import { useTranslation } from 'react-i18next'
import UsersDrawer from './users-drawer/users-drawer'
import Head from '../../../hoc/head/head'
import AdditonalInfos from '../../core/additonal-infos/additonal-infos'
import { useUser } from '../../../hooks/query/users'
import ErrorAlert from '../../core/error-alert/error-alert'
import { useRoleChecker } from '../../../hooks/useRoleChecker'

interface props {}

const User: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()

  const { t } = useTranslation(['translation', 'staff'])
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin'])

  const { isLoading, isError, error, data } = useUser(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  return (
    <Head title={data!.name}>
      <PageHeader
        title={data!.name}
        subTitle={t('staff:userSubtitle')}
        onBack={() => window.history.back()}
        extra={[
          access && <UsersDrawer key={'1'} updateOne={true} title={'editTitle'} user={data!} />,
        ]}
      />
      <Row gutter={[32, 32]}>
        <Col xs={24} lg={12}>
          <UserCard user={data!} />
        </Col>
        <Col xs={24} lg={12}>
          <AdditonalInfos additionalInfos={data!.additional_infos} />
        </Col>
      </Row>
    </Head>
  )
}

export default User
