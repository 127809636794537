import React from 'react'
import { Card, Descriptions } from 'antd'
import { IUser, IUserRole } from '../../../../interfaces'
import { useTranslation } from 'react-i18next'
import UserRole from '../user-role/user-role'
import UserStatus from '../user-status/user-status'
import PhoneNumber from '../../../core/phone-number/phone-number'
import { descriptionsSettings } from '../../../../lib/settings'
import DateWithMonth from '../../../core/date-with-month/date-with-month'
import UserBranches from '../../../core/user-branches/user-branches'

interface props {
  user: IUser
}

const UserCard: React.FC<props> = ({ user }) => {
  const { t } = useTranslation()
  const { name, phone, branches, birthday, roles, status, created_at } = user

  return (
    <Card bordered={false} title={t('generalInformation')}>
      <Descriptions {...descriptionsSettings} column={1}>
        <Descriptions.Item label={t('firstName')}>{name}</Descriptions.Item>
        <Descriptions.Item label={t('phone')}>
          <PhoneNumber phone={phone} />
        </Descriptions.Item>
        <Descriptions.Item label={t('birthday')}>
          <DateWithMonth date={birthday} />
        </Descriptions.Item>
        <Descriptions.Item label={t('role')}>
          {roles.map((role: IUserRole) => (
            <UserRole key={role.id} role={role.slug} />
          ))}
        </Descriptions.Item>
        <Descriptions.Item label={t('createdAt')}>
          <DateWithMonth date={created_at} />
        </Descriptions.Item>
        <Descriptions.Item label={t('status')}>
          <UserStatus status={status} />
        </Descriptions.Item>
        <Descriptions.Item label={t('branches')}>
          <UserBranches branches={branches} />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default UserCard
