import React from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer, message } from 'antd'
import { IOrderBid, IOrderColumn } from '../../../../../../interfaces'
import { createGroupApi } from '../../../../../../api/groups'
import { drawerSettings } from '../../../../../../lib/settings'
import DrawerFooter from '../../../../../core/drawer/drawer-footer/drawer-footer'
import GroupsForm from '../../../../groups/groups-form/groups-form'
import { idType } from '../../../../../../interfaces/types/types'
import { validPhone } from '../../../../../../lib/validPhone'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../../../index'
import { LEADS_KEY } from '../../../../../../hooks/query/leads'

interface props {
  id: idType
  name: string
  bids: IOrderBid[]
  drawerToggle: any
  visible: boolean
}

const LeadsCardNewGroup: React.FC<props> = ({ id, name, bids, visible, drawerToggle }) => {
  const { t } = useTranslation(['translation', 'groups'])

  const { isLoading, mutateAsync } = useMutation(createGroupApi, {
    onSuccess: () => {
      const columns = queryClient.getQueryData<IOrderColumn[]>(LEADS_KEY)
      queryClient.setQueryData(
        LEADS_KEY,
        columns!.map(column => ({
          ...column,
          cards: column.cards.filter(card => card.id !== id),
        })),
      )
      message.success(t('responseSuccess'))
      drawerToggle()
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  const submitForm = async (fieldValues: any) => {
    const validBids = bids.map(({ phone, name }) => {
      phone = validPhone(phone)
      return { phone, name }
    })

    const values = {
      ...fieldValues,
      start_time: fieldValues['start_time'].format('HH:mm'),
      end_time: fieldValues['end_time'].format('HH:mm'),
      start_date: fieldValues['start_date'][0].format('YYYY-MM-DD'),
      end_date: fieldValues['start_date'][1].format('YYYY-MM-DD'),
      status: fieldValues['status'] || false,
      bids: validBids,
    }

    return mutateAsync(values)
  }

  return (
    <Drawer
      {...drawerSettings}
      onClose={drawerToggle}
      title={t('groups:addTitle')}
      visible={visible}
      footer={
        <DrawerFooter disabled={isLoading} drawerToggle={drawerToggle} formId={'group-form'} />
      }
    >
      <GroupsForm name={name} formId={'group-form'} submitForm={submitForm} />
    </Drawer>
  )
}

export default LeadsCardNewGroup
