import React from 'react'
import RoomsTable from './rooms-table/rooms-table'
import ErrorAlert from '../../core/error-alert/error-alert'
import { PageHeader, Skeleton } from 'antd'
import MyPagination from '../../core/my-pagination/my-pagination'
import { useTranslation } from 'react-i18next'
import RoomsDrawer from './rooms-drawer/rooms-drawer'
import Head from '../../../hoc/head/head'
import { ROOMS_KEY, useRooms } from '../../../hooks/query/rooms'
import RoomsFilters from './rooms-filters/rooms-filters'
import FiltersCard from '../../core/filters-card/filters-card'

interface props {}

const Rooms: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'rooms'])

  const { isLoading, isError, error, data } = useRooms()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { data: rooms, ...pagination } = data!

  return (
    <Head title={t('rooms')}>
      <PageHeader
        title={t('rooms')}
        subTitle={t('rooms:roomsSubtitle')}
        onBack={() => window.history.back()}
        extra={[<RoomsDrawer key={1} title={'addTitle'} />]}
      />
      <FiltersCard>
        <RoomsFilters />
      </FiltersCard>
      <RoomsTable rooms={rooms} />
      <MyPagination fetchingQueryKey={ROOMS_KEY} pagination={pagination} />
    </Head>
  )
}

export default Rooms
