import React from 'react'
import { PageHeader, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import GroupsTable from './groups-table/groups-table'
import ErrorAlert from '../../core/error-alert/error-alert'
import MyPagination from '../../core/my-pagination/my-pagination'
import GroupsDrawer from './groups-drawer/groups-drawer'
import Head from '../../../hoc/head/head'
import { GROUPS_KEY, useGroups } from '../../../hooks/query/groups'
import { useRoleChecker } from '../../../hooks/useRoleChecker'
import FiltersCard from '../../core/filters-card/filters-card'
import GroupsFilters from './groups-filters/groups-filters'

interface props {}

const Groups: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'groups'])

  const { isLoading, isError, error, data: response } = useGroups()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception'])
  const showFilters = roleChecker(['reception', 'cashier', 'accountant', 'admin'])

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { data, ...pagination } = response!

  return (
    <Head title={t('groups')}>
      <PageHeader
        onBack={() => window.history.back()}
        title={t('groups')}
        subTitle={t('groups:groupsSubtitle')}
        extra={[access && <GroupsDrawer key={1} title={t('addTitle')} />]}
      />
      {showFilters && (
        <FiltersCard>
          <GroupsFilters />
        </FiltersCard>
      )}
      <GroupsTable groups={data} />
      <MyPagination pagination={pagination} fetchingQueryKey={GROUPS_KEY} />
    </Head>
  )
}

export default Groups
