import React from 'react'
import { useLatestTestResult } from '../../../../hooks/query/testing'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'
import ErrorAlert from '../../../core/error-alert/error-alert'
import ResultBlock from './result-block/result-block'

interface props {}

const LatestResult: React.FC<props> = () => {
  const { id: testId } = useParams<{ id: string }>()
  const { isLoading, isError, error, data } = useLatestTestResult(testId)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  return <ResultBlock result={data!} />
}

export default LatestResult
