import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IGroup } from '../../../../interfaces'
import DrawerOpen from '../../../core/drawer/drawer-open/drawer-open'
import { Drawer, message } from 'antd'
import { drawerSettings } from '../../../../lib/settings'
import DrawerFooter from '../../../core/drawer/drawer-footer/drawer-footer'
import GroupsForm from '../groups-form/groups-form'
import { createGroupApi, updateGroupApi } from '../../../../api/groups'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../index'
import { GROUP_STUDENTS_KEY, GROUPS_KEY } from '../../../../hooks/query/groups'
import { deleteQueryFields } from '../../../../lib/deleteQueryFields'

interface props {
  group?: IGroup
  title: string
  updateOne?: boolean
}

const GroupsDrawer: React.FC<props> = ({ group, title, updateOne }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const drawerToggle = () => {
    deleteQueryFields('branch_id')
    setVisible(!visible)
  }
  const { t } = useTranslation(['translation', 'groups'])

  const onSuccess = async () => {
    deleteQueryFields('branch_id')
    await queryClient.invalidateQueries(
      updateOne ? [GROUPS_KEY, group!.id!.toString()] : GROUPS_KEY,
    )
    updateOne && (await queryClient.invalidateQueries([GROUP_STUDENTS_KEY, group!.id!.toString()]))
    message.success(t('responseSuccess'))
    drawerToggle()
  }
  const onError = (e: any) => {
    message.error(e.response.data.message)
  }
  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(createGroupApi, {
    onSuccess,
    onError,
  })
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(updateGroupApi, {
    onSuccess,
    onError,
  })

  const submitForm = async (fieldValues: any) => {
    const values = {
      ...fieldValues,
      start_time: fieldValues['start_time'].format('HH:mm'),
      end_time: fieldValues['end_time'].format('HH:mm'),
      start_date: fieldValues['start_date'][0].format('YYYY-MM-DD'),
      end_date: fieldValues['start_date'][1].format('YYYY-MM-DD'),
      status: fieldValues['status'] || false,
    }

    const handler = group ? update : create

    return handler(values)
  }

  return (
    <>
      <DrawerOpen drawerToggle={drawerToggle} edit={group} />
      <Drawer
        {...drawerSettings}
        onClose={drawerToggle}
        title={t('groups:' + title)}
        visible={visible}
        footer={
          <DrawerFooter
            disabled={isCreateLoading || isUpdateLoading}
            drawerToggle={drawerToggle}
            formId={'group-form'}
          />
        }
      >
        <GroupsForm item={group} formId={'group-form'} submitForm={submitForm} />
      </Drawer>
    </>
  )
}

export default React.memo(GroupsDrawer)
