import React from 'react'
import { useTranslation } from 'react-i18next'
import { IGroup, IStudent } from '../../../../../interfaces'
import { Link } from 'react-router-dom'
import { Card, Table, Tag } from 'antd'
import { priceFormatter } from '../../../../../lib/priceFormatter'
import PhoneNumber from '../../../../core/phone-number/phone-number'
import StudentLink from '../../../../core/student-link/student-link'
import TableMobileTitle from '../../../../core/table-mobile-title/table-mobile-title'

interface props {
  debtors: IStudent[]
  debtors_sum: number
  debtors_count: number
}

const FinancesDebtorTable: React.FC<props> = ({ debtors_sum, debtors_count, debtors }) => {
  const { t } = useTranslation()

  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      render: (text: string) => (
        <>
          <TableMobileTitle title={'ID'} /># {text}
        </>
      ),
    },
    {
      title: t('firstName'),
      render: ({ id, name }: IStudent) => (
        <>
          <TableMobileTitle title={t('firstName')} />
          <StudentLink studentId={id} studentName={name} />
        </>
      ),
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('phone')} />
          <PhoneNumber phone={text} />
        </>
      ),
    },
    {
      title: t('groups'),
      dataIndex: 'groups',
      render: (groups: IGroup[]) => (
        <>
          <TableMobileTitle title={t('groups')} />
          {groups.map(({ id, name }) => (
            <Link key={id} to={`/groups/details/${id}`}>
              <Tag color={'volcano'}>{name}</Tag>
            </Link>
          ))}
        </>
      ),
    },
    {
      title: t('balance'),
      dataIndex: 'balance',
      align: 'right' as 'right',
      render: (text: number) => (
        <span style={{ color: '#f5222d' }}>
          <b>{priceFormatter(text)}</b>
        </span>
      ),
    },
  ]

  return (
    <Card bordered={false}>
      <Table
        className={'adaptive-table'}
        columns={columns}
        dataSource={debtors}
        pagination={false}
        rowKey={'id'}
        scroll={{ x: true }}
        summary={() => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <b>{t('total')}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={3} index={1}>
                <b>{debtors_count}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align={'right'}>
                <b>{priceFormatter(debtors_sum)}</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )
        }}
      />
    </Card>
  )
}

export default FinancesDebtorTable
