import React, { useState } from 'react'
import { Button, message, Modal } from 'antd'
import { BarChartOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../interfaces/types/types'
import { groupFinanceRecalculationApi } from '../../../../api/groups'
import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryClient } from '../../../../index'
import { GROUP_STUDENTS_KEY, GROUPS_FINANCES_KEY } from '../../../../hooks/query/groups'

interface props {
  groupId: idType
}

const GroupFinanceRecalculation: React.FC<props> = ({ groupId }) => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState<boolean>(false)
  const openModal = () => setVisible(true)
  const closeModal = () => setVisible(false)

  const { isLoading, mutateAsync } = useMutation(groupFinanceRecalculationApi, {
    onSuccess: async (response: AxiosResponse<any>) => {
      await queryClient.invalidateQueries([GROUP_STUDENTS_KEY, groupId!.toString()])
      await queryClient.invalidateQueries([GROUPS_FINANCES_KEY, groupId!.toString()])
      message.success(response.data.message)
      closeModal()
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  const handleOk = () => mutateAsync(groupId)

  return (
    <React.Fragment>
      <Button type="default" onClick={openModal}>
        <BarChartOutlined /> Финансовый перерасчет
      </Button>
      <Modal
        title="Финансовый перерасчет"
        visible={visible}
        onOk={handleOk}
        okText={t('yes')}
        cancelText={t('cancel')}
        confirmLoading={isLoading}
        onCancel={closeModal}
      >
        Вы уверены, что хотите сделать финансовый перерасчет в этой группе? Эта операция займет
        некоторое время.
      </Modal>
    </React.Fragment>
  )
}

export default GroupFinanceRecalculation
