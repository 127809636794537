import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IGroupDates, IStudent, IStudentNameId, IStudentPayment } from '../../../../../interfaces'
import { idType } from '../../../../../interfaces/types/types'
import SumInput from '../../../../core/form-group/sum-input/sum-input'
import DebounceSelect from '../../../../core/debounce-select/debounce-select'
import { Form, FormInstance, Input, message } from 'antd'
import { getStudentByIdApi, studentSearchApi } from '../../../../../api/students'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import FormPaymentMethods from '../../../../core/form-payment-methods/form-payment-methods'
import StudentPaymentsGroupsSelect from '../student-payments-groups-select/student-payments-groups-select'
import FormStudyMonthPicker from '../../../../core/form-study-month-picker/form-study-month-picker'
import moment from 'moment'

interface props {
  form: FormInstance<any>
  submitForm: (fields: any) => void
  formId: string
  student?: IStudentNameId
  groupId?: idType
  payment?: IStudentPayment
}

const StudentPaymentsForm: React.FC<props> = ({
  form,
  submitForm,
  formId,
  student,
  groupId,
  payment,
}) => {
  const [user, setUser] = useState<IStudentNameId | undefined>(student)
  const [groupDates, setGroupDates] = useState<IGroupDates>({
    start_date: payment?.group?.start_date || '',
    end_date: payment?.group?.end_date || '',
  })

  const { mutateAsync: searchStudent } = useMutation(studentSearchApi)

  const { mutateAsync: setStudent } = useMutation(getStudentByIdApi, {
    onSuccess: (response: AxiosResponse<IStudent>) => {
      setUser({
        id: response.data.id,
        name: response.data.name,
      })
      form.resetFields(['group_id'])
      form.setFieldsValue({ student_id: response.data.id })
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const handleSearch = (value: string): Promise<IStudent[]> =>
    searchStudent(value).then(response =>
      response.data.map(({ name, id }: IStudent) => ({
        label: name,
        value: id,
      })),
    )

  const handleChange = async (selectedStudent: { value: number; label: string }) =>
    setStudent(selectedStudent.value)

  const { t } = useTranslation(['translation', 'students', 'groups'])

  const onFinish = (values: any) => submitForm(values)

  return (
    <Form
      form={form}
      id={formId}
      onFinish={onFinish}
      layout={'vertical'}
      initialValues={{
        amount: payment?.amount,
        method: payment?.method,
        comment: payment?.comment,
        date: payment?.date && moment(payment?.date, 'YYYY-MM-DD'),
        id: payment?.id,
        student_id: user?.id,
      }}
    >
      <Form.Item name={'id'} hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item hidden={true} name={'student_id'}>
        <Input />
      </Form.Item>

      {!student && !groupId ? (
        <Form.Item label={t('chooseStudent')} name={'selected'}>
          <DebounceSelect
            onChange={handleChange}
            value={user}
            placeholder={t('chooseStudent')}
            fetchOptions={handleSearch}
          />
        </Form.Item>
      ) : (
        <Form.Item label={t('student')}>
          <Input value={student?.name} disabled={true} />
        </Form.Item>
      )}

      {user && (
        <StudentPaymentsGroupsSelect
          form={form}
          setGroupDates={setGroupDates}
          groupId={groupId}
          studentId={user.id}
        />
      )}
      <FormPaymentMethods />
      <SumInput name={'amount'} label={t('sum')} />
      <FormStudyMonthPicker
        groupStartDate={groupDates.start_date}
        groupEndDate={groupDates.end_date}
      />
      <Form.Item name="comment" label={t('comment')}>
        <Input.TextArea placeholder={t('inputComment')} rows={6} />
      </Form.Item>
    </Form>
  )
}

export default StudentPaymentsForm
