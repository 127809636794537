import React from 'react'
import Stuff from '../../../app/modules/archive/stuff/stuff'

interface props {}

const ArchiveStuffPage: React.FC<props> = () => {
  return (
    <>
      <Stuff />
    </>
  )
}

export default ArchiveStuffPage
