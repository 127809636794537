import React from 'react'
import FinancesGroups from '../../../app/modules/finances/finances-groups/finances-groups'

interface props {}

const FinancesGroupsPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <FinancesGroups />
    </React.Fragment>
  )
}

export default FinancesGroupsPage
