import React from 'react'
import styles from './leads-column-dropdown.module.scss'
import { FormOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import LeadsColumnDelete from '../leads-column-delete/leads-column-delete'
import LeadsColumnModal from '../leads-column-modal/leads-column-modal'
import { IOrderColumn } from '../../../../../../interfaces'
import LeadsColumnTransfer from '../leads-column-transfer/leads-column-transfer'

interface props {
  column: IOrderColumn
}

const LeadsColumnDropdown: React.FC<props> = ({ column }) => {
  const { id, name } = column

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <LeadsColumnModal id={id} name={name} />
      </Menu.Item>
      <Menu.Item key={'2'}>
        <LeadsColumnTransfer column={column} />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" danger={true}>
        <LeadsColumnDelete id={id} />
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} placement={'bottomCenter'}>
      <FormOutlined className={styles.button} />
    </Dropdown>
  )
}

export default LeadsColumnDropdown
