import React from 'react'
import {
  DatabaseOutlined,
  ExceptionOutlined,
  FallOutlined,
  FileZipOutlined,
  FundOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LayoutOutlined,
  ProjectOutlined,
  ReadOutlined,
  SettingOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import {
  adminAccess,
  coursesAndTeachersAccess,
  eventsAccess,
  financesAccess,
  groupsAccess,
  receptionAccess,
  referencesAccess,
  studentsAccess,
  testsAccess,
} from '../routes'

interface IMenuLink {
  name: string
  route?: string
  icon: React.ReactNode
  access: string[]
  children?: IMenuLink[]
}

interface IMenuGroup {
  title: string
  access?: string[]
  children: IMenuLink[]
}

export function useMenuLinks() {
  const menu: IMenuGroup[] = [
    {
      title: 'menu',
      children: [
        {
          name: 'dashboard',
          route: '/dashboard',
          icon: <InfoCircleOutlined />,
          access: receptionAccess,
        },
        {
          name: 'leads',
          route: '/leads',
          icon: <ProjectOutlined />,
          access: receptionAccess,
        },
        {
          name: 'students',
          route: '/students',
          icon: <UserOutlined />,
          access: studentsAccess,
        },
        {
          name: 'groups',
          route: '/groups',
          icon: <TeamOutlined />,
          access: groupsAccess,
        },
        {
          name: 'teachers',
          route: '/teachers',
          icon: <SolutionOutlined />,
          access: coursesAndTeachersAccess,
        },
        {
          name: 'courses',
          route: '/courses',
          icon: <ReadOutlined />,
          access: coursesAndTeachersAccess,
        },
      ],
    },
    {
      title: 'testing',
      access: eventsAccess,
      children: [
        {
          name: 'events',
          route: '/testing/events',
          icon: <ExceptionOutlined />,
          access: eventsAccess,
        },
        {
          name: 'tests',
          route: '/testing/tests',
          icon: <DatabaseOutlined />,
          access: testsAccess,
        },
      ],
    },
    {
      title: 'finances',
      access: financesAccess,
      children: [
        {
          name: 'finances',
          route: '/finances',
          icon: <FundOutlined />,
          access: financesAccess,
        },
      ],
    },
    {
      title: 'references',
      access: referencesAccess,
      children: [
        {
          name: 'expenses',
          route: '/references/expenses',
          icon: <FallOutlined />,
          access: financesAccess,
        },
      ],
    },
    {
      title: 'educationCenter',
      access: receptionAccess,
      children: [
        {
          name: 'archive',
          icon: <FileZipOutlined />,
          access: receptionAccess,
          route: '/archive',
        },
        {
          name: 'settings',
          icon: <SettingOutlined />,
          access: adminAccess,
          children: [
            {
              name: 'rooms',
              route: '/rooms',
              icon: <LayoutOutlined />,
              access: adminAccess,
            },
            {
              name: 'staff',
              route: '/staff',
              icon: <UserSwitchOutlined />,
              access: adminAccess,
            },
            {
              name: 'branches',
              route: '/branches',
              icon: <HomeOutlined />,
              access: adminAccess,
            },
          ],
        },
      ],
    },
  ]

  return [menu]
}
