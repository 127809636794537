import { request as axios } from './index'
import { idType } from '../interfaces/types/types'
import { IUser } from '../interfaces'

export function getAllUsersApi() {
  return axios.get('/users')
}

export function getArchivedUsers() {
  return axios.get('/users', {
    params: {
      onlyTrashed: true,
    },
  })
}

export function getUserByIdApi(id: idType) {
  return axios.get(`/users/${id}`)
}

export const registerUserApi = (user: IUser) => {
  return axios.post(`/users`, user)
}

export const updateUserApi = (user: any) => {
  return axios.patch(`/users/${user.id}`, user)
}

export const deleteUserApi = (id: idType) => {
  return axios.delete(`/users/${id}`)
}
export const restoreUserApi = (id: idType) => {
  return axios.post(`/users/${id}/restore`)
}

export const changeUserPassword = ({ id, password }: { id: idType; password: string }) => {
  return axios.patch(`/users/${id}/change-password`, { password })
}
