import { Button, Dropdown, Menu } from 'antd'
import React from 'react'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import StudentPaymentsDrawer from '../../modules/students/student-payments/student-payments-drawer/student-payments-drawer'
import { IStudentNameId, StudentStatusType } from '../../../interfaces'
import isEqual from 'react-fast-compare'
import { useRoleChecker } from '../../../hooks/useRoleChecker'
import DeleteStudentFromGroup from '../delete-student-from-group/delete-student-from-group'
import ChangeAddToGroupDate from '../change-add-to-group-date/change-add-to-group-date'
import ToExcludeStudentModal from '../to-exclude-student-modal/to-exclude-student-modal'
import TransferStudentToAnotherGroup from '../transfer-student-to-another-group/transfer-student-to-another-group'
import IndividualPriceModal from '../individual-price-modal/individual-price-modal'

interface props {
  student: IStudentNameId
  studentAddedAt: string
  groupId: string
  groupStartDate: string
  groupEndDate: string
  studentStatus: StudentStatusType
  paymentQueries: Array<string | string[]>
  deleteStudentQueries: Array<string | string[]>
  excludeStudentQueries: Array<string | string[]>
  transferQueries: Array<string | string[]>
  individualPriceQueries: Array<string | string[]>
  individualPrice: number
}

const GroupStudentsDropdown: React.FC<props> = ({
  student,
  studentAddedAt,
  groupId,
  groupStartDate,
  groupEndDate,
  studentStatus,
  paymentQueries,
  deleteStudentQueries,
  excludeStudentQueries,
  transferQueries,
  individualPriceQueries,
  individualPrice,
}) => {
  const { roleChecker } = useRoleChecker()
  const cashierAccess = roleChecker(['admin', 'cashier'])
  const receptionAccess = roleChecker(['admin', 'reception'])
  const { t } = useTranslation(['translation', 'students'])

  const menu = (
    <Menu>
      {cashierAccess && studentStatus !== 'transferred' && (
        <Menu.Item key="payments">
          <StudentPaymentsDrawer
            student={student}
            groupId={groupId}
            paymentQueries={paymentQueries}
          />
        </Menu.Item>
      )}
      {receptionAccess && (
        <React.Fragment>
          {studentStatus === 'active' && (
            <React.Fragment>
              <Menu.Item key={'change-add-to-group'}>
                <ChangeAddToGroupDate groupId={groupId} studentIds={[student.id]} />
              </Menu.Item>
              <Menu.Item key={'input-individual-price'}>
                <IndividualPriceModal
                  studentId={student.id}
                  studentName={student.name}
                  groupId={groupId}
                  price={individualPrice}
                  individualPriceQueries={individualPriceQueries}
                />
              </Menu.Item>
              <Menu.Item key={'transfer-to-another-group'}>
                <TransferStudentToAnotherGroup
                  groupId={groupId}
                  studentName={student.name}
                  studentId={student.id}
                  transferQueries={transferQueries}
                />
              </Menu.Item>
              <Menu.Item key="to-exclude-student">
                <ToExcludeStudentModal
                  groupId={groupId}
                  studentId={student.id}
                  studentName={student.name}
                  groupStartDate={groupStartDate}
                  groupEndDate={groupEndDate}
                  studentAddedAt={studentAddedAt}
                  excludeStudentQueries={excludeStudentQueries}
                />
              </Menu.Item>
            </React.Fragment>
          )}
          <Menu.Divider />
          <Menu.Item key="delete-student" danger={true}>
            <DeleteStudentFromGroup
              groupId={groupId}
              studentId={student.id}
              deleteStudentQueries={deleteStudentQueries}
            />
          </Menu.Item>
        </React.Fragment>
      )}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement={'bottomCenter'} trigger={['click']}>
      <Button type={'primary'}>
        {t('actions')} <DownOutlined />
      </Button>
    </Dropdown>
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps.student, nextProps.student)
}

export default React.memo(GroupStudentsDropdown, propsAreEqual)
