import React from 'react'
import { Badge, Card, Col, Image, List, Result as AntdResult, Row, Typography } from 'antd'
import styles from './result-block.module.scss'
import ResultIcon from '../result-icon/result-icon'
import TeacherLink from '../../../../core/teacher-link/teacher-link'
import CourseLink from '../../../../core/course-link/course-link'
import moment from 'moment'
import { ITestResult, ITestResultQuestion } from '../../../../../interfaces'
import { CheckCircleTwoTone, CloseCircleTwoTone, StarTwoTone } from '@ant-design/icons'
import Head from '../../../../../hoc/head/head'
import { useTranslation } from 'react-i18next'
import EventResultPercent from '../../../../core/event-result-percent/event-result-percent'
import StudentLink from '../../../../core/student-link/student-link'
import TestVariantWrapper from '../../../../core/test-variant-wrapper/test-variant-wrapper'
import TestQuestionImage from '../../tests/test-question-image/test-question-image'

interface props {
  result: ITestResult
}

const ResultBlock: React.FC<props> = ({ result }) => {
  const { t } = useTranslation(['translation', 'testing'])
  const {
    test,
    spent_time,
    correct_answers,
    correct_percent,
    results,
    incorrect_answers,
    user,
    created_at,
  } = result

  return (
    <Head title={test.title}>
      <Row gutter={[0, 32]}>
        <Col xs={24}>
          <Card bordered={false}>
            <AntdResult
              className={styles.result}
              icon={<ResultIcon percent={correct_percent} />}
              title={<StudentLink studentId={user.id} studentName={user.name} />}
              extra={[
                <React.Fragment key={'1'}>
                  <Typography.Title className={styles.title} level={4}>
                    {test.title}
                  </Typography.Title>

                  <Typography.Text className={styles.paragraph}>
                    {t('teacher')}:{' '}
                    <strong>
                      <TeacherLink name={test.user.name} id={test.user.id} />
                    </strong>
                  </Typography.Text>
                  <Typography.Text className={styles.paragraph}>
                    {t('course')}:{' '}
                    <strong>
                      <CourseLink courseName={test.course.name} courseId={test.course.id} />
                    </strong>
                  </Typography.Text>
                  <Typography.Text className={styles.paragraph}>
                    {t('testing:questionsCount')}: <strong>{test.questions_count}</strong>
                  </Typography.Text>
                  <Typography.Text className={styles.paragraph}>
                    {t('testing:rightAnswers')}:{' '}
                    <strong>
                      {correct_answers} / {test.questions_count}
                    </strong>
                  </Typography.Text>
                  <Typography.Text className={styles.paragraph}>
                    {t('testing:wrongAnswers')}:{' '}
                    <strong>
                      {incorrect_answers} / {test.questions_count}
                    </strong>
                  </Typography.Text>
                  <Typography.Text className={styles.paragraph}>
                    {t('result')}: <EventResultPercent percent={correct_percent} />
                  </Typography.Text>
                  <Typography.Text className={styles.paragraph}>
                    {t('testing:timeTakenToSolveTheTest')}: <strong>{spent_time}</strong>
                  </Typography.Text>
                  <Typography.Text className={styles.paragraph}>
                    {t('testing:testDate')}:{' '}
                    <strong>{moment(created_at).format('DD-MM-YYYY HH:mm')}</strong>
                  </Typography.Text>
                </React.Fragment>,
              ]}
            />
          </Card>
        </Col>
        <Col xs={24}>
          <Card bordered={false}>
            <List
              header={
                <Badge count={test.questions_count} offset={[10, 0]}>
                  <Typography.Title level={4}>{t('testing:theListOfQuestions')}</Typography.Title>
                </Badge>
              }
              dataSource={results}
              renderItem={({
                question,
                question_image,
                status,
                variants,
                hint,
                mark,
              }: ITestResultQuestion) => (
                <List.Item className={status === 'correct' ? styles.correct : styles.incorrect}>
                  <List.Item.Meta
                    avatar={
                      status === 'correct' ? (
                        <CheckCircleTwoTone twoToneColor={'#52c41a'} />
                      ) : (
                        <CloseCircleTwoTone twoToneColor={'#f5222d'} />
                      )
                    }
                    title={
                      <TestVariantWrapper className={styles.question}>
                        <div dangerouslySetInnerHTML={{ __html: question }} />
                        {mark && <StarTwoTone className={styles.mark} twoToneColor={'red'} />}
                        {question_image ? <Image src={question_image} height={55} preview /> : null}
                      </TestVariantWrapper>
                    }
                    description={
                      <div>
                        <ol className={styles.variants}>
                          {variants.map(variant => (
                            <li key={variant.id}>
                              <div dangerouslySetInnerHTML={{ __html: variant.text }} />
                              {variant.image && (
                                <TestQuestionImage
                                  rest={{
                                    src: variant.image,
                                    height: 50,
                                  }}
                                />
                              )}
                            </li>
                          ))}
                        </ol>
                        {hint && hint !== 'undefined' ? (
                          <div className={styles.hint} dangerouslySetInnerHTML={{ __html: hint }} />
                        ) : null}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </Head>
  )
}

export default ResultBlock
