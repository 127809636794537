import React from 'react'
import { useTranslation } from 'react-i18next'

interface props {
  phone: string
}

const PhoneNumber: React.FC<props> = ({ phone }) => {
  const { t } = useTranslation()

  if (!phone) {
    return <React.Fragment>{t('notIndicated')}</React.Fragment>
  }

  return (
    <a href={`tel:+998 ${phone}`} rel={'noopener noreferrer'}>
      +998 {phone}
    </a>
  )
}

export default React.memo(PhoneNumber)
