import React, { useState } from 'react'
import { Button, Divider, Form, Input, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../../interfaces/types/types'
import { PercentageOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import FormStudyMonthPicker from '../../../../core/form-study-month-picker/form-study-month-picker'
import PercentageInput from '../../../../core/form-group/percentage-input/percentage-input'
import FormGroupStudents from '../../../../core/form-group-students/form-group-students'
import { useMutation } from 'react-query'
import { setGroupDiscounts } from '../../../../../api/discounts'
import { queryClient } from '../../../../../index'
import { GROUP_DISCOUNTS_KEY } from '../../../../../hooks/query/groups'

interface props {
  studentId?: idType
  studentName?: string
  groupStartDate: string
  groupEndDate: string
}

const GroupDiscountModal: React.FC<props> = ({
  studentId,
  studentName,
  groupEndDate,
  groupStartDate,
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const modalHandler = () => setVisible(!visible)
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation(['translation', 'groups'])

  const { isLoading, mutateAsync } = useMutation(setGroupDiscounts, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([GROUP_DISCOUNTS_KEY, id])
      message.success(t('responseSuccess'))
      modalHandler()
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const onFinish = (values: any) => mutateAsync(values)

  const title = studentId ? t('groups:addIndividualDiscount') : t('groups:addBulkDiscount')
  return (
    <React.Fragment>
      <Button
        icon={<PercentageOutlined />}
        type={studentId ? 'primary' : 'default'}
        onClick={modalHandler}
      >
        {title}
      </Button>
      <Modal
        title={
          <React.Fragment>
            {title}{' '}
            {studentName && (
              <>
                <Divider type={'vertical'} /> {studentName}
              </>
            )}
          </React.Fragment>
        }
        visible={visible}
        onCancel={modalHandler}
        okText={t('submit')}
        okButtonProps={{ htmlType: 'submit', form: 'discount-form' }}
        confirmLoading={isLoading}
        destroyOnClose={true}
        centered
      >
        <Form id={'discount-form'} layout={'vertical'} onFinish={onFinish}>
          <Form.Item hidden name={'group_id'} initialValue={id}>
            <Input />
          </Form.Item>
          {studentId ? (
            <Form.Item name={'student_ids'} initialValue={[studentId]} hidden>
              <Input />
            </Form.Item>
          ) : (
            <FormGroupStudents
              name={'student_ids'}
              label={t('groups:chooseStudents')}
              mode={'multiple'}
            />
          )}
          <PercentageInput name={'percent'} label={t('percentage')} min={1} />
          <FormStudyMonthPicker groupStartDate={groupStartDate} groupEndDate={groupEndDate} />
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default GroupDiscountModal
