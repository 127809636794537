import React, { Component } from 'react'
import { Result } from 'antd'

class ErrorBoundary extends Component {
  state = {
    error: false,
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error: true,
    })
    console.error(error, errorInfo)
  }

  render() {
    if (!this.state.error) {
      return this.props.children
    }
    return (
      <Result
        status="500"
        title="500"
        subTitle="Простите, что-то пошло не так. Мы скоро исправим ошибку!"
        extra={
          <a href={'/'} className={'ant-btn ant-btn-primary'}>
            Обновить страницу
          </a>
        }
      />
    )
  }
}

export default ErrorBoundary
