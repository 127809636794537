import React from 'react'
import { IUserAdditional } from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import { Card, Descriptions } from 'antd'
import { descriptionsSettings } from '../../../lib/settings'

interface props {
  additionalInfos: IUserAdditional[]
}

const AdditonalInfos: React.FC<props> = ({ additionalInfos }) => {
  const { t } = useTranslation()

  return (
    <Card bordered={false} title={t('additionalInformation')}>
      <Descriptions {...descriptionsSettings} layout={'horizontal'} column={1}>
        {!additionalInfos || !additionalInfos.length ? (
          <span>{t('emptyAdditionalInfo')}</span>
        ) : (
          additionalInfos.map((field: IUserAdditional) => (
            <Descriptions.Item key={field.id} label={field.key}>
              {field.value}
            </Descriptions.Item>
          ))
        )}
      </Descriptions>
    </Card>
  )
}

export default AdditonalInfos
