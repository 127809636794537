import { useQuery, UseQueryOptions } from 'react-query'
import { getUserInformationApi } from '../../api/auth'
import { AxiosResponse } from 'axios'
import { IUser } from '../../interfaces'

export const AUTH_USER_KEY = 'auth_user_key'

export function useAuthUserInformation(options?: UseQueryOptions<IUser, Error>) {
  return useQuery<IUser, Error>(
    AUTH_USER_KEY,
    () => getUserInformationApi().then((response: AxiosResponse<IUser>) => response.data),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options,
    },
  )
}
