import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  status: boolean
}

const BranchStatus: React.FC<props> = ({ status }) => {
  const { t } = useTranslation(['branches'])

  return <Tag color={status ? 'green' : 'red'}>{t(status ? 'open' : 'close')}</Tag>
}

export default BranchStatus
