import { useQuery, UseQueryOptions } from 'react-query'
import {
  getAllGroupsApi,
  getGroupByIdApi,
  getGroupDiscounts,
  getGroupEventsApi,
  getGroupEventStudentsResultsApi,
  getGroupNotificationsApi,
  getGroupsListApi,
  getGroupStudentsActivityApi,
  getGroupStudentsApi,
} from '../../api/groups'
import { AxiosResponse } from 'axios'
import {
  IFinanceGroupResponse,
  IGroup,
  IGroupActivity,
  IGroupNotification,
  IGroupsWithPagination,
  IStudent,
  ITestEvent,
  ITestResult,
  IStudentWithDiscount,
} from '../../interfaces'
import { idType } from '../../interfaces/types/types'
import { getAllFinancesGroupsApi, getFinancesGroupByIdApi } from '../../api/finances'

export const GROUPS_KEY = 'groups_key'
export const GROUPS_FINANCES_KEY = 'groups_finances_key'
export const GROUPS_LIST_KEY = 'groups_list_key'
export const GROUP_STUDENTS_KEY = 'group_students_key'
export const GROUP_NOTIFICATIONS_KEY = 'group_notifications_key'
export const GROUP_STUDENTS_ACTIVITY_KEY = 'group_students_activity_key'
export const GROUP_DISCOUNTS_KEY = 'group_discounts_key'
export const GROUP_EVENTS_KEY = 'group_events_key'
export const GROUP_EVENT_RESULTS_KEY = 'group_event_results_key'

export function useGroups(options?: UseQueryOptions<IGroupsWithPagination, Error>) {
  return useQuery<IGroupsWithPagination, Error>(
    GROUPS_KEY,
    () => getAllGroupsApi().then((response: AxiosResponse<IGroupsWithPagination>) => response.data),
    {
      ...options,
    },
  )
}

export function useGroupsList(options?: UseQueryOptions<IGroup[], Error>) {
  return useQuery<IGroup[], Error>(
    GROUPS_LIST_KEY,
    () => getGroupsListApi().then((response: AxiosResponse<IGroup[]>) => response.data),
    {
      ...options,
    },
  )
}

export function useGroup(id: idType, options?: UseQueryOptions<IGroup, Error>) {
  return useQuery<IGroup, Error>(
    [GROUPS_KEY, id],
    () => getGroupByIdApi(id).then((response: AxiosResponse<IGroup>) => response.data),
    {
      ...options,
    },
  )
}

export function useGroupStudents(id: idType, options?: UseQueryOptions<IStudent[], Error>) {
  return useQuery<IStudent[], Error>(
    [GROUP_STUDENTS_KEY, id],
    () => getGroupStudentsApi(id).then((response: AxiosResponse<IStudent[]>) => response.data),
    {
      ...options,
    },
  )
}

export function useGroupNotifications(
  id: idType,
  options?: UseQueryOptions<IGroupNotification[], Error>,
) {
  return useQuery<IGroupNotification[], Error>(
    [GROUP_NOTIFICATIONS_KEY, id],
    () =>
      getGroupNotificationsApi(id).then(
        (response: AxiosResponse<IGroupNotification[]>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function useGroupStudentsActivity(
  id: idType,
  options?: UseQueryOptions<IGroupActivity, Error>,
) {
  return useQuery<IGroupActivity, Error>(
    [GROUP_STUDENTS_ACTIVITY_KEY, id],
    () =>
      getGroupStudentsActivityApi(id).then(
        (response: AxiosResponse<IGroupActivity>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function useGroupDiscounts(
  id: idType,
  options?: UseQueryOptions<IStudentWithDiscount[], Error>,
) {
  return useQuery<IStudentWithDiscount[], Error>(
    [GROUP_DISCOUNTS_KEY, id],
    () =>
      getGroupDiscounts(id).then(
        (response: AxiosResponse<IStudentWithDiscount[]>) => response.data,
      ),
    {
      ...options,
    },
  )
}

// FINANCES

export function useFinancesGroups(options?: UseQueryOptions<IGroupsWithPagination, Error>) {
  return useQuery<IGroupsWithPagination, Error>(
    GROUPS_FINANCES_KEY,
    () =>
      getAllFinancesGroupsApi().then(
        (response: AxiosResponse<IGroupsWithPagination>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function useFinancesGroup(
  id: idType,
  options?: UseQueryOptions<IFinanceGroupResponse, Error>,
) {
  return useQuery<IFinanceGroupResponse, Error>(
    [GROUPS_FINANCES_KEY, id],
    () =>
      getFinancesGroupByIdApi(id).then(
        (response: AxiosResponse<IFinanceGroupResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function useGroupEvents(id: idType, options?: UseQueryOptions<ITestEvent[], Error>) {
  return useQuery<ITestEvent[], Error>(
    [GROUP_EVENTS_KEY, id],
    () => getGroupEventsApi(id).then((response: AxiosResponse<ITestEvent[]>) => response.data),
    {
      ...options,
    },
  )
}

export function useGroupEventResults(
  groupId: idType,
  testId: idType,
  options?: UseQueryOptions<ITestResult[], Error>,
) {
  return useQuery<ITestResult[], Error>(
    [GROUP_EVENT_RESULTS_KEY, groupId, testId],
    () =>
      getGroupEventStudentsResultsApi({
        groupId,
        testId,
      }).then((response: AxiosResponse<ITestResult[]>) => response.data),
    {
      ...options,
    },
  )
}
