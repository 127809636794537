import React from 'react'
import { Form, Input } from 'antd'
import { IRoom } from '../../../../interfaces'
import NameInput from '../../../core/form-group/name-input/name-input'

interface props {
  formId: string
  submitForm: any
  item?: IRoom
}

const RoomsForm: React.FC<props> = ({ formId, submitForm, item }) => {
  return (
    <Form
      id={formId}
      initialValues={item}
      name="rooms_form"
      onFinish={submitForm}
      layout={'vertical'}
    >
      <Form.Item hidden name="id">
        <Input />
      </Form.Item>
      <NameInput label={'name'} />
    </Form>
  )
}

export default RoomsForm
