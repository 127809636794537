import { IUserRole } from '../../interfaces'
import { getUserRolesApi } from '../../api/roles'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'

export const ROLES_KEY = 'roles_key'

export function useRoles(options?: UseQueryOptions<IUserRole[], Error>) {
  return useQuery<IUserRole[], Error>(
    ROLES_KEY,
    () => getUserRolesApi().then((response: AxiosResponse<IUserRole[]>) => response.data),
    {
      ...options,
    },
  )
}
