import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface props {
  startDate: string
  endDate: string
}

const GroupPeriodOfActivity: React.FC<props> = ({ startDate, endDate }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      {t('fromTo', {
        from: moment(startDate).format('DD MMMM YYYY'),
        to: moment(endDate).format('DD MMMM YYYY'),
      })}
    </React.Fragment>
  )
}

export default GroupPeriodOfActivity
