import React from 'react'
import AdditionalExpenses from '../../../app/modules/finances/additional-expenses/additional-expenses'

interface props {}

const AdditionalExpensesPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <AdditionalExpenses />
    </React.Fragment>
  )
}

export default AdditionalExpensesPage
