import React from 'react'
import { Card, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { IGroup } from '../../../../interfaces'
import GroupList from '../../../core/group-list/group-list'

const { TabPane } = Tabs

interface props {
  groups: IGroup[]
}

const RoomTabs: React.FC<props> = ({ groups }) => {
  const { t } = useTranslation(['translation', 'rooms'])

  return (
    <Card bordered={false}>
      <Tabs>
        <TabPane tab={t('rooms:groupList')}>
          <GroupList groups={groups} />
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default RoomTabs
