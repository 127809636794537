import { request as axios } from './index'
import { idType } from '../interfaces/types/types'
import { IAddStudentToGroup, IUser } from '../interfaces'

export function getAllStudentsApi() {
  return axios.get('/students')
}

export function getStudentByIdApi(id: idType) {
  return axios.get(`/students/${id}`)
}

export function getStudentGroupsApi(id: idType) {
  return axios.get(`/students/${id}/groups`)
}

export function getStudentPaymentsApi(id: idType) {
  return axios.get(`/students/${id}/payments`)
}

export const registerStudentApi = (user: IUser) => {
  return axios.post(`/students`, user)
}

export const updateStudentApi = (user: IUser) => {
  return axios.patch(`/students/${user.id}`, user)
}

export function deleteStudentApi(id: idType) {
  return axios.delete(`/students/${id}`)
}

export function getAllGroupsInStudentsApi() {
  return axios.get('/groups', {
    params: {
      per_page: 1000,
    },
  })
}

// Только добавление новых групп
export function addStudentToGroupsApi({ id, data }: { id: idType; data: IAddStudentToGroup[] }) {
  return axios.post(`/students/${id}/add-groups`, {
    groups: data,
  })
}

// Поиск
export function studentSearchApi(value: string) {
  return axios.get(`/students/${value}/search`)
}

// Результат теста

export function getStudentTestResultApi({
  studentId,
  testId,
}: {
  studentId: idType
  testId: idType
}) {
  return axios.get(`/students/${studentId}/tests/${testId}/results`)
}

export function getAllStudentTestResultsApi(id: idType) {
  return axios.get(`/students/${id}/all-test-results`)
}
