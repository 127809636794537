import React, { useState } from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { idType } from '../../../../../interfaces/types/types'
import { useMutation } from 'react-query'
import { createQuestionBaseApi, updateQuestionBaseApi } from '../../../../../api/question-bases'
import { AxiosResponse } from 'axios'
import { IQuestionBase } from '../../../../../interfaces'
import { useHistory } from 'react-router-dom'
import { queryClient } from '../../../../../index'
import { QUESTION_BASES_KEY } from '../../../../../hooks/query/question-bases'

interface props {
  testName?: string
  testId?: idType
  updateOne?: boolean
}

const TestModal: React.FC<props> = ({ testName, testId, updateOne }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const visibleHandler = () => setVisible(!visible)
  const { push } = useHistory()
  const { t } = useTranslation(['translation', 'testing'])

  const onError = () => {
    message.error(t('responseError'))
  }
  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(createQuestionBaseApi, {
    onSuccess: (response: AxiosResponse<IQuestionBase>) => {
      message.success(t('responseSuccess'))
      visibleHandler()
      push('/testing/tests/details/' + response.data.id)
    },
    onError,
  })

  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(updateQuestionBaseApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        updateOne ? [QUESTION_BASES_KEY, testId!.toString()] : QUESTION_BASES_KEY,
      )
      message.success(t('responseSuccess'))
      visibleHandler()
    },
    onError,
  })

  const onFinish = ({ title }: any) => (testId ? update({ id: testId, title }) : create(title))

  const renderTitle = testName ? t('testing:editTest') : t('testing:addTest')

  return (
    <React.Fragment>
      <Button
        type={'primary'}
        icon={testName ? <EditOutlined /> : <PlusCircleOutlined />}
        onClick={visibleHandler}
      >
        {renderTitle}
      </Button>
      <Modal
        title={renderTitle}
        visible={visible}
        onCancel={visibleHandler}
        okButtonProps={{ form: 'test-form', htmlType: 'submit' }}
        okText={t('submit')}
        confirmLoading={isCreateLoading || isUpdateLoading}
      >
        <Form
          onFinish={onFinish}
          id={'test-form'}
          initialValues={{ title: testName }}
          layout={'vertical'}
        >
          <Form.Item name={'title'} label={t('name')} rules={[{ required: true }]}>
            <Input placeholder={t('inputName')} />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default TestModal
