import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserSwitchOutlined } from '@ant-design/icons'
import { Alert, DatePicker, Divider, Form, message, Modal, Space } from 'antd'
import { idType } from '../../../interfaces/types/types'
import { useMutation } from 'react-query'
import { transferStudentApi } from '../../../api/groups'
import { queryClient } from '../../../index'
import FormGroupsList from '../form-groups-list/form-groups-list'

interface props {
  groupId: idType
  studentId: idType
  studentName: string
  transferQueries: Array<string | string[]>
}

const TransferStudentToAnotherGroup: React.FC<props> = ({
  groupId,
  studentId,
  studentName,
  transferQueries,
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const modalHandler = () => setVisible(!visible)
  const { t } = useTranslation(['translation', 'groups'])

  const { isLoading, mutateAsync } = useMutation(transferStudentApi, {
    onSuccess: () => {
      const queries = transferQueries.map(q => queryClient.invalidateQueries(q))
      Promise.all(queries).finally(() => {
        message.success(t('responseSuccess'))
        modalHandler()
      })
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const onFinish = (values: any) =>
    mutateAsync({
      from: groupId,
      to: values.selected,
      studentId,
      date: values.date,
    })

  const title = t('groups:transferStudent')
  return (
    <React.Fragment>
      <button className={'ant-btn-no-style'} onClick={modalHandler}>
        <UserSwitchOutlined /> <span>{title}</span>
      </button>
      <Modal
        visible={visible}
        title={
          <Space split={<Divider type={'vertical'} />}>
            <span>{title}</span>
            <span>{studentName}</span>
          </Space>
        }
        okText={t('submit')}
        onCancel={modalHandler}
        okButtonProps={{ htmlType: 'submit', form: 'transfer-form' }}
        destroyOnClose={true}
        confirmLoading={isLoading}
        centered={true}
      >
        <Alert
          message={
            'Данная процедура позволяет перевести студента вместе с платежом за один учебный месяц в другую группу. В случае, если студент посещал занятия в этой группе, будет произведен перерасчет.'
          }
          banner
          showIcon={false}
          type={'warning'}
        />
        <Divider />
        <Form id={'transfer-form'} layout={'vertical'} onFinish={onFinish}>
          <FormGroupsList
            disabledGroupId={groupId}
            mode={'single'}
            required={true}
            allowClear={false}
          />
          <Form.Item
            name={'date'}
            label={t('groups:transferDate')}
            rules={[{ required: true, message: t('required') }]}
          >
            <DatePicker format={'DD MMMM YYYY'} style={{ width: '100%' }} allowClear={false} />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default TransferStudentToAnotherGroup
