import React from 'react'
import { Modal } from 'antd'
import LeadsColumnForm from '../leads-column-form/leads-column-form'
import { useTranslation } from 'react-i18next'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { idType } from '../../../../../../interfaces/types/types'

interface props {
  name?: string
  id?: idType
}

const LeadsColumnModal: React.FC<props> = ({ name, id }) => {
  const [visible, setVisible] = React.useState(false)
  const modalHandler = () => setVisible(!visible)
  const { t } = useTranslation(['translation', 'leads'])

  return (
    <>
      <button className={name ? 'ant-btn-no-style' : 'ant-btn'} onClick={modalHandler}>
        {name ? <EditOutlined /> : <PlusCircleOutlined />}{' '}
        <span>{name ? t('edit') : t('leads:addColumn')}</span>
      </button>

      <Modal
        destroyOnClose={true}
        onCancel={modalHandler}
        title={name ? t('leads:editColumn') : t('leads:addColumn')}
        footer={null}
        visible={visible}
      >
        <LeadsColumnForm setVisible={setVisible} id={id} name={name} />
      </Modal>
    </>
  )
}

export default LeadsColumnModal
