import React from 'react'
import LatestResult from '../../../app/modules/testing/result/latest-result'

interface props {}

const LatestResultPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <LatestResult />
    </React.Fragment>
  )
}

export default LatestResultPage
