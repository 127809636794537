import React from 'react'
import { Card, Descriptions } from 'antd'
import { useTranslation } from 'react-i18next'
import { ITeacher } from '../../../../interfaces'
import PhoneNumber from '../../../core/phone-number/phone-number'
import TeacherRate from '../teacher-rate/teacher-rate'
import isEqual from 'react-fast-compare'
import { descriptionsSettings } from '../../../../lib/settings'
import DateWithMonth from '../../../core/date-with-month/date-with-month'
import UserBranches from '../../../core/user-branches/user-branches'

interface props {
  teacher: Omit<ITeacher, 'groups'>
}

const TeacherCard: React.FC<props> = ({ teacher }) => {
  const { t } = useTranslation()
  const { name, phone, branches, birthday, rate, created_at } = teacher

  return (
    <Card bordered={false} title={t('generalInformation')}>
      <Descriptions {...descriptionsSettings} column={1}>
        <Descriptions.Item label={t('firstName')}>{name}</Descriptions.Item>
        <Descriptions.Item label={t('phone')}>
          <PhoneNumber phone={phone} />
        </Descriptions.Item>
        <Descriptions.Item label={t('salary')}>
          <TeacherRate value={rate.salary} sum={true} />
        </Descriptions.Item>
        <Descriptions.Item label={t('percentage')}>
          <TeacherRate value={rate.percentage} />
        </Descriptions.Item>
        <Descriptions.Item label={t('birthday')}>
          <DateWithMonth date={birthday} />
        </Descriptions.Item>
        <Descriptions.Item label={t('createdAt')}>
          <DateWithMonth date={created_at} />
        </Descriptions.Item>
        <Descriptions.Item label={t('branches')}>
          <UserBranches branches={branches} />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}
const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps, nextProps)
}

export default React.memo(TeacherCard, propsAreEqual)
