import React from 'react'
import { priceFormatter } from '../../../lib/priceFormatter'
import { useTranslation } from 'react-i18next'

interface props {
  free: boolean
  price: number | null
}

const StudentIndividualPriceStatus: React.FC<props> = ({ free, price }) => {
  const { t } = useTranslation()

  const renderStatus = () => {
    if (free) return t('freeEducation')
    if (price === null) return '-'
    return priceFormatter(price)
  }

  return <strong>{renderStatus()}</strong>
}

export default React.memo(StudentIndividualPriceStatus)
