import React from 'react'
import FinancesTeachers from '../../../app/modules/finances/finances-teachers/finances-teachers'

interface props {}

const FinancesTeachersPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <FinancesTeachers />
    </React.Fragment>
  )
}

export default FinancesTeachersPage
