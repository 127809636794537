import { useQuery, UseQueryOptions } from 'react-query'
import {
  getAllStudentsApi,
  getAllStudentTestResultsApi,
  getStudentByIdApi,
  getStudentGroupsApi,
  getStudentPaymentsApi,
  getStudentTestResultApi,
} from '../../api/students'
import { AxiosResponse } from 'axios'
import {
  IGroup,
  IStudent,
  IStudentPayment,
  IStudentsWithPagination,
  ITestResult,
  ITestResultWithPagination,
} from '../../interfaces'
import { idType } from '../../interfaces/types/types'

export const STUDENTS_KEY = 'students_key'
export const STUDENT_GROUPS_KEY = 'student_groups_key'
export const STUDENT_PAYMENTS_KEY = 'student_payments_key'
export const STUDENT_TEST_RESULT_KEY = 'student_test_result_key'
export const STUDENT_ALL_TESTS_RESULTS_KEY = 'student_all_tests_results_key'

export function useStudents(options?: UseQueryOptions<IStudentsWithPagination, Error>) {
  return useQuery<IStudentsWithPagination, Error>(
    STUDENTS_KEY,
    () =>
      getAllStudentsApi().then((response: AxiosResponse<IStudentsWithPagination>) => response.data),
    {
      ...options,
    },
  )
}

export function useStudent(id: idType, options?: UseQueryOptions<IStudent, Error>) {
  return useQuery<IStudent, Error>(
    [STUDENTS_KEY, id],
    () => getStudentByIdApi(id).then((response: AxiosResponse<IStudent>) => response.data),
    {
      ...options,
    },
  )
}

export function useStudentGroups(id: idType, options?: UseQueryOptions<IGroup[], Error>) {
  return useQuery<IGroup[], Error>(
    [STUDENT_GROUPS_KEY, id],
    () => getStudentGroupsApi(id).then((response: AxiosResponse<IGroup[]>) => response.data),
    {
      ...options,
    },
  )
}

export function useStudentPayments(
  id: idType,
  options?: UseQueryOptions<IStudentPayment[], Error>,
) {
  return useQuery<IStudentPayment[], Error>(
    [STUDENT_PAYMENTS_KEY, id],
    () =>
      getStudentPaymentsApi(id).then((response: AxiosResponse<IStudentPayment[]>) => response.data),
    {
      ...options,
    },
  )
}

export function useStudentTestResult(
  data: { studentId: idType; testId: idType },
  options?: UseQueryOptions<ITestResultWithPagination, Error>,
) {
  return useQuery<ITestResultWithPagination, Error>(
    [STUDENT_TEST_RESULT_KEY, data.studentId, data.testId],
    () =>
      getStudentTestResultApi({
        studentId: data.studentId,
        testId: data.testId,
      }).then((response: AxiosResponse<ITestResultWithPagination>) => response.data),
    {
      ...options,
    },
  )
}

export function useAllStudentTestsResults(
  id: idType,
  options?: UseQueryOptions<ITestResult[], Error>,
) {
  return useQuery<ITestResult[], Error>(
    [STUDENT_ALL_TESTS_RESULTS_KEY, id],
    () =>
      getAllStudentTestResultsApi(id).then(
        (response: AxiosResponse<ITestResult[]>) => response.data,
      ),
    {
      ...options,
    },
  )
}
