import React from 'react'
import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  name?: string
  label?: string
  placeholder?: string
  required?: boolean
}

const SearchInput: React.FC<props> = ({
  label = 'nameSearch',
  placeholder = 'search',
  name = 'search',
  required,
}) => {
  const { t } = useTranslation()

  return (
    <Form.Item name={name} label={t(label)} rules={[{ required, message: t('required') }]}>
      <Input placeholder={t(placeholder)} allowClear={!required} />
    </Form.Item>
  )
}

export default SearchInput
