import React, { useState } from 'react'
import { Button, Form, message, Modal } from 'antd'
import { idType } from '../../../../interfaces/types/types'
import { useTranslation } from 'react-i18next'
import TeacherSalary from '../../../core/form-group/teacher-salary/teacher-salary'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import MyDatePicker from '../../../core/form-group/my-date-picker/my-date-picker'
import { useMutation } from 'react-query'
import { createTeacherSalaryHistory, updateTeacherSalaryHistory } from '../../../../api/rates'
import { queryClient } from '../../../../index'
import { TEACHER_SALARY_HISTORY_KEY, TEACHERS_KEY } from '../../../../hooks/query/teachers'

interface props {
  rateId?: idType
  salary?: number
  percentage?: number
  teacherId: idType
}

const TeacherSalaryHistoryModal: React.FC<props> = ({ rateId, salary, percentage, teacherId }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const modalHandler = () => setVisible(!visible)
  const { t } = useTranslation(['translation', 'teachers'])

  const onSuccess = async () => {
    await queryClient.invalidateQueries([TEACHERS_KEY, teacherId!.toString()])
    await queryClient.invalidateQueries([TEACHER_SALARY_HISTORY_KEY, teacherId!.toString()])
    message.success(t('responseSuccess'))
    modalHandler()
  }

  const onError = () => {
    message.error(t('responseError'))
  }

  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(
    createTeacherSalaryHistory,
    {
      onSuccess,
      onError,
    },
  )
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(
    updateTeacherSalaryHistory,
    {
      onSuccess,
      onError,
    },
  )

  const onFinish = (values: any) => {
    const { salary, percentage, date } = values

    if (rateId) return update({ salary, percentage, rateId })
    return create({ salary, percentage, date, teacher_id: teacherId })
  }

  const title = rateId ? t('teachers:changeRate') : t('teachers:newRate')
  return (
    <>
      <Button
        type={rateId ? 'primary' : 'default'}
        onClick={modalHandler}
        icon={rateId ? <EditOutlined /> : <PlusCircleOutlined />}
      >
        {title}
      </Button>
      <Modal
        title={title}
        visible={visible}
        onCancel={modalHandler}
        okText={t('submit')}
        okButtonProps={{ htmlType: 'submit', form: 'teacher-salary-history-form' }}
        destroyOnClose={true}
        confirmLoading={isUpdateLoading || isCreateLoading}
      >
        <Form
          id={'teacher-salary-history-form'}
          onFinish={onFinish}
          layout={'vertical'}
          initialValues={{ salary, percentage }}
        >
          <TeacherSalary />
          {!rateId && (
            <MyDatePicker
              name={'date'}
              format={'MMMM YYYY'}
              label={'teachers:actualSince'}
              rest={{
                picker: 'month',
              }}
            />
          )}
        </Form>
      </Modal>
    </>
  )
}

export default TeacherSalaryHistoryModal
