import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuestionBase } from '../../../../hooks/query/question-bases'
import { Card, Col, Descriptions, PageHeader, Row, Skeleton } from 'antd'
import ErrorAlert from '../../../core/error-alert/error-alert'
import Head from '../../../../hoc/head/head'
import { useTranslation } from 'react-i18next'
import { descriptionsSettings } from '../../../../lib/settings'
import TeacherLink from '../../../core/teacher-link/teacher-link'
import TestQuestions from './test-questions/test-questions'
import TestActions from './test-actions/test-actions'

const { Item } = Descriptions

interface props {}

const Test: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation(['translation', 'testing'])
  const { isLoading, isError, error, data } = useQuestionBase(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { title, created_at, user } = data!

  return (
    <Head title={title}>
      <PageHeader
        title={title}
        subTitle={t('detailedInformation')}
        onBack={() => window.history.back()}
        extra={[<TestActions key={'1'} testId={id} title={title} teacherId={user.id} />]}
      />
      <Row gutter={[0, 32]}>
        <Col xs={24}>
          <Card bordered={false} title={t('generalInformation')}>
            <Descriptions {...descriptionsSettings} column={{ xs: 1, md: 3 }}>
              <Item label={t('name')}>{title}</Item>
              <Item label={t('teacher')}>
                <TeacherLink id={user.id} name={user.name} />
              </Item>
              <Item label={t('createdAt')}>{created_at}</Item>
            </Descriptions>
          </Card>
        </Col>
        <Col xs={24}>
          <TestQuestions teacherId={user.id} />
        </Col>
      </Row>
    </Head>
  )
}

export default Test
