import React, { useState } from 'react'
import { Button, Checkbox, Col, Form, Image, Row, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { UploadChangeParam } from 'antd/es/upload'
import { UploadFile } from 'antd/es/upload/interface'

interface props {
  fieldFieldName?: string | any[]
  deleteImageFieldName: string | any[]
  uploadedImagePath: string | undefined
}

const TestUploadImage: React.FC<props> = ({
  fieldFieldName = 'file',
  deleteImageFieldName,
  uploadedImagePath,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false)

  const { t } = useTranslation()

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const checkInfo = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status !== 'removed') {
      return setDisabled(true)
    }

    setDisabled(false)
  }

  return (
    <Row>
      <Col xs={24} lg={12}>
        <Form.Item
          name={fieldFieldName}
          label={t('attachImage')}
          valuePropName={'file'}
          getValueFromEvent={normFile}
        >
          <Upload beforeUpload={() => false} maxCount={1} onChange={checkInfo}>
            <Button icon={<UploadOutlined />}>{t('selectFile')}</Button>
          </Upload>
        </Form.Item>
      </Col>
      {uploadedImagePath && !disabled && (
        <Col xs={24} lg={12}>
          <Form.Item name={deleteImageFieldName} valuePropName={'checked'}>
            <Checkbox>
              {t('delete')} <Image src={uploadedImagePath} height={50} preview={false} />
            </Checkbox>
          </Form.Item>
        </Col>
      )}
    </Row>
  )
}

export default TestUploadImage
