import React, { useState } from 'react'
import { Col, DatePicker, Form, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import FilterButtons from '../../../core/form-group/filter-buttons/filter-buttons'
import { grid } from '../../../../constants/filtersGrid'
import moment from 'moment'
import FormTeachersList from '../../../core/form-teachers-list/form-teachers-list'
import FormTeacherPaymentType from '../../../core/form-teacher-payment-type/form-teacher-payment-type'
import SumInput from '../../../core/form-group/sum-input/sum-input'
import { serialize } from '../../../../lib/serializeQuery'
import { queryClient } from '../../../../index'
import { TEACHERS_PAYMENTS_KEY } from '../../../../hooks/query/salaries'
import queryString from 'query-string'
import { history } from '../../../../api'

interface props {}

const PaymentsToTeachersFilters: React.FC<props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { push } = useHistory()

  const onFinish = async (values: any) => {
    setIsLoading(true)
    const query = {
      ...values,
      created_at:
        values['created_at'] &&
        `${moment(values['created_at'][0]).format('YYYY-MM-DD')};${moment(
          values['created_at'][1],
        ).format('YYYY-MM-DD')}`,
    }
    await push({
      search: serialize(query),
    })
    await queryClient.invalidateQueries(TEACHERS_PAYMENTS_KEY)
    setIsLoading(false)
  }
  const onReset = async () => {
    setIsLoading(true)
    await push({ search: '' })
    form.resetFields()
    await queryClient.invalidateQueries(TEACHERS_PAYMENTS_KEY)
    setIsLoading(false)
  }

  const initialValues = () => {
    const qs = queryString.parse(history.location.search)

    const created_at = qs.created_at
      ? qs.created_at
          .toString()
          .split(';')
          .map(date => moment(date, 'YYYY-MM-DD'))
      : undefined

    return {
      ...qs,
      created_at,
      teacher_id: qs.teacher_id ? Number(qs.teacher_id) : undefined,
    }
  }
  const style = { width: '100%' }
  return (
    <Form form={form} onFinish={onFinish} layout={'vertical'} initialValues={initialValues()}>
      <Row gutter={[16, 0]}>
        <Col {...grid}>
          <Form.Item name={'created_at'} label={t('paymentDate')}>
            <DatePicker.RangePicker
              style={style}
              format={'DD MMMM YYYY'}
              disabledDate={date => date > moment()}
            />
          </Form.Item>
        </Col>
        <Col {...grid}>
          <FormTeachersList required={false} />
        </Col>
        <Col {...grid}>
          <FormTeacherPaymentType required={false} />
        </Col>
        <Col xs={24}>
          <SumInput name={'amount'} label={t('amount')} rest={{ rules: [{ required: false }] }} />
        </Col>
        <Col xs={24}>
          <FilterButtons isLoading={isLoading} onReset={onReset} />
        </Col>
      </Row>
    </Form>
  )
}

export default PaymentsToTeachersFilters
