import React from 'react'
import MyTable from '../../../core/my-table/my-table'
import { IFinanceTeacherSalary, ITeacher, ITeacherPayment, IUser } from '../../../../interfaces'
import { TEACHERS_PAYMENTS_KEY } from '../../../../hooks/query/salaries'
import { idType } from '../../../../interfaces/types/types'
import { useTranslation } from 'react-i18next'
import TeacherLink from '../../../core/teacher-link/teacher-link'
import DateWithMonth from '../../../core/date-with-month/date-with-month'
import { priceFormatter } from '../../../../lib/priceFormatter'
import { Tag } from 'antd'
import DeleteModal from '../../../core/delete-modal/delete-modal'
import { deleteTeacherPaymentApi } from '../../../../api/payments'

interface props {
  payments: ITeacherPayment[]
}

const PaymentsToTeachersTable: React.FC<props> = ({ payments }) => {
  const { t } = useTranslation()
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: idType) => <span># {text}</span>,
    },
    {
      title: t('paymentDate'),
      dataIndex: 'created_at',
      sorter: true,
      render: (date: string) => <DateWithMonth date={date} />,
    },
    {
      title: t('teacher'),
      dataIndex: 'teacher',
      render: ({ id, name }: ITeacher) => <TeacherLink name={name} id={id} />,
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      render: (sum: string) => <strong>{priceFormatter(sum)}</strong>,
    },
    {
      title: t('paymentType'),
      dataIndex: 'type',
      render: (text: string) => <Tag color={'green'}>{t(text)}</Tag>,
    },
    {
      title: t('responsible'),
      dataIndex: 'user',
      render: ({ name }: IUser) => <span>{name}</span>,
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      render: ({ id }: IFinanceTeacherSalary) => (
        <DeleteModal
          id={id}
          modalText={'teachers:deleteTeacherPayment'}
          deleteFunction={deleteTeacherPaymentApi}
          fetchingQueryKey={TEACHERS_PAYMENTS_KEY}
        />
      ),
    },
  ]
  return <MyTable columns={columns} fetchingQueryKey={TEACHERS_PAYMENTS_KEY} data={payments} />
}

export default PaymentsToTeachersTable
