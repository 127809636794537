import React, { useState } from 'react'
import { Col, Form, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SearchInput from '../../../core/form-group/search-input/search-input'
import FilterButtons from '../../../core/form-group/filter-buttons/filter-buttons'
import { serialize } from '../../../../lib/serializeQuery'
import { queryClient } from '../../../../index'
import { ROOMS_KEY } from '../../../../hooks/query/rooms'
import queryString from 'query-string'

interface props {}

const RoomsFilters: React.FC<props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { push, location } = useHistory()
  const [form] = Form.useForm()

  const onFinish = async (values: any) => {
    setIsLoading(true)
    await push({ search: serialize(values) })
    await queryClient.invalidateQueries(ROOMS_KEY)
    setIsLoading(false)
  }
  const onReset = async () => {
    setIsLoading(true)
    await push({ search: '' })
    await queryClient.invalidateQueries(ROOMS_KEY)
    setIsLoading(false)
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      initialValues={{ ...queryString.parse(location.search) }}
    >
      <Row gutter={[16, 0]}>
        <Col flex={1}>
          <SearchInput />
        </Col>
        <Col xs={24}>
          <FilterButtons isLoading={isLoading} onReset={onReset} />
        </Col>
      </Row>
    </Form>
  )
}

export default RoomsFilters
