import React from 'react'
import Event from '../../../app/modules/testing/events/event'

interface props {}

const EventPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Event />
    </React.Fragment>
  )
}

export default EventPage
