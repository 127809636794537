import React from 'react'
import { Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQuestionBasesList } from '../../../hooks/query/question-bases'
import { filterOption } from '../../../lib/filterOption'

interface props {
  name?: string
  required?: boolean
}

const FormTestsList: React.FC<props> = ({ name = 'question_bases', required = true }) => {
  const { t } = useTranslation(['translation', 'testing'])
  const { isLoading, data } = useQuestionBasesList()

  return (
    <Form.Item name={name} label={t('testing:selectTests')} rules={[{ required }]}>
      <Select
        mode={'multiple'}
        disabled={isLoading}
        loading={isLoading}
        placeholder={t('testing:inputSelectTests')}
        filterOption={filterOption}
        showSearch
      >
        {data?.map(qb => (
          <Select.Option value={qb.id!} key={qb.id}>
            {qb.title}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FormTestsList
