import React from 'react'
import Head from '../../../hoc/head/head'
import { useTranslation } from 'react-i18next'
import { PageHeader, Skeleton } from 'antd'
import BranchesTable from './branches-table/branches-table'
import { useBranches } from '../../../hooks/query/branches'
import BranchesDrawer from './branches-drawer/branches-drawer'
import ErrorAlert from '../../core/error-alert/error-alert'

interface props {}

const Branches: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'branches'])
  const { isLoading, isError, error, data } = useBranches()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const title = t('branches')
  return (
    <Head title={title}>
      <PageHeader
        onBack={() => window.history.back()}
        title={title}
        subTitle={t('branches:branchesSubtitle')}
        extra={[<BranchesDrawer key={'1'} title={'add'} />]}
      />
      <BranchesTable branches={data!} />
    </Head>
  )
}

export default Branches
