import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import { Table } from 'antd'
import { idType } from '../../../../interfaces/types/types'
import { useTeacherSalaryHistory } from '../../../../hooks/query/teachers'
import ErrorAlert from '../../../core/error-alert/error-alert'
import TeacherRate from '../teacher-rate/teacher-rate'
import moment from 'moment'
import TeacherSalaryHistoryModal from './teacher-salary-history-modal'
import { ITeacherSalaryHistory } from '../../../../interfaces'
import StatusTag from '../../../core/status-tag/status-tag'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'

interface props {}

const TeacherSalaryHistory: React.FC<props> = () => {
  const { id: teacherId } = useParams<{ id: string }>()
  const { t } = useTranslation(['translation', 'teachers'])
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin'])

  const { isLoading, isError, error, data } = useTeacherSalaryHistory(teacherId)

  if (isError) return <ErrorAlert msg={error?.message} />

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: idType) => (
        <>
          <TableMobileTitle title={'ID'} /># {text}
        </>
      ),
    },
    {
      title: t('salary'),
      dataIndex: 'salary',
      render: (salary: number) => (
        <>
          <TableMobileTitle title={t('salary')} />
          <TeacherRate value={salary} sum={true} />
        </>
      ),
    },
    {
      title: t('percentage'),
      dataIndex: 'percentage',
      render: (percent: number) => (
        <>
          <TableMobileTitle title={t('percentage')} />
          <TeacherRate value={percent} />
        </>
      ),
    },
    {
      title: t('teachers:actualSince'),
      dataIndex: 'date',
      render: (date: string) => (
        <>
          <TableMobileTitle title={t('teachers:actualSince')} />
          <span>{moment(date).format('MMMM YYYY')}</span>
        </>
      ),
    },
    {
      title: t('status'),
      dataIndex: 'actual',
      render: (actual: boolean) => (
        <>
          <TableMobileTitle title={t('status')} />
          <StatusTag status={actual} />
        </>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right' as 'right',
      className: !access ? 'hide' : '',
      render: ({ id, salary, percentage }: ITeacherSalaryHistory) =>
        access && (
          <TeacherSalaryHistoryModal
            teacherId={teacherId}
            salary={salary}
            percentage={percentage}
            rateId={id}
          />
        ),
    },
  ]

  return (
    <Table
      className={'adaptive-table'}
      columns={columns}
      scroll={{ x: true }}
      pagination={false}
      rowKey={'id'}
      loading={isLoading}
      dataSource={data}
    />
  )
}

export default TeacherSalaryHistory
