import { idType } from '../interfaces/types/types'
import { request as axios } from './index'

export const url = '/students-groups'

export function changeStudentAddedToGroupDateApi({
  studentIds,
  addedAt,
  groupId,
}: {
  groupId: idType
  studentIds: idType[]
  addedAt: string
}) {
  return axios.patch(url, {
    group_id: groupId,
    student_ids: studentIds,
    added_at: addedAt,
  })
}
