import React from 'react'
import { IOrderBid } from '../../../../interfaces'
import LeadsBid from './leads-bid/leads-bid'
import { Draggable } from 'react-beautiful-dnd'

interface props {
  bids: IOrderBid[]
}

const LeadsBids = ({ bids }: props) => {
  return (
    <React.Fragment>
      {bids.map((item, index) => (
        <Draggable key={item.id} draggableId={item.id!.toString()} index={index}>
          {(provided, snapshot) => <LeadsBid provided={provided} item={item} />}
        </Draggable>
      ))}
    </React.Fragment>
  )
}

export default React.memo(LeadsBids)
