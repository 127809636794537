import React from 'react'
import { Popover } from 'antd'
import moment from 'moment'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'

interface props {
  title: string
  content: any
  day: string
  visible: boolean
  onChange: () => void
}

const GroupPopover: React.FC<props> = ({ title, day, content, children, visible, onChange }) => {
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'teacher'])

  return access ? (
    <Popover
      placement="top"
      title={`${title} ${moment(day).format('D MMMM')}?`}
      trigger="click"
      content={content}
      visible={visible}
      onVisibleChange={onChange}
      destroyTooltipOnHide={true}
    >
      {children}
    </Popover>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  )
}

export default GroupPopover
