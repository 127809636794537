import React from 'react'
import { Space, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import GroupDiscountModal from './group-discount-modal/group-discount-modal'
import { useGroupDiscounts } from '../../../../hooks/query/groups'
import ErrorAlert from '../../../core/error-alert/error-alert'
import { IStudentDiscount, IStudentWithDiscount } from '../../../../interfaces'
import GroupStudentDiscount from './group-student-discount/group-student-discount'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'

interface props {
  groupStartDate: string
  groupEndDate: string
}

const GroupDiscounts: React.FC<props> = ({ groupEndDate, groupStartDate }) => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const { isLoading, isError, error, data } = useGroupDiscounts(id)

  if (isError) return <ErrorAlert msg={error?.message} />

  const columns = [
    {
      title: '#',
      key: 'index',
      render: (text: string, record: any, index: number) => <># {index + 1}</>,
    },
    {
      title: t('firstName'),
      dataIndex: 'name',
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('firstName')} />
          {text}
        </>
      ),
    },
    {
      title: t('discounts'),
      dataIndex: 'discounts',
      render: (discounts: IStudentDiscount[]) => (
        <>
          <TableMobileTitle title={t('discounts')} />
          <Space>
            {discounts.length
              ? discounts.map(discount => (
                  <GroupStudentDiscount key={discount.id} discount={discount} />
                ))
              : t('groups:noDiscounts')}
          </Space>
        </>
      ),
    },
    {
      title: '',
      key: 'action',
      align: 'right' as 'right',
      render: ({ id, name }: IStudentWithDiscount) => (
        <GroupDiscountModal
          groupStartDate={groupStartDate}
          groupEndDate={groupEndDate}
          studentId={id}
          studentName={name}
        />
      ),
    },
  ]

  return (
    <Table
      className={'adaptive-table'}
      scroll={{ x: true }}
      pagination={false}
      rowKey={'id'}
      loading={isLoading}
      dataSource={data}
      columns={columns}
    />
  )
}

export default GroupDiscounts
