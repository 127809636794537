import React from 'react'
import { Link } from 'react-router-dom'
import { idType } from '../../../interfaces/types/types'
import { useRoleChecker } from '../../../hooks/useRoleChecker'

interface props {
  studentName: string
  studentId: idType
}

const StudentLink: React.FC<props> = ({ studentId, studentName }) => {
  const { roleChecker } = useRoleChecker()
  const isStudent = roleChecker(['student'])

  if (isStudent) return <React.Fragment>{studentName}</React.Fragment>

  return <Link to={'/students/details/' + studentId}>{studentName}</Link>
}

export default React.memo(StudentLink)
