import React from 'react'
import styles from './layout-footer.module.scss'
import { Layout } from 'antd'

interface props {}

const LayoutFooter: React.FC<props> = () => (
  <Layout.Footer className={styles.footer}>
    Copyright © {new Date().getFullYear()}.{' '}
    <a href="https://educrm.uz" rel={'noopener noreferrer'} target={'_blank'}>
      <span>EDUCRM.UZ</span>
    </a>
  </Layout.Footer>
)

export default LayoutFooter
