import React from 'react'
import { IRoom } from '../../../../interfaces'
import MyTable from '../../../core/my-table/my-table'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../interfaces/types/types'
import { Link } from 'react-router-dom'
import RoomsAction from '../rooms-action/rooms-action'
import { ROOMS_KEY } from '../../../../hooks/query/rooms'
import { ColumnsType } from 'antd/es/table'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'

interface props {
  rooms: IRoom[]
}

const RoomsTable: React.FC<props> = ({ rooms }) => {
  const { t } = useTranslation()

  const columns: ColumnsType<IRoom> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (text: idType) => (
        <>
          <TableMobileTitle title={'ID'} /># {text}
        </>
      ),
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
      render: (text: string, record: IRoom) => (
        <>
          <TableMobileTitle title={t('name')} />
          <Link to={`/rooms/details/${record.id}`}>{text}</Link>
        </>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      render: (_: any, record: IRoom) => <RoomsAction room={record} />,
    },
  ]

  return <MyTable fetchingQueryKey={ROOMS_KEY} columns={columns} data={rooms} />
}

export default RoomsTable
