import React, { useState } from 'react'
import { idType } from '../../../../interfaces/types/types'
import { Col, Image, Row, Select, Typography } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { GROUP_EVENT_RESULTS_KEY, useGroupEvents } from '../../../../hooks/query/groups'
import { useTranslation } from 'react-i18next'
import GroupEventResultTable from '../group-event-result-table/group-event-result-table'
import { SelectValue } from 'antd/es/select'
import { getQueryParam } from '../../../../lib/getQueryParam'
import { serialize } from '../../../../lib/serializeQuery'
import image from './undraw_file_bundle_re_6q1e.svg'

import styles from './group-event.module.scss'
import { queryClient } from '../../../../index'

interface props {}

const GroupEvent: React.FC<props> = () => {
  const { push } = useHistory()
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation(['translation', 'testing'])
  const { isLoading, data } = useGroupEvents(id)
  const [selectedEvent, setSelectedEvent] = useState<idType>(getQueryParam('selected_event'))

  const handleEventChange = (value: SelectValue) => {
    queryClient.removeQueries(GROUP_EVENT_RESULTS_KEY)
    push({
      search: serialize('selected_event', value),
    })
    setSelectedEvent(value as idType)
  }

  return (
    <Row gutter={[32, 32]}>
      <Col xs={24}>
        <Typography.Title level={3}>{t('testing:chooseEvent')}</Typography.Title>
        <Select
          className={styles.select}
          defaultValue={getQueryParam('selected_event')}
          loading={isLoading}
          placeholder={t('testing:chooseEvent')}
          onChange={handleEventChange}
        >
          {data?.map(event => (
            <Select.Option value={event.id!.toString()} key={event.id}>
              {event.title}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col xs={24}>
        {selectedEvent ? (
          <GroupEventResultTable eventId={selectedEvent} groupId={id} />
        ) : (
          <Image src={image} preview={false} height={300} />
        )}
      </Col>
    </Row>
  )
}

export default GroupEvent
