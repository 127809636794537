import React from 'react'
import { DatePicker, Form } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { useTranslation } from 'react-i18next'

interface props {}

const BirthdaySelect: React.FC<props> = () => {
  const { t } = useTranslation()

  const style = { width: `100%` }

  return (
    <Form.Item name="birthday" label={t('birthday') + ` (${t('notRequired').toLowerCase()})`}>
      <DatePicker locale={locale} style={style} />
    </Form.Item>
  )
}

export default BirthdaySelect
