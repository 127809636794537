import React from 'react'
import styles from './layout-user.module.scss'
import { useTranslation } from 'react-i18next'
import { Avatar, Dropdown, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { useAuthUserInformation } from '../../../hooks/query/auth'
import { useLogout } from '../../../hooks/useLogout'
import { DownOutlined, UserOutlined } from '@ant-design/icons'

interface props {}

const LayoutUser: React.FC<props> = () => {
  const { t } = useTranslation()
  const { isLoading, logout } = useLogout()

  const { data } = useAuthUserInformation()

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to={`/profile`}>{t('profile')}</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" style={{ color: 'red' }} disabled={isLoading} onClick={logout}>
        {t('exit')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown
      placement={'bottomCenter'}
      className={styles.dropdown}
      overlay={menu}
      trigger={['click']}
    >
      <div className={styles.wrapper}>
        <Avatar className={styles.avatar} size={38} icon={<UserOutlined />} />
        <span className={styles.name}>{data?.name}</span>
        <DownOutlined className={styles.down} />
      </div>
    </Dropdown>
  )
}

export default LayoutUser
