import React from 'react'
import { Table } from 'antd'
import { IFinanceTeacherSummary } from '../../../../../interfaces'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import TeacherRate from '../../../teachers/teacher-rate/teacher-rate'
import FinancesGroupDifference from '../../finance-difference/finances-difference'
import FinanceDifference from '../../finance-difference/finances-difference'
import { priceFormatter } from '../../../../../lib/priceFormatter'
import isEqual from 'react-fast-compare'

interface props {
  summary: IFinanceTeacherSummary[]
}

const FinanceTeacherSummary: React.FC<props> = ({ summary }) => {
  const { t } = useTranslation(['translation', 'groups'])

  const columns = [
    {
      title: t('month'),
      dataIndex: 'first_day',
      fixed: 'left',
      render: (text: string) => <span>{moment(text).format('MMMM, YYYY')}</span>,
    },
    {
      title: t('groups:studentsCount'),
      dataIndex: 'students_count',
    },
    {
      title: t('groupsCount'),
      dataIndex: 'groups_count',
    },
    {
      title: t('numberOfLessons'),
      dataIndex: 'lessons_count',
    },
    {
      title: t('missedForReason'),
      dataIndex: 'reasons_count',
    },
    {
      title: t('reasonSum'),
      dataIndex: 'reasons_total',
      render: (number: number) => <span>{priceFormatter(number)}</span>,
    },
    {
      title: t('salary'),
      dataIndex: 'teacher_salary',
      render: (number: number) => <TeacherRate value={number} sum={true} />,
    },
    {
      title: t('percentage'),
      dataIndex: 'teacher_percent',
      render: (number: number) => <TeacherRate value={number} />,
    },
    {
      title: t('costOfTeacherGroups'),
      dataIndex: 'groups_summary',
      render: (n: number) => <span>{priceFormatter(n)}</span>,
    },
    {
      title: t('amountOfPaidStudents'),
      dataIndex: 'received_from_groups',
      render: (n: number) => <FinanceDifference difference={n} />,
    },
    {
      title: t('teacherSalary'),
      dataIndex: 'teacher_total_in_fact',
      render: (n: number) => <FinanceDifference difference={n} />,
    },
    {
      title: t('paidToTheTeacher'),
      dataIndex: 'teacher_paid',
      render: (number: number) => <span>{priceFormatter(number)}</span>,
    },
    {
      title: t('difference'),
      dataIndex: 'teacher_difference',
      render: (number: number) => <FinancesGroupDifference difference={number} />,
    },
    {
      title: t('teacherPotentialSalary'),
      dataIndex: 'teacher_total',
      render: (number: number) => <span>{priceFormatter(number)}</span>,
    },
  ]

  return (
    <Table
      //@ts-ignore
      columns={columns}
      dataSource={summary}
      pagination={false}
      rowKey={'first_day'}
      scroll={{ x: true }}
      bordered
      summary={(pageData: readonly IFinanceTeacherSummary[]) => {
        if (pageData.length) {
          const reason = pageData
            .map(f => f.reasons_total)
            .reduce((v, sum) => Number(v) + Number(sum))

          const groupsSummary = pageData
            .map(f => f.groups_summary)
            .reduce((v, sum) => Number(v) + Number(sum))

          const receivedFromGroups = pageData
            .map(f => f.received_from_groups)
            .reduce((v, sum) => Number(v) + Number(sum))

          const total = pageData
            .map(f => f.teacher_total)
            .reduce((v, sum) => Number(v) + Number(sum))

          const totalInFact = pageData
            .map(f => f.teacher_total_in_fact)
            .reduce((v, sum) => Number(v) + Number(sum))

          const paidUp = pageData
            .map(f => f.teacher_paid)
            .reduce((v, sum) => Number(v) + Number(sum))

          const difference = pageData
            .map(f => f.teacher_difference)
            .reduce((v, sum) => Number(v) + Number(sum))

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={5} index={1}>
                <b>{t('total')}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={3} index={2}>
                <b>{priceFormatter(reason)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <b>{priceFormatter(groupsSummary)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <b>{priceFormatter(receivedFromGroups)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <b>{priceFormatter(totalInFact)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                <b>{priceFormatter(paidUp)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <FinancesGroupDifference difference={difference} />
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                <b>{priceFormatter(total)}</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )
        }
      }}
    />
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps.summary, nextProps.summary)
}

export default React.memo(FinanceTeacherSummary, propsAreEqual)
