import React from 'react'
import { Divider, Space, Typography } from 'antd'
import { IBranch } from '../../../interfaces'

interface props {
  branches: IBranch[]
}

const UserBranches: React.FC<props> = ({ branches }) => {
  return (
    <Space split={<Divider type={'vertical'} />}>
      {branches.map(({ id, title }) => (
        <Typography.Text key={id}>{title}</Typography.Text>
      ))}
    </Space>
  )
}

export default UserBranches
