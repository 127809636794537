import React from 'react'
import { PageHeader, Skeleton, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/es/table'
import { ITeacher, IUser } from '../../../../interfaces'
import { idType } from '../../../../interfaces/types/types'
import { Link } from 'react-router-dom'
import DateWithMonth from '../../../core/date-with-month/date-with-month'
import { ARCHIVED_USERS_KEY, useArchivedUsers } from '../../../../hooks/query/users'
import ErrorBoundary from '../../../../hoc/error-boundary'
import MyPagination from '../../../core/my-pagination/my-pagination'
import RestoreModal from '../../../core/restore-modal/restore-modal'
import { restoreUserApi } from '../../../../api/user'

interface props {}

const Stuff: React.FC<props> = () => {
  const { t } = useTranslation()
  const { isLoading, isError, data } = useArchivedUsers()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorBoundary />

  const columns: ColumnsType<IUser> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => `# ${id}`,
    },
    {
      title: t('firstName'),
      key: 'name',
      render: (item: ITeacher) => <Link to={'/teachers/details/' + item.id}>{item.name}</Link>,
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      render: (phone: string) => `+998 ${phone}`,
    },
    {
      title: t('createdAt'),
      dataIndex: 'created_at',
      render: (text: string) => <DateWithMonth date={text} />,
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      render: (item: IUser) => (
        <RestoreModal
          id={item.id}
          restoreFunction={restoreUserApi}
          fetchingQueryKey={ARCHIVED_USERS_KEY}
          modalText={'restoreUser'}
        />
      ),
    },
  ]

  const { data: users, ...pagination } = data!
  return (
    <>
      <PageHeader title={t('stuffArchive')} onBack={() => window.history.back()} />
      <Table
        scroll={{ x: true }}
        pagination={false}
        bordered={false}
        rowKey={'id'}
        dataSource={users}
        columns={columns}
      />
      <MyPagination pagination={pagination} fetchingQueryKey={ARCHIVED_USERS_KEY} />
    </>
  )
}

export default Stuff
