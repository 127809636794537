import React from 'react'
import { Table, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../../interfaces/types/types'
import { IFinanceGroupStudents, IFinanceTotal } from '../../../../../interfaces'
import { priceFormatter } from '../../../../../lib/priceFormatter'
import FinancesGroupDifference from '../../finance-difference/finances-difference'
import PhoneNumber from '../../../../core/phone-number/phone-number'
import StudentLink from '../../../../core/student-link/student-link'
import DateWithMonth from '../../../../core/date-with-month/date-with-month'

interface props {
  month_reports: IFinanceGroupStudents[]
  totals: IFinanceTotal | undefined
}

const FinancesGroupStudents: React.FC<props> = ({ month_reports, totals }) => {
  const { t } = useTranslation(['translation', 'groups'])

  const columns = [
    {
      title: t('id'),
      dataIndex: 'student_id',
      render: (text: idType) => <span># {text}</span>,
    },
    {
      title: t('firstName'),
      key: 'students',
      render: ({ student_id, student_name }: IFinanceGroupStudents) => (
        <StudentLink studentId={student_id} studentName={student_name} />
      ),
    },
    {
      title: t('phone'),
      dataIndex: 'student_phone',
      render: (phone: string) => <PhoneNumber phone={phone} />,
    },
    {
      title: t('groups:addedToGroup'),
      dataIndex: 'added_at',
      render: (text: string) => <DateWithMonth date={text} />,
    },
    {
      title: t('numberOfLessons'),
      dataIndex: 'lessons_count',
    },
    {
      title: t('missedForReason'),
      dataIndex: 'reasons_count',
    },
    {
      title: t('discount'),
      dataIndex: 'discount',
      align: 'center' as 'center',
      render: (discount: number) =>
        discount ? <Tag color={'green'}>{discount} %</Tag> : <strong>-</strong>,
    },
    {
      title: t('totalPayment'),
      dataIndex: 'total',
      render: (text: number) => <span>{priceFormatter(text)}</span>,
    },
    {
      title: t('paidUp'),
      dataIndex: 'paid',
      render: (text: number) => <span>{priceFormatter(text)}</span>,
    },
    {
      title: t('studentBalance'),
      dataIndex: 'difference',
      render: (text: number) => <FinancesGroupDifference difference={text} />,
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={month_reports}
      scroll={{ x: true }}
      pagination={false}
      rowKey={'student_id'}
      summary={(pageData: readonly IFinanceGroupStudents[]) => {
        if (pageData.length) {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={7} index={1}>
                <b>{t('total')}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <b>{priceFormatter(totals!.total)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <b>{priceFormatter(totals!.paid)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <FinancesGroupDifference difference={totals!.difference} />
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )
        }
      }}
    />
  )
}

export default React.memo(FinancesGroupStudents)
