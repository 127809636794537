import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { IFinanceDebtorsResponse } from '../../interfaces'
import { getAllFinancesDebtorsApi } from '../../api/finances'

export const DEBTORS_KEY = 'debtors_key'

export function useDebtors(options?: UseQueryOptions<IFinanceDebtorsResponse, Error>) {
  return useQuery<IFinanceDebtorsResponse, Error>(
    DEBTORS_KEY,
    () =>
      getAllFinancesDebtorsApi().then(
        (response: AxiosResponse<IFinanceDebtorsResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}
