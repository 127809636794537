import React from 'react'
import { Form, FormItemProps, InputNumber } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  name: string
  label: string
  disabled?: boolean
  rest?: FormItemProps
}

const SumInput: React.FC<props> = ({ name, label, disabled, rest }) => {
  const { t } = useTranslation()

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: true,
          message: t('required'),
        },
      ]}
      {...rest}
    >
      <InputNumber
        disabled={disabled}
        style={{ width: `100%` }}
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value: string | undefined) => value!.replace(/\$\s?|(,*)/g, '')}
        placeholder={t('inputSum')}
      />
    </Form.Item>
  )
}

export default React.memo(SumInput)
