import React from 'react'
import Archive from '../../app/modules/archive/archive'

interface props {}

const ArchivePage: React.FC<props> = () => {
  return (
    <>
      <Archive />
    </>
  )
}

export default ArchivePage
