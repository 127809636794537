import React, { useState } from 'react'
import { IGroupActivity, IGroupStudentGrade } from '../../../../../interfaces'
import { useTranslation } from 'react-i18next'
import { Button, message } from 'antd'
import GroupPopover from '../../group-popover/group-popover'
import { createGradeApi, updateGradeApi } from '../../../../../api/groups'
import GroupGradesForm from '../group-grades-form/group-grades-form'
import styles from './group-grades-mark.module.scss'
import { useParams } from 'react-router-dom'
import { queryClient } from '../../../../../index'
import { GROUP_STUDENTS_ACTIVITY_KEY } from '../../../../../hooks/query/groups'
import { useMutation } from 'react-query'
import { HighlightOutlined } from '@ant-design/icons'
import { idType } from '../../../../../interfaces/types/types'
import { AxiosResponse } from 'axios'
import isEqual from 'react-fast-compare'

interface props {
  studentId: idType
  grade: IGroupStudentGrade
  day: string
}

const GroupGradesMark: React.FC<props> = ({ studentId, grade, day }) => {
  const [gradesPopover, setGradesPopover] = useState<boolean>(false)
  const onPopoverVisibleChange = () => setGradesPopover(!gradesPopover)
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation(['translation', 'groups'])

  const onSuccess = async (response: AxiosResponse<IGroupStudentGrade[]>) => {
    const oldQueryData = queryClient.getQueryData<IGroupActivity>([GROUP_STUDENTS_ACTIVITY_KEY, id])

    queryClient.setQueryData([GROUP_STUDENTS_ACTIVITY_KEY, id], {
      ...oldQueryData,
      students: oldQueryData?.students.map(student => {
        if (student.id === studentId) {
          return { ...student, grades: response.data }
        }
        return student
      }),
    })
    onPopoverVisibleChange()
  }

  const onError = (e: any) => {
    message.error(t(e.response.data.message))
  }

  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(createGradeApi, {
    onSuccess,
    onError,
  })
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(updateGradeApi, {
    onSuccess,
    onError,
  })

  const submitGrades = (mark: number) => {
    const handler = grade ? update : create

    return handler({
      mark,
      date: day,
      group_id: id,
      student_id: studentId,
      id: grade?.id,
    })
  }

  return (
    <div className={styles.wrapper}>
      <GroupPopover
        day={day}
        title={t('groups:gradeQuestion')}
        visible={gradesPopover}
        onChange={onPopoverVisibleChange}
        content={
          <GroupGradesForm
            loading={isCreateLoading || isUpdateLoading}
            mark={grade?.mark}
            submitGrades={submitGrades}
          />
        }
      >
        {!grade ? (
          <Button className={styles.button} size={'small'} icon={<HighlightOutlined />} />
        ) : (
          <span className={styles.mark}>{grade.mark}</span>
        )}
      </GroupPopover>
    </div>
  )
}

const propAreEqual = (prevProps: props, nextProps: props) => {
  return (
    isEqual(prevProps.grade, nextProps.grade) &&
    prevProps.day === nextProps.day &&
    prevProps.studentId === nextProps.studentId
  )
}
export default React.memo(GroupGradesMark, propAreEqual)
