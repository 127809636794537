import React from 'react'
import { Col, PageHeader, Row, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import TeacherCard from '../../teachers/teacher-card/teacher-card'
import FinancesTeacherPayment from './finances-teacher-payment/finances-teacher-payment'
import Head from '../../../../hoc/head/head'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import { useFinancesTeacher } from '../../../../hooks/query/teachers'
import ErrorAlert from '../../../core/error-alert/error-alert'
import FinanceTeacherInfo from '../../../core/finance-teacher-info/finance-teacher-info'
import LinkButton from '../../../core/link-button/link-button'

interface props {}

const FinancesTeacher: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'teachers'])
  const { id } = useParams<{ id: string }>()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'cashier'])

  const { isLoading, isError, error, data } = useFinancesTeacher(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { teacher, students, groups_summaries, salaries, teacher_summaries, totals } = data!

  return (
    <Head title={teacher.name}>
      <PageHeader
        title={teacher.name}
        subTitle={t('teachers:teacherSubtitle')}
        onBack={() => window.history.back()}
        extra={[
          <LinkButton key={'1'} pathname={'/teachers/details/' + teacher.id}>
            {t('teachers:goToTeacher')}
          </LinkButton>,
        ]}
      />
      <Row gutter={[32, 32]}>
        <Col xs={24} lg={13}>
          <TeacherCard teacher={teacher} />
        </Col>
        <Col xs={24} lg={{ span: 9, offset: 2 }}>
          {access && <FinancesTeacherPayment teacherId={teacher.id} />}
        </Col>
        <Col xs={24}>
          <FinanceTeacherInfo
            teacherId={id}
            totals={totals}
            students={students}
            groupsSummaries={groups_summaries}
            salaries={salaries}
            teacherSummaries={teacher_summaries}
          />
        </Col>
      </Row>
    </Head>
  )
}

export default FinancesTeacher
