import React from 'react'
import { ICourse } from '../../../../interfaces'
import MyTable from '../../../core/my-table/my-table'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../interfaces/types/types'
import { priceFormatter } from '../../../../lib/priceFormatter'
import CourseType from '../course-type/course-type'
import { Link } from 'react-router-dom'
import CourseActions from '../course-actions/course-actions'
import StatusTag from '../../../core/status-tag/status-tag'
import { COURSES_KEY } from '../../../../hooks/query/courses'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import { ColumnsType } from 'antd/es/table'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'
import DateWithMonth from '../../../core/date-with-month/date-with-month'

interface props {
  courses: ICourse[]
}

const CoursesTable: React.FC<props> = ({ courses }) => {
  const { t } = useTranslation()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception'])

  const columns: ColumnsType<ICourse> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (text: idType) => (
        <>
          <TableMobileTitle title={'ID'} /># {text}
        </>
      ),
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
      render: (text: string, record: ICourse) => (
        <>
          <TableMobileTitle title={t('name')} />
          <Link to={`/courses/details/${record.id}`}>{text}</Link>
        </>
      ),
    },
    {
      title: t('lessonDuration'),
      dataIndex: 'lesson_duration',
      key: 'lesson_duration',
      sorter: true,
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('lessonDuration')} />
          {text}
        </>
      ),
    },
    {
      title: t('price'),
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (text: number) => (
        <>
          <TableMobileTitle title={t('price')} />
          <span>{priceFormatter(text)}</span>
        </>
      ),
    },
    {
      title: t('createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('createdAt')} />
          <DateWithMonth date={text} />
        </>
      ),
    },
    {
      title: t('type'),
      dataIndex: 'course_type_id',
      key: 'course_type_id',
      sorter: true,
      render: (text: number) => (
        <>
          <TableMobileTitle title={t('type')} />
          <CourseType type={text} />
        </>
      ),
    },
    {
      title: t('status'),
      dataIndex: 'status',
      sorter: true,
      render: (text: boolean) => (
        <>
          <TableMobileTitle title={t('status')} />
          <StatusTag status={text} />
        </>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      className: access ? 'show' : 'hide',
      render: (record: ICourse) => <CourseActions course={record} />,
    },
  ]

  return <MyTable fetchingQueryKey={COURSES_KEY} columns={columns} data={courses} />
}

export default React.memo(CoursesTable)
