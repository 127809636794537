import React from 'react'
import Finances from '../../app/modules/finances/finances'

interface props {}

const FinancesPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Finances />
    </React.Fragment>
  )
}

export default FinancesPage
