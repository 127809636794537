import React from 'react'
import { priceFormatter } from '../../../../lib/priceFormatter'

interface props {
  difference: number | undefined
}

const FinanceDifference: React.FC<props> = ({ difference }) => {
  const styles = { color: difference && difference < 0 ? '#f5222d' : '#389e0d' }

  return <strong style={styles}>{priceFormatter(difference)}</strong>
}

export default React.memo(FinanceDifference)
