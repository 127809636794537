import React, { useState } from 'react'
import { Card, Tabs } from 'antd'
import GroupList from '../../../core/group-list/group-list'
import { useTranslation } from 'react-i18next'
import { IGroup } from '../../../../interfaces'
import TeacherSalaryHistory from '../teacher-salary-history/teacher-salary-history'
import TeacherSalaryHistoryModal from '../teacher-salary-history/teacher-salary-history-modal'
import { getQueryParam } from '../../../../lib/getQueryParam'
import { useHistory, useParams } from 'react-router-dom'
import { serialize } from '../../../../lib/serializeQuery'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'

const { TabPane } = Tabs

interface props {
  groups: IGroup[]
}

type tabKeys = 'groups' | 'rate-history'
const TeacherTabs: React.FC<props> = ({ groups }) => {
  const { id: teacherId } = useParams<{ id: string }>()
  const [activeKey, setActiveKey] = useState<tabKeys>((getQueryParam('tab') as tabKeys) || 'groups')
  const { t } = useTranslation(['teachers'])
  const { replace } = useHistory()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin'])

  const handleTabChange = (key: string) => {
    replace({
      search: serialize('tab', key),
    })
    setActiveKey(key as tabKeys)
  }

  const renderTabExtraContent = () => {
    if (activeKey === 'rate-history' && access)
      return <TeacherSalaryHistoryModal teacherId={teacherId} />

    return null
  }

  return (
    <Card bordered={false}>
      <Tabs
        defaultActiveKey={activeKey}
        onChange={handleTabChange}
        tabBarExtraContent={renderTabExtraContent()}
        destroyInactiveTabPane={true}
      >
        <TabPane key={'groups' as tabKeys} tab={t('groupList')}>
          <GroupList groups={groups} />
        </TabPane>
        <TabPane key={'rate-history' as tabKeys} tab={t('salaryHistory')}>
          <TeacherSalaryHistory />
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default TeacherTabs
