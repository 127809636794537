import React from 'react'
import { Form, TimePicker } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  name?: string
  label?: string
  required?: boolean
  changeTime?: (time: any) => void
}

const StartTime: React.FC<props> = ({
  name = 'start_time',
  label = 'groups:groupStartTime',
  required = true,
  changeTime,
}) => {
  const { t } = useTranslation()

  return (
    <Form.Item name={name} label={t(label)} rules={[{ required }]}>
      <TimePicker
        style={{ width: '100%' }}
        hideDisabledOptions={true}
        showNow={false}
        minuteStep={10}
        format={'HH:mm'}
        onChange={changeTime}
        allowClear={!required}
      />
    </Form.Item>
  )
}

export default StartTime
