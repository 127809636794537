import { Divider, Form, FormInstance, FormItemProps, Select } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useExpenseTypes } from '../../../../hooks/query/expense-types'
import { filterOption } from '../../../../lib/filterOption'
import ExpenseTypesAdd from './expense-types-add/expense-types-add'

interface props {
  name?: string
  label?: string
  required?: boolean
  rest?: FormItemProps
  form?: FormInstance
}

const ExpenseTypes: React.FC<props> = ({
  name = 'expense_type_id',
  label = 'expenseType',
  required = true,
  form,
}) => {
  const { t } = useTranslation(['finances', 'translation'])
  const { isLoading, data } = useExpenseTypes()

  return (
    <Form.Item name={name} label={t(label)} rules={[{ required, message: t('required') }]}>
      <Select
        disabled={isLoading}
        loading={isLoading}
        filterOption={filterOption}
        showSearch
        allowClear={!required}
        placeholder={t('chooseExpenseType')}
        dropdownRender={menu => (
          <div>
            {menu}
            {form && (
              <React.Fragment>
                <Divider style={{ margin: '4px 0' }} />
                <ExpenseTypesAdd form={form} fieldName={name} />
              </React.Fragment>
            )}
          </div>
        )}
      >
        {data?.map(({ id, title }) => (
          <Select.Option value={id!} key={id}>
            {title}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default ExpenseTypes
