import React from 'react'
import { Button } from 'antd'
import i18n from '../../../i18n'
import styles from './layout-language.module.scss'
import ru from './ru.png'
import uz from './uz.png'
import en from './en.png'

interface props {}

const LayoutLanguage: React.FC<props> = () => {
  const languages = [
    { key: 'ru', image: ru },
    { key: 'uz', image: uz },
    { key: 'en', image: en },
  ]

  const handleChange = (value: string) => {
    i18n.changeLanguage(value)
  }

  return (
    <div className={styles.wrapper}>
      {languages.map(({ key, image }) => (
        <Button
          size={'small'}
          type={'link'}
          key={key}
          onClick={() => handleChange(key)}
          disabled={i18n.language === key || key === 'uz'}
          className={styles.button}
        >
          <img src={image} width={15} alt="" />
          {key.toUpperCase()}
        </Button>
      ))}
    </div>
  )
}

export default LayoutLanguage
