import React from 'react'
import { Link } from 'react-router-dom'
import { idType } from '../../../../interfaces/types/types'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'

interface props {
  roomName: string
  roomId: idType
}

const GroupRoom: React.FC<props> = ({ roomName, roomId }) => {
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception', 'accountant'])

  return access ? <Link to={'/rooms/details/' + roomId}>{roomName}</Link> : <>{roomName}</>
}

export default React.memo(GroupRoom)
