import React from 'react'
import { Form, Radio } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  name?: string
  label?: string
  required?: boolean
}

const FormCourseType: React.FC<props> = ({
  name = 'course_type_id',
  label = 'type',
  required = true,
}) => {
  const { t } = useTranslation()

  return (
    <Form.Item
      name={name}
      label={t(label)}
      rules={[
        {
          required,
          message: t('required'),
        },
      ]}
    >
      <Radio.Group>
        <Radio value={1}>{t('offline')}</Radio>
        <Radio value={2}>{t('online')}</Radio>
        <Radio value={3}>{t('videocourse')}</Radio>
      </Radio.Group>
    </Form.Item>
  )
}

export default FormCourseType
