import React from 'react'
import { useParams } from 'react-router-dom'
import { Col, PageHeader, Row, Skeleton } from 'antd'
import RoomCard from './room-card/room-card'
import { useTranslation } from 'react-i18next'
import RoomsDrawer from './rooms-drawer/rooms-drawer'
import Head from '../../../hoc/head/head'
import { useRoom } from '../../../hooks/query/rooms'
import ErrorAlert from '../../core/error-alert/error-alert'
import { useRoleChecker } from '../../../hooks/useRoleChecker'
import RoomTabs from './room-tabs/room-tabs'

interface props {}

const Room: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()

  const { t } = useTranslation(['translation', 'rooms'])
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin'])

  const { isLoading, isError, data, error } = useRoom(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { name, groups } = data!

  return (
    <Head title={name}>
      <PageHeader
        title={name}
        subTitle={t('rooms:roomSubtitle')}
        onBack={() => window.history.back()}
        extra={[
          access && <RoomsDrawer key={'1'} updateOne={true} title={'editTitle'} room={data!} />,
        ]}
      />
      <Row gutter={[32, 32]}>
        <Col xs={24}>
          <RoomCard room={data!} />
        </Col>
        <Col xs={24}>
          <RoomTabs groups={groups} />
        </Col>
      </Row>
    </Head>
  )
}

export default Room
