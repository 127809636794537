import React from 'react'
import Students from '../../app/modules/students/students'

interface props {}

const StudentsPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Students />
    </React.Fragment>
  )
}

export default StudentsPage
