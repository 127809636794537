import { ITestResult } from '../../interfaces'
import { getTestResultApi } from '../../api/test-session'
import { idType } from '../../interfaces/types/types'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'

export const GET_TEST_RESULT_KEY = 'get_test_result_key'

export function useTestResult(id: idType, options?: UseQueryOptions<ITestResult, Error>) {
  return useQuery<ITestResult, Error>(
    [GET_TEST_RESULT_KEY, id],
    () => getTestResultApi(id).then((response: AxiosResponse<ITestResult>) => response.data),
    {
      ...options,
    },
  )
}
