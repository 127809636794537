import React from 'react'
import { Tag, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { BankOutlined, CreditCardOutlined, DollarOutlined } from '@ant-design/icons'

interface props {
  method: string
}

const StudentPaymentMethod: React.FC<props> = ({ method }) => {
  const { t } = useTranslation()

  const renderTag = () => {
    switch (method) {
      case 'cash':
        return (
          <Tag color={'#87d068'}>
            <DollarOutlined />
          </Tag>
        )
      case 'card':
        return (
          <Tag color={'#2db7f5'}>
            <CreditCardOutlined />
          </Tag>
        )
      case 'bank':
        return (
          <Tag color={'#f50'}>
            <BankOutlined />
          </Tag>
        )
    }
  }

  return <Tooltip title={t(method)}>{renderTag()}</Tooltip>
}

export default React.memo(StudentPaymentMethod)
