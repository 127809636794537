import React from 'react'
import { Form, Select } from 'antd'
import { filterOption } from '../../../lib/filterOption'
import { useTranslation } from 'react-i18next'
import { ICourse } from '../../../interfaces'
import { useCourses } from '../../../hooks/query/courses'
import { idType } from '../../../interfaces/types/types'

interface props {
  name?: string
  activeCourseId?: idType
}

const CourseParentSelect: React.FC<props> = ({ name = 'parent_id', activeCourseId }) => {
  const { t } = useTranslation(['translation', 'course'])
  const { data, isFetching } = useCourses()

  const renderCourses = data?.data
    .filter(({ id }: ICourse) => id !== activeCourseId)
    .map(({ id, name }: ICourse) => (
      <Select.Option key={id} label={name} value={id!}>
        {name}
      </Select.Option>
    ))

  return (
    <Form.Item name={'parent_id'} label={t('course:parent')}>
      <Select
        disabled={isFetching}
        loading={isFetching}
        allowClear
        showSearch
        filterOption={filterOption}
        placeholder={t('course:selectParent')}
        style={{ width: '100%' }}
      >
        {renderCourses}
      </Select>
    </Form.Item>
  )
}

export default CourseParentSelect
