import React from 'react'
import { ICourse } from '../../../../interfaces'
import { List } from 'antd'
import { Link } from 'react-router-dom'
import { priceFormatter } from '../../../../lib/priceFormatter'
import CourseType from '../course-type/course-type'

interface props {
  children: ICourse[]
}

const CourseChildren: React.FC<props> = ({ children }) => {
  return (
    <React.Fragment>
      <List
        dataSource={children}
        renderItem={(item: ICourse) => (
          <List.Item>
            <List.Item.Meta
              title={
                <span>
                  <Link to={`/courses/details/${item.id}`}>{item.name}</Link> |{' '}
                  <small>{priceFormatter(item.price)}</small>
                </span>
              }
              description={<CourseType type={item.course_type_id} />}
            />
          </List.Item>
        )}
      />
    </React.Fragment>
  )
}

export default CourseChildren
