import { request as axios } from './index'
import { IExpense } from '../interfaces'
import { idType } from '../interfaces/types/types'

const url = '/expenses'

export function getAllExpenses() {
  return axios.get(url)
}

export function createExpense(data: IExpense) {
  return axios.post(url, data)
}

export function updateExpense(data: IExpense) {
  return axios.patch(url + '/' + data.id, data)
}

export function deleteExpense(id: idType) {
  return axios.delete(url + '/' + id)
}
