import React, { useState } from 'react'
import { Card, Tabs } from 'antd'
import GroupList from '../../../core/group-list/group-list'
import { ICourse, IGroup } from '../../../../interfaces'
import CourseChildren from '../course-children/course-children'
import { useTranslation } from 'react-i18next'
import { getQueryParam } from '../../../../lib/getQueryParam'
import { useHistory, useParams } from 'react-router-dom'
import { serialize } from '../../../../lib/serializeQuery'
import CoursePriceHistory from '../course-price-history/course-price-history'
import CoursePriceHistoryModal from '../course-price-history/course-price-history-modal/course-price-history-modal'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'

const { TabPane } = Tabs

interface props {
  childrenCourses: ICourse[]
  groups: IGroup[]
}

const CourseTabs: React.FC<props> = ({ groups, childrenCourses }) => {
  const { id } = useParams<{ id: string }>()
  const [activeKey, setActiveKey] = useState<string>(getQueryParam('tab') || 'groups')
  const { replace } = useHistory()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception'])

  const activeKeyHandler = (key: string) => {
    replace({
      search: serialize('tab', key),
    })
    setActiveKey(key)
  }
  const renderExtraContent = () => {
    if (activeKey === 'price-history' && access) return <CoursePriceHistoryModal courseId={id} />
  }

  const { t } = useTranslation(['translation', 'course'])

  return (
    <Card bordered={false}>
      <Tabs
        defaultActiveKey={activeKey}
        onChange={activeKeyHandler}
        tabBarExtraContent={renderExtraContent()}
      >
        <TabPane key={'groups'} tab={t('course:groupList')}>
          <GroupList groups={groups} />
        </TabPane>
        <TabPane key={'children'} tab={t('course:childCourses')}>
          <CourseChildren children={childrenCourses} />
        </TabPane>
        <TabPane key={'price-history'} tab={t('course:coursePriceHistory')}>
          <CoursePriceHistory />
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default CourseTabs
