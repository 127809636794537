import React, { FC, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { ExclamationCircleTwoTone } from '@ant-design/icons'

const ServiceWorkerWrapper: FC = ({ children }) => {
  const { t } = useTranslation()

  const history = useHistory()

  const checkServiceWorker = useCallback(() => {
    navigator.serviceWorker.getRegistrations().then(regs =>
      regs.forEach(reg =>
        reg.update().then((response: any) => {
          if (response.waiting) {
            const handleUpdate = () => {
              response.waiting?.postMessage({ type: 'SKIP_WAITING' })
              window.location.reload()
            }

            notification.open({
              key: '1',
              message: t('appUpdateTitle'),
              description: t('appUpdateMessage'),
              icon: <ExclamationCircleTwoTone twoToneColor={'red'} />,
              duration: null,
              btn: (
                <Button type={'primary'} onClick={handleUpdate}>
                  {t('appUpdateButton')}
                </Button>
              ),
            })
          }
        }),
      ),
    )
  }, [t])

  useEffect(() => {
    checkServiceWorker()
  }, [checkServiceWorker])

  useEffect(() => {
    history.listen(() => {
      checkServiceWorker()
    })
  }, [history, t, checkServiceWorker])

  return <>{children}</>
}

export default ServiceWorkerWrapper
