import React from 'react'
import styles from './leads-card-form.module.scss'
import { Button, Form, Input, message, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../../../interfaces/types/types'
import { IOrderCard, IOrderColumn } from '../../../../../../interfaces'
import { AppstoreAddOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { v4 as uuid } from 'uuid'
import { useMutation } from 'react-query'
import { addCardApi, editCardApi } from '../../../../../../api/leads'
import { queryClient } from '../../../../../../index'
import { AxiosResponse } from 'axios'
import { LEADS_KEY } from '../../../../../../hooks/query/leads'

interface props {
  column_id?: idType
  card?: IOrderCard
  handler?: Function
}

const LeadsCardForm: React.FC<props> = ({ column_id, card, handler }) => {
  const { t } = useTranslation(['translation', 'leads'])
  const [form] = Form.useForm()

  const onSuccess = async (response: AxiosResponse<IOrderCard>) => {
    const columns = queryClient.getQueryData<IOrderColumn[]>(LEADS_KEY)
    queryClient.setQueryData(
      LEADS_KEY,
      columns?.map(column => {
        if (column.id === response.data.column_id) {
          return {
            ...column,
            cards: card
              ? column.cards.map(c => (c.id === response.data.id ? response.data : c))
              : [response.data, ...column.cards],
          }
        }
        return column
      }),
    )
    form.resetFields()
    handlerFunction()
  }
  const onError = () => {
    message.error(t('responseError'))
  }
  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(addCardApi, {
    onSuccess,
    onError,
  })
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(editCardApi, {
    onSuccess,
    onError,
  })

  const handlerFunction = () => {
    handler && handler((state: boolean) => !state)
  }

  const renderSuffix = () => {
    if (isCreateLoading || isUpdateLoading) return <LoadingOutlined />
    else if (card) {
      return (
        <Tooltip title={t('cancel')}>
          <CloseCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} onClick={handlerFunction} />
        </Tooltip>
      )
    }

    return <AppstoreAddOutlined className={styles.submit} onClick={form.submit} />
  }

  const onFinish = (item: IOrderCard) => {
    const values = { ...item, sort: card?.sort || 0 }
    return card ? update(values) : create(values)
  }

  return (
    <Form form={form} onFinish={onFinish} initialValues={card} name={uuid()} layout={'vertical'}>
      <Form.Item name="name" rules={[{ required: true, message: '' }]}>
        <Input
          autoFocus={card && true}
          required={true}
          className={styles.input}
          placeholder={t('leads:addCard')}
          suffix={renderSuffix()}
          disabled={isCreateLoading || isUpdateLoading}
        />
      </Form.Item>

      <Form.Item hidden={true} name={'id'}>
        <Input />
      </Form.Item>
      <Form.Item hidden={true} name={'sort'}>
        <Input />
      </Form.Item>
      <Form.Item hidden={true} initialValue={column_id} name="column_id">
        <Input />
      </Form.Item>

      <Form.Item hidden={true}>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LeadsCardForm
