import React from 'react'
import { Card, Table } from 'antd'
import { IQuestionBase } from '../../../../../interfaces'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/es/table'
import { idType } from '../../../../../interfaces/types/types'
import { Link } from 'react-router-dom'
import TeacherLink from '../../../../core/teacher-link/teacher-link'
import TableMobileTitle from '../../../../core/table-mobile-title/table-mobile-title'
import TestActions from '../test-actions/test-actions'

interface props {
  tests: IQuestionBase[]
}

const TestsTable: React.FC<props> = ({ tests }) => {
  const { t } = useTranslation(['translation', 'testing'])

  const columns: ColumnsType<IQuestionBase> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: idType) => (
        <>
          <TableMobileTitle title={'ID'} /># {text}
        </>
      ),
    },
    {
      title: t('name'),
      key: 'title',
      render: ({ id, title }: IQuestionBase) => (
        <>
          <TableMobileTitle title={t('name')} />
          <Link to={'/testing/tests/details/' + id}>{title}</Link>
        </>
      ),
    },
    {
      title: t('teacher'),
      key: 'user',
      render: ({ user }: IQuestionBase) => (
        <>
          <TableMobileTitle title={t('teacher')} />
          {user && <TeacherLink id={user.id} name={user.name} />}
        </>
      ),
    },
    {
      title: t('createdAt'),
      dataIndex: 'created_at',
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('createdAt')} />
          {text}
        </>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      render: ({ id, title, user_id }: IQuestionBase) => (
        <TestActions testId={id} title={title} teacherId={user_id} />
      ),
    },
  ]

  return (
    <Card bordered={false}>
      <Table
        className={'adaptive-table'}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
        dataSource={tests}
        rowKey={'id'}
      />
    </Card>
  )
}

export default TestsTable
