import React from 'react'
import { Button, Form, Input } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

interface props {
  submitStatus: (values: any) => void
  comment?: string
}

const GroupReasonAttendanceForm: React.FC<props> = ({ submitStatus, comment }) => {
  const { t } = useTranslation()
  return (
    <Form layout={'inline'} onFinish={submitStatus} initialValues={{ comment, status: 'reason' }}>
      <Form.Item name={'status'} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={'comment'} rules={[{ required: true, message: t('required') }]}>
        <Input placeholder={t('goodReasonExample')} />
      </Form.Item>
      <Button type={'primary'} htmlType={'submit'}>
        <CheckCircleOutlined />
      </Button>
    </Form>
  )
}

export default GroupReasonAttendanceForm
