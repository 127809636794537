import React, { useEffect } from 'react'
import Auth from '../../app/modules/auth/auth'
import { useHistory } from 'react-router-dom'
import { ACCESS_TOKEN } from '../../constants/auth'

interface props {}

const AuthPage: React.FC<props> = () => {
  const { replace } = useHistory()

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      return replace('/dashboard')
    }
  }, [replace])

  return (
    <React.Fragment>
      <Auth />
    </React.Fragment>
  )
}

export default AuthPage
