import { useTranslation } from 'react-i18next'

export function useDays() {
  const { t } = useTranslation()

  return [
    {
      label: t('mon'),
      value: 'mon',
    },
    {
      label: t('tue'),
      value: 'tue',
    },
    {
      label: t('wed'),
      value: 'wed',
    },
    {
      label: t('thi'),
      value: 'thi',
    },
    {
      label: t('fri'),
      value: 'fri',
    },
    {
      label: t('sat'),
      value: 'sat',
    },
    {
      label: t('sun'),
      value: 'sun',
    },
  ]
}
