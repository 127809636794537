import React, { useState } from 'react'
import { Card, Divider, Tabs } from 'antd'
import FinanceReportInfo from '../../modules/finances/finance-report-info/finance-report-info'
import FinanceTeacherFilter from '../../modules/finances/finances-teachers/finance-teacher-filter/finance-teacher-filter'
import FinanceTeacherStudents from '../../modules/finances/finances-teachers/finance-teacher-students/finance-teacher-students'
import FinanceTeacherGroups from '../../modules/finances/finances-teachers/finance-teacher-groups/finance-teacher-groups'
import FinanceTeacherReport from '../../modules/finances/finances-teachers/finance-teacher-report/finance-teacher-report'
import FinanceTeacherSummary from '../../modules/finances/finances-teachers/finance-teacher-summary/finance-teacher-summary'
import { idType } from '../../../interfaces/types/types'
import {
  IFinanceTeacherGroup,
  IFinanceTeacherSalary,
  IFinanceTeacherStudent,
  IFinanceTeacherSummary,
  IFinanceTeacherTotal,
} from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import { getQueryParam } from '../../../lib/getQueryParam'
import { useHistory } from 'react-router-dom'
import { serialize } from '../../../lib/serializeQuery'

const { TabPane } = Tabs

interface props {
  teacherId: idType
  totals: IFinanceTeacherTotal
  students: IFinanceTeacherStudent[]
  groupsSummaries: IFinanceTeacherGroup[]
  salaries: IFinanceTeacherSalary[]
  teacherSummaries: IFinanceTeacherSummary[]
}

const FinanceTeacherInfo: React.FC<props> = ({
  teacherId,
  teacherSummaries,
  groupsSummaries,
  totals,
  salaries,
  students,
}) => {
  const [activeKey, setActiveKey] = useState<string>(getQueryParam('tab') || 'students')
  const { push } = useHistory()

  const activeKeyHandler = (key: string) => {
    push({
      search: serialize('tab', key),
    })

    setActiveKey(activeKey)
  }

  const { t } = useTranslation()

  return (
    <Card bordered={false}>
      <FinanceReportInfo />
      <Divider type={'horizontal'} />
      <FinanceTeacherFilter teacher_id={teacherId} totals={totals} />
      <Tabs defaultActiveKey={activeKey} onChange={activeKeyHandler}>
        <TabPane tab={t('students')} key={'students'}>
          <FinanceTeacherStudents students={students} />
        </TabPane>
        <TabPane tab={t('groups')} key={'groups'}>
          <FinanceTeacherGroups groups={groupsSummaries} />
        </TabPane>
        <TabPane tab={t('salaries')} key={'salaries'}>
          <FinanceTeacherReport teacherId={teacherId} salaries={salaries} />
        </TabPane>
        <TabPane tab={t('summary')} key={'summary'}>
          <FinanceTeacherSummary summary={teacherSummaries} />
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default FinanceTeacherInfo
