import React from 'react'
import { useFinancesGroup } from '../../../../../hooks/query/groups'
import { Spin } from 'antd'
import ErrorAlert from '../../../../core/error-alert/error-alert'
import FinancesGroupInfo from '../../../../core/finances-group-info/finances-group-info'
import { useParams } from 'react-router-dom'

interface props {}

const GroupFinanceDrawerReport: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { isLoading, isSuccess, isError, error, data } = useFinancesGroup(id)

  return (
    <Spin spinning={isLoading}>
      {isError && <ErrorAlert msg={error?.message} />}
      {isSuccess && (
        <FinancesGroupInfo
          groupId={id}
          groupStartDate={data!.group.start_date}
          groupEndDate={data!.group.end_date}
          groupTotals={data!.totals}
          payments={data!.payments}
          students={data!.students}
          summaries={data!.summaries}
        />
      )}
    </Spin>
  )
}

export default GroupFinanceDrawerReport
