import React from 'react'
import { idType } from '../../../../interfaces/types/types'
import { useGroupEventResults } from '../../../../hooks/query/groups'
import { Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import { ITestResult } from '../../../../interfaces'
import { useAuthUserInformation } from '../../../../hooks/query/auth'
import { PlayCircleOutlined } from '@ant-design/icons'
import LinkButton from '../../../core/link-button/link-button'
import EventResultPercent from '../../../core/event-result-percent/event-result-percent'
import EventResultTable from '../../../core/event-result-table/event-result-table'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'
import EventAllResultsModal from '../../testing/events/event-all-results-modal/event-all-results-modal'

interface props {
  eventId: idType
  groupId: idType
}

const GroupEventResultTable: React.FC<props> = ({ eventId, groupId }) => {
  const { data: authenticatedUser } = useAuthUserInformation()
  const { isFetching, data } = useGroupEventResults(groupId, eventId)
  const { t } = useTranslation()
  const { roleChecker } = useRoleChecker()

  const detailsAccess = roleChecker(['admin', 'reception', 'teacher'])
  const isStudent = roleChecker(['student'])

  const columns = [
    {
      title: '#',
      key: 'index',
      render: (record: ITestResult, result: ITestResult, index: number) => (
        <React.Fragment>
          <TableMobileTitle title={'#'} />
          <span>{index + 1}</span>
        </React.Fragment>
      ),
    },
    {
      title: t('firstName'),
      dataIndex: 'name',
      render: (text: string) => (
        <React.Fragment>
          <TableMobileTitle title={t('firstName')} />
          {text}
        </React.Fragment>
      ),
    },
    {
      title: t('testing:rightAnswers'),
      dataIndex: 'correct_answers',
      render: (c: number | null) => (
        <React.Fragment>
          <TableMobileTitle title={t('testing:rightAnswers')} />
          {c !== null ? c : t('noResults')}
        </React.Fragment>
      ),
    },
    {
      title: t('testing:wrongAnswers'),
      dataIndex: 'incorrect_answers',
      render: (ic: number | null) => (
        <React.Fragment>
          <TableMobileTitle title={t('testing:wrongAnswers')} />
          {ic !== null ? ic : t('noResults')}
        </React.Fragment>
      ),
    },
    {
      title: t('result'),
      dataIndex: 'correct_percent',
      render: (percent: number | null) => (
        <React.Fragment>
          <TableMobileTitle title={t('result')} />
          {percent !== null ? <EventResultPercent percent={percent} /> : t('noResults')}
        </React.Fragment>
      ),
    },
    {
      title: t('testing:timeTakenToSolveTheTest'),
      dataIndex: 'spent_time',
      render: (spent_time: string | null) => (
        <React.Fragment>
          <TableMobileTitle title={t('testing:timeTakenToSolveTheTest')} />
          {spent_time ? spent_time : t('noResults')}
        </React.Fragment>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      render: ({ user_id, id, name }: ITestResult) => (
        <Space>
          {id && detailsAccess && (
            <React.Fragment>
              <EventAllResultsModal studentName={name} studentId={user_id} testId={id} />
            </React.Fragment>
          )}

          {id && (user_id === authenticatedUser?.id || detailsAccess) ? (
            <LinkButton pathname={'/testing/results/details/' + id}>{t('moreDetails')}</LinkButton>
          ) : null}

          {isStudent && user_id === authenticatedUser?.id ? (
            <LinkButton pathname={'/testing/events/details/' + eventId}>
              <PlayCircleOutlined /> <span>{t('begin')}</span>
            </LinkButton>
          ) : null}
        </Space>
      ),
    },
  ]

  return <EventResultTable columns={columns} dataSource={data} isLoading={isFetching} />
}

export default GroupEventResultTable
