import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styles from '../../../hoc/public-layout/public-layout.module.scss'
import AuthForm from './auth-form/auth-form'

interface props {}

const Auth: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'auth'])

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('authorization')}</title>
      </Helmet>
      <h2 className={styles.title}>
        {t('auth:greeting')} <span>EDUCRM</span>
      </h2>
      <h4 className={styles.subtitle}>{t('auth:message')}</h4>
      <AuthForm />
    </React.Fragment>
  )
}

export default Auth
