import React, { useState } from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { IExpenseType } from '../../../../interfaces'
import { EditOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'
import { updateExpenseType } from '../../../../api/expense-types'
import { queryClient } from '../../../../index'
import { EXPENSE_TYPE_KEY } from '../../../../hooks/query/expense-types'

interface props {
  expenseType: IExpenseType
}

const EditExpenseType: React.FC<props> = ({ expenseType }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const handleOpenChange = () => setOpen(!open)

  const { isLoading, mutateAsync } = useMutation(updateExpenseType, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(EXPENSE_TYPE_KEY)
      message.success(t('responseSuccess'))
      handleOpenChange()
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const onFinish = (data: Pick<IExpenseType, 'title' | 'id'>) => mutateAsync(data)

  const title = t('edit')
  const formId = 'edit-expense-form'
  return (
    <React.Fragment>
      <Button type={'primary'} onClick={handleOpenChange} icon={<EditOutlined />}>
        {title}
      </Button>
      <Modal
        title={title}
        visible={open}
        onCancel={handleOpenChange}
        destroyOnClose
        okButtonProps={{
          htmlType: 'submit',
          form: formId,
          disabled: isLoading,
          loading: isLoading,
        }}
      >
        <Form id={formId} onFinish={onFinish} layout={'vertical'} initialValues={expenseType}>
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={'title'} label={t('name')} rules={[{ required: true }]}>
            <Input placeholder={t('inputName')} />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default EditExpenseType
