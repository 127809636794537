import React from 'react'
import Head from '../../../../hoc/head/head'
import { PageHeader, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import TestingFilterForm from '../testing-filter-form/testing-filter-form'
import { QUESTION_BASES_KEY, useQuestionBases } from '../../../../hooks/query/question-bases'
import ErrorAlert from '../../../core/error-alert/error-alert'
import TestsTable from './tests-table/tests-table'
import MyPagination from '../../../core/my-pagination/my-pagination'
import TestModal from './test-modal/test-modal'

interface props {}

const Tests: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'testing'])
  const { isLoading, isError, error, data } = useQuestionBases()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { data: tests, ...pagination } = data!

  return (
    <Head title={t('tests')}>
      <PageHeader
        title={t('tests')}
        subTitle={t('testing:testingSubtitle')}
        onBack={() => window.history.back()}
        extra={[<TestModal key={'1'} />]}
      />
      <TestingFilterForm courseFilter={false} fetchingQueryKey={QUESTION_BASES_KEY} />
      <TestsTable tests={tests} />
      <MyPagination fetchingQueryKey={QUESTION_BASES_KEY} pagination={pagination} />
    </Head>
  )
}

export default Tests
