import React from 'react'
import { Table, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../../interfaces/types/types'
import { IFinanceTeacherStudent } from '../../../../../interfaces'
import { Link } from 'react-router-dom'
import FinancesGroupDifference from '../../finance-difference/finances-difference'
import { priceFormatter } from '../../../../../lib/priceFormatter'
import isEqual from 'react-fast-compare'
import StudentLink from '../../../../core/student-link/student-link'

interface props {
  students: IFinanceTeacherStudent[]
}

const FinanceTeacherStudents: React.FC<props> = ({ students }) => {
  const { t } = useTranslation()

  const columns = [
    {
      title: t('id'),
      key: 'id',
      render: (text: idType, record: IFinanceTeacherStudent, index: number) => (
        <span># {index + 1}</span>
      ),
    },
    {
      title: t('firstName'),
      key: 'name',
      render: ({ student_id, name }: IFinanceTeacherStudent) => (
        <StudentLink studentId={student_id} studentName={name} />
      ),
    },
    {
      title: t('group'),
      key: 'group',
      render: ({ group_name, group_id }: IFinanceTeacherStudent) => (
        <Link to={`/groups/details/${group_id}`}>
          <Tag color={'blue'}>{group_name}</Tag>
        </Link>
      ),
    },
    {
      title: t('numberOfLessons'),
      dataIndex: 'lessons_count',
    },
    {
      title: t('missedForReason'),
      dataIndex: 'reasons_count',
    },
    {
      title: t('paidUp'),
      dataIndex: 'paid',
      render: (number: number) => <span>{priceFormatter(number)}</span>,
    },
    {
      title: t('totalPayment'),
      dataIndex: 'total',
      render: (number: number) => <span>{priceFormatter(number)}</span>,
    },
    {
      title: t('studentBalance'),
      dataIndex: 'difference',
      render: (text: number) => <FinancesGroupDifference difference={text} />,
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={students}
      scroll={{ x: true }}
      pagination={false}
      rowKey={'id'}
    />
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps.students, nextProps.students)
}

export default React.memo(FinanceTeacherStudents, propsAreEqual)
