import React from 'react'
import { Button, DatePicker, Form, message } from 'antd'
import moment from 'moment'
import { FilterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../../../interfaces/types/types'
import { useMutation } from 'react-query'
import { getFinancesGroupByIdApi } from '../../../../../../api/finances'
import { AxiosResponse } from 'axios'
import { IFinanceGroupResponse } from '../../../../../../interfaces'
import { queryClient } from '../../../../../../index'
import { GROUPS_FINANCES_KEY } from '../../../../../../hooks/query/groups'
import { useHistory } from 'react-router-dom'
import { serialize } from '../../../../../../lib/serializeQuery'
import { getQueryParam } from '../../../../../../lib/getQueryParam'

interface props {
  startDate: string
  groupId: idType
  endDate: string
}

const FinanceGroupFilterForm: React.FC<props> = ({ groupId, endDate, startDate }) => {
  const { t } = useTranslation()
  const { push } = useHistory()

  const { isLoading, mutateAsync } = useMutation(getFinancesGroupByIdApi, {
    onSuccess: async (response: AxiosResponse<IFinanceGroupResponse>) => {
      await queryClient.setQueryData([GROUPS_FINANCES_KEY, groupId!.toString()], response.data)
      message.success(t('responseSuccess'))
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const onFinish = (values: any) => {
    push({
      search: serialize('date', moment(values['date']).format('YYYY-MM-DD')),
    })

    return mutateAsync(groupId)
  }

  const initialDate = getQueryParam('date')

  return (
    <Form onFinish={onFinish}>
      <h2>{t('chooseMonth')}</h2>
      <Form.Item
        name={'date'}
        rules={[{ required: true, message: t('required') }]}
        initialValue={initialDate ? moment(initialDate, 'YYYY-MM-DD') : undefined}
      >
        <DatePicker
          picker={'month'}
          format={'MMMM, YYYY'}
          disabledDate={date =>
            date < moment(startDate).subtract(1, 'month') ||
            date > moment(endDate).add(1, 'month') ||
            date > moment().add(1, 'month').endOf('month')
          }
          allowClear={false}
          style={{ width: `100%` }}
        />
      </Form.Item>
      <Form.Item>
        <Button block type={'primary'} htmlType={'submit'} disabled={isLoading}>
          <FilterOutlined /> {t('filter')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default FinanceGroupFilterForm
