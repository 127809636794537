import React, { useState } from 'react'
import FinanceReportInfo from '../../modules/finances/finance-report-info/finance-report-info'
import { Card, Divider, Tabs } from 'antd'
import FinancesGroupFilter from '../../modules/finances/finances-groups/finance-group-filter/finances-group-filter'
import FinancesGroupStudents from '../../modules/finances/finances-groups/finances-group-students/finances-group-students'
import FinancesGroupReport from '../../modules/finances/finances-groups/finances-group-report/finances-group-report'
import FinanceGroupSummary from '../../modules/finances/finances-groups/finance-group-summary/finance-group-summary'
import { idType } from '../../../interfaces/types/types'
import {
  IFinanceGroupPayment,
  IFinanceGroupStudents,
  IFinanceGroupSummary,
  IFinanceTotal,
} from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import { getQueryParam } from '../../../lib/getQueryParam'
import { useHistory } from 'react-router-dom'
import { serialize } from '../../../lib/serializeQuery'

const { TabPane } = Tabs

interface props {
  groupId: idType
  groupEndDate: string
  groupStartDate: string
  groupTotals: IFinanceTotal
  students: IFinanceGroupStudents[]
  payments: IFinanceGroupPayment[]
  summaries: IFinanceGroupSummary[]
}

const FinancesGroupInfo: React.FC<props> = ({
  groupId,
  groupStartDate,
  groupTotals,
  groupEndDate,
  payments,
  summaries,
  students,
}) => {
  const [activeKey, setActiveKey] = useState<string>(getQueryParam('tab') || 'payments')
  const { push } = useHistory()

  const activeKeyHandler = (key: string) => {
    push({
      search: serialize('tab', key),
    })

    setActiveKey(key)
  }

  const { t } = useTranslation()

  return (
    <Card bordered={false}>
      <FinanceReportInfo />
      <Divider type={'horizontal'} />
      <FinancesGroupFilter
        groupId={groupId}
        endDate={groupEndDate}
        startDate={groupStartDate}
        totals={groupTotals}
      />
      <Tabs defaultActiveKey={activeKey} onChange={activeKeyHandler}>
        <TabPane tab={t('payments')} key="payments">
          <FinancesGroupReport
            payments={payments}
            groupId={groupId}
            groupStartDate={groupStartDate}
            groupEndDate={groupEndDate}
          />
        </TabPane>
        <TabPane tab={t('students')} key="students">
          <FinancesGroupStudents totals={groupTotals} month_reports={students} />
        </TabPane>
        <TabPane tab={t('summary')} key="summary">
          <FinanceGroupSummary summary={summaries} />
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default FinancesGroupInfo
