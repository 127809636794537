import React, { useEffect } from 'react'
import LeadsColumns from './leads-columns/leads-columns'
import ErrorAlert from '../../core/error-alert/error-alert'
import { Skeleton } from 'antd'
import Head from '../../../hoc/head/head'
import { useTranslation } from 'react-i18next'
import { LEADS_KEY, useLeads } from '../../../hooks/query/leads'
import LeadsColumnModal from './leads-columns/leads-column/leads-column-modal/leads-column-modal'
import SearchInputHandler from '../../core/search-input-handler/search-input-handler'
import styles from './leads.module.scss'

interface props {}

const Leads: React.FC<props> = () => {
  // const echo = useContext(SocketContext)
  const { t } = useTranslation(['translation', 'leads'])

  useEffect(() => {
    // echo?.private('lead').listen('LeadUpdated', (e: IOrderColumn[]) => {
    //   queryClient.setQueryData(LEADS_KEY, e)
    // })
  }, [])

  const { isLoading, isError, error, data } = useLeads()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  return (
    <Head title={t('leads')}>
      <div className={styles.header}>
        <SearchInputHandler placeholder={t('leads:search')} fetchingQueryKey={LEADS_KEY} />
        <LeadsColumnModal />
      </div>
      <LeadsColumns columns={data!} />
    </Head>
  )
}

export default Leads
