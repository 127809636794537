import React from 'react'
import { message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@ant-design/icons'
import { idType } from '../../../../../../interfaces/types/types'
import { useMutation } from 'react-query'
import { deleteColumnApi } from '../../../../../../api/leads'
import { queryClient } from '../../../../../../index'
import { LEADS_KEY } from '../../../../../../hooks/query/leads'
import { IOrderColumn } from '../../../../../../interfaces'

interface props {
  id: idType
}

const LeadsColumnDelete: React.FC<props> = ({ id }) => {
  const [visible, setVisible] = React.useState(false)
  const modalHandler = () => setVisible(!visible)

  const { t } = useTranslation(['translation, leads'])

  const { isLoading, mutateAsync } = useMutation(deleteColumnApi, {
    onSuccess: () => {
      const columns = queryClient.getQueryData<IOrderColumn[]>(LEADS_KEY)
      queryClient.setQueryData(
        LEADS_KEY,
        columns?.filter(column => column.id !== id),
      )
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const handleOk = async () => mutateAsync(id)

  return (
    <>
      <button className={'ant-btn-no-style'} onClick={modalHandler}>
        <DeleteOutlined /> <span>{t('translation:delete')}</span>
      </button>
      <Modal
        title={t('translation:delete')}
        visible={visible}
        onOk={handleOk}
        okText={t('translation:yes')}
        cancelText={t('translation:cancel')}
        confirmLoading={isLoading}
        onCancel={modalHandler}
      >
        {t('leads:deleteMessage')}
      </Modal>
    </>
  )
}

export default LeadsColumnDelete
