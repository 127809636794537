import React, { useState } from 'react'
import { useExpenseTypes } from '../../../hooks/query/expense-types'
import ErrorBoundary from '../../../hoc/error-boundary'
import { Divider, Input, PageHeader, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/es/table'
import { IExpenseType } from '../../../interfaces'
import moment from 'moment'
import EditExpenseType from '../../../app/modules/references/edit-expense-type/edit-expense-type'

interface props {}

const ExpensesTypesPage: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'references'])
  const { data, isLoading, isError } = useExpenseTypes()
  const [value, setValue] = useState<string>('')
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)

  if (isError) return <ErrorBoundary />

  const columns: ColumnsType<IExpenseType> = [
    {
      dataIndex: 'id',
      title: 'ID',
    },
    {
      dataIndex: 'title',
      title: t('name'),
    },
    {
      dataIndex: 'created_at',
      title: t('createdAt'),
      render: (date: string) => moment(date).format('YYYY-MM-DD'),
    },
    {
      key: 'actions',
      title: t('actions'),
      align: 'right',
      render: (expenseType: IExpenseType) => <EditExpenseType expenseType={expenseType} />,
    },
  ]

  return (
    <React.Fragment>
      <PageHeader title={t('references:expensesTypesReference')} />
      <Input.Search
        value={value}
        onChange={handleValueChange}
        disabled={isLoading}
        loading={isLoading}
        placeholder={t('search')}
      />
      <Divider />
      <Table
        loading={isLoading}
        rowKey={'id'}
        scroll={{ x: true }}
        dataSource={data?.filter(({ title }) =>
          title.toLowerCase().includes(value.trim().toLowerCase()),
        )}
        columns={columns}
      />
    </React.Fragment>
  )
}

export default ExpensesTypesPage
