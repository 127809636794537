import React, { useState } from 'react'
import { Button, Divider, Drawer, Form, Input, message, Switch } from 'antd'
import DrawerFooter from '../../../core/drawer/drawer-footer/drawer-footer'
import { useMutation } from 'react-query'
import { IBranch } from '../../../../interfaces'
import { useTranslation } from 'react-i18next'
import DrawerOpen from '../../../core/drawer/drawer-open/drawer-open'
import { queryClient } from '../../../../index'
import { BRANCHES_KEY } from '../../../../hooks/query/branches'
import { createBranch, updateBranch } from '../../../../api/branches'
import { drawerSettings } from '../../../../lib/settings'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import PhoneInput from '../../../core/form-group/phone-input/phone-input'

interface props {
  branch?: IBranch
  title: 'add' | 'edit'
}

const BranchesDrawer: React.FC<props> = ({ branch, title }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState<boolean>(false)
  const changeVisibleHandler = () => setVisible(!visible)

  const onSuccess = () => {
    message.success(t('responseSuccess'))
    queryClient.invalidateQueries(BRANCHES_KEY)
    changeVisibleHandler()
  }
  const onError = () => {
    message.error(t('responseError'))
  }

  const create = useMutation(createBranch, { onSuccess, onError })
  const update = useMutation(updateBranch, { onSuccess, onError })

  const onFinish = (values: IBranch) =>
    branch ? update.mutateAsync(values) : create.mutateAsync(values)

  const formId = 'branches-form'
  return (
    <React.Fragment>
      <DrawerOpen drawerToggle={changeVisibleHandler} edit={branch} />
      <Drawer
        {...drawerSettings}
        visible={visible}
        title={t('branches:' + title)}
        onClose={changeVisibleHandler}
        footer={
          <DrawerFooter
            drawerToggle={changeVisibleHandler}
            formId={formId}
            disabled={create.isLoading || update.isLoading}
          />
        }
      >
        <Form
          id={formId}
          onFinish={onFinish}
          layout={'vertical'}
          initialValues={{
            active: true,
            ...branch,
          }}
        >
          <Form.Item hidden name={'id'}>
            <Input />
          </Form.Item>
          <Form.Item name={'title'} label={t('name')} rules={[{ required: true }]}>
            <Input placeholder={t('inputName')} />
          </Form.Item>
          <Form.Item name={'address'} label={t('address')} rules={[{ required: true }]}>
            <Input placeholder={t('inputAddress')} />
          </Form.Item>

          <Form.List name={'phones'}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey }) => (
                  <PhoneInput
                    key={key}
                    name={name}
                    label={''}
                    required={true}
                    rest={{
                      addonAfter: <MinusCircleOutlined onClick={() => remove(name)} />,
                    }}
                  />
                ))}
                <Button type={'dashed'} block onClick={() => add()} icon={<PlusCircleOutlined />}>
                  {t('addPhoneNumber')}
                </Button>
              </>
            )}
          </Form.List>

          <Divider />
          <Form.Item name={'active'} label={t('status')} valuePropName={'checked'}>
            <Switch checkedChildren={t('branches:open')} unCheckedChildren={t('branches:close')} />
          </Form.Item>
        </Form>
      </Drawer>
    </React.Fragment>
  )
}

export default BranchesDrawer
