import React from 'react'
import { Link } from 'react-router-dom'
import { idType } from '../../../interfaces/types/types'

interface props {
  id: idType
  title: string
}

const EventLink: React.FC<props> = ({ id, title }) => {
  return <Link to={'/testing/events/details/' + id}>{title}</Link>
}

export default React.memo(EventLink)
