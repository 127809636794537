import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Divider, Form, message, Modal, Space } from 'antd'
import { WalletOutlined } from '@ant-design/icons'
import SumInput from '../form-group/sum-input/sum-input'
import { idType } from '../../../interfaces/types/types'
import { useMutation } from 'react-query'
import { setIndividualPriceForStudentApi } from '../../../api/groups'
import { queryClient } from '../../../index'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

interface props {
  studentId: idType
  studentName: string
  groupId: idType
  price: number
  individualPriceQueries: Array<string | string[]>
}

const IndividualPriceModal: React.FC<props> = ({
  studentName,
  studentId,
  groupId,
  price,
  individualPriceQueries,
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const modalHandler = () => setVisible(!visible)

  const [coursePrice, setCoursePrice] = useState<boolean>(false)
  const coursePriceHandler = (e: CheckboxChangeEvent) => setCoursePrice(e.target.checked)
  const { t } = useTranslation()

  const { isLoading, mutateAsync } = useMutation(setIndividualPriceForStudentApi, {
    onSuccess: async () => {
      const queries = individualPriceQueries.map(q => queryClient.invalidateQueries(q))
      Promise.all(queries).finally(() => {
        message.success(t('responseSuccess'))
        setCoursePrice(false)
        modalHandler()
      })
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const onFinish = ({ price }: any) =>
    mutateAsync({
      groupId,
      studentId,
      price: coursePrice ? null : price,
      set_course_price: coursePrice,
    })
  const title = t('inputIndividualPrice')

  return (
    <>
      <button onClick={modalHandler} className={'ant-btn-no-style'}>
        <WalletOutlined /> <span>{title}</span>
      </button>
      <Modal
        visible={visible}
        destroyOnClose={true}
        onCancel={modalHandler}
        title={
          <Space split={<Divider type={'vertical'} />}>
            <span>{title}</span>
            <span>{studentName}</span>
          </Space>
        }
        okText={t('submit')}
        okButtonProps={{ htmlType: 'submit', form: 'individual-price-form' }}
        confirmLoading={isLoading}
        centered
      >
        <Form
          id={'individual-price-form'}
          onFinish={onFinish}
          layout={'vertical'}
          initialValues={{ price }}
        >
          <SumInput
            name={'price'}
            label={t('individualPrice')}
            disabled={coursePrice}
            rest={{
              rules: [{ required: !coursePrice }],
            }}
          />
          <Divider>{t('or')}</Divider>
          <Checkbox checked={coursePrice} onChange={coursePriceHandler}>
            Указать стоимость курса
          </Checkbox>
        </Form>
      </Modal>
    </>
  )
}

export default IndividualPriceModal
