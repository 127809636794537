import { idType } from '../interfaces/types/types'
import { request as axios } from './index'

const url = '/testing'

export const getCurrentQuestionApi = (id: idType) => {
  return axios.get(url + '/' + id + '/current-question')
}

export const saveAnswerApi = ({
  variantId,
  questionId,
}: {
  questionId: idType
  variantId: idType | null
}) => {
  return axios.post(url + '/' + questionId + '/save-answer', {
    variant_id: variantId,
  })
}

export const getLatestTestResultApi = (testId: idType) => {
  return axios.get(url + '/' + testId + '/latest-result')
}

export const markQuestion = (questionId: idType) => {
  return axios.patch(url + '/' + questionId + '/mark')
}
