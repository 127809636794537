import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import isEqual from 'react-fast-compare'

interface props {
  days: string[]
}

const GroupDays: React.FC<props> = ({ days }) => {
  const { t } = useTranslation()

  const renderDays = days.map((day: string) => (
    <Tag color="blue" key={day}>
      {t(day)}
    </Tag>
  ))

  return <React.Fragment>{renderDays}</React.Fragment>
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps, nextProps)
}

export default React.memo(GroupDays, propsAreEqual)
