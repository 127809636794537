import { request as axios } from './index'
import { idType } from '../interfaces/types/types'
import { IOrderBid, IOrderCard, IOrderColumn } from '../interfaces'

export function getAllLeadsApi() {
  return axios.get(`/leads`)
}

export function getColumnsListApi() {
  return axios.get('/columns/list')
}

export function addColumnApi(column: IOrderColumn) {
  return axios.post(`/columns`, column)
}

export function deleteColumnApi(id: idType) {
  return axios.delete(`/columns/${id}`)
}

export function editColumnApi(column: IOrderColumn) {
  return axios.patch(`/columns/${column.id}`, column)
}

export function getCardsListApi() {
  return axios.get('/cards/list')
}

export function addCardApi(card: IOrderCard) {
  return axios.post(`/cards`, card)
}

export function editCardApi(card: IOrderCard) {
  return axios.patch(`/cards/${card.id}`, card)
}

export function deleteCardApi(id: idType) {
  return axios.delete(`/cards/${id}`)
}

export function addBidApi(bid: IOrderBid) {
  return axios.post(`/bids`, bid)
}

export function editBidApi(bid: IOrderBid) {
  return axios.patch(`/bids/${bid.id}`, bid)
}

export function deleteBidApi(id: idType) {
  return axios.delete(`/bids/${id}`)
}

// DND
export function cardsDndApi(cards: IOrderCard[]) {
  return axios.patch(`/leads/cards`, {
    cards,
  })
}

export function bidsDndApi(bids: IOrderBid[]) {
  return axios.patch('/leads/bids', {
    bids,
  })
}

export function createStudentFromBidApi(data: { name: string; phone: string }) {
  return axios.post('/bids/create-student', data)
}
