import { DropResult } from 'react-beautiful-dnd'
import { IOrderColumn } from '../interfaces'
import { Button, notification } from 'antd'
import { AlertOutlined } from '@ant-design/icons'
import { queryClient } from '../index'
import { LEADS_KEY } from './query/leads'
import { bidsDndApi, cardsDndApi } from '../api/leads'
import { getCard, getColumn, innerDndReorder, outerDndReorder } from '../lib/dndFunctions'

const openNotification = () => {
  notification.error({
    message: 'Ошибка!',
    type: 'error',
    duration: null,
    icon: <AlertOutlined style={{ color: 'red' }} />,
    description:
      'Возможно, у вас возникли проблемы с интернетом. Последние изменения не будут сохранены в системе. Пожалуйста, обновите страницу и попробуйте снова.',
    btn: (
      <Button type={'primary'} danger size={'large'} onClick={() => window.location.reload()}>
        Обновить страницу
      </Button>
    ),
  })
}

export function useOnDragEnd() {
  return function onDragEnd(result: DropResult, columns: IOrderColumn[]) {
    const { source, destination } = result
    if (!destination) return

    if (result.type === 'droppableCard') {
      if (source.droppableId !== destination.droppableId) {
        const sourceColumn = getColumn(columns, source.droppableId)
        const destColumn = getColumn(columns, destination.droppableId)

        const [sortedSourceItems, sortedDestItems] = outerDndReorder(
          sourceColumn.cards,
          destColumn.cards,
          'column_id',
          source.index,
          destination.index,
          destination.droppableId,
        )

        const newSourceColumn = { ...sourceColumn, cards: sortedSourceItems }
        const newDestColumn = { ...destColumn, cards: sortedDestItems }

        queryClient.setQueryData(
          LEADS_KEY,
          columns.map(oldColumn => {
            if (newSourceColumn.id === oldColumn.id) {
              return newSourceColumn
            } else if (newDestColumn.id === oldColumn.id) {
              return newDestColumn
            }
            return oldColumn
          }),
        )

        return cardsDndApi([...sortedSourceItems, ...sortedDestItems]).catch(e =>
          openNotification(),
        )
      } else {
        const column = getColumn(columns, source.droppableId)

        const cards = innerDndReorder(column.cards, source.index, destination.index)

        const newColumn = { ...column, cards }

        queryClient.setQueryData(
          LEADS_KEY,
          columns.map(oldColumn => {
            if (oldColumn.id === newColumn.id) {
              return newColumn
            }
            return oldColumn
          }),
        )

        return cardsDndApi(cards).catch(e => openNotification())
      }
    } else if (result.type === 'droppableBids') {
      if (source.droppableId !== destination.droppableId) {
        let [sourceCard, destCard] = getCard(columns, source.droppableId, destination.droppableId)

        const [sortedSourceBids, sortedDestBids] = outerDndReorder(
          sourceCard.bids,
          destCard.bids,
          'card_id',
          source.index,
          destination.index,
          destination.droppableId,
        )

        const newSourceCard = { ...sourceCard, bids: sortedSourceBids }
        const newDestCard = { ...destCard, bids: sortedDestBids }

        queryClient.setQueryData(
          LEADS_KEY,
          columns.map(column => {
            if (
              newSourceCard.column_id === newDestCard.column_id &&
              newDestCard.column_id === column.id
            ) {
              return {
                ...column,
                cards: column.cards.map(card => {
                  if (card.id === newSourceCard.id) return newSourceCard
                  else if (card.id === newDestCard.id) return newDestCard
                  return card
                }),
              }
            } else {
              if (column.id === newSourceCard.column_id) {
                return {
                  ...column,
                  cards: column.cards.map(card => {
                    if (card.id === newSourceCard.id) {
                      return newSourceCard
                    }
                    return card
                  }),
                }
              } else if (column.id === newDestCard.column_id) {
                return {
                  ...column,
                  cards: column.cards.map(card => {
                    if (card.id === newDestCard.id) {
                      return newDestCard
                    }
                    return card
                  }),
                }
              }
            }

            return column
          }),
        )

        return bidsDndApi([...sortedSourceBids, ...sortedDestBids]).catch(e => openNotification())
      } else {
        let [card] = getCard(columns, source.droppableId)

        const bids = innerDndReorder(card.bids, source.index, destination.index)

        const newCard = { ...card, bids }

        queryClient.setQueryData(
          LEADS_KEY,
          columns.map(oldColumn => {
            if (oldColumn.id === newCard.column_id) {
              return {
                ...oldColumn,
                cards: oldColumn.cards.map(card => {
                  if (card.id === newCard.id) {
                    return newCard
                  }
                  return card
                }),
              }
            }
            return oldColumn
          }),
        )

        return bidsDndApi(bids).catch(e => openNotification())
      }
    }
  }
}
