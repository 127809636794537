import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  type: number
}

const CourseType: React.FC<props> = ({ type }) => {
  const { t } = useTranslation()

  function renderType() {
    if (type === 1) {
      return <Tag color={'orange'}>{t('offline')}</Tag>
    } else if (type === 2) {
      return <Tag color={'green'}>{t('online')}</Tag>
    } else return <Tag color={'blue'}>{t('videocourse')}</Tag>
  }

  return <React.Fragment>{renderType()}</React.Fragment>
}

export default React.memo(CourseType)
