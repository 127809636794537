import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  role: string
}

const UserRole: React.FC<props> = ({ role }) => {
  const { t } = useTranslation()

  return <Tag color={'blue'}>{t(role)}</Tag>
}

export default React.memo(UserRole)
