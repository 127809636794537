import React, { useState } from 'react'
import { Button, Divider, Drawer, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { FundOutlined } from '@ant-design/icons'
import TeacherFinanceDrawerReport from './teacher-finance-drawer-report/teacher-finance-drawer-report'

interface props {
  teacherName: string
}

const TeacherFinanceDrawer: React.FC<props> = ({ teacherName }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const drawerHandler = () => setVisible(!visible)
  const { t } = useTranslation()

  const title = t('financialReport')

  return (
    <React.Fragment>
      <Button type={'primary'} onClick={drawerHandler} icon={<FundOutlined />}>
        {title}
      </Button>
      <Drawer
        title={
          <Space split={<Divider type={'vertical'} />}>
            <span>{title}</span>
            <span>{teacherName}</span>
          </Space>
        }
        width={'100%'}
        destroyOnClose={true}
        visible={visible}
        onClose={drawerHandler}
      >
        <TeacherFinanceDrawerReport />
      </Drawer>
    </React.Fragment>
  )
}

export default TeacherFinanceDrawer
