import React from 'react'
import { PageHeader, Skeleton } from 'antd'
import Head from '../../../../hoc/head/head'
import { useTranslation } from 'react-i18next'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import TestingFilterForm from '../testing-filter-form/testing-filter-form'
import EventsTable from './events-table/events-table'
import MyPagination from '../../../core/my-pagination/my-pagination'
import { TEST_EVENTS_KEY, useTestEvents } from '../../../../hooks/query/events'
import ErrorAlert from '../../../core/error-alert/error-alert'
import EventModal from './event-modal/event-modal'

interface props {}

const Events: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'testing'])
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'teacher'])

  const { isLoading, isError, error, data } = useTestEvents()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { data: events, ...pagination } = data!

  return (
    <Head title={t('events')}>
      <PageHeader
        title={t('events')}
        subTitle={t('testing:eventsSubtitle')}
        onBack={() => window.history.back()}
        extra={[access && <EventModal key={'1'} />]}
      />
      <TestingFilterForm courseFilter={true} fetchingQueryKey={TEST_EVENTS_KEY} />
      <EventsTable events={events} />
      <MyPagination fetchingQueryKey={TEST_EVENTS_KEY} pagination={pagination} />
    </Head>
  )
}

export default Events
