import { idType } from '../interfaces/types/types'
import { request as axios } from './index'
import { ITestEvent } from '../interfaces'

const url = '/tests'

export const getAllTestsApi = () => {
  return axios.get(url)
}

export const getTestByIdApi = (id: idType) => {
  return axios.get(url + '/' + id)
}

export const createTestApi = (data: ITestEvent) => {
  return axios.post(url, data)
}

export const updateTestApi = (data: ITestEvent) => {
  return axios.patch(url + '/' + data.id, data)
}
export const deleteTestApi = (id: idType) => {
  return axios.delete(url + '/' + id)
}

export const startTestApi = ({ id, password }: { id: idType; password: string }) => {
  return axios.post(url + '/' + id + '/start', {
    password,
  })
}

export const getTestResultsApi = (id: idType) => {
  return axios.get(url + '/' + id + '/results')
}
