import React from 'react'
import { useTranslation } from 'react-i18next'
import { IGroup } from '../../../../interfaces'
import { Card, Descriptions } from 'antd'
import GroupDays from '../group-days/group-days'
import GroupRoom from '../group-room/group-room'
import StatusTag from '../../../core/status-tag/status-tag'
import isEqual from 'react-fast-compare'
import { descriptionsSettings } from '../../../../lib/settings'
import TeacherLink from '../../../core/teacher-link/teacher-link'
import CourseLink from '../../../core/course-link/course-link'
import DateWithMonth from '../../../core/date-with-month/date-with-month'

interface props {
  group: IGroup
}

const GroupCard: React.FC<props> = ({ group }) => {
  const { t } = useTranslation(['translation', 'groups'])
  const {
    name,
    course,
    branch,
    teacher,
    room,
    end_date,
    status,
    start_date,
    start_time,
    end_time,
    days,
  } = group

  return (
    <Card bordered={false} title={t('generalInformation')}>
      <Descriptions {...descriptionsSettings} column={{ xs: 1, md: 3 }}>
        <Descriptions.Item label={t('name')}>{name}</Descriptions.Item>
        <Descriptions.Item label={t('teacher')}>
          {teacher && <TeacherLink name={teacher.name} id={teacher.id} />}
        </Descriptions.Item>
        <Descriptions.Item label={t('groups:lessonTime')}>
          {start_time} - {end_time}
        </Descriptions.Item>
        <Descriptions.Item label={t('course')}>
          <CourseLink courseId={course.id} courseName={course.name} />
        </Descriptions.Item>
        <Descriptions.Item label={t('days')}>
          <GroupDays days={days} />
        </Descriptions.Item>
        <Descriptions.Item label={t('room')}>
          <GroupRoom roomName={room.name} roomId={room.id} />
        </Descriptions.Item>
        <Descriptions.Item label={t('groups:groupStartDate')}>
          <DateWithMonth date={start_date} />
        </Descriptions.Item>
        <Descriptions.Item label={t('groups:groupEndDate')}>
          <DateWithMonth date={end_date} />
        </Descriptions.Item>
        <Descriptions.Item label={t('status')}>
          <StatusTag status={status} />
        </Descriptions.Item>
        <Descriptions.Item label={t('branch')}>{branch.title}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps, nextProps)
}

export default React.memo(GroupCard, propsAreEqual)
