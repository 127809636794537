import { Form, FormItemProps, Select } from 'antd'
import React from 'react'
import { useColumnsList } from '../../../../hooks/query/leads'
import { useTranslation } from 'react-i18next'

interface props {
  name?: string
  label?: string
  required?: boolean
  formItemProps?: FormItemProps
}

const ColumnsList: React.FC<props> = ({
  name = 'column_id',
  label = 'column',
  required = true,
  formItemProps,
}) => {
  const { t } = useTranslation()
  const { isRefetching, data } = useColumnsList()

  return (
    <Form.Item name={name} label={t(label)} rules={[{ required }]} {...formItemProps}>
      <Select disabled={isRefetching} loading={isRefetching} placeholder={t('chooseColumn')}>
        {data?.map(({ id, name }) => (
          <Select.Option value={id!} key={id}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default ColumnsList
