import React from 'react'
import { Badge, Divider, Space, Typography } from 'antd'
import styles from './test-questions-header.module.scss'
import { useTranslation } from 'react-i18next'
import SearchInputHandler from '../../../../core/search-input-handler/search-input-handler'
import { QUESTION_BASE_QUESTIONS } from '../../../../../hooks/query/question-bases'
import TestQuestionDrawer from '../test-question-drawer/test-question-drawer'
import TestQuestionsImport from '../test-questions-import/test-questions-import'
import { idType } from '../../../../../interfaces/types/types'
import { useAuthUserInformation } from '../../../../../hooks/query/auth'

interface props {
  questionsCount: number | undefined
  questionsBaseId: string
  teacherId: idType
}

const TestQuestionsHeader: React.FC<props> = ({ questionsBaseId, questionsCount, teacherId }) => {
  const { t } = useTranslation(['translation', 'testing'])
  const authUserInformation = useAuthUserInformation()
  const access = teacherId === authUserInformation.data?.id

  return (
    <div className={styles.header}>
      <Badge count={questionsCount} offset={[15, 5]}>
        <Typography.Title level={4}>{t('questions')} </Typography.Title>
      </Badge>
      <div className={styles.actions}>
        <Space>
          <SearchInputHandler
            placeholder={t('testing:questionSearch')}
            fetchingQueryKey={[QUESTION_BASE_QUESTIONS, questionsBaseId]}
          />
          {access && (
            <React.Fragment>
              <Divider type={'vertical'} />
              <TestQuestionsImport />
              <Divider type={'vertical'} />
              <TestQuestionDrawer />
            </React.Fragment>
          )}
        </Space>
      </div>
    </div>
  )
}

export default TestQuestionsHeader
