import React from 'react'
import { Form, Select } from 'antd'
import { useRoomsList } from '../../../hooks/query/rooms'
import { useTranslation } from 'react-i18next'
import { IRoom } from '../../../interfaces'
import { filterOption } from '../../../lib/filterOption'

interface props {
  name?: string
  required?: boolean
  label?: string
}

const FormRoomsList: React.FC<props> = ({ name = 'room_id', required = true, label = 'room' }) => {
  const { t } = useTranslation()
  const { isFetching, data } = useRoomsList()

  return (
    <Form.Item
      name={name}
      label={t(label)}
      rules={[
        {
          required,
          message: t('required'),
        },
      ]}
    >
      <Select
        disabled={isFetching}
        loading={isFetching}
        showSearch={true}
        placeholder={t('chooseRoom')}
        filterOption={filterOption}
        allowClear={!required}
      >
        {data?.map(({ id, name }: IRoom) => {
          return (
            <Select.Option key={id} value={id!} label={name}>
              {name}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export default FormRoomsList
