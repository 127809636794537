import React, { useEffect } from 'react'
import { Form, FormInstance, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../../../interfaces/types/types'
import { useStudentGroups } from '../../../../../hooks/query/students'
import { IGroupDates } from '../../../../../interfaces'

interface props {
  groupId: idType
  studentId: idType
  setGroupDates: (obj: IGroupDates) => void
  form: FormInstance
}

const StudentPaymentsGroupsSelect: React.FC<props> = ({
  setGroupDates,
  groupId,
  studentId,
  form,
}) => {
  const { isLoading, data } = useStudentGroups(studentId)
  const { t } = useTranslation()

  const setDefaultGroupDates = (id?: string) => {
    const defaultSelectedGroup = data?.find(group => {
      if (id) return group.id?.toString() === id
      return group.id?.toString() === groupId
    })

    if (defaultSelectedGroup) {
      setGroupDates({
        start_date: defaultSelectedGroup.start_date,
        end_date: defaultSelectedGroup.end_date,
      })
    }
  }

  useEffect(() => {
    setDefaultGroupDates()
  }, [data])

  const onChange = (value: string) => {
    form.resetFields(['date'])
    setDefaultGroupDates(value)
  }

  return (
    <Form.Item
      label={t('chooseGroup')}
      name="group_id"
      initialValue={groupId}
      rules={[
        {
          required: !groupId,
          message: t('required'),
        },
      ]}
      hidden={!!groupId}
    >
      {!groupId ? (
        <Select
          placeholder={t('chooseGroup')}
          disabled={isLoading}
          loading={isLoading}
          onChange={onChange}
        >
          {data
            ?.filter(s => s.pivot?.status === 'active')
            .map(group => (
              <Select.Option key={group.id} value={group.id!.toString()}>
                {group.name}
              </Select.Option>
            ))}
        </Select>
      ) : (
        <Input />
      )}
    </Form.Item>
  )
}

export default StudentPaymentsGroupsSelect
