import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input } from 'antd'
import { idType } from '../../../../interfaces/types/types'
import { LockOutlined } from '@ant-design/icons'

interface props {
  name?: string
  label?: string
  id?: idType
  min?: number
}

const PasswordInput: React.FC<props> = ({ name = 'password', label, id, min = 6 }) => {
  const { t } = useTranslation()

  return (
    <Form.Item
      name={name}
      label={label ? t(label) : null}
      rules={[
        { min, message: t('passwordMinLength', { number: min }) },
        { required: !id, message: t('required') },
      ]}
    >
      <Input.Password
        addonBefore={<LockOutlined className="site-form-item-icon" />}
        placeholder={t('inputPassword')}
      />
    </Form.Item>
  )
}

export default PasswordInput
