import {
  getAllQuestionBasesApi,
  getQuestionBaseById,
  getQuestionBaseQuestionsApi,
  getQuestionBasesListApi,
} from '../../api/question-bases'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { IQuestionBase, IQuestionBaseResponse, IQuestionBasesResponse } from '../../interfaces'
import { idType } from '../../interfaces/types/types'

export const QUESTION_BASES_KEY = 'question_bases_key'
export const QUESTION_BASES_LIST_KEY = 'question_bases_list_key'
export const QUESTION_BASE_QUESTIONS = 'question_base_questions_key'

export function useQuestionBases(options?: UseQueryOptions<IQuestionBasesResponse, Error>) {
  return useQuery<IQuestionBasesResponse, Error>(
    QUESTION_BASES_KEY,
    () =>
      getAllQuestionBasesApi().then(
        (response: AxiosResponse<IQuestionBasesResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function useQuestionBasesList(options?: UseQueryOptions<IQuestionBase[], Error>) {
  return useQuery<IQuestionBase[], Error>(
    QUESTION_BASES_LIST_KEY,
    () =>
      getQuestionBasesListApi().then((response: AxiosResponse<IQuestionBase[]>) => response.data),
    {
      ...options,
    },
  )
}

export function useQuestionBase(id: idType, options?: UseQueryOptions<IQuestionBase, Error>) {
  return useQuery<IQuestionBase, Error>(
    [QUESTION_BASES_KEY, id],
    () => getQuestionBaseById(id).then((response: AxiosResponse<IQuestionBase>) => response.data),
    {
      ...options,
    },
  )
}

export function useQuestionsBaseQuestions(
  id: idType,
  options?: UseQueryOptions<IQuestionBaseResponse, Error>,
) {
  return useQuery<IQuestionBaseResponse, Error>(
    [QUESTION_BASE_QUESTIONS, id],
    () =>
      getQuestionBaseQuestionsApi(id).then(
        (response: AxiosResponse<IQuestionBaseResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}
