import { useQuery, UseQueryOptions } from 'react-query'
import { IDashboardSchedule, IDashboardStatistic } from '../../interfaces'
import { getDashboardSchedule, getDashboardStatistic } from '../../api/dashboard'
import { AxiosResponse } from 'axios'

export const DASHBOARD_STATISTIC_KEY = 'dashboard_statistic_key'
export const DASHBOARD_SCHEDULE_KEY = 'dashboard_schedule_key'

export function useDashboardStatistic(options?: UseQueryOptions<IDashboardStatistic, ErrorEvent>) {
  return useQuery<IDashboardStatistic, ErrorEvent>(
    DASHBOARD_STATISTIC_KEY,
    () =>
      getDashboardStatistic().then((response: AxiosResponse<IDashboardStatistic>) => response.data),
    {
      ...options,
    },
  )
}

export function useDashboardSchedule(options?: UseQueryOptions<IDashboardSchedule, Error>) {
  return useQuery<IDashboardSchedule, Error>(
    DASHBOARD_SCHEDULE_KEY,
    () =>
      getDashboardSchedule().then((response: AxiosResponse<IDashboardSchedule>) => response.data),
    {
      ...options,
    },
  )
}
