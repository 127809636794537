import React from 'react'
import Users from '../../app/modules/users/users'

interface props {}

const UsersPage: React.FC<props> = () => {
  return (
    <React.Fragment>
      <Users />
    </React.Fragment>
  )
}

export default UsersPage
