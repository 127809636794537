import { ITestEvent, ITestEventResponse, ITestResultWithPagination } from '../../interfaces'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { getAllTestsApi, getTestByIdApi, getTestResultsApi } from '../../api/tests'
import { idType } from '../../interfaces/types/types'

export const TEST_EVENTS_KEY = 'test_events_key'
export const TEST_EVENT_RESULTS_KEY = 'test_event_results_key'

export function useTestEvents(options?: UseQueryOptions<ITestEventResponse, Error>) {
  return useQuery<ITestEventResponse, Error>(
    TEST_EVENTS_KEY,
    () => getAllTestsApi().then((response: AxiosResponse<ITestEventResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function useTestEvent(id: idType, options?: UseQueryOptions<ITestEvent, Error>) {
  return useQuery<ITestEvent, Error>(
    [TEST_EVENTS_KEY, id],
    () => getTestByIdApi(id).then((response: AxiosResponse<ITestEvent>) => response.data),
    {
      ...options,
    },
  )
}

export function useTestEventResults(
  id: idType,
  options?: UseQueryOptions<ITestResultWithPagination, Error>,
) {
  return useQuery<ITestResultWithPagination, Error>(
    [TEST_EVENT_RESULTS_KEY, id],
    () =>
      getTestResultsApi(id).then(
        (response: AxiosResponse<ITestResultWithPagination>) => response.data,
      ),
    {
      ...options,
    },
  )
}
