import { IOrderCard, IOrderColumn } from '../interfaces'

export function getSortedArray(arr: any[]) {
  return arr.map((item, index) => ({ ...item, sort: index }))
}

export function getColumn(columns: IOrderColumn[], id: string) {
  return columns.find(column => column.id === id)!
}

export function getCard(columns: IOrderColumn[], source?: string, destination?: string) {
  let sourceCard: IOrderCard | undefined
  let destCard: IOrderCard | undefined

  columns.forEach(({ cards }: IOrderColumn) => {
    return cards.find(card => {
      if (card.id === source) {
        sourceCard = card
      } else if (card.id === destination) {
        destCard = card
      }
      return false
    })
  })

  return [sourceCard!, destCard!]
}

export function outerDndReorder(
  sourceArray: any,
  destArray: any,
  destField: string,
  source: number,
  destination: number,
  droppableId: string,
) {
  const sourceCards = [...sourceArray]
  const destCards = [...destArray]

  const [removed] = sourceCards.splice(source, 1)
  const newRemoved = { ...removed, [destField]: droppableId }
  destCards.splice(destination, 0, newRemoved)

  return [getSortedArray(sourceCards), getSortedArray(destCards)]
}

export function innerDndReorder(arr: any, source: number, destination: number) {
  const destruct = [...arr]
  const [removed] = destruct.splice(source, 1)
  destruct.splice(destination, 0, removed)

  return getSortedArray(destruct)
}
