import React from 'react'
import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  name?: string
  label?: string
}

const NameInput: React.FC<props> = ({ name = 'name', label = 'firstName' }) => {
  const { t } = useTranslation()

  return (
    <Form.Item name={name} label={t(label)} rules={[{ required: true, message: t('required') }]}>
      <Input placeholder={t('inputName')} />
    </Form.Item>
  )
}

export default NameInput
