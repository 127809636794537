import axios from 'axios'
import { ACCESS_TOKEN, SELECTED_BRANCH } from '../constants/auth'
import { createBrowserHistory } from 'history'
import { queryClient } from '../index'
import { IUser } from '../interfaces'
import { AUTH_USER_KEY } from '../hooks/query/auth'

export const history = createBrowserHistory()

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/api',
  params: '',
})

request.interceptors.request.use(
  async config => {
    const authUser = queryClient.getQueryData<IUser>(AUTH_USER_KEY)
    const token = localStorage.getItem(ACCESS_TOKEN)
    const isSelectedBranchExist =
      localStorage.getItem(SELECTED_BRANCH) ||
      (authUser?.branches[0] && authUser?.branches[0].id?.toString())
    const {
      location: { search },
    } = history

    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    if (isSelectedBranchExist) {
      config.headers = {
        ...config.headers,
        'branch-id': isSelectedBranchExist,
      }
    }

    if (search.length && config.method?.toLowerCase() === 'get') {
      const urlParams = new URLSearchParams(search)
      config.params = Object.fromEntries(urlParams)
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

request.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401 && window.location.pathname !== '/auth') {
      localStorage.removeItem(ACCESS_TOKEN)
      history.replace('/auth')
    }

    return Promise.reject(error)
  },
)
