import React from 'react'
import styles from './layout-active-branch.module.scss'
import { HomeTwoTone } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useActiveBranch } from '../../../hooks/useActiveBranch'

interface props {}

const LayoutActiveBranch: React.FC<props> = () => {
  const activeBranch = useActiveBranch()
  const { t } = useTranslation()

  return (
    <div className={styles.activeBranch}>
      <div className={styles.top}>
        <HomeTwoTone twoToneColor={'#ff4d4f'} className={styles.icon} /> {t('branch')}
      </div>
      <div className={styles.bottom}>{activeBranch?.title}</div>
    </div>
  )
}

export default LayoutActiveBranch
