import { useQuery, UseQueryOptions } from 'react-query'
import {
  getAllTeachersApi,
  getTeacherByIdApi,
  getTeacherSalaryHistory,
  getTeachersListApi,
} from '../../api/teachers'
import { AxiosResponse } from 'axios'
import {
  IFinanceTeacherResponse,
  ITeacher,
  ITeacherSalaryHistory,
  ITeachersWithPagination,
} from '../../interfaces'
import { idType } from '../../interfaces/types/types'
import { getAllFinancesTeachersApi, getFinancesTeacherByIdApi } from '../../api/finances'

export const TEACHERS_KEY = 'teachers_key'
export const TEACHERS_FINANCES_KEY = 'teachers_finances_key'
export const TEACHERS_LIST_KEY = 'teachers_list_key'
export const TEACHER_SALARY_HISTORY_KEY = 'teacher_salary_history_key'

export function useTeachers(options?: UseQueryOptions<ITeachersWithPagination, Error>) {
  return useQuery<ITeachersWithPagination, Error>(
    TEACHERS_KEY,
    () =>
      getAllTeachersApi().then((response: AxiosResponse<ITeachersWithPagination>) => response.data),
    {
      ...options,
    },
  )
}

export function useTeachersList(options?: UseQueryOptions<ITeacher[], Error>) {
  return useQuery<ITeacher[], Error>(
    TEACHERS_LIST_KEY,
    () => getTeachersListApi().then((response: AxiosResponse<ITeacher[]>) => response.data),
    {
      ...options,
    },
  )
}

export function useTeacher(id: idType, options?: UseQueryOptions<ITeacher, Error>) {
  return useQuery<ITeacher, Error>(
    [TEACHERS_KEY, id],
    () => getTeacherByIdApi(id).then((response: AxiosResponse<ITeacher>) => response.data),
    {
      ...options,
    },
  )
}

export function useTeacherSalaryHistory(
  id: idType,
  options?: UseQueryOptions<ITeacherSalaryHistory[], Error>,
) {
  return useQuery<ITeacherSalaryHistory[], Error>(
    [TEACHER_SALARY_HISTORY_KEY, id],
    () =>
      getTeacherSalaryHistory(id).then(
        (response: AxiosResponse<ITeacherSalaryHistory[]>) => response.data,
      ),
    {
      ...options,
    },
  )
}

// FINANCES

export function useFinancesTeachers(options?: UseQueryOptions<ITeachersWithPagination, Error>) {
  return useQuery<ITeachersWithPagination, Error>(
    TEACHERS_FINANCES_KEY,
    () =>
      getAllFinancesTeachersApi().then(
        (response: AxiosResponse<ITeachersWithPagination>) => response.data,
      ),
    {
      ...options,
    },
  )
}

export function useFinancesTeacher(
  id: idType,
  options?: UseQueryOptions<IFinanceTeacherResponse, Error>,
) {
  return useQuery<IFinanceTeacherResponse, Error>(
    [TEACHERS_FINANCES_KEY, id],
    () =>
      getFinancesTeacherByIdApi(id).then(
        (response: AxiosResponse<IFinanceTeacherResponse>) => response.data,
      ),
    {
      ...options,
    },
  )
}
