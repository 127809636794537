import React from 'react'
import { ICourse } from '../../../../interfaces'
import { updateCourseApi } from '../../../../api/course'
import { Button, message, Space, Tooltip } from 'antd'
import CoursesDrawer from '../courses-drawer/courses-drawer'
import isEqual from 'react-fast-compare'
import { PoweroffOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../index'
import { COURSES_KEY } from '../../../../hooks/query/courses'

interface props {
  course: ICourse
}

const CourseActions: React.FC<props> = ({ course }) => {
  const { t } = useTranslation()

  const { isLoading, mutateAsync } = useMutation(updateCourseApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(COURSES_KEY)
      message.success(t('responseSuccess'))
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  const changeStatusHandler = () => mutateAsync({ ...course, status: !course.status })

  return (
    <Space>
      <CoursesDrawer course={course} title={'editTitle'} />
      <Tooltip title={t('changeStatus')}>
        <Button type={'ghost'} disabled={isLoading} onClick={changeStatusHandler}>
          <PoweroffOutlined />
        </Button>
      </Tooltip>
    </Space>
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps, nextProps)
}

export default React.memo(CourseActions, propsAreEqual)
