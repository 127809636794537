import React from 'react'
import { Space } from 'antd'
import UsersDrawer from '../users-drawer/users-drawer'
import { IUser } from '../../../../interfaces'
import isEqual from 'react-fast-compare'
import DeleteModal from '../../../core/delete-modal/delete-modal'
import { deleteUserApi } from '../../../../api/user'
import { USERS_KEY } from '../../../../hooks/query/users'

interface props {
  user: IUser
}

const UsersAction: React.FC<props> = ({ user }) => {
  return (
    <Space>
      <UsersDrawer title={'editTitle'} user={user} />
      <DeleteModal
        deleteFunction={deleteUserApi}
        id={user.id}
        fetchingQueryKey={USERS_KEY}
        redirect={false}
        modalText={'deleteMessage'}
      />
    </Space>
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps, nextProps)
}

export default React.memo(UsersAction, propsAreEqual)
