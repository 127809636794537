import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row } from 'antd'
import { ITestEvent } from '../../../../../interfaces'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import FormCoursesList from '../../../../core/form-courses-list/form-courses-list'
import PasswordInput from '../../../../core/form-group/password-input/password-input'
import FormTestsList from '../../../../core/form-tests-list/form-tests-list'
import moment from 'moment'
import { useAuthUserInformation } from '../../../../../hooks/query/auth'
import { useMutation } from 'react-query'
import { createTestApi, updateTestApi } from '../../../../../api/tests'
import { queryClient } from '../../../../../index'
import { TEST_EVENTS_KEY } from '../../../../../hooks/query/events'

const { RangePicker } = DatePicker

interface props {
  updateOne?: boolean
  event?: ITestEvent
}

const EventModal: React.FC<props> = ({ updateOne, event }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const modalHandler = () => setVisible(!visible)
  const { t } = useTranslation(['translation', 'testing'])
  const { data } = useAuthUserInformation()

  const onSuccess = async () => {
    await queryClient.invalidateQueries(
      updateOne ? [TEST_EVENTS_KEY, event?.id!.toString()] : TEST_EVENTS_KEY,
    )
    message.success(t('responseSuccess'))
    modalHandler()
  }
  const onError = (e: any) => {
    message.error(e.response.data.message)
  }
  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(createTestApi, {
    onSuccess,
    onError,
  })
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(updateTestApi, {
    onSuccess,
    onError,
  })

  const format = 'YYYY-MM-DD HH:mm:ss'
  const onFinish = (fieldValues: any) => {
    const values = {
      ...fieldValues,
      start_at: fieldValues['period'][0] && moment(fieldValues['period'][0]).format(format),
      end_at: fieldValues['period'][1] && moment(fieldValues['period'][1]).format(format),
    }

    return event ? update(values) : create(values)
  }

  const title = event ? t('testing:editEvent') : t('testing:addEvent')

  return (
    <React.Fragment>
      <Button
        onClick={modalHandler}
        icon={event ? <EditOutlined /> : <PlusCircleOutlined />}
        type={'primary'}
      >
        {title}
      </Button>
      <Modal
        visible={visible}
        title={title}
        onCancel={modalHandler}
        okButtonProps={{ htmlType: 'submit', form: 'event-form' }}
        okText={t('submit')}
        confirmLoading={isCreateLoading || isUpdateLoading}
        destroyOnClose={true}
      >
        <Form
          onFinish={onFinish}
          id={'event-form'}
          layout={'vertical'}
          initialValues={{
            ...event,
            question_bases: event?.question_bases.map(qb => qb.id),
            period: [
              event?.start_at && moment(event.start_at, format),
              event?.end_at && moment(event.end_at, format),
            ],
            user_id: data?.id,
          }}
        >
          <Form.Item name={'id'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={'user_id'} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={'title'} label={t('name')} rules={[{ required: true }]}>
            <Input placeholder={t('inputName')} />
          </Form.Item>
          <FormCoursesList />
          <Form.Item
            name={'period'}
            label={`${t('testing:eventStartDate')} - ${t('testing:eventEndDate')} (${t(
              'notRequired',
            )})`}
          >
            <RangePicker showTime={{ format: 'HH:mm' }} format={format} style={{ width: '100%' }} />
          </Form.Item>
          <FormTestsList />
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={12}>
              <Form.Item
                name={'questions_count'}
                label={t('testing:questionsCount')}
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={1}
                  placeholder={t('testing:inputQuestionsCount')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <PasswordInput label={'password'} min={4} />
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default EventModal
