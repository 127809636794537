import React from 'react'
import { Form, Select } from 'antd'
import { useCoursesList } from '../../../hooks/query/courses'
import { ICourse } from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import { filterOption } from '../../../lib/filterOption'

interface props {
  name?: string
  required?: boolean
  label?: string
  setCourseTime?: (lesson_duration: string) => void
}

const FormCoursesList: React.FC<props> = ({
  name = 'course_id',
  setCourseTime,
  label = 'course',
  required = true,
}) => {
  const { t } = useTranslation()
  const { isLoading, data } = useCoursesList()

  const selectCourse = (value: string) => {
    const findedCourse = data?.find(course => course.id === value)

    if (setCourseTime) {
      setCourseTime(findedCourse!.lesson_duration)
    }
  }

  return (
    <Form.Item
      name={name}
      label={t(label)}
      rules={[
        {
          required,
          message: t('required'),
        },
      ]}
    >
      <Select
        disabled={isLoading}
        loading={isLoading}
        onChange={setCourseTime && selectCourse}
        showSearch={true}
        placeholder={t('chooseCourse')}
        filterOption={filterOption}
        allowClear={!required}
      >
        {data?.map(({ id, name }: ICourse) => {
          return (
            <Select.Option key={id} value={id!} label={name}>
              {name}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export default FormCoursesList
