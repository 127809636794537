import React from 'react'
import { Divider, Form, Input } from 'antd'
import { IStudent } from '../../../../interfaces'
import moment from 'moment'
import PasswordInput from '../../../core/form-group/password-input/password-input'
import PhoneInput from '../../../core/form-group/phone-input/phone-input'
import NameInput from '../../../core/form-group/name-input/name-input'
import BirthdaySelect from '../../../core/form-group/birthday-select/birthday-select'
import StatusSwitch from '../../../core/form-group/status-switch/status-switch'
import AdditionalInputs from '../../../core/additional-inputs/additional-inputs'

interface props {
  item?: Omit<IStudent, 'groups' | 'payments'>
  formId: string
  submitForm: any
}

const StudentsForm: React.FC<props> = ({ item, formId, submitForm }) => {
  let user: any = {}

  if (item)
    user = {
      ...item,
      birthday: item.birthday ? moment(item.birthday) : undefined,
    }

  return (
    <Form id={formId} onFinish={submitForm} initialValues={user} layout={'vertical'}>
      <Form.Item name={'id'} hidden>
        <Input />
      </Form.Item>

      <NameInput />

      <PhoneInput label={'student_phone'} />
      <PhoneInput label={'additionalPhone'} name={'additional_phone'} required={false} />
      <PasswordInput id={item?.id} label={'password'} />

      <BirthdaySelect />

      <StatusSwitch />

      <Divider />

      <AdditionalInputs name={'additional_infos'} />
    </Form>
  )
}

export default StudentsForm
