import React, { useEffect, useState } from 'react'
import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Row,
  Space,
  Spin,
  Statistic,
  Typography,
} from 'antd'
import { useFinancePaymentsInfo } from '../../../../hooks/query/finances'
import { priceFormatter } from '../../../../lib/priceFormatter'
import moment, { Moment } from 'moment'
import FinanceDifference from '../finance-difference/finances-difference'
import { useTranslation } from 'react-i18next'

interface props {}

const format = 'YYYY-MM-DD'

const FinancePaymentsInfo: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'finances'])
  const [date, setDate] = useState<string>(moment().format(format))
  const [isPeriod, setIsPeriod] = useState<boolean>(false)
  const changeIsPeriodState = () => {
    setIsPeriod(!isPeriod)
    setDate(moment().format(format))
  }

  const { isFetching, data, refetch } = useFinancePaymentsInfo({}, { date })

  useEffect(() => {
    refetch()
  }, [date])

  const onDateChange = (values: any | any[]) => {
    const convertDate = (value: Moment) => moment(value).format(format)

    setDate(
      Array.isArray(values)
        ? `${convertDate(values[0])};${convertDate(values[1])}`
        : moment(values).format(format),
    )
  }

  const datePickerProps = {
    format: 'DD MMMM YYYY',
    allowClear: false,
  }

  return (
    <Spin spinning={isFetching}>
      <Card
        bordered={false}
        title={t('finances:summaryFinancialReport')}
        extra={[
          <Space key={'1'} size={12}>
            <Checkbox onChange={changeIsPeriodState}>{t('period')}</Checkbox>
            {isPeriod ? (
              <DatePicker.RangePicker {...datePickerProps} onChange={onDateChange} />
            ) : (
              <DatePicker
                {...datePickerProps}
                defaultValue={moment(date, format)}
                onChange={onDateChange}
              />
            )}
          </Space>,
        ]}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <Typography.Title level={4} style={{ color: '#389e0d' }}>
              {t('finances:incoming')}
            </Typography.Title>
            <Statistic title={t('paymentsCount')} value={data?.payments_count} />
            <Statistic title={t('amount')} value={priceFormatter(data?.total_payments)} />
          </Col>
          <Col xs={24} lg={12}>
            <Typography.Title level={4} style={{ color: '#cf1322' }}>
              {t('finances:expenditure')}
            </Typography.Title>
            <Statistic title={t('paymentsToTeachers')} value={data?.salaries_count} />
            <Statistic title={t('amount')} value={priceFormatter(data?.total_salaries)} />
            <Statistic title={t('additionalExpenses')} value={data?.expenses_count} />
            <Statistic title={t('amount')} value={priceFormatter(data?.total_expenses)} />
          </Col>
        </Row>
        <Divider />
        <Typography.Title level={4}>
          {t('total')}: <FinanceDifference difference={data?.balance} />
        </Typography.Title>
      </Card>
    </Spin>
  )
}

export default FinancePaymentsInfo
