import React, { useState } from 'react'
import { Card, Col, Form, Input, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import FilterButtons from '../../../../core/form-group/filter-buttons/filter-buttons'
import FormGroupsList from '../../../../core/form-groups-list/form-groups-list'
import { useHistory } from 'react-router-dom'
import { serialize } from '../../../../../lib/serializeQuery'
import { queryClient } from '../../../../../index'
import { DEBTORS_KEY } from '../../../../../hooks/query/debtors'
import queryString from 'query-string'

interface props {}

const FinancesDebtorsFilter: React.FC<props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const { push, location } = useHistory()
  const [form] = Form.useForm()

  const onFinish = async (values: any) => {
    setIsLoading(true)
    await push({ search: serialize(values) })
    await queryClient.invalidateQueries(DEBTORS_KEY)
    setIsLoading(false)
  }

  const onReset = async () => {
    setIsLoading(true)
    await push({ search: '' })
    form.resetFields()
    await queryClient.invalidateQueries(DEBTORS_KEY)
    setIsLoading(false)
  }

  const qs = queryString.parse(location.search)

  return (
    <Card bordered={false} title={t('filters')}>
      <Form
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        initialValues={{
          ...qs,
          group_id:
            qs.group_id &&
            qs.group_id
              .toString()
              .split(',')
              .map(e => Number(e)),
        }}
      >
        <Row gutter={[16, 0]}>
          <Col flex={1}>
            <FormGroupsList name={'group_id'} mode={'multiple'} required={false} />
          </Col>
          <Col flex={1}>
            <Form.Item name={'search'} label={t('firstnamePhoneSearch')}>
              <Input placeholder={t('search')} />
            </Form.Item>
          </Col>
        </Row>
        <FilterButtons isLoading={isLoading} onReset={onReset} />
      </Form>
    </Card>
  )
}

export default FinancesDebtorsFilter
