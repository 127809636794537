import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer, message } from 'antd'
import DrawerOpen from '../../../core/drawer/drawer-open/drawer-open'
import { drawerSettings } from '../../../../lib/settings'
import DrawerFooter from '../../../core/drawer/drawer-footer/drawer-footer'
import { ICourse } from '../../../../interfaces'
import { createCourseApi, updateCourseApi } from '../../../../api/course'
import CoursesForm from '../courses-form/courses-form'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../index'
import { COURSE_PRICE_HISTORY_KEY, COURSES_KEY } from '../../../../hooks/query/courses'

interface props {
  course?: ICourse
  title: string
  updateOne?: boolean
}

const CoursesDrawer: React.FC<props> = ({ title, course, updateOne }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const drawerToggle = () => setVisible(!visible)

  const { t } = useTranslation(['translation', 'course'])

  const onSuccess = async () => {
    await queryClient.invalidateQueries(
      updateOne ? [COURSES_KEY, course!.id!.toString()] : COURSES_KEY,
    )
    updateOne &&
      (await queryClient.invalidateQueries([COURSE_PRICE_HISTORY_KEY, course!.id!.toString()]))
    message.success(t('responseSuccess'))
    drawerToggle()
  }

  const onError = (e: any) => {
    message.error(e.response.data.message)
  }

  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(createCourseApi, {
    onSuccess,
    onError,
  })
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(updateCourseApi, {
    onSuccess,
    onError,
  })

  const submitForm = async (fieldsValues: any) => {
    const handler = course ? update : create

    const values = {
      ...fieldsValues,
      parent_id: fieldsValues['parent_id'] || null,
      status: fieldsValues['status'] || false,
      lesson_duration: fieldsValues['lesson_duration'].format('HH:mm'),
    }

    return handler(values)
  }

  return (
    <>
      <DrawerOpen drawerToggle={drawerToggle} edit={course} />
      <Drawer
        {...drawerSettings}
        onClose={drawerToggle}
        title={t('course:' + title)}
        visible={visible}
        footer={
          <DrawerFooter
            disabled={isUpdateLoading || isCreateLoading}
            drawerToggle={drawerToggle}
            formId={'course-form'}
          />
        }
      >
        <CoursesForm item={course} formId={'course-form'} submitForm={submitForm} />
      </Drawer>
    </>
  )
}

export default CoursesDrawer
