import React, { useState } from 'react'
import { Alert, Button, Col, Drawer, Form, Input, message, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { ImportOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'
import { importQuestionBaseApi } from '../../../../../api/question-bases'
import { queryClient } from '../../../../../index'
import { useParams } from 'react-router-dom'
import { QUESTION_BASE_QUESTIONS } from '../../../../../hooks/query/question-bases'
import DrawerFooter from '../../../../core/drawer/drawer-footer/drawer-footer'

interface props {}

const TestQuestionsImport: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const [visible, setVisible] = useState<boolean>(false)
  const modalHandler = () => setVisible(!visible)

  const { t } = useTranslation()
  const title = t('testing:importQuestionsFromFile')

  const { isLoading, mutateAsync } = useMutation(importQuestionBaseApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUESTION_BASE_QUESTIONS, id])
      message.success(t('responseSuccess'))
      modalHandler()
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  })

  const onFinish = (values: any) => mutateAsync({ id, data: values.text })

  return (
    <React.Fragment>
      <Button icon={<ImportOutlined />} type={'primary'} onClick={modalHandler}>
        {title}
      </Button>
      <Drawer
        visible={visible}
        title={title}
        closable={true}
        onClose={modalHandler}
        height={'100%'}
        placement={'top'}
        destroyOnClose={true}
        footer={
          <DrawerFooter drawerToggle={modalHandler} formId={'import-form'} disabled={isLoading} />
        }
      >
        <Row gutter={[32, 32]}>
          <Col xs={24}>
            <Alert
              type={'info'}
              message={'Знаки'}
              description={
                <React.Fragment>
                  <div>"??" - Начало и конец вопроса</div>
                  <div>"**" - Начало и конец неверного ответа</div>
                  <div>"++" - Начало и конец правильного ответа</div>
                  <div>"##" - Закрытие блока вопрос-ответ</div>
                </React.Fragment>
              }
            />
          </Col>
          <Col xs={24}>
            <Alert
              type={'warning'}
              message={<Typography.Title level={4}>Пример структуры вопроса</Typography.Title>}
              description={
                <React.Fragment>
                  <Typography.Title level={5}>
                    ?? В каком году началась Вторая мировая война? ??
                  </Typography.Title>
                  <div>** 1941 **</div>
                  <div>++ 1939 ++</div>
                  <div>** 1942 **</div>
                  <div>** 1938 **</div>
                  <div>##</div>
                  <br />
                  <Typography.Title level={5}>
                    ?? Чему будет равно выражение 1+1? ??
                  </Typography.Title>
                  <div>** 3 **</div>
                  <div>++ 2 ++</div>
                  <div>** 4 **</div>
                  <div>** 5 **</div>
                  <div>##</div>
                </React.Fragment>
              }
            />
          </Col>
          <Col xs={24}>
            <Form id={'import-form'} onFinish={onFinish} layout={'vertical'}>
              <Form.Item
                name={'text'}
                label={t('testing:inputQuestionsFromFile')}
                rules={[{ required: true }]}
              >
                <Input.TextArea rows={15} placeholder={t('inputField')} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Drawer>
    </React.Fragment>
  )
}

export default TestQuestionsImport
