import React from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import CoursePriceHistoryModal from './course-price-history-modal/course-price-history-modal'
import { useParams } from 'react-router-dom'
import { useCoursePriceHistory } from '../../../../hooks/query/courses'
import ErrorAlert from '../../../core/error-alert/error-alert'
import { priceFormatter } from '../../../../lib/priceFormatter'
import { idType } from '../../../../interfaces/types/types'
import moment from 'moment'
import { ICoursePriceHistory } from '../../../../interfaces'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import StatusTag from '../../../core/status-tag/status-tag'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'

interface props {}

const CoursePriceHistory: React.FC<props> = () => {
  const { id: courseId } = useParams<{ id: string }>()
  const { t } = useTranslation(['translation', 'course'])
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception'])
  const { isLoading, isError, error, data } = useCoursePriceHistory(courseId)

  if (isError) return <ErrorAlert msg={error?.message} />

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: idType) => (
        <>
          <TableMobileTitle title={'ID'} /># {text}
        </>
      ),
    },
    {
      title: t('coursePrice'),
      dataIndex: 'price',
      render: (price: number) => (
        <>
          <TableMobileTitle title={t('coursePrice')} />
          <strong>{priceFormatter(price)}</strong>
        </>
      ),
    },
    {
      title: t('course:validPriceFrom'),
      dataIndex: 'date',
      render: (date: string) => (
        <>
          <TableMobileTitle title={t('course:validPriceFrom')} />
          <span>{moment(date).format('MMMM YYYY')}</span>
        </>
      ),
    },
    {
      title: t('status'),
      dataIndex: 'actual',
      render: (actual: boolean) => (
        <>
          <TableMobileTitle title={t('status')} />
          <StatusTag status={actual} />
        </>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right' as 'right',
      className: !access ? 'hide' : '',
      render: ({ id, price }: ICoursePriceHistory) =>
        access && <CoursePriceHistoryModal courseId={courseId} priceId={id} price={price} />,
    },
  ]

  return (
    <Table
      className={'adaptive-table'}
      rowKey={'id'}
      scroll={{ x: true }}
      pagination={false}
      columns={columns}
      loading={isLoading}
      dataSource={data}
    />
  )
}

export default CoursePriceHistory
