import React from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import FormGroupsList from '../../../../core/form-groups-list/form-groups-list'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { serialize } from '../../../../../lib/serializeQuery'
import { queryClient } from '../../../../../index'
import queryString from 'query-string'

interface props {
  fetchingQueryKey: string | string[]
}

const EventResultFilterForm: React.FC<props> = ({ fetchingQueryKey }) => {
  const location = useLocation()
  const { t } = useTranslation()
  const { push } = useHistory()

  const onFinish = (values: any) => {
    push({
      search: serialize(values),
    })

    return queryClient.invalidateQueries(fetchingQueryKey)
  }

  let qs = queryString.parse(location.search)

  return (
    <Form
      onFinish={onFinish}
      layout={'inline'}
      initialValues={{
        q: qs['q'],
        group_id: Number(qs['group_id']) || undefined,
      }}
    >
      <Row gutter={[0, 16]}>
        <Col flex={1}>
          <FormGroupsList
            allowClear={true}
            mode={'single'}
            required={false}
            name={'group_id'}
            label={''}
          />
        </Col>
        <Col flex={1}>
          <Form.Item name={'q'}>
            <Input placeholder={t('studentSearch')} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item>
            <Button htmlType={'submit'} type={'primary'}>
              {t('filter')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default EventResultFilterForm
