import React from 'react'
import { TEST_EVENT_RESULTS_KEY, useTestEventResults } from '../../../../../hooks/query/events'
import { Link, useParams } from 'react-router-dom'
import { Skeleton } from 'antd'
import ErrorAlert from '../../../../core/error-alert/error-alert'
import EventResultTable from '../../../../core/event-result-table/event-result-table'
import { idType } from '../../../../../interfaces/types/types'
import { ITestResult } from '../../../../../interfaces'
import EventResultPercent from '../../../../core/event-result-percent/event-result-percent'
import { useTranslation } from 'react-i18next'
import EventResultFilterForm from '../event-result-filter-form/event-result-filter-form'
import TableMobileTitle from '../../../../core/table-mobile-title/table-mobile-title'
import EventAllResultsModal from '../event-all-results-modal/event-all-results-modal'

interface props {}

const EventResults: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { isFetching, isLoading, isError, error, data } = useTestEventResults(id)
  const { t } = useTranslation()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { data: results, ...pagination } = data!

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => (
        <React.Fragment>
          <TableMobileTitle title={'ID'} />
          <span># {id}</span>
        </React.Fragment>
      ),
    },
    {
      title: t('firstName'),
      key: 'user',
      render: ({ id, user }: ITestResult) => (
        <React.Fragment>
          <TableMobileTitle title={t('firstName')} />
          <Link to={'/testing/results/details/' + id}>{user.name}</Link>
        </React.Fragment>
      ),
    },
    {
      title: t('testing:rightAnswers'),
      dataIndex: 'correct_answers',
      render: (c: number) => (
        <React.Fragment>
          <TableMobileTitle title={t('testing:rightAnswers')} />
          {c}
        </React.Fragment>
      ),
    },
    {
      title: t('testing:wrongAnswers'),
      dataIndex: 'incorrect_answers',
      render: (ic: number) => (
        <React.Fragment>
          <TableMobileTitle title={t('testing:wrongAnswers')} />
          {ic}
        </React.Fragment>
      ),
    },
    {
      title: t('result'),
      dataIndex: 'correct_percent',
      render: (percent: number) => (
        <React.Fragment>
          <TableMobileTitle title={t('result')} />
          <EventResultPercent percent={percent} />
        </React.Fragment>
      ),
    },
    {
      title: t('testing:timeTakenToSolveTheTest'),
      dataIndex: 'spent_time',
      render: (spent_time: string) => (
        <React.Fragment>
          <TableMobileTitle title={t('testing:timeTakenToSolveTheTest')} />
          {spent_time}
        </React.Fragment>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right',
      render: (data: ITestResult) => (
        <EventAllResultsModal studentName={data.user.name} testId={id} studentId={data.user_id} />
      ),
    },
  ]

  return (
    <EventResultTable
      columns={columns}
      dataSource={results}
      isLoading={isFetching}
      pagination={pagination}
      fetchingQueryKey={[TEST_EVENT_RESULTS_KEY, id]}
      title={() => <EventResultFilterForm fetchingQueryKey={[TEST_EVENT_RESULTS_KEY, id]} />}
    />
  )
}

export default EventResults
