import React from 'react'
import { IStudentDiscount } from '../../../../../interfaces'
import { Divider, message, Tag } from 'antd'
import moment from 'moment'
import { useMutation } from 'react-query'
import { deleteStudentDiscount } from '../../../../../api/discounts'
import { useTranslation } from 'react-i18next'

interface props {
  discount: IStudentDiscount
}

const GroupStudentDiscount: React.FC<props> = ({ discount }) => {
  const { date, id, percent } = discount
  const { t } = useTranslation()

  const { mutateAsync } = useMutation(deleteStudentDiscount, {
    onSuccess: () => {
      message.success(t('responseSuccess'))
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const handleDeleteDiscount = () => mutateAsync(id)

  return (
    <Tag color={'green'} onClose={handleDeleteDiscount} closable>
      {moment(date).format('MMMM YYYY')} <Divider type={'vertical'} /> {percent}%
    </Tag>
  )
}

export default GroupStudentDiscount
