import React from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import FinancesGroupDifference from '../../finance-difference/finances-difference'
import { priceFormatter } from '../../../../../lib/priceFormatter'
import { IFinanceGroupSummary } from '../../../../../interfaces'
import isEqual from 'react-fast-compare'
import DateWithMonth from '../../../../core/date-with-month/date-with-month'

interface props {
  summary: IFinanceGroupSummary[]
}

const FinanceGroupSummary: React.FC<props> = ({ summary }) => {
  const { t } = useTranslation(['translation', 'groups'])

  const columns = [
    {
      title: t('month'),
      key: 'range',
      fixed: 'left',
      render: ({ first_day, last_day }: IFinanceGroupSummary) => (
        <span>
          <DateWithMonth date={first_day} /> - <DateWithMonth date={last_day} />
        </span>
      ),
    },
    {
      title: t('groups:studentsCount'),
      dataIndex: 'students_count',
    },

    {
      title: t('numberOfLessons'),
      dataIndex: 'lessons_count',
    },
    {
      title: t('missedForReason'),
      dataIndex: 'reasons_count',
    },
    {
      title: t('coursePrice'),
      dataIndex: 'course_price',
      render: (text: number) => <span>{priceFormatter(text)}</span>,
    },
    {
      title: t('lessonPrice'),
      dataIndex: 'lesson_price',
      render: (text: number) => <span>{priceFormatter(text)}</span>,
    },
    {
      title: t('reasonSum'),
      dataIndex: 'reasons_total',
      render: (text: number) => <span>{priceFormatter(text)}</span>,
    },
    {
      title: t('totalPayment'),
      dataIndex: 'total',
      render: (text: number) => <span>{priceFormatter(text)}</span>,
    },
    {
      title: t('paidUp'),
      dataIndex: 'paid',
      render: (text: number) => <span>{priceFormatter(text)}</span>,
    },
    {
      title: t('difference'),
      dataIndex: 'difference',
      render: (text: number) => <FinancesGroupDifference difference={text} />,
    },
  ]

  return (
    <Table
      bordered={true}
      dataSource={summary}
      //@ts-ignore
      columns={columns}
      pagination={false}
      scroll={{ x: true }}
      rowKey={'first_day'}
      summary={(pageData: readonly IFinanceGroupSummary[]) => {
        if (pageData.length) {
          const reason = pageData
            .map(f => f.reasons_total)
            .reduce((v, sum) => Number(v) + Number(sum))

          const total = pageData.map(f => f.total).reduce((v, sum) => Number(v) + Number(sum))

          const paidUp = pageData.map(f => f.paid).reduce((v, sum) => Number(v) + Number(sum))

          const difference = pageData
            .map(f => f.difference)
            .reduce((v, sum) => Number(v) + Number(sum))

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={6} index={1}>
                <b>{t('total')}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <b>{priceFormatter(reason)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <b>{priceFormatter(total)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <b>{priceFormatter(paidUp)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <FinancesGroupDifference difference={difference} />
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )
        }
      }}
    />
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps.summary, nextProps.summary)
}

export default React.memo(FinanceGroupSummary, propsAreEqual)
