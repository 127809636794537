import React from 'react'
import { Form, Switch } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {}

const StatusSwitch: React.FC<props> = () => {
  const { t } = useTranslation()

  return (
    <Form.Item name="status" label={t('status')} valuePropName="checked">
      <Switch
        checkedChildren={<span>{t('enabled')}</span>}
        unCheckedChildren={<span>{t('disabled')}</span>}
      />
    </Form.Item>
  )
}

export default StatusSwitch
