import React from 'react'
import ErrorAlert from '../../core/error-alert/error-alert'
import { PageHeader, Skeleton } from 'antd'
import CoursesTable from './courses-table/courses-table'
import MyPagination from '../../core/my-pagination/my-pagination'
import { useTranslation } from 'react-i18next'
import CoursesDrawer from './courses-drawer/courses-drawer'
import Head from '../../../hoc/head/head'
import { COURSES_KEY, useCourses } from '../../../hooks/query/courses'
import { useRoleChecker } from '../../../hooks/useRoleChecker'
import FiltersCard from '../../core/filters-card/filters-card'
import CoursesFilters from './courses-filters/courses-filters'

interface props {}

const Courses: React.FC<props> = () => {
  const { t } = useTranslation(['translation', 'course'])
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception'])

  const { isLoading, isError, error, data } = useCourses()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { data: courses, ...pagination } = data!

  return (
    <Head title={t('courses')}>
      <PageHeader
        title={t('courses')}
        subTitle={t('course:coursesSubtitle')}
        onBack={() => window.history.back()}
        extra={[access && <CoursesDrawer key={1} title={'addTitle'} />]}
      />
      <FiltersCard>
        <CoursesFilters />
      </FiltersCard>
      <CoursesTable courses={courses} />
      <MyPagination pagination={pagination} fetchingQueryKey={COURSES_KEY} />
    </Head>
  )
}

export default Courses
