import React from 'react'
import { useParams } from 'react-router-dom'
import { Col, PageHeader, Row, Skeleton } from 'antd'
import CourseCard from './course-card/course-card'
import { useTranslation } from 'react-i18next'
import CoursesDrawer from './courses-drawer/courses-drawer'
import Head from '../../../hoc/head/head'
import { useRoleChecker } from '../../../hooks/useRoleChecker'
import { useCourse } from '../../../hooks/query/courses'
import ErrorAlert from '../../core/error-alert/error-alert'
import CourseTabs from './course-tabs/course-tabs'

interface props {}

const Course: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception'])

  const { t } = useTranslation(['translation', 'course'])

  const { isLoading, isError, error, data } = useCourse(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { name, children, groups } = data!

  return (
    <Head title={name}>
      <PageHeader
        title={name}
        subTitle={t('course:courseSubtitle')}
        onBack={() => window.history.back()}
        extra={[
          access && <CoursesDrawer key={'1'} updateOne={true} title={'editTitle'} course={data!} />,
        ]}
      />
      <Row gutter={[32, 32]}>
        <Col xs={24}>
          <CourseCard course={data!} />
        </Col>
        <Col xs={24}>
          <CourseTabs childrenCourses={children} groups={groups} />
        </Col>
      </Row>
    </Head>
  )
}

export default Course
