import React from 'react'
import { Badge, Card, List, Tabs, Typography } from 'antd'
import EventResults from '../event-results/event-results'
import { IQuestionBase } from '../../../../../interfaces'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuthUserInformation } from '../../../../../hooks/query/auth'
import EventStudentResult from '../event-student-result/event-student-result'
import { idType } from '../../../../../interfaces/types/types'

interface props {
  userId: idType
  questionBases: IQuestionBase[]
}

const EventTabs: React.FC<props> = ({ questionBases, userId }) => {
  const { data } = useAuthUserInformation()
  const { t } = useTranslation(['translation', 'testing'])

  const isStudent = data!.roles_slug.includes('student')

  return (
    <Card bordered={false}>
      <Tabs defaultActiveKey={'1'}>
        <Tabs.TabPane tab={t('testing:testsResult')} key={'1'}>
          {isStudent ? (
            <EventStudentResult studentName={data!.name} studentId={data!.id} />
          ) : (
            <EventResults />
          )}
        </Tabs.TabPane>
        {userId === data!.id && (
          <Tabs.TabPane
            tab={
              <Badge count={questionBases.length} offset={[10, -5]}>
                {t('testing:selectedTests')}
              </Badge>
            }
            key={'2'}
          >
            <List
              itemLayout={'vertical'}
              dataSource={questionBases}
              renderItem={(item: IQuestionBase) => (
                <List.Item>
                  <Typography.Title level={5}>
                    <Link to={'/testing/tests/details/' + item.id}>{item.title}</Link>
                  </Typography.Title>
                </List.Item>
              )}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Card>
  )
}

export default EventTabs
