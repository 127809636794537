import React from 'react'
import { Space } from 'antd'
import TestModal from '../test-modal/test-modal'
import DeleteModal from '../../../../core/delete-modal/delete-modal'
import { deleteQuestionBaseApi } from '../../../../../api/question-bases'
import { QUESTION_BASES_KEY } from '../../../../../hooks/query/question-bases'
import { idType } from '../../../../../interfaces/types/types'
import { useAuthUserInformation } from '../../../../../hooks/query/auth'

interface props {
  title: string
  testId: idType
  teacherId: idType
}

const TestActions: React.FC<props> = ({ title, testId, teacherId }) => {
  const authUserInformation = useAuthUserInformation()
  const access = teacherId === authUserInformation.data?.id

  if (!access) return <React.Fragment />

  return (
    <Space>
      <TestModal testName={title} testId={testId} />
      <DeleteModal
        deleteFunction={deleteQuestionBaseApi}
        fetchingQueryKey={QUESTION_BASES_KEY}
        id={testId}
        redirect={true}
        redirectTo={'/testing/tests'}
        modalText={'testing:deleteTest'}
      />
    </Space>
  )
}

export default TestActions
