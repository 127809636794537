import React from 'react'

interface props {
  phones: string[] | undefined
}

const BranchPhones: React.FC<props> = ({ phones }) => {
  return <>{phones ? phones.map((phone, i) => <div key={i}>+998 {phone}</div>) : '-'}</>
}

export default React.memo(BranchPhones)
