import React from 'react'
import { IGroup } from '../../../interfaces'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { idType } from '../../../interfaces/types/types'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import GroupDays from '../../modules/groups/group-days/group-days'
import GroupPeriodOfActivity from '../group-period-of-activity/group-period-of-activity'
import TableMobileTitle from '../table-mobile-title/table-mobile-title'

interface props {
  groups: IGroup[]
}

const GroupList: React.FC<props> = ({ groups }) => {
  const { t } = useTranslation(['translation', 'groups'])

  let columns: ColumnsType<IGroup> = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (id: idType) => (
        <>
          <TableMobileTitle title={'ID'} /># {id}
        </>
      ),
    },
    {
      title: t('name'),
      key: 'name',
      render: ({ name, id }: IGroup) => (
        <>
          <TableMobileTitle title={t('name')} />
          <Link to={`/groups/details/${id}`}>{name}</Link>
        </>
      ),
    },
    {
      title: `${t('groups:groupStartTime')} - ${t('groups:groupEndTime')}`,
      key: 'lesson_duration',
      render: ({ start_time, end_time }: IGroup) => (
        <>
          <TableMobileTitle title={`${t('groups:groupStartTime')} - ${t('groups:groupEndTime')}`} />
          <span>
            {start_time} - {end_time}
          </span>
        </>
      ),
    },
    {
      title: t('days'),
      dataIndex: 'days',
      render: (days: string[]) => (
        <>
          <TableMobileTitle title={t('days')} />
          <GroupDays days={days} />
        </>
      ),
    },
    {
      title: t('period'),
      key: 'period',
      render: ({ start_date, end_date }: IGroup) => (
        <>
          <TableMobileTitle title={t('period')} />
          <GroupPeriodOfActivity startDate={start_date} endDate={end_date} />
        </>
      ),
    },
  ]

  return (
    <Table
      className={'adaptive-table'}
      columns={columns}
      dataSource={groups}
      scroll={{ x: true }}
      rowKey={'id'}
      pagination={false}
    />
  )
}

export default React.memo(GroupList)
