import React from 'react'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { IGroup } from '../../../../interfaces'
import DashboardGroup from '../dashboard-group/dashboard-group'

interface props {
  groups: IGroup[] | undefined
  loaded: boolean
}

const days: { key: string; day: string }[] = [
  {
    key: 'mon',
    day: 'monday',
  },
  {
    key: 'tue',
    day: 'tuesday',
  },
  {
    key: 'wed',
    day: 'wednesday',
  },
  {
    key: 'thi',
    day: 'thursday',
  },
  {
    key: 'fri',
    day: 'friday',
  },
  {
    key: 'sat',
    day: 'saturday',
  },
  {
    key: 'sun',
    day: 'sunday',
  },
]

const DashboardGroupTable: React.FC<props> = ({ groups, loaded }) => {
  const { t } = useTranslation()

  const renderGroup = (key: string, group: IGroup) => {
    if (group.days.includes(key)) {
      return <DashboardGroup group={group} />
    }
  }

  const columns = days.map(({ day, key }) => ({
    title: t(day),
    key,
    render: (group: IGroup) => renderGroup(key, group),
  }))

  let sameKey: any

  return (
    <Table
      dataSource={groups}
      rowKey={'id'}
      columns={[
        {
          title: '',
          dataIndex: 'start_time',
          align: 'center' as 'center',
          render: (value: string) => {
            const obj: any = {
              children: <strong>{value}</strong>,
              props: {},
            }
            if (!(sameKey !== value)) {
              obj.props.rowSpan = 0
              return obj
            }
            const count = groups?.filter(item => item.start_time === value).length

            sameKey = value
            obj.props.rowSpan = count

            return obj
          },
        },
        ...columns,
      ]}
      pagination={false}
      scroll={{ x: true }}
      loading={loaded}
    />
  )
}

export default DashboardGroupTable
