import React, { useEffect, useState } from 'react'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { ACCESS_TOKEN } from '../constants/auth'
import axios from 'axios'
//@ts-ignore
window.Pusher = Pusher

export const SocketContext = React.createContext<Echo | null>(null)

export const SocketProvider: React.FC<any> = ({ children }) => {
  const [echo, setEcho] = useState<Echo | null>(null)

  useEffect(() => {
    setEcho(
      new Echo({
        broadcaster: 'pusher',
        key: process.env.REACT_APP_WS_KEY,
        cluster: process.env.REACT_APP_WS_CLUSTER,
        wsHost: process.env.REACT_APP_WS_HOST,
        wsPort: process.env.REACT_APP_WS_PORT,
        disableStats: true,
        encrypted: true,
        forceTLS: false,
        authorizer: (channel: any, options: any) => {
          return {
            authorize: (socketId: any, callback: any) => {
              axios
                .post(
                  process.env.REACT_APP_API_URL + '/broadcasting/auth',
                  {
                    socket_id: socketId,
                    channel_name: channel.name,
                  },
                  {
                    headers: {
                      Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                  },
                )
                .then((response: any) => {
                  callback(false, response.data)
                })
                .catch((error: any) => {
                  callback(true, error)
                })
            },
          }
        },
      }),
    )
  }, [])

  return <SocketContext.Provider value={echo}>{children}</SocketContext.Provider>
}
