import React from 'react'
import styles from '../layout.module.scss'
import { Layout } from 'antd'

interface props {}

const LayoutContent: React.FC<props> = ({ children }) => {
  return <Layout.Content className={styles.content}>{children}</Layout.Content>
}

export default LayoutContent
