import React from 'react'
import { Dropdown, Space } from 'antd'
import { AppstoreOutlined } from '@ant-design/icons'
import styles from './layout-dropdown.module.scss'
import StudentsDrawer from '../../../app/modules/students/students-drawer/students-drawer'
import StudentPaymentsDrawer from '../../../app/modules/students/student-payments/student-payments-drawer/student-payments-drawer'
import { useTranslation } from 'react-i18next'
import LayoutLanguage from '../layout-language/layout-language'
import { useRoleChecker } from '../../../hooks/useRoleChecker'
import {
  STUDENT_GROUPS_KEY,
  STUDENT_PAYMENTS_KEY,
  STUDENTS_KEY,
} from '../../../hooks/query/students'
import LayoutBranches from '../layout-branches/layout-branches'

interface props {}

const LayoutDropdown: React.FC<props> = () => {
  const { roleChecker } = useRoleChecker()
  const addStudentAccess = roleChecker(['admin', 'reception'])
  const paymentAccess = roleChecker(['admin', 'cashier'])
  const isStudent = roleChecker(['student'])

  const { t } = useTranslation()

  const renderDropdown = () => {
    if (addStudentAccess || paymentAccess) {
      return (
        <div className={styles.content}>
          <h4 className={styles.title}>{t('students')}</h4>
          <Space>
            {addStudentAccess && <StudentsDrawer title={'addTitle'} />}
            {paymentAccess && (
              <StudentPaymentsDrawer
                paymentQueries={[STUDENT_PAYMENTS_KEY, STUDENT_GROUPS_KEY, STUDENTS_KEY]}
                btnStyle={true}
              />
            )}
          </Space>
        </div>
      )
    }
  }

  const content = (
    <div className={styles.dropdown}>
      {renderDropdown()}
      {!isStudent && (
        <div className={styles.content}>
          <h4 className={styles.title}>{t('branches')}</h4>
          <LayoutBranches />
        </div>
      )}
      <div className={styles.content}>
        <LayoutLanguage />
      </div>
    </div>
  )

  return (
    <Dropdown overlay={content} placement="bottomCenter" arrow trigger={['click']}>
      <AppstoreOutlined className={styles.icon} />
    </Dropdown>
  )
}

export default LayoutDropdown
