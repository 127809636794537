import React from 'react'
import { Layout, Space } from 'antd'
import styles from './layout-header.module.scss'
import LayoutUser from '../layout-user/layout-user'
import LayoutFullscreenButton from '../layout-fullscreen-button/layout-fullscreen-button'
import LayoutDropdown from '../layout-dropdown/layout-dropdown'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import LayoutActiveBranch from '../layout-active-branch/layout-active-branch'
import { useRoleChecker } from '../../../hooks/useRoleChecker'

interface props {
  collapsed: boolean
  setCollapsed: (v: boolean) => void
}

const LayoutHeader: React.FC<props> = ({ collapsed, setCollapsed }) => {
  const changeCollapseHandler = () => setCollapsed(!collapsed)
  const { roleChecker } = useRoleChecker()
  const isStudent = roleChecker(['student'])

  return (
    <Layout.Header className={[styles.header, collapsed ? styles.collapsed : ''].join(' ')}>
      <div className={styles.wrapper}>
        <div className={styles.triggerWrapper}>
          <span onClick={changeCollapseHandler} className={styles.trigger}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </span>
        </div>
        <Space align={'center'} size={25}>
          {!isStudent && <LayoutActiveBranch />}
          <LayoutDropdown />
          <LayoutFullscreenButton />
          <LayoutUser />
        </Space>
      </div>
    </Layout.Header>
  )
}

export default LayoutHeader
