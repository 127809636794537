import React from 'react'
import { STUDENTS_PAYMENTS_KEY, useStudentsPayments } from '../../../hooks/query/payments'
import { Divider, PageHeader, Skeleton } from 'antd'
import Head from '../../../hoc/head/head'
import { useTranslation } from 'react-i18next'
import FiltersCard from '../../core/filters-card/filters-card'
import PaymentHistoryFilters from './payment-history-filters/payment-history-filters'
import PaymentsHistoryTable from './payment-history-table/payments-history-table'
import ErrorAlert from '../../core/error-alert/error-alert'
import MyPagination from '../../core/my-pagination/my-pagination'
import PaymentsTotal from '../../core/payments-total/payments-total'

interface props {}

const PaymentsHistory: React.FC<props> = () => {
  const { t } = useTranslation()
  const { isLoading, isError, error, data: response } = useStudentsPayments()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const {
    payments: { data, ...pagination },
    sum,
  } = response!

  return (
    <Head title={t('paymentsHistory')}>
      <PageHeader title={t('paymentsHistory')} onBack={() => window.history.back()} />
      <FiltersCard>
        <PaymentHistoryFilters />
      </FiltersCard>
      <PaymentsTotal sum={sum} totalPayments={pagination.total} />
      <Divider />
      <PaymentsHistoryTable payments={data} />
      <MyPagination pagination={pagination} fetchingQueryKey={STUDENTS_PAYMENTS_KEY} />
    </Head>
  )
}

export default PaymentsHistory
