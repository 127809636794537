import React from 'react'
import { useParams } from 'react-router-dom'
import { useTestEvent } from '../../../../hooks/query/events'
import { Card, Col, Descriptions, PageHeader, Row, Skeleton } from 'antd'
import ErrorAlert from '../../../core/error-alert/error-alert'
import Head from '../../../../hoc/head/head'
import { useTranslation } from 'react-i18next'
import EventActions from './event-actions/event-actions'
import { descriptionsSettings } from '../../../../lib/settings'
import CourseLink from '../../../core/course-link/course-link'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import TeacherLink from '../../../core/teacher-link/teacher-link'
import EventTabs from './event-tabs/event-tabs'

const { Item } = Descriptions

interface props {}

const Event: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation(['translation', 'testing'])
  const { roleChecker } = useRoleChecker()

  const access = roleChecker(['admin', 'teacher'])

  const { isLoading, isError, error, data } = useTestEvent(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { title, course, user, password, questions_count, question_bases, start_at, end_at } = data!

  return (
    <Head title={title}>
      <PageHeader
        title={title}
        subTitle={t('detailedInformation')}
        onBack={() => window.history.back()}
        extra={[
          <EventActions
            updateOne={true}
            key={'1'}
            event={data!}
            redirect={true}
            redirectTo={'/testing/events'}
          />,
        ]}
      />
      <Row gutter={[0, 32]}>
        <Col xs={24}>
          <Card bordered={false} title={t('generalInformation')}>
            <Descriptions {...descriptionsSettings} column={{ xs: 1, md: 3 }}>
              <Item label={t('name')}>{title}</Item>
              <Item label={t('teacher')}>
                <TeacherLink id={user.id} name={user.name} />
              </Item>
              <Item label={t('course')}>
                <CourseLink courseId={course.id} courseName={course.name} />
              </Item>
              <Item label={t('questionsCount')}>{questions_count}</Item>
              <Item label={t('period')}>
                {start_at} - {end_at}
              </Item>
              {access && <Item label={t('password')}>{password}</Item>}
            </Descriptions>
          </Card>
        </Col>
        <Col xs={24}>
          <EventTabs userId={user.id} questionBases={question_bases} />
        </Col>
      </Row>
    </Head>
  )
}

export default Event
