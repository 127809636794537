import React, { useState } from 'react'
import { Button, Drawer } from 'antd'
import { ITestQuestion } from '../../../../../interfaces'
import { useTranslation } from 'react-i18next'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import TestQuestionForm from '../test-question-form/test-question-form'

interface props {
  question?: ITestQuestion
}

const TestQuestionDrawer: React.FC<props> = ({ question }) => {
  const { t } = useTranslation(['translation', 'testing'])
  const [visible, setVisible] = useState<boolean>(false)
  const modalHandler = () => setVisible(!visible)

  const title = t(question ? 'testing:editQuestion' : 'testing:addQuestion')

  return (
    <React.Fragment>
      <Button
        type={'primary'}
        onClick={modalHandler}
        icon={question ? <EditOutlined /> : <PlusCircleOutlined />}
      >
        {title}
      </Button>
      <Drawer
        width={window.screen.width < 1200 ? '100%' : 1200}
        visible={visible}
        title={title}
        footer={false}
        onClose={modalHandler}
      >
        <TestQuestionForm modalHandler={modalHandler} question={question} />
      </Drawer>
    </React.Fragment>
  )
}

export default TestQuestionDrawer
