import { request as axios } from './index'
import { idType } from '../interfaces/types/types'
import { ICourse } from '../interfaces'

export function getAllCoursesApi() {
  return axios.get('/courses')
}

export function getCoursesListApi() {
  return axios.get('/courses/list')
}

export function getCourseByIdApi(id: idType) {
  return axios.get(`/courses/${id}`)
}

export function createCourseApi(course: ICourse) {
  return axios.post('/courses', course)
}

export function updateCourseApi(course: ICourse) {
  return axios.patch(`/courses/${course.id}`, course)
}

export function deleteCourseApi(id: idType) {
  return axios.delete(`/courses/${id}`)
}

export function getCoursePriceHistory(id: idType) {
  return axios.get('/courses/' + id + '/prices')
}
