import React from 'react'
import { Col, Row } from 'antd'
import DashboardCard from '../dashboard-card/dashboard-card'
import {
  QuestionCircleOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import { useDashboardStatistic } from '../../../../hooks/query/dashboard'
import ErrorAlert from '../../../core/error-alert/error-alert'

interface props {}

const DashboardStatistics: React.FC<props> = () => {
  const { t } = useTranslation()
  const { isError, error, data } = useDashboardStatistic()
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin'])

  if (isError) return <ErrorAlert msg={error?.message} />
  return (
    <React.Fragment>
      <h2>{t('statistics')}</h2>
      <Row gutter={[16, 32]}>
        <Col flex={1}>
          <DashboardCard
            title={'newBids'}
            value={data?.bids_count}
            icon={UserAddOutlined}
            url={'leads'}
          />
        </Col>
        <Col flex={1}>
          <DashboardCard
            title={'numberOfGroups'}
            value={data?.groups_count}
            icon={TeamOutlined}
            url={'groups'}
          />
        </Col>
        <Col flex={1}>
          <DashboardCard
            title={'numberOfActiveStudents'}
            value={data?.students_count}
            icon={UserOutlined}
            url={'students'}
          />
        </Col>
        <Col flex={1}>
          <DashboardCard
            title={'numberOfDebtors'}
            value={data?.debtors_count}
            icon={QuestionCircleOutlined}
            url={'finances/debtors'}
            color={'#fa8c16'}
          />
        </Col>
        {access && (
          <Col flex={1}>
            <DashboardCard
              title={'debtPerMonth'}
              value={data?.debtors_sum}
              icon={WarningOutlined}
              url={'finances/groups'}
              color={'#cf1322'}
            />
          </Col>
        )}
      </Row>
    </React.Fragment>
  )
}

export default React.memo(DashboardStatistics)
