import React, { useState } from 'react'
import { Card, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import StudentPayments from '../student-payments/student-payments'
import StudentsAddToGroup from '../students-add-to-group/students-add-to-group'
import StudentPaymentsDrawer from '../student-payments/student-payments-drawer/student-payments-drawer'
import StudentGroups from '../student-groups/student-groups'
import { useHistory } from 'react-router-dom'
import { getQueryParam } from '../../../../lib/getQueryParam'
import { serialize } from '../../../../lib/serializeQuery'
import { STUDENT_PAYMENTS_KEY, STUDENTS_KEY } from '../../../../hooks/query/students'
import StudentEventsResults from '../student-events-results/student-events-results'
import { idType } from '../../../../interfaces/types/types'
import { IStudentNameId } from '../../../../interfaces'

const { TabPane } = Tabs

interface props {
  id: idType
  name: string
}

type keyType = 'groups' | 'payments'

const StudentTabs: React.FC<props> = ({ id, name }) => {
  const { replace } = useHistory()
  const { roleChecker } = useRoleChecker()

  const defaultActiveKey = () => {
    if (getQueryParam('tab')) return getQueryParam('tab') as keyType
    return isCashier ? 'payments' : 'groups'
  }

  const isCashier = roleChecker(['cashier'])
  const [activeKey, setActiveKey] = useState<keyType>(defaultActiveKey())

  const activeKeyHandler = (key: string) => {
    replace({
      search: serialize('tab', key),
    })
    setActiveKey(key as keyType)
  }

  const { t } = useTranslation(['translation', 'students'])

  const receptionAccess = roleChecker(['admin', 'reception'])
  const paymentAccess = roleChecker(['admin', 'cashier'])

  const studentNameId: IStudentNameId = { id, name }

  const renderExtraContent = () => {
    if (activeKey === 'groups' && receptionAccess) return <StudentsAddToGroup studentId={id} />
    if (activeKey === 'payments' && paymentAccess)
      return (
        <StudentPaymentsDrawer
          btnStyle
          student={studentNameId}
          paymentQueries={[
            [STUDENT_PAYMENTS_KEY, id!.toString()],
            [STUDENTS_KEY, id!.toString()],
          ]}
        />
      )
  }

  return (
    <Card bordered={false}>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey={activeKey}
        onChange={activeKeyHandler}
        tabBarExtraContent={renderExtraContent()}
      >
        <TabPane tab={t('students:groupsList')} key={'groups'}>
          <StudentGroups name={name} id={id} />
        </TabPane>

        <TabPane tab={t('paymentsHistory')} key={'payments'}>
          <StudentPayments name={name} />
        </TabPane>

        <TabPane tab={t('testing')} key={'tests'}>
          <StudentEventsResults studentId={id} />
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default React.memo(StudentTabs)
