import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer, Form, message } from 'antd'
import { drawerSettings } from '../../../../../lib/settings'
import DrawerFooter from '../../../../core/drawer/drawer-footer/drawer-footer'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import StudentPaymentsForm from '../student-payments-form/student-payments-form'
import { addNewStudentPaymentApi, changeStudentPaymentApi } from '../../../../../api/payments'
import { IStudentNameId, IStudentPayment } from '../../../../../interfaces'
import { idType } from '../../../../../interfaces/types/types'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../../index'
import moment from 'moment'

interface props {
  student?: IStudentNameId
  groupId?: idType
  btnStyle?: boolean
  paymentQueries: Array<string | string[]>
  payment?: IStudentPayment
}

const StudentPaymentsDrawer: React.FC<props> = ({
  student,
  groupId,
  btnStyle = false,
  paymentQueries,
  payment,
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const drawerToggle = () => setVisible(!visible)
  const [form] = Form.useForm()

  const { t } = useTranslation()

  const onSuccess = async () => {
    drawerToggle()
    const requests = paymentQueries.map(query => queryClient.invalidateQueries(query))
    Promise.all(requests).finally(() => {
      message.success(t('responseSuccess'))
      form.resetFields()
    })
  }
  const onError = (e: any) => {
    message.error(e.response.data.message)
  }

  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(addNewStudentPaymentApi, {
    onSuccess,
    onError,
  })
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(changeStudentPaymentApi, {
    onSuccess,
    onError,
  })

  const submitForm = (fieldsValues: any) => {
    const handler = payment ? update : create
    return handler({
      ...fieldsValues,
      date: moment(fieldsValues['date']).format('YYYY-MM-DD'),
    })
  }

  const title = payment ? t('editPayment') : t('addPayment')

  return (
    <>
      <button className={btnStyle ? 'ant-btn' : 'ant-btn-no-style'} onClick={drawerToggle}>
        {payment ? <EditOutlined /> : <PlusCircleOutlined />} {title}
      </button>
      <Drawer
        {...drawerSettings}
        onClose={drawerToggle}
        title={title}
        visible={visible}
        footer={
          <DrawerFooter
            disabled={isCreateLoading || isUpdateLoading}
            drawerToggle={drawerToggle}
            formId={'payments-form'}
          />
        }
      >
        <StudentPaymentsForm
          form={form}
          formId={'payments-form'}
          student={student}
          groupId={groupId}
          submitForm={submitForm}
          payment={payment}
        />
      </Drawer>
    </>
  )
}

export default StudentPaymentsDrawer
