import React from 'react'
import { Form, Radio } from 'antd'
import { useTranslation } from 'react-i18next'

interface props {
  required?: boolean
  name?: string
}

const FormPaymentMethods: React.FC<props> = ({ name = 'method', required = true }) => {
  const { t } = useTranslation()

  return (
    <Form.Item
      label={t('paymentMethod')}
      name={name}
      rules={[
        {
          required,
          message: t('required'),
        },
      ]}
    >
      <Radio.Group>
        <Radio value={'cash'}>{t('cash')}</Radio>
        <Radio value={'card'}>{t('card')}</Radio>
        <Radio value={'bank'}>{t('bank')}</Radio>
      </Radio.Group>
    </Form.Item>
  )
}

export default FormPaymentMethods
