import React, { useState } from 'react'
import { Col, Form, Row, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import FilterButtons from '../../../core/form-group/filter-buttons/filter-buttons'
import { grid } from '../../../../constants/filtersGrid'
import FormItemSelect from '../../../core/form-group/form-item-select/form-item-select'
import SearchInput from '../../../core/form-group/search-input/search-input'
import MyDatePicker from '../../../core/form-group/my-date-picker/my-date-picker'
import { serialize } from '../../../../lib/serializeQuery'
import { queryClient } from '../../../../index'
import { TEACHERS_KEY } from '../../../../hooks/query/teachers'
import moment from 'moment'
import queryString from 'query-string'

interface props {}

const TeachersFilters: React.FC<props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { push, location } = useHistory()

  const onFinish = async (values: any) => {
    setIsLoading(true)
    const query = {
      ...values,
      created_at: values.created_at && moment(values.created_at).format('YYYY-MM-DD'),
    }
    await push({
      search: serialize(query),
    })
    await queryClient.invalidateQueries(TEACHERS_KEY)
    setIsLoading(false)
  }

  const onReset = async () => {
    setIsLoading(true)
    await push({ search: '' })
    form.resetFields()
    await queryClient.invalidateQueries(TEACHERS_KEY)
    setIsLoading(false)
  }

  const initialValues = () => {
    const qs = queryString.parse(location.search)
    return {
      ...qs,
      created_at: qs.created_at ? moment(qs.created_at, 'YYYY-MM-DD') : undefined,
    }
  }

  return (
    <Form form={form} layout={'vertical'} onFinish={onFinish} initialValues={initialValues()}>
      <Row gutter={[16, 0]}>
        <Col {...grid}>
          <FormItemSelect name={'status'} label={'status'}>
            <Select.Option value={'true'}>{t('teachers:active')}</Select.Option>
            <Select.Option value={'false'}>{t('teachers:inactive')}</Select.Option>
          </FormItemSelect>
        </Col>
        {/*<Col {...grid}>*/}
        {/*	<FormItemSelect label={'salary'} name={'salary'}>*/}
        {/*		<Select.Option value={'true'}>*/}
        {/*			Зарплата указана*/}
        {/*		</Select.Option>*/}
        {/*		<Select.Option value={'false'}>*/}
        {/*			Зарплата отсутствует*/}
        {/*		</Select.Option>*/}
        {/*	</FormItemSelect>*/}
        {/*</Col>*/}
        {/*<Col {...grid}>*/}
        {/*	<FormItemSelect label={'percentage'} name={'percentage'}>*/}
        {/*		<Select.Option value={'true'}>*/}
        {/*			Процент указан*/}
        {/*		</Select.Option>*/}
        {/*		<Select.Option value={'false'}>*/}
        {/*			Процент отсутствует*/}
        {/*		</Select.Option>*/}
        {/*	</FormItemSelect>*/}
        {/*</Col>*/}
        <Col {...grid}>
          <MyDatePicker
            required={false}
            name={'created_at'}
            label={'createdAt'}
            disableFutureDays={true}
          />
        </Col>
        <Col flex={1}>
          <SearchInput label={'firstnamePhoneSearch'} />
        </Col>
        <Col xs={24}>
          <FilterButtons isLoading={isLoading} onReset={onReset} />
        </Col>
      </Row>
    </Form>
  )
}

export default TeachersFilters
