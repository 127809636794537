import React from 'react'
import { Card, Divider, Space, Typography } from 'antd'
import { priceFormatter } from '../../../lib/priceFormatter'
import queryString from 'query-string'
import { history } from '../../../api'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import styles from './payments-total.module.scss'

interface props {
  sum: string
  totalPayments: number
}

const PaymentsTotal: React.FC<props> = ({ sum, totalPayments }) => {
  const { t } = useTranslation()
  const qs = queryString.parse(history.location.search)
  const created_at = qs.created_at ? qs.created_at.toString().split(';') : undefined

  return (
    <Card
      bordered={false}
      title={
        created_at
          ? t('fromTo', {
              from: moment(created_at[0]).format('DD MMMM YYYY'),
              to: moment(created_at[1]).format('DD MMMM YYYY'),
            })
          : t('allPeriod')
      }
    >
      <Space split={<Divider type={'vertical'} />}>
        <Typography.Text className={styles.text}>
          {t('amount')} : <strong>{priceFormatter(sum)}</strong>
        </Typography.Text>
        <Typography.Text className={styles.text}>
          {t('paymentsCount')}: <strong>{totalPayments}</strong>
        </Typography.Text>
      </Space>
    </Card>
  )
}

export default PaymentsTotal
