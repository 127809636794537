import { IRoom, IRoomsResponse } from '../../interfaces'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosResponse } from 'axios'
import { getAllRoomsApi, getRoomByIdApi, getRoomsListApi } from '../../api/rooms'
import { idType } from '../../interfaces/types/types'

export const ROOMS_KEY = 'rooms_key'
export const ROOMS_LIST_KEY = 'rooms_list_key'

export function useRooms(options?: UseQueryOptions<IRoomsResponse, Error>) {
  return useQuery<IRoomsResponse, Error>(
    ROOMS_KEY,
    () => getAllRoomsApi().then((response: AxiosResponse<IRoomsResponse>) => response.data),
    {
      ...options,
    },
  )
}

export function useRoomsList(options?: UseQueryOptions<IRoom[], Error>) {
  return useQuery<IRoom[], Error>(
    ROOMS_LIST_KEY,
    () => getRoomsListApi().then((response: AxiosResponse<IRoom[]>) => response.data),
    {
      ...options,
    },
  )
}

export function useRoom(id: idType, options?: UseQueryOptions<IRoom, Error>) {
  return useQuery<IRoom, Error>(
    [ROOMS_KEY, id],
    () => getRoomByIdApi(id).then((response: AxiosResponse<IRoom>) => response.data),
    {
      ...options,
    },
  )
}
