import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useBranch } from '../../../hooks/query/branches'
import { Card, Descriptions, PageHeader, Skeleton } from 'antd'
import ErrorAlert from '../../core/error-alert/error-alert'
import Head from '../../../hoc/head/head'
import { descriptionsSettings } from '../../../lib/settings'
import BranchStatus from './branch-status/branch-status'
import BranchPhones from './branch-phones/branch-phones'

const { Item } = Descriptions

interface props {}

const Branch: React.FC<props> = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation(['translation', 'branches'])
  const { isLoading, isError, error, data } = useBranch(id)

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const { title, active, address, phones } = data!

  return (
    <Head title={title}>
      <PageHeader
        title={title}
        subTitle={t('branches:branchSubtitle')}
        onBack={() => window.history.back()}
      />
      <Card bordered={false}>
        <Descriptions {...descriptionsSettings} column={{ xs: 1, md: 3 }}>
          <Item label={t('name')}>{title}</Item>
          <Item label={t('address')}>{address}</Item>
          <Item label={t('status')}>
            <BranchStatus status={active} />
          </Item>
          <Item label={t('phoneNumbers')}>
            <BranchPhones phones={phones} />
          </Item>
        </Descriptions>
      </Card>
    </Head>
  )
}

export default Branch
