import React from 'react'
import { IGroup, IStudent, IStudentPayment } from '../../../../interfaces'
import { ColumnsType } from 'antd/es/table'
import MyTable from '../../../core/my-table/my-table'
import { STUDENTS_PAYMENTS_KEY } from '../../../../hooks/query/payments'
import { idType } from '../../../../interfaces/types/types'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'
import { useTranslation } from 'react-i18next'
import StudentLink from '../../../core/student-link/student-link'
import { Link } from 'react-router-dom'
import DateWithMonth from '../../../core/date-with-month/date-with-month'
import StudentPaymentMethod from '../../students/student-payments/student-payment-method/student-payment-method'
import { priceFormatter } from '../../../../lib/priceFormatter'
import { Divider, Space } from 'antd'
import StudentPaymentsDrawer from '../../students/student-payments/student-payments-drawer/student-payments-drawer'
import DeleteModal from '../../../core/delete-modal/delete-modal'
import { deleteStudentPaymentApi } from '../../../../api/payments'
import moment from 'moment'

interface props {
  payments: IStudentPayment[]
}

const PaymentsHistoryTable: React.FC<props> = ({ payments }) => {
  const { t } = useTranslation()

  const columns: ColumnsType<IStudentPayment> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (text: idType) => (
        <React.Fragment>
          <TableMobileTitle title={'ID'} /># {text}
        </React.Fragment>
      ),
    },
    {
      title: t('paymentDate'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('paymentDate')} />
          <DateWithMonth date={text} />
        </>
      ),
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('amount')} />
          <strong>{priceFormatter(text)}</strong>
        </>
      ),
    },
    {
      title: t('student'),
      dataIndex: 'student',
      render: ({ id, name }: IStudent) => (
        <>
          <TableMobileTitle title={t('student')} />
          <StudentLink studentId={id} studentName={name} />
        </>
      ),
    },
    {
      title: t('group'),
      dataIndex: 'group',
      render: ({ id, name }: IGroup) => (
        <>
          <TableMobileTitle title={t('name')} />
          <Link to={`/groups/details/${id}`}>{name}</Link>
        </>
      ),
    },
    {
      title: t('paidFor'),
      dataIndex: 'date',
      render: (text: string) => (
        <>
          <TableMobileTitle title={t('paidFor')} />
          <span>{moment(text).format('MMMM').toUpperCase()}</span>
        </>
      ),
    },
    {
      title: t('paymentMethod'),
      dataIndex: 'method',
      render: (method: string) => (
        <>
          <TableMobileTitle title={t('paymentMethod')} />
          <StudentPaymentMethod method={method} />
        </>
      ),
    },
    {
      title: t('actions'),
      key: 'actions',
      align: 'right' as 'right',
      render: (payment: IStudentPayment) => (
        <Space split={<Divider type={'horizontal'} />}>
          <StudentPaymentsDrawer
            btnStyle={true}
            paymentQueries={[STUDENTS_PAYMENTS_KEY]}
            payment={payment}
            student={{ name: payment.student!.name, id: payment.student_id }}
            groupId={payment.group_id}
          />
          <DeleteModal
            id={payment.id}
            modalText={'teachers:deleteTeacherPayment'}
            deleteFunction={deleteStudentPaymentApi}
            fetchingQueryKey={STUDENTS_PAYMENTS_KEY}
          />
        </Space>
      ),
    },
  ]

  return <MyTable columns={columns} data={payments} fetchingQueryKey={STUDENTS_PAYMENTS_KEY} />
}

export default PaymentsHistoryTable
