import React, { useState } from 'react'
import { Button, Divider, Input, Skeleton, Space, Table, Typography } from 'antd'
import { IStudent, IStudentGroupPivot } from '../../../../interfaces'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import { GROUP_STUDENTS_KEY, useGroupStudents } from '../../../../hooks/query/groups'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PhoneNumber from '../../../core/phone-number/phone-number'
import GroupStudentsDropdown from '../../../core/group-students-dropdown/group-students-dropdown'
import GroupListBalance from '../../../core/group-list/group-list-balance/group-list-balance'
import styles from './group-students.module.scss'
import StudentLink from '../../../core/student-link/student-link'
import StudentStatus from '../../students/student-status/student-status'
import moment from 'moment'
import GroupBulkActions from '../group-bulk-actions/group-bulk-actions'
import { idType } from '../../../../interfaces/types/types'
import StudentIndividualPriceStatus from '../../../core/student-individual-price-status/student-individual-price-status'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import isEqual from 'react-fast-compare'
import { queryClient } from '../../../../index'
import { useMutation } from 'react-query'
import { groupStudentsSortApi } from '../../../../api/groups'
import ErrorAlert from '../../../core/error-alert/error-alert'
import { MenuOutlined, SearchOutlined } from '@ant-design/icons'
import { FilterDropdownProps } from 'antd/es/table/interface'
import DateWithMonth from '../../../core/date-with-month/date-with-month'
import TableMobileTitle from '../../../core/table-mobile-title/table-mobile-title'

interface props {
  groupStartDate: string
  groupEndDate: string
}

const GroupStudents: React.FC<props> = ({ groupStartDate, groupEndDate }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<idType[]>([])
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, isError, error } = useGroupStudents(id)
  const { t } = useTranslation()
  const { mutateAsync: sortStudents } = useMutation(groupStudentsSortApi)

  const { roleChecker } = useRoleChecker()
  const editAccess = roleChecker(['admin', 'reception', 'cashier'])
  const statusAccess = roleChecker(['admin', 'reception', 'teacher', 'accountant', 'cashier'])
  const draggableAccess = roleChecker(['admin', 'teacher'])
  const isStudent = roleChecker(['student'])

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const searchDropdown = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={t('search')}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size={'small'}>
            {t('search')}
          </Button>
          <Button onClick={clearFilters} size={'small'}>
            {t('reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: IStudent) =>
      record.name ? record.name.toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: ({ name, id, phone }: IStudent) => (
      <>
        <TableMobileTitle title={t('student')} />
        <StudentLink studentId={id} studentName={name} />
        {statusAccess && (
          <div>
            <PhoneNumber phone={phone} />
          </div>
        )}
      </>
    ),
  })

  const studentsInvalidateKey = [GROUP_STUDENTS_KEY, id]
  const columns = [
    {
      title: '#',
      key: 'number',
      render: (record: IStudent, student: IStudent, index: any) => <># {index + 1}</>,
    },
    {
      title: t('student'),
      key: 'name',
      ...searchDropdown(),
    },
    {
      title: t('status'),
      key: 'status',
      className: !statusAccess ? 'hide' : '',
      render: ({ group_balance, pivot }: IStudent) => (
        <>
          <TableMobileTitle title={t('status')} />
          <StudentStatus
            balance={group_balance!}
            groupStartDate={groupStartDate}
            added_at={pivot.added_at}
            status={pivot.status}
            excluded_at={pivot.excluded_at}
            transferred_at={pivot.transferred_at}
          />
        </>
      ),
    },
    {
      title: t('groups:addedToGroup'),
      dataIndex: 'pivot',
      render: ({ added_at }: IStudentGroupPivot) => (
        <>
          <TableMobileTitle title={t('groups:addedToGroup')} />
          <DateWithMonth date={added_at} />
        </>
      ),
    },
    {
      title: t('individualPrice'),
      dataIndex: 'pivot',
      className: !statusAccess ? 'hide' : '',
      render: ({ price, free }: IStudentGroupPivot) => (
        <>
          <TableMobileTitle title={t('individualPrice')} />
          <StudentIndividualPriceStatus price={price} free={free} />
        </>
      ),
    },
    {
      title: t('balance'),
      key: 'balance',
      className: !statusAccess ? 'hide' : '',
      render: ({ group_balance }: IStudent) => (
        <>
          <TableMobileTitle title={t('balance')} />
          <GroupListBalance balance={group_balance} />
        </>
      ),
    },
    {
      title: '',
      align: 'right' as 'right',
      className: !editAccess ? 'hide' : '',
      key: 'actions',
      render: ({ id: studentId, name, pivot }: IStudent) => (
        <GroupStudentsDropdown
          studentAddedAt={pivot.added_at}
          groupId={id}
          groupStartDate={groupStartDate}
          groupEndDate={groupEndDate}
          studentStatus={pivot.status}
          student={{ id: studentId, name }}
          individualPrice={pivot.price}
          paymentQueries={[studentsInvalidateKey]}
          transferQueries={[studentsInvalidateKey]}
          deleteStudentQueries={[studentsInvalidateKey]}
          excludeStudentQueries={[studentsInvalidateKey]}
          individualPriceQueries={studentsInvalidateKey}
        />
      ),
    },
  ]

  if (draggableAccess)
    columns.unshift({
      title: '',
      key: 'sort',
      render: () => <MenuOutlined />,
    })

  const tableTitle = () => (
    <div className={styles.title}>
      {editAccess && <GroupBulkActions groupId={id} selectedStudents={selectedRowKeys} />}
      {!isStudent && (
        <Space split={<Divider type={'vertical'} />}>
          <Typography.Title level={5}>
            {t('groups:studentsCount')}:{' '}
            {data?.filter(student => student.pivot.status === 'active').length}
          </Typography.Title>
          <Typography.Title level={5}>
            {t('groups:studentsDebtorsCount')}:{' '}
            {data?.filter(student => student.group_balance! < 0).length}
          </Typography.Title>
        </Space>
      )}
    </div>
  )

  const onSelectItem = (selectedRowKeys: React.ReactText[]) => setSelectedRowKeys(selectedRowKeys)

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return
    }
    if (destination.index === source.index) return

    const queryData = queryClient.getQueryData<IStudent[]>([GROUP_STUDENTS_KEY, id])!
    const items = Array.from(queryData)
    const [reorderedItem] = items.splice(source.index, 1)
    items.splice(destination.index, 0, reorderedItem)

    queryClient.setQueryData([GROUP_STUDENTS_KEY, id], [...items])

    return sortStudents({
      groupId: id,
      students: items.map(({ id }, index) => ({ student_id: id, index })),
    })
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Table
        title={tableTitle}
        className={[styles.table, 'adaptive-table'].join(' ')}
        dataSource={data?.sort(a => (a.pivot.status === 'active' ? -1 : 1))}
        //@ts-ignore
        columns={columns}
        scroll={{ x: true }}
        pagination={false}
        rowKey={'id'}
        rowSelection={{
          selectedRowKeys: selectedRowKeys as React.ReactText[],
          onChange: onSelectItem,
          getCheckboxProps: (record: IStudent) => ({
            disabled: record.pivot.status !== 'active',
          }),
        }}
        components={
          draggableAccess
            ? {
                body: {
                  wrapper: React.memo(
                    (props: any) => {
                      return (
                        <Droppable droppableId={'droppable'}>
                          {provided => (
                            <tbody className={'ant-table-tbody'} ref={provided.innerRef}>
                              {Array.isArray(props.children[1]) &&
                                props.children[1].map((node: any) => node)}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      )
                    },
                    (prevProps, nextProps) => isEqual(prevProps.children, nextProps.children),
                  ),
                  row: React.memo(
                    ({ className, style, ...restProps }: any) => {
                      const index = data!.findIndex(x => x.id === restProps['data-row-key'])
                      const record = data!.find(student => student.id === restProps['data-row-key'])

                      return (
                        <Draggable draggableId={restProps['data-row-key'].toString()} index={index}>
                          {(provided, snapshot) => {
                            return (
                              <tr
                                className={
                                  record?.group_balance! < 0 &&
                                  moment(record?.pivot.added_at) < moment() &&
                                  record?.pivot.status === 'active'
                                    ? styles.debtor
                                    : ''
                                }
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                {...restProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  backgroundColor: snapshot.isDragging ? '#FAFAFA' : 'white',
                                }}
                              />
                            )
                          }}
                        </Draggable>
                      )
                    },
                    (prevProps, nextProps) =>
                      isEqual(prevProps['data-row-key'], nextProps['data-row-key']),
                  ),
                },
              }
            : undefined
        }
      />
    </DragDropContext>
  )
}

export default React.memo(GroupStudents)
