import React from 'react'
import { Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useGroupStudents } from '../../../hooks/query/groups'
import { useParams } from 'react-router-dom'
import { filterOption } from '../../../lib/filterOption'

interface props {
  name?: string
  required?: boolean
  mode?: 'multiple' | 'tags'
  label?: string
}

const FormGroupStudents: React.FC<props> = ({
  name = 'student_id',
  required = true,
  mode,
  label,
}) => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation(['translation', 'groups'])
  const { isFetching, data } = useGroupStudents(id)

  return (
    <Form.Item name={name} rules={[{ required }]} label={label}>
      <Select
        mode={mode}
        placeholder={mode ? t('groups:chooseStudents') : t('chooseStudent')}
        disabled={isFetching}
        loading={isFetching}
        filterOption={filterOption}
        showSearch
      >
        {data?.map(({ id, name }) => (
          <Select.Option key={id} value={id!} label={name}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FormGroupStudents
