import React, { useState } from 'react'
import { Alert, Button, Divider, Form, Input, message, Modal } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { changeUserPassword } from '../../../api/user'
import { useHistory, useLocation } from 'react-router-dom'
import { AUTH_USER_KEY, useAuthUserInformation } from '../../../hooks/query/auth'
import { ACCESS_TOKEN } from '../../../constants/auth'
import PasswordInput from '../form-group/password-input/password-input'
import { queryClient } from '../../../index'

interface props {}

const RestorePasswordModal: React.FC<props> = () => {
  const location = useLocation()
  const { push } = useHistory()
  const { isRedirect }: any = location.state || { isRedirect: undefined }
  const { data } = useAuthUserInformation()
  const [visible, setVisible] = useState<boolean>(isRedirect || false)
  const modalHandler = () => setVisible(!visible)

  const { t } = useTranslation()

  const { isLoading, mutateAsync } = useMutation(changeUserPassword, {
    onSuccess: () => {
      message.success(t('loginAgain'))
      localStorage.removeItem(ACCESS_TOKEN)
      queryClient.removeQueries(AUTH_USER_KEY)
      setVisible(false)
      push({
        pathname: '/auth',
      })
    },
  })

  const onFinish = ({ password }: any) => mutateAsync({ id: data!.id, password })

  return (
    <React.Fragment>
      <Button onClick={modalHandler} type={'primary'} icon={<LockOutlined />}>
        {t('changePassword')}
      </Button>
      <Modal
        visible={visible}
        onCancel={modalHandler}
        title={t('changePassword')}
        okButtonProps={{
          form: 'restore-password-form',
          htmlType: 'submit',
          disabled: isLoading,
          loading: isLoading,
        }}
        okText={t('submit')}
      >
        {isRedirect && (
          <React.Fragment>
            <Alert banner message={t('changePasswordMessage')} type="warning" showIcon={false} />
            <Divider type={'horizontal'} />
          </React.Fragment>
        )}
        <Form id={'restore-password-form'} onFinish={onFinish} layout={'vertical'}>
          <PasswordInput label={t('newPassword')} name={'password'} />
          <Form.Item
            name="confirm"
            label={t('confirmPassword')}
            dependencies={['password']}
            hasFeedback
            rules={[
              { required: true, message: t('confirmPassword') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t('passwordMismatch')))
                },
              }),
            ]}
          >
            <Input.Password
              addonBefore={<LockOutlined className="site-form-item-icon" />}
              placeholder={t('inputPassword')}
            />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default RestorePasswordModal
