import React from 'react'
import { Form, message, Modal } from 'antd'
import BranchesList from '../../../../../core/form-group/branches-list/branches-list'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { editBidApi } from '../../../../../../api/leads'
import { queryClient } from '../../../../../../index'
import { LEADS_CARDS_LIST_KEY, LEADS_KEY } from '../../../../../../hooks/query/leads'
import { IOrderBid } from '../../../../../../interfaces'
import { deleteQueryFields } from '../../../../../../lib/deleteQueryFields'
import CardsList from '../../../../../core/form-group/cards-list/cards-list'
import { serialize } from '../../../../../../lib/serializeQuery'
import { useHistory } from 'react-router-dom'

interface props {
  bid: IOrderBid
  visible: boolean
  setVisible: () => void
}

const LeadsBidTransferModal: React.FC<props> = ({ bid, visible, setVisible }) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { push } = useHistory()
  const transferFormId = 'transfer-form-id'

  const changeBranch = (value: string) => {
    form.resetFields(['card_id'])
    push({ search: serialize('branch_id', value) })
    queryClient.invalidateQueries(LEADS_CARDS_LIST_KEY)
  }

  const { isLoading: isTransferLoading, mutateAsync: transfer } = useMutation(editBidApi, {
    onSuccess: async () => {
      deleteQueryFields('branch_id')
      await queryClient.invalidateQueries(LEADS_KEY)
      message.success(t('responseSuccess'))
      setVisible()
    },
    onError: () => {
      message.error(t('responseError'))
    },
  })

  const transferBid = ({ branch_id, card_id }: any) => transfer({ ...bid, branch_id, card_id })

  return (
    <Modal
      title={t('transferToAnotherBranch')}
      onCancel={setVisible}
      visible={visible}
      okText={t('submit')}
      confirmLoading={isTransferLoading}
      okButtonProps={{ form: transferFormId, htmlType: 'submit' }}
    >
      <Form form={form} id={transferFormId} onFinish={transferBid} layout={'vertical'}>
        <BranchesList onChange={changeBranch} mode={'single'} />
        <CardsList />
      </Form>
    </Modal>
  )
}

export default LeadsBidTransferModal
