import React from 'react'
import { Card, Skeleton, Statistic } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface props {
  title: string
  value: string | number | undefined
  color?: string
  icon: React.FC
  url: string
}

const DashboardCard: React.FC<props> = ({ title, value, color, icon: Icon, url }) => {
  const { t } = useTranslation(['translation', 'dashboard'])

  return (
    <Skeleton active loading={value === undefined}>
      <Link to={'/' + url}>
        <Card bordered={false}>
          <Statistic
            groupSeparator={' '}
            title={t('dashboard:' + title)}
            value={value}
            valueStyle={{ color: !color ? '#3f8600' : color }}
            suffix={<Icon />}
          />
        </Card>
      </Link>
    </Skeleton>
  )
}

export default React.memo(DashboardCard)
