import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ITeacher, Omit } from '../../../../interfaces'
import { Drawer, message } from 'antd'
import DrawerOpen from '../../../core/drawer/drawer-open/drawer-open'
import { drawerSettings } from '../../../../lib/settings'
import DrawerFooter from '../../../core/drawer/drawer-footer/drawer-footer'
import TeachersForm from '../teachers-form/teachers-form'
import { createTeacherApi, updateTeacherApi } from '../../../../api/teachers'
import { useMutation } from 'react-query'
import { queryClient } from '../../../../index'
import { TEACHERS_KEY } from '../../../../hooks/query/teachers'

interface props {
  title: string
  teacher?: Omit<ITeacher, 'groups'>
  updateOne?: boolean
}

const TeachersDrawer: React.FC<props> = ({ teacher, title, updateOne }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const drawerToggle = () => setVisible(!visible)

  const { t } = useTranslation(['translation', 'teachers'])

  const onSuccess = async () => {
    await queryClient.invalidateQueries(
      updateOne ? [TEACHERS_KEY, teacher!.id!.toString()] : TEACHERS_KEY,
    )
    message.success(t('responseSuccess'))
    drawerToggle()
  }
  const onError = (e: any) => {
    message.error(e.response.data.message)
  }
  const { isLoading: isCreateLoading, mutateAsync: create } = useMutation(createTeacherApi, {
    onSuccess,
    onError,
  })
  const { isLoading: isUpdateLoading, mutateAsync: update } = useMutation(updateTeacherApi, {
    onSuccess,
    onError,
  })

  const submitForm = async (fieldsValues: any) => {
    const values: ITeacher = {
      ...fieldsValues,
      birthday: fieldsValues['birthday'] && fieldsValues['birthday'].format('YYYY-MM-DD'),
      status: fieldsValues['status'] || false,
    }

    const handler = teacher ? update : create

    return handler(values)
  }

  return (
    <>
      <DrawerOpen drawerToggle={drawerToggle} edit={teacher} />
      <Drawer
        {...drawerSettings}
        onClose={drawerToggle}
        title={t('teachers:' + title)}
        visible={visible}
        footer={
          <DrawerFooter
            disabled={isCreateLoading || isUpdateLoading}
            drawerToggle={drawerToggle}
            formId={'teacher-form'}
          />
        }
      >
        <TeachersForm item={teacher} formId={'teacher-form'} submitForm={submitForm} />
      </Drawer>
    </>
  )
}

export default React.memo(TeachersDrawer)
