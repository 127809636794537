import React from 'react'
import { Form, Select } from 'antd'
import { useTeachersList } from '../../../hooks/query/teachers'
import { useTranslation } from 'react-i18next'
import { ITeacher } from '../../../interfaces'
import { filterOption } from '../../../lib/filterOption'

interface props {
  name?: string
  required?: boolean
  label?: string
}

const FormTeachersList: React.FC<props> = ({
  name = 'teacher_id',
  required = true,
  label = 'teacher',
}) => {
  const { t } = useTranslation()
  const { isLoading, data } = useTeachersList()

  return (
    <Form.Item
      name={name}
      label={t(label)}
      rules={[
        {
          required,
          message: t('required'),
        },
      ]}
    >
      <Select
        disabled={isLoading}
        loading={isLoading}
        showSearch={true}
        placeholder={t('chooseTeacher')}
        filterOption={filterOption}
        allowClear={!required}
      >
        {data?.map(({ id, name }: ITeacher) => {
          return (
            <Select.Option key={id} value={id!} label={name}>
              {name}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export default FormTeachersList
