import React from 'react'
import { TEACHERS_PAYMENTS_KEY, useTeachersPayments } from '../../../hooks/query/salaries'
import { Divider, PageHeader, Skeleton } from 'antd'
import ErrorAlert from '../../core/error-alert/error-alert'
import Head from '../../../hoc/head/head'
import { useTranslation } from 'react-i18next'
import FiltersCard from '../../core/filters-card/filters-card'
import PaymentsTotal from '../../core/payments-total/payments-total'
import MyPagination from '../../core/my-pagination/my-pagination'
import PaymentsToTeachersTable from './payments-to-teachers-table/payments-to-teachers-table'
import PaymentsToTeachersFilters from './payments-to-teachers-filters/payments-to-teachers-filters'

interface props {}

const PaymentsToTeachers: React.FC<props> = () => {
  const { t } = useTranslation()
  const { isLoading, isError, error, data: response } = useTeachersPayments()

  if (isLoading) return <Skeleton active />
  if (isError) return <ErrorAlert msg={error?.message} />

  const {
    sum,
    salaries: { data, ...pagination },
  } = response!

  return (
    <Head title={t('paymentsToTeachers')}>
      <PageHeader title={t('paymentsToTeachers')} onBack={() => window.history.back()} />
      <FiltersCard>
        <PaymentsToTeachersFilters />
      </FiltersCard>
      <PaymentsTotal sum={sum} totalPayments={pagination.total} />
      <Divider />
      <PaymentsToTeachersTable payments={data} />
      <MyPagination pagination={pagination} fetchingQueryKey={TEACHERS_PAYMENTS_KEY} />
    </Head>
  )
}

export default PaymentsToTeachers
