import React, { useState } from 'react'
import { Col, Form, Input, Row } from 'antd'
import FormTeachersList from '../../../core/form-teachers-list/form-teachers-list'
import { useTranslation } from 'react-i18next'
import { SearchOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { serialize } from '../../../../lib/serializeQuery'
import { queryClient } from '../../../../index'
import queryString from 'query-string'
import FormCoursesList from '../../../core/form-courses-list/form-courses-list'
import FilterButtons from '../../../core/form-group/filter-buttons/filter-buttons'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import FiltersCard from '../../../core/filters-card/filters-card'

interface props {
  fetchingQueryKey: string
  courseFilter: boolean
}

const TestingFilterForm: React.FC<props> = ({ fetchingQueryKey, courseFilter = false }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { push, location } = useHistory()
  const { roleChecker } = useRoleChecker()
  const isAdmin = roleChecker(['admin'])

  const onFinish = async (values: any) => {
    setIsLoading(true)

    push({
      search: serialize(values),
    })

    await queryClient.invalidateQueries(fetchingQueryKey)
    setIsLoading(false)
  }

  const onReset = async () => {
    setIsLoading(true)
    await push({ search: '' })
    form.resetFields()
    await queryClient.invalidateQueries(fetchingQueryKey)
    setIsLoading(false)
  }

  let qs = queryString.parse(location.search)

  return (
    <FiltersCard>
      <Form
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        initialValues={{
          q: qs['q'],
          teacher_id: Number(qs['teacher_id']) || undefined,
          course_id: Number(qs['course_id']) || undefined,
        }}
      >
        <Row gutter={[16, 0]}>
          {isAdmin && (
            <React.Fragment>
              {courseFilter && (
                <Col flex={1}>
                  <FormCoursesList required={false} />
                </Col>
              )}
              <Col flex={1}>
                <FormTeachersList required={false} />
              </Col>
            </React.Fragment>
          )}
          <Col flex={1}>
            <Form.Item label={t('nameSearch')} name={'q'}>
              <Input suffix={<SearchOutlined />} placeholder={t('search')} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <FilterButtons onReset={onReset} isLoading={isLoading} />
          </Col>
        </Row>
      </Form>
    </FiltersCard>
  )
}

export default TestingFilterForm
