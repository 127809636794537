import { request as axios } from './index'
import { idType } from '../interfaces/types/types'

const url = '/questions'

export const getAllQuestionsApi = () => {
  return axios.get(url)
}

export const getQuestionByIdApi = (id: idType) => {
  return axios.get(url + '/' + id)
}

export const createQuestionApi = (data: FormData) => {
  return axios.post(url, data)
}

export const updateQuestionApi = ({ data, id }: { data: FormData; id: idType }) => {
  return axios.post(url + '/' + id, data)
}

export const deleteQuestionApi = (id: idType) => {
  return axios.delete(url + '/' + id)
}
