import React from 'react'
import { Link } from 'react-router-dom'

interface props {
  pathname: string
}

const LinkButton: React.FC<props> = ({ pathname, children }) => {
  return (
    <Link to={pathname} className={'ant-btn ant-btn-primary'}>
      {children}
    </Link>
  )
}

export default LinkButton
