import React from 'react'
import { Button, Divider, Space } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

interface props {
  isLoading: boolean
  onReset: () => void
}

const FilterButtons: React.FC<props> = ({ isLoading, onReset }) => {
  const { t } = useTranslation()
  return (
    <Space split={<Divider type={'vertical'} />}>
      <Button
        disabled={isLoading}
        loading={isLoading}
        icon={<FilterOutlined />}
        type={'primary'}
        htmlType={'submit'}
      >
        {t('filter')}
      </Button>
      <Button disabled={isLoading} type={'default'} onClick={onReset} htmlType={'reset'}>
        {t('reset')}
      </Button>
    </Space>
  )
}

export default FilterButtons
