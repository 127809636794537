import React from 'react'
import { Card, Descriptions } from 'antd'
import { ICourse } from '../../../../interfaces'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CourseType from '../course-type/course-type'
import { priceFormatter } from '../../../../lib/priceFormatter'
import { descriptionsSettings } from '../../../../lib/settings'
import DateWithMonth from '../../../core/date-with-month/date-with-month'

interface props {
  course: ICourse
}

const CourseCard: React.FC<props> = ({ course }) => {
  const { t } = useTranslation(['translation', 'course'])
  const { name, description, lesson_duration, parent, course_type_id, price, created_at } = course

  return (
    <Card bordered={false} title={t('generalInformation')}>
      <Descriptions {...descriptionsSettings} column={1}>
        <Descriptions.Item label={t('name')}>{name}</Descriptions.Item>
        <Descriptions.Item label={t('type')}>
          <CourseType type={course_type_id} />
        </Descriptions.Item>
        <Descriptions.Item label={t('lessonDuration')}>{lesson_duration}</Descriptions.Item>
        <Descriptions.Item label={t('price')}>{priceFormatter(price)}</Descriptions.Item>
        <Descriptions.Item label={t('course:parent')}>
          {parent ? <Link to={`/courses/details/${parent.id}`}>{parent.name}</Link> : t('notFound')}
        </Descriptions.Item>
        <Descriptions.Item label={t('createdAt')}>
          <DateWithMonth date={created_at} />
        </Descriptions.Item>
        <Descriptions.Item label={t('description')}>{description}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default CourseCard
