import React from 'react'
import { Table, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { priceFormatter } from '../../../../../lib/priceFormatter'
import { IFinanceTeacherSalary } from '../../../../../interfaces'
import { Link } from 'react-router-dom'
import isEqual from 'react-fast-compare'
import DeleteModal from '../../../../core/delete-modal/delete-modal'
import { deleteTeacherPaymentApi } from '../../../../../api/payments'
import { idType } from '../../../../../interfaces/types/types'
import { TEACHERS_FINANCES_KEY } from '../../../../../hooks/query/teachers'
import DateWithMonth from '../../../../core/date-with-month/date-with-month'

interface props {
  salaries: IFinanceTeacherSalary[]
  teacherId: idType
}

const FinanceTeacherReport: React.FC<props> = ({ salaries, teacherId }) => {
  const { t } = useTranslation()

  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      render: (text: string) => <span># {text}</span>,
    },
    {
      title: t('firstName'),
      key: 'user',
      render: ({ name, user_id }: IFinanceTeacherSalary) => (
        <Link to={`/profile/details/${user_id}`}>{name}</Link>
      ),
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      render: (text: string) => <span>{priceFormatter(text)}</span>,
    },
    {
      title: t('paymentDate'),
      dataIndex: 'date',
    },
    {
      title: t('createdAt'),
      dataIndex: 'created_at',
      render: (text: string) => <DateWithMonth date={text} />,
    },
    {
      title: t('paymentType'),
      dataIndex: 'type',
      render: (text: string) => <Tag color={'green'}>{t(text)}</Tag>,
    },
    {
      title: t('comment'),
      dataIndex: 'comment',
    },
    {
      title: t('actions'),
      key: 'actions',
      render: ({ id }: IFinanceTeacherSalary) => (
        <DeleteModal
          id={id}
          modalText={'teachers:deleteTeacherPayment'}
          deleteFunction={deleteTeacherPaymentApi}
          fetchingQueryKey={TEACHERS_FINANCES_KEY}
        />
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={salaries}
      pagination={false}
      scroll={{ x: true }}
      rowKey={'id'}
    />
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps.salaries, nextProps.salaries)
}

export default React.memo(FinanceTeacherReport, propsAreEqual)
