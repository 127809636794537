import React from 'react'
import { IGroup } from '../../../../interfaces'
import { Space } from 'antd'
import GroupsDrawer from '../groups-drawer/groups-drawer'
import DeleteModal from '../../../core/delete-modal/delete-modal'
import { deleteGroupApi } from '../../../../api/groups'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'
import { GROUPS_KEY } from '../../../../hooks/query/groups'

interface props {
  group: IGroup
}

const GroupActions: React.FC<props> = ({ group }) => {
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin'])

  return (
    <Space>
      <GroupsDrawer title={'editTitle'} group={group} />
      {access && (
        <DeleteModal
          deleteFunction={deleteGroupApi}
          fetchingQueryKey={GROUPS_KEY}
          modalText={'groups:deleteGroup'}
          confirmationName={group.name}
          id={group.id}
        />
      )}
    </Space>
  )
}

export default React.memo(GroupActions)
