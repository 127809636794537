import React from 'react'
import { Col, Row } from 'antd'
import FinanceTeacherFilterForm from './finance-teacher-filter-form/finance-teacher-filter-form'
import FinanceTeacherFilterStatistic from './finance-teacher-filter-statistic/finance-teacher-filter-statistic'
import { IFinanceTeacherTotal } from '../../../../../interfaces'
import isEqual from 'react-fast-compare'
import { idType } from '../../../../../interfaces/types/types'

interface props {
  teacher_id: idType
  totals: IFinanceTeacherTotal
}

const FinanceTeacherFilter: React.FC<props> = ({ teacher_id, totals }) => {
  return (
    <Row gutter={[32, 32]} align={'middle'} justify={'space-between'}>
      <Col xs={24} lg={7}>
        <FinanceTeacherFilterForm teacher_id={teacher_id} />
      </Col>
      <Col xs={24} lg={17}>
        <FinanceTeacherFilterStatistic totals={totals} />
      </Col>
    </Row>
  )
}

const propsAreEqual = (prevProps: props, nextProps: props) => {
  return isEqual(prevProps, nextProps)
}

export default React.memo(FinanceTeacherFilter, propsAreEqual)
