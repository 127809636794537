import React, { useState } from 'react'
import { Alert, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { removeStudentFromGroupApi, removeStudentsFromGroupApi } from '../../../api/groups'
import { idType } from '../../../interfaces/types/types'
import { DeleteOutlined } from '@ant-design/icons'
import { queryClient } from '../../../index'

interface props {
  groupId: idType
  studentId: idType | idType[]
  btnStyle?: boolean
  deleteStudentQueries: Array<string | string[]>
}

const DeleteStudentFromGroup: React.FC<props> = ({
  btnStyle,
  groupId,
  studentId,
  deleteStudentQueries,
}) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const deleteModalHandler = () => setDeleteModal(!deleteModal)
  const { t } = useTranslation(['translation', 'students', 'groups'])

  const settings = {
    onSuccess: () => {
      const requests = deleteStudentQueries.map(query => queryClient.invalidateQueries(query))
      Promise.all(requests).finally(() => {
        message.success(t('responseSuccess'))
        typeof studentId === 'object' && deleteModalHandler()
      })
    },
    onError: (e: any) => {
      message.error(e.response.data.message)
    },
  }

  const { isLoading: studentRemoveLoading, mutateAsync: removeStudent } = useMutation(
    removeStudentFromGroupApi,
    settings,
  )
  const { isLoading: studentsRemoveLoading, mutateAsync: removeStudents } = useMutation(
    removeStudentsFromGroupApi,
    settings,
  )

  const handleDeleteStudent = () => {
    if (typeof studentId === 'object') return removeStudents({ groupId, ids: studentId })
    else return removeStudent({ studentId, groupId })
  }

  return (
    <React.Fragment>
      <button className={btnStyle ? 'ant-btn' : 'ant-btn-no-style'} onClick={deleteModalHandler}>
        <DeleteOutlined /> <span>{t('groups:removeFromGroup')}</span>
      </button>
      <Modal
        title={t('students:deleteStudentFromGroup')}
        visible={deleteModal}
        onCancel={deleteModalHandler}
        okText={t('yes')}
        confirmLoading={studentRemoveLoading || studentsRemoveLoading}
        centered
        onOk={handleDeleteStudent}
      >
        <Alert
          type={'warning'}
          showIcon={false}
          banner
          message={t('groups:deleteFromGroupMessage')}
        />
      </Modal>
    </React.Fragment>
  )
}

export default DeleteStudentFromGroup
