import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuthUserInformation } from '../hooks/query/auth'
import { useRoleChecker } from '../hooks/useRoleChecker'

interface props {
  path: string
  exact?: boolean
  component: React.FC
  roles?: string[]
  accessRoute?: string
}

const RouteGuard: React.FC<props> = ({
  component: Component,
  roles,
  accessRoute = 'groups',
  ...rest
}) => {
  const { data } = useAuthUserInformation()
  const { roleChecker } = useRoleChecker()

  if (roles && data && !roleChecker(roles)) return <Redirect to={`/${accessRoute}`} />

  return <Route {...rest} render={(props: object) => <Component {...props} />} />
}

export default RouteGuard
