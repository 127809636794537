import React from 'react'
import { DoubleLeftOutlined } from '@ant-design/icons'
import styles from './leads-card-collapse.module.scss'

interface props {
  collapse: boolean
  collapseHandler: any
}

const LeadsCardCollapse: React.FC<props> = ({ collapse, collapseHandler }) => {
  return (
    <div className={styles.wrapper} onClick={collapseHandler}>
      <DoubleLeftOutlined className={collapse ? styles.true : styles.false} />
    </div>
  )
}

export default React.memo(LeadsCardCollapse)
